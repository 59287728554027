import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Popconfirm, Pagination } from "antd";
import { Table, Tag, Space } from "antd";
import { Input, Select, Col, Row } from "antd";
import { getUsers, updateUser, deleteUser } from "../../modules/actions/userAction";
import { getBranch } from "../../modules/actions/branchActions";
import logo from "./../../Images/Logo 2.png";

const { Column, ColumnGroup } = Table;
const { Option } = Select;

const { Search } = Input;

function UserList(props) {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [userActive, setUserActive] = useState("all");
	const [keyword, setKeyWord] = useState("");
	const [searchEnter, setSearchEnter] = useState(false);

	useEffect(() => {
		if (props.permission.branchCode) {
			props.getUsers(props.permission.branchCode, userActive, keyword, page, pageSize);
		}
	}, [userActive, searchEnter, props.permission.branchCode, page, pageSize]);

	const blockUnblock = (a, bool) => {
		let obj = { ...a };
		obj.is_active = bool;
		props.updateUser(obj, props.permission.branchCode, userActive, keyword, page, pageSize);
	};

	const userDelete = (a) => {
		props.deleteUser(a, props.permission.branchCode, userActive, keyword, page, pageSize);
	};

	useEffect(() => {
		props.getBranch(props.permission.branchCode);
	}, [props.permission.branchCode]);

	return (
		<div className="UserList">
			<div className="filter">
				<Row className="spaceBetween">
					<Col xs={14} sm={14} md={14} lg={14} xl={14}>
						<div className="inputDiv">
							<label>
								<h6>Search</h6>
								<Space direction="vertical" className="w100P">
									<Search
										onSearch={() => setSearchEnter(searchEnter ? false : true)}
										onChange={(e) => setKeyWord(e.target.value)}
										placeholder="input search text"
										className="w100P"
										style={{ width: 2 }}
									/>
								</Space>
							</label>
						</div>
					</Col>
					<Col xs={4} sm={4} md={4} lg={4} xl={4}>
						<div className="inputDiv">
							<label>
								<h6>Active</h6>
								<Select
									onChange={(e) => setUserActive(e)}
									defaultValue="All"
									style={{ width: 120 }}
								>
									<Option value="all">ALL</Option>
									<Option value={1}>Active</Option>
									<Option value={0}>Blocked</Option>
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={4} sm={4} md={4} lg={4} xl={4}>
						<div className="inputDiv">
							<label>
								<h6>Type</h6>
								<Select defaultValue="All" style={{ width: 120 }}>
									<Option value="All">All</Option>
									<Option value="Admin">Admin</Option>
									<Option value="Manager">Manager</Option>
									<Option value="User">User</Option>
								</Select>
							</label>
						</div>
					</Col>
				</Row>
			</div>
			<div className="userTableList tableDiv">
				{props.userLoading ? (
					<div className="loaderDiv tableLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : null}
				<Table
					pagination={false}
					className="itemsTable"
					dataSource={props.branches ? props.users : []}
				>
					<Column filterMultiple title="User Name" dataIndex="username" key="username" />
					<Column
						filterMultiple
						title="First Name"
						render={(text, record) => {
							return <div onClick={() => console.log(record)}>{record.first_name}</div>;
						}}
					/>
					<Column title="Last Name" dataIndex="last_name" key="last_name" />
					<Column title="User's Email" dataIndex="email" key="email" />
					<Column
						title="City"
						render={(text, record) => {
							return props.branches && record.userPermission ? (
								<>
									{
										props.branches.filter(
											(a) => a.branchCode === record.userPermission.branchCode
										)[0].branchName
									}
								</>
							) : null;
						}}
					/>
					<Column
						title="Type"
						render={(text, record) => {
							return <>{record.userPermission && record.userPermission.userType}</>;
						}}
					/>
					<Column
						title="Actions"
						render={(text, record) => {
							return (
								<div className="userTableButton">
									{props.userPermissions.user_report_edit && (
										<a
											onClick={() => {
												window.location.href = (`/add-user?id=${record.id}`);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="#001529"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</a>
									)}

									{props.userPermissions.user_report_delete && (
										<Popconfirm
											title="Are you sure to delete this user?"
											okText="Yes"
											cancelText="No"
											onConfirm={() => userDelete(record)}
										>
											<a>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="rgb(245, 0, 87)"
													className="bi bi-trash-fill"
													viewBox="0 0 16 16"
												>
													<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
												</svg>
											</a>
										</Popconfirm>
									)}

									{props.userPermissions.user_report_edit && (
										<Button
											onClick={() => window.location.href = (`/user-permissions?id=${record.id}`)}
											style={{ width: 110 }}
											type="primary"
										>
											Permissions
										</Button>
									)}

									{props.userPermissions.user_report_edit ? (
										record.is_active ? (
											<Button
												onClick={() => blockUnblock(record, false)}
												style={{
													background: "rgb(245, 0, 87)",
													border: "rgb(245, 0, 87)",
													width: 100
												}}
												type="primary"
											>
												BLOCK
											</Button>
										) : (
											<Button
												onClick={() => blockUnblock(record, true)}
												style={{ width: 100 }}
												type="primary"
											>
												UNBLOCK
											</Button>
										)
									) : null}
								</div>
							);
						}}
					/>
				</Table>
				<Table className="itemsTable" dataSource={[{ total: props.total }]} style={{ marginTop: '0' }}>
					<Column title="Total Users" dataIndex="total" key="total" />
					{/* <Column title="Total Advance Amount" dataIndex="advance" key="advance" />
					<Column title="Total Discount Amount" dataIndex="discount" key="discount" />
					<Column title="Total Payment Recieved" dataIndex="paymentRecieved" key="paymentRecieved" /> */}
				</Table>
				<Pagination
					onChange={(ev, size) => {
						setPage(ev);
						setPageSize(size);
					}}
					pageSize={pageSize}
					current={props.page}
					total={props.total}
				/>
			</div>
		</div >
	);
}

const mapStateToProps = (store) => {
	return {
		users: store.users.users,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission,
		branches: store.branchStore.branch,
		userLoading: store.users.userLoading,
		page: store.users.page,
		total: store.users.total
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUsers,
			updateUser,
			deleteUser,
			getBranch
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
