import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import store from '../store';
import { getNotification } from './AccountActions';


// user loader true
function userLoadingBool(bool) {
    return dispatch => {
        dispatch(userLoading(bool));
    };
}
export const USER_LOADING = 'USER_LOADING';
export const userLoading = (bool) => {
    return {
        type: USER_LOADING,
        payload: bool
    }
}

//Get users
export function getUsers(branchCode, userActive, searchText, page, pageSize) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}user/?branchCode=${branchCode}&userActive=${userActive}&searchText=${searchText}&page=${page}&page_size=${pageSize}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            let data = res.data.results ? res.data.results : []
            let page = res.data.page
            let total = res.data.total
            dispatch(getUsersSuccess(data, page, total))
            dispatch(userLoadingBool(false))
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}
export const GET_USERS = 'GET_USERS';
export const getUsersSuccess = (data, page, total) => ({
    type: GET_USERS,
    payload: { data, page, total }
});

//Get single user
export function getSingleUser(id, branchCode) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}user/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getSingleUserSuccess(res.data))
            dispatch(userLoadingBool(false))
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const getSingleUserSuccess = (data) => ({
    type: GET_SINGLE_USER,
    payload: { data }
});

// update user
export function updateUser(obj, branchCode, userActive, searchText, page, pageSize) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}user/${obj.id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("User Updated")
            dispatch(getUsers(branchCode, userActive, searchText, page, pageSize))
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}

// delete user
export function deleteUser(obj, branchCode, userActive, searchText, page, pageSize) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}user/${obj.id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            message.success("User Delete")
            dispatch(getUsers(branchCode, userActive, searchText, page, pageSize))
            dispatch(getNotification())
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}

// add user
export function addUserObj(obj, clearState, userPermissions, branchCode,msg) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}user/?branchCode=${branchCode}`,
            // headers: {
            //     'Authorization': `JWT ${token}`
            // },
            data: obj
        }).then((res) => {
            message.success(msg)
            dispatch(userLoadingBool(false))
            let per = userPermissions
            per.user_id = res.data.id
            clearState()
            dispatch(postUserPermissions(per, branchCode, true))
        }).catch((error) => {
            dispatch(userLoadingBool(false))
            if (error.response) {
                // let arr = [];
                for (const property in error.response.data) {
                    message.error(error.response.data[property]);
                }
            }
        });
    };
}

// update user
export function updateUserObj(obj, goBack, branchCode,userPermissions,id) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}user/${obj.id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("User Updated")
            dispatch(userLoadingBool(false))
            // let per = userPermissions
            // per.user_id = res.data.id
            // clearState()
            dispatch(updateUserPermissions(userPermissions, branchCode,id))
            dispatch(getNotification())
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}

//Get user permissions
export function getUserPermissions(id, branchCode) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(userLoadingBool(false))
            dispatch(getUserPermissionsSuccess(res.data))
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}
export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const getUserPermissionsSuccess = (data) => ({
    type: GET_USER_PERMISSION,
    payload: { data }
});

//Get single user permissions
export function getSingleUserPermissions(id, branchCode) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(userLoadingBool(false))
            dispatch(getSingleUserPermissionsSuccess(res.data))
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}

export const GET_SINGLE_USER_PERMISSION = 'GET_SINGLE_USER_PERMISSION';
export const getSingleUserPermissionsSuccess = (data) => ({
    type: GET_SINGLE_USER_PERMISSION,
    payload: { data }
});

//clear user permissions
export function clearUserPermissions() {
    return dispatch => {
        dispatch(getSingleUserPermissionsSuccess(false))
    };
}

//post user permissions
export function postUserPermissions(obj, branchCode, credNot) {
    return dispatch => {
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        if (credNot) {

            Axios({
                method: 'post',
                url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/?branchCode=${branchCode}`,
                data: obj
            }).then((res) => {
                dispatch(userLoadingBool(false))
                message.success("Successfully added")
            }).catch((res) => {
                dispatch(userLoadingBool(false))
            })
        } else{
            Axios({
                method: 'post',
                url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/?branchCode=${branchCode}`,
                headers: {
                    'Authorization': `JWT ${token}`
                },
                data: obj
            }).then((res) => {
                dispatch(userLoadingBool(false))
                message.success("Successfully added")
            }).catch((res) => {
                dispatch(userLoadingBool(false))
            })
        }
    };
}

//update user permissions
export function updateUserPermissions(obj, branchCode,id) {
    return dispatch => {
        console.log(obj)
        dispatch(userLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            dispatch(userLoadingBool(false))
            message.success("Successfully updated")
        }).catch((res) => {
            dispatch(userLoadingBool(false))
        })
    };
}