import Axios from 'axios'
import { GET_BRANCH, BRANCH_LOADING } from '../actions/branchActions';

const initialState = {
    branchLoading: false,
    branch: false,
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case BRANCH_LOADING:
            return {
                ...state,
                branchLoading: action.payload,
            };
        case GET_BRANCH:
            return {
                ...state,
                branch: action.payload.data.results,
           
            };
      
        default:
            return state
    }
}

