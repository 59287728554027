import { combineReducers } from 'redux'
import customers from './stores/customers'
import FormData from './stores/formDialogue'
import dataEntry from './stores/dataEntry'
import consultation from './stores/consultation'
import reports from './stores/reports'
import summaryReports from './stores/summaryReports'
import authentication from './stores/authentication'
import managerPanel from './stores/managerPanel'
import users from './stores/users'
import customerReports from './stores/customerReports'
import accountStore from './stores/accountStore'
import branchStore from './stores/branchStore'
import FieldsStore from './stores/FieldsStore'
import FormValues from './stores/FormValues'

export default combineReducers({
  customers,
  FormData,
  dataEntry,
  consultation,
  reports,
  authentication,
  managerPanel,
  users,
  customerReports,
  accountStore,
  branchStore,
  summaryReports,
  FieldsStore,
  FormValues,
 
})
