import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, Col, Row, } from 'antd';
import { NavLink } from 'react-router-dom';
import { Table, Tag, Space, Button } from 'antd';
import { Modal } from 'antd';


const { Column, ColumnGroup } = Table;

function AllStone() {

    let data = [
        {
            stoneCode: '1254',
            memberCode: '347852',
            stoneName: 'Diamond',
        },
        {
            stoneCode: '1254',
            memberCode: '347852',
            stoneName: 'Diamond',
        },
        {
            stoneCode: '1254',
            memberCode: '347852',
            stoneName: 'Diamond',
        },
    ]

    // const [isModalVisible, setIsModalVisible] = useState(false);


    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };

    // const handleCancel = () => {
    //     setIsModalVisible(false);
    // };
    const { Option } = Select;
    // const { Panel } = Collapse;
    const { Column, ColumnGroup } = Table;
    const { TextArea } = Input;

    return (
        <div className="diamondGrading labRate">
            <div className="innerDiamondGrading">
                <div className="filter">
                    <Row className="spaceBetween">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="filterButtons">
                                <button className="toFormBtn"
                                // onClick={() => setIsModalVisible(true)}
                                >Add Customer</button>
                                {/* <AddRate isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel} /> */}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Table className="itemsTable" style={{ border: "1px solid #0000001c" }} dataSource={data} >
                        <Column title="Stone Code" dataIndex="stoneCode" key="stoneCode" />
                        <Column title="Member Code" dataIndex="memberCode" key="memberCode" />
                        <Column title="Stone Name" dataIndex="stoneName" key="stoneName" />
                        <Column
                            title="Approve"
                            key="action"
                            render={(text, record) => (
                                <div className="inputFields">
                                <label>
                                    <h5>Is Sold</h5>
                                    <Select className="select w100P" defaultValue="Not Sold" >
                                        <Option value="not sold">Not Sold</Option>
                                        <Option value="sold">Sold</Option>
                                    </Select>
                                </label>
                            </div>
                            )}
                        />
                        <Column
                            title="Approve"
                            key="action"
                            render={(text, record) => (
                                <div className="inputFields">
                                <label>
                                    <h5>Is Hot</h5>
                                    <Select className="select w100P" defaultValue="Not Hot" >
                                        <Option value="not hot">Not Hot</Option>
                                        <Option value="hot">Hot</Option>
                                    </Select>
                                </label>
                            </div>
                            )}
                        />

                        <Column
                            title="Approve"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn DeleteBtn'>Delete Member</button>
                                </Space>
                            )}
                        />

                    </Table>

                </div>
            </div>
        </div>

    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllStone)