import Axios from 'axios'
import {
    ALL_COLOR_FIELDS_DATA,
    ALL_CUT_FIELDS_DATA,
    ALL_Species_FIELDS_DATA,
    ALL_CUTTING_STYLE_FIELDS_DATA,
    ALL_ORIGIN_GEM_FIELDS_DATA,
    ALL_Gridles_FIELDS_DATA,
    ALL_REMART_FORM_FIELDS_DATA,
    ALL_VARIETY_FIELDS_DATA,
    ALL_SHAPE_FIELDS_DATA,
    ALL_OBJECTS__FIELDS_DATA
} from '../actions/FormValues';
const initialState = {
    color: [],
    cuts: [],
    species: [],
    cutting_style: [],
    origion_gem: [],
    gridle3: [],
    remarks: [],
    variety: [],
    shapes: [],
    objects: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ALL_COLOR_FIELDS_DATA:
            return {
                ...state,
                color: action.payload,
            };
        case ALL_CUT_FIELDS_DATA:
            return {
                ...state,
                cuts: action.payload,
            };
        case ALL_Species_FIELDS_DATA:
            return {
                ...state,
                species: action.payload,
            };
        case ALL_CUTTING_STYLE_FIELDS_DATA:
            return {
                ...state,
                cutting_style: action.payload,
            };
        case ALL_ORIGIN_GEM_FIELDS_DATA:
            return {
                ...state,
                origion_gem: action.payload,
            };
        case ALL_Gridles_FIELDS_DATA:
            return {
                ...state,
                gridle3: action.payload,
            };
        case ALL_REMART_FORM_FIELDS_DATA:
            return {
                ...state,
                remarks: action.payload,
            };
        case ALL_VARIETY_FIELDS_DATA:
            return {
                ...state,
                variety: action.payload,
            };
        case ALL_SHAPE_FIELDS_DATA:
            return {
                ...state,
                shapes: action.payload,
            };
        case ALL_OBJECTS__FIELDS_DATA:
            return {
                ...state,
                objects: action.payload,
            };
        default:
            return state
    }
}

