import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Tag, Space, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Column, ColumnGroup } = Table;

function Items({ data, editDelete, editItem, deleteItem, userPermissions }) {
	const confirm = (e, i, record) => {
		let arr = [...data];
		arr.splice(i, 1);
		deleteItem(arr, record);
	};
	const abcArray = [
		"a",
		"b",
		"c",
		"d",
		"e",
		"f",
		"g",
		"h",
		"i",
		"j",
		"k",
		"l",
		"m",
		"n",
		"o",
		"p",
		"q",
		"r",
		"s",
		"t",
		"u",
		"v",
		"w",
		"x",
		"y",
		"z"
	];

	return (
		<Table
			pagination={false}
			className="itemsTable"
			// expandedRowKeys="itemIndex"
			defaultExpandAllRows={true}
			dataSource={data}
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => { } // click row
				};
			}}
			expandable={{
				expandedRowRender: (record, rowIndex) => {
					return (
						<table>
							<thead>
								<th>Stone</th>
								<th>Color</th>
								<th>Quanitity</th>
								<th>Weight</th>
								<th>Seal</th>
								<th>Mine</th>
								<th>urgent</th>
								<th>Rate</th>
								<th></th>
							</thead>
							<tbody>
								{record.stoneArr &&
									record.stoneArr.map((a, i) => {
										return (
											<tr>
												<td>{`${rowIndex + 1}(${abcArray[i]}) ${record.service}(${record.jewelleryItems
													})(${a.selectStone})`}</td>
												<td>{a.color}</td>
												<td>{a.Sub_quantity}</td>
												<td>{a.sub_weight}</td>
												<td>
													{record.sealRate ? (
														<h1>
															<CheckOutlined />
														</h1>
													) : (
														<h1>
															{" "}
															<CloseOutlined />{" "}
														</h1>
													)}
												</td>
												<td>
													{record.mineRate ? (
														<h1>
															<CheckOutlined />
														</h1>
													) : (
														<h1>
															{" "}
															<CloseOutlined />{" "}
														</h1>
													)}
												</td>
												<td>
													{record.urgentRate ? (
														<h1>
															<CheckOutlined />
														</h1>
													) : (
														<h1>
															{" "}
															<CloseOutlined />{" "}
														</h1>
													)}
												</td>
												<td>{a.sub_Rate}</td>
												<td></td>
											</tr>
										);
									})}
							</tbody>
						</table>
					);
				},
				rowExpandable: (record) => record.stoneArr && record.stoneArr.length != 0
			}}
		>
			<Column
				title="Service"
				dataIndex="service"
				key="service"
				render={(text, record, index) => {
					return (
						<>
							{(record.reportObj && record.reportObj.length > 0) ||
								record.consultationDetailColor ||
								record.service == "No Decision" ? (
								<>
									<span onClick={() => console.log(record)}>•</span> {index + 1} {record.service}
								</>
							) : (
								`${index + 1}) ${record.service}`
							)}
						</>
					);
				}}
			/>
			<Column title="Color" dataIndex="color" key="color" />
			<Column title="Quantity" dataIndex="quantity" key="quantity" />
			<Column title="Weight" dataIndex="weight" key="weight" />
			<Column
				title="Seal"
				dataIndex="seal"
				key="seal"
				render={(text, record, index) => {
					return record.sealRate ? (
						<h1>
							<CheckOutlined />
						</h1>
					) : (
						<h1 onClick={() => console.log(record)}>
							{" "}
							<CloseOutlined />{" "}
						</h1>
					);
				}}
			/>
			<Column
				title="Mine"
				dataIndex="mine"
				key="mine"
				render={(text, record, index) => {
					return record.mineRate ? (
						<h1>
							<CheckOutlined />
						</h1>
					) : (
						<h1>
							{" "}
							<CloseOutlined />{" "}
						</h1>
					);
				}}
			/>
			<Column
				title="Urgent"
				dataIndex="urgent"
				key="urgent"
				render={(text, record, index) => {
					return record.urgentRate ? (
						<h1>
							<CheckOutlined />
						</h1>
					) : (
						<h1>
							{" "}
							<CloseOutlined />{" "}
						</h1>
					);
				}}
			/>
			<Column
				title="Rate"
				dataIndex="serviceRate"
				key="rate"
				render={(text, record, index) => {
					let { sealRate, mineRate, urgentRate, goldTestingRate } = record;
					let getServiceRate =
						Number(record.serviceRate) +
						Number(sealRate) +
						Number(mineRate) +
						Number(urgentRate) +
						Number(goldTestingRate);

					return <p>{getServiceRate}</p>;
				}}
			/>
			{editDelete || (userPermissions && userPermissions.userType == "Admin") ? (
				<Column
					title="Action"
					key="action"
					render={(text, record, index) => (
						<Space size="middle">
							<a>
								<EditOutlined onClick={() => editItem(record, index)} />
							</a>
							<Popconfirm
								onConfirm={(e) => confirm(e, index, record)}
								title="Are you sure you want to delete？"
								okText="Yes"
								cancelText="No"
							>
								<a>
									<DeleteOutlined />
								</a>
							</Popconfirm>
						</Space>
					)}
				/>
			) : null}
		</Table>
	);
}

const mapStateToProps = (store) => ({
	userPermissions: store.authentication.userPermissions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Items);
