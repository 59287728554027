import Axios from 'axios'
import { REPORTS_LOADING, GET_RECIEPTS_GENERATED_ENTRIES, GET_DIRECT_CASH, GET_CUSTOMER_SUMMARY_ENTRIES, GET_SERVICES_ENTRIES, GET_SINGLE_CUSTOMER_ENTRIES, GET_ALL_EXPENSE_NAMES, GET_ALL_ACCOUNT_ENTRIES, GET_SELECTED_RECIEPT_NO, GET_CASH_RECIEPTS_GENERATED_ENTRIES} from '../actions/reportsAction';
import { REPORTS_DAY_LOADING, REPORTS_MONTH_LOADING, GET_SUMMARY_LOADING } from '../actions/summaryReportsAction';

const initialState = {
    reportsLoading: false,
    reportsMonthLoading: false,
    reportsDayLoading: false,
    entries: false,
    serviceEntries: false,
    cashRecEntries: false,
    customerSummaryEntries: false,
    singleCustomerEntries: false,
    expenseNames: false,
    allAccountEntries: false,
    summaryObj: false,
    page: 1,
    page2: 1,
    total: 0,
    total2: 0,
    footer: false,
    SummaryLoading: false,
    pageSize: 10,
    SingleEntry: false,
   
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORTS_LOADING:
            console.log(action.payload, 'reportsLoading')
            return {
                ...state,
                reportsLoading: action.payload,
            };
        case REPORTS_MONTH_LOADING:
            return {
                ...state,
                reportsMonthLoading: action.payload,
            }
        case REPORTS_DAY_LOADING:
            return {
                ...state,
                reportsDayLoading: action.payload,
            }
        case GET_RECIEPTS_GENERATED_ENTRIES:
            // console.log("Workinggggggggg 2", action.payload);
            return {
                ...state,
                reportsLoading: false,
                searchInput: false,
                entries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
                footer: action.payload.footer,
            };
        case GET_CASH_RECIEPTS_GENERATED_ENTRIES:
            console.log("Workinggggggggg cash receipt generated entries", action.payload);
            return {
                ...state,
                reportsLoading: false,
                searchInput: false,
                entries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
                footer: action.payload.footer,
            };
            case GET_SELECTED_RECIEPT_NO:
         
                return {
                    ...state,
         
                    SingleEntry: action.payload,
               
                };
        case GET_SERVICES_ENTRIES:
            return {
                ...state,
                reportsLoading: false,
                serviceEntries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
                footer: action.payload.footer,
            };
        case GET_DIRECT_CASH:
            console.log("im workingg again", action.payload.footer)
            return {
                ...state,
                reportsLoading: false,
                cashRecEntries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
                footer: action.payload.footer,
            };
        case GET_CUSTOMER_SUMMARY_ENTRIES:
            return {
                ...state,
                reportsLoading: false,
                customerSummaryEntries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
            };
        case GET_SINGLE_CUSTOMER_ENTRIES:
            return {
                ...state,
                reportsLoading: false,
                singleCustomerEntries: action.payload.data,
                page2: action.payload.page,
                total2: action.payload.total,
                summaryObj: action.payload.summaryObj
            };
        case GET_ALL_ACCOUNT_ENTRIES:
            console.log(action.payload)
            return {
                ...state,
                reportsLoading: false,
                allAccountEntries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
                pageSize: action.payload.pageSize,
                footer: action.payload.footer
            };
        case GET_ALL_EXPENSE_NAMES:
            return {
                ...state,
                expenseNames: action.payload.data,
            };

        case GET_SUMMARY_LOADING:
            console.log(action.payload)
            return {
                ...state,
                SummaryLoading: action.payload,
            };

        default:
            return state
    }
}

