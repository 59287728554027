import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, Button, Col, Row, Table, Space, Select } from "antd";
import {
	addUserObj,
	getSingleUser,
	getSingleUserPermissions,
	updateUserObj
} from "../../../modules/actions/userAction";
import { getBranch } from "../../../modules/actions/branchActions";
const { Column, ColumnGroup } = Table;

function AddUser(props) {
	const [userName, setUserName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [city, setCity] = useState("");
	const [userType, setUserType] = useState("Admin");

	const [edit, setEdit] = useState(false);

	const userAdd = () => {
		let obj = {
			email: email,
			first_name: firstName,
			last_name: lastName,
			username: userName,
			password: password,
			is_superuser: true,
			is_staff: true,
			is_active: true
		};
		let userPermissions = {};
		if (userType === "Admin") {
			userPermissions = props.userStore.adminPermissions;
			userPermissions.branchCode = city;
			userPermissions.userType = userType;
		}
		if (userType === "Manager") {
			userPermissions = props.userStore.managerPermissions;
			userPermissions.branchCode = city;
			userPermissions.userType = userType;
		}
		if (userType === "User") {
			userPermissions = props.userStore.userPermissions;
			userPermissions.branchCode = city;
			userPermissions.userType = userType;
		}
		props.addUserObj(obj, clearState, userPermissions, props.permission.branchCode, "User Added");
	};

	const clearState = () => {
		setUserName("");
		setFirstName("");
		setLastName("");
		setEmail("");
		setPassword("");
		setPassword2("");
		setCity("");
	};

	useEffect(() => {
		props.getBranch(props.permission.branchCode);
	}, [props.permission.branchCode]);

	useEffect(() => {
		if (props.permission.branchCode) {
			var url = new URL(window.location);
			let id = url.searchParams.get("id");
			if (id) {
				props.getSingleUserPermissions(id, props.permission.branchCode);
				props.getSingleUser(id, props.permission.branchCode);
			} else {
				clearState();
			}
		}
	}, [props.permission.branchCode]);

	useEffect(() => {
		if (props.userStore.singleUser && props.singleUserPermission) {
			let user = props.userStore.singleUser;
			setEdit(true);
			setFirstName(user.first_name);
			setLastName(user.last_name);
			setUserName(user.username);
			setEmail(user.email);
			setCity(props.singleUserPermission.branchCode);
		}
	}, [props.userStore.singleUser, props.singleUserPermission]);

	const updateUser = () => {
		var url = new URL(window.location);
		let id = url.searchParams.get("id");
		let obj = {
			email: email,
			first_name: firstName,
			last_name: lastName,
			username: userName,
			password: props.userStore.singleUser.password,
			is_superuser: true,
			id: props.userStore.singleUser.id,
			is_staff: true
		};
		let userPermissions = {};
		if (userType === "Admin") {
			userPermissions = props.userStore.adminPermissions;
			userPermissions.branchCode = city;
			userPermissions.userType = userType;
			userPermissions.user_id = userPermissions.user_id ? userPermissions.user_id : id;
		}
		if (userType === "Manager") {
			userPermissions = props.userStore.managerPermissions;
			userPermissions.branchCode = city;
			userPermissions.user_id = userPermissions.user_id ? userPermissions.user_id : id;
		}
		if (userType === "User") {
			userPermissions = props.userStore.userPermissions;
			userPermissions.branchCode = city;
			userPermissions.user_id = userPermissions.user_id ? userPermissions.user_id : id;
		}
		props.updateUserObj(obj, goBack, props.permission.branchCode,userPermissions,id);
	};

	const goBack = () => {
		props.history.goBack();
	};
	useEffect(() => {
		var url = new URL(window.location);
		let id = url.searchParams.get("id");
		if (!id) {
			clearState();
		}
	}, []);
	return (
		<div className="addUser">
			<div className="innerAddUser">
				<Row className="centerAlign">
					<Col xs={10} sm={10} md={10} lg={10} xl={10}>
						<div className="addUserForm">
							<Row className="spaceBetween">
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<div className="inputFields">
										<label>
											<h5>Username</h5>
											<Input onChange={(e) => setUserName(e.target.value)} value={userName} />
										</label>
									</div>
								</Col>
								<Col xs={11} sm={11} md={11} lg={11} xl={11}>
									<div className="inputFields">
										<label>
											<h5>First Name</h5>
											<Input onChange={(e) => setFirstName(e.target.value)} value={firstName} />
										</label>
									</div>
								</Col>
								<Col xs={11} sm={11} md={11} lg={11} xl={11}>
									<div className="inputFields">
										<label>
											<h5>Last Name</h5>
											<Input onChange={(e) => setLastName(e.target.value)} value={lastName} />
										</label>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<div className="inputFields">
										<label>
											<h5>Email</h5>
											<Input onChange={(e) => setEmail(e.target.value)} value={email} />
										</label>
									</div>
								</Col>

								{!edit && (
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<div className="inputFields">
											<label>
												<h5>Password</h5>
												<Input
													onChange={(e) => setPassword(e.target.value)}
													value={password}
													type="password"
												/>
											</label>
										</div>
									</Col>
								)}
								{!edit && (
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<div className="inputFields">
											<label>
												<h5>Confirm Password</h5>
												<Input
													onChange={(e) => setPassword2(e.target.value)}
													value={password2}
													type="password"
												/>
											</label>
										</div>
									</Col>
								)}

								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<div className="inputFields">
										<label>
											<h5>Select Branch</h5>
											<Select style={{ width: "100%" }} onChange={(e) => setCity(e)} value={city}>
												{props.branch.map((a, i) => {
													return (
														<Select.Option key={i} value={a.branchCode}>
															{a.branchName}
														</Select.Option>
													);
												})}
											</Select>
										</label>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<div className="inputFields">
										<label>
											<h5>User Type</h5>
											<Select
												disabled={edit}
												style={{ width: "100%" }}
												onChange={(e) => setUserType(e)}
												value={userType}
											>
												<Select.Option value="Admin">Admin</Select.Option>
												<Select.Option value="Manager">Manager</Select.Option>
												<Select.Option value="User">User</Select.Option>
											</Select>
										</label>
									</div>
								</Col>
							</Row>
						</div>
						{edit ? (
							<Button onClick={updateUser} style={{ marginTop: 10 }} type="primary">
								UPDATE USER
							</Button>
						) : (
							<Button
								onClick={userAdd}
								style={{ marginTop: 10 }}
								type="primary"
								disabled={props.userLoading}
							>
								{" "}
								{props.userLoading ? <div className="loading1 reportLoder"></div> : "ADD USER"}{" "}
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		branch: store.branchStore.branch ? store.branchStore.branch : [],
		userStore: store.users,
		permission: store.users.permission,
		singleUserPermission: store.users.singleUserPermission,
		userLoading: store.users.userLoading
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addUserObj,
			getBranch,
			getSingleUser,
			getSingleUserPermissions,
			updateUserObj
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
