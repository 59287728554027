import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, Select, Col, Row, Button, Divider, message } from "antd";
import {
	createImageFile,
	getSingleReportSuccess,
	setCertificate,
	updateCertificate
} from "../../../modules/actions/managerPanelActions";
import { getSingleItem, getSingleReport, getSingleItemConsultation, getSingleItemSuccess } from "../../../modules/actions/dataEntryActions";
import { dateFormat } from "../../../common/common";
import upload from "./../../../Images/upload.png";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getColorFormDataAdd } from "../../../modules/actions/FormValues";
// import { color } from "html2canvas/dist/types/css/types/color";

const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function SmallJewelleryForm(props) {
	const [obj, setobj] = useState({
		branchCode: props.permission.branchCode,
		relatedEntryNo: "",
		itemId: "",
		Id_of_temp_data: "",
		serial_number: "",
		certificate_number: "",
		color_gem: "",
		weight: "",
		length_gem: "",
		width_gem: "",
		depth_gem: "",
		description: "",
		comments_gem: "",
		species_gem: "",
		variety_gem: "",
		picture_id: "",
		cart_id: "",
		path: "",
		ext: "",
		Fpath: "",
		columnname: "",
		path2: "",
		ext3: "",
		bpath: "",
		customername: "",
		treatment: "",
		Customer_Number: "",
		eptyp_temp: "",
		Number_Of_Diamonds: "",
		Total_Dia_Weight: "",
		Shap_And_Cut: "",
		DiamondCut: "",
		Diamond_Color: "",
		number: "",
		filename: "",
		date: dateFormat(new Date()),
		shape: "",
		cut: "",
		measurement_mm: "",
		measurements: "",
		measurements2: "",
		measurements3: "",
		measurement_unit: "",
		depth: "",
		table: "",
		gridle: "",
		gridle2: "",
		gridle3: "",
		crown_angle: "",
		pavilion_depth: "",
		culet: "",
		polish: "",
		symmetry: "",
		flourescence: "",
		flourescenceColor: "",
		carat_weight: "",
		weight_unit: "",
		color: "",
		clarity: "",
		cut_grade: "",
		entry_date: dateFormat(new Date()),
		comment: "",
		key_to_symbol: "",
		object: "",
		transparency: "",
		species: "",
		variety: "",
		report_id: 3,
		report_page: "",
		remarks: "",
		finish: "",
		diamond1: "",
		diamond2: "",
		diamond3: "",
		diamond4: "",
		diamond5: "",
		key_symbol1: "",
		key_symbol2: "",
		key_symbol3: "",
		key_symbol4: "",
		key_symbol5: "",
		key_symbol6: "",
		key_symbol7: "",
		key_symbol8: "",
		key_symbol9: "",
		key_symbol10: "",
		key_symbol11: "",
		key_symbol12: "",
		key_symbol13: "",
		key_symbol14: "",
		key_symbol15: "",
		key_symbol16: "",
		key_symbol17: "",
		key_symbol18: "",
		disabled_key_symbol: "",
		origion_gem: "",
		pavilion_angle_d: "",
		color_grade: "",
		clarity_grade: "",
		Enhancements: "",
		RI: "",
		RI1: "",
		Polariscope: "",
		uv_flourescence: "",
		micro_evidence: "",
		cutting_style: "",
		microscope: "",
		FTIR: "",
		UV: "",
		Refractometer: "",
		Lamp: "",
		XRF: "",
		uv_flourescence1: ""
	});

	const [runOn, setRunOn] = useState(false);

	const [isReported, setIsReported] = useState(false);

	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const [image, setImage] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [formEditId, setFormEditId] = useState();

	function handleChange(e) {
		setImageUrl(e.target.files[0]);
		getBase64(e.target.files[0]).then((data) => {
			setImage(data);
		});
	}

	useEffect(() => {

		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let id = url.searchParams.get("id");
		let editId = url.searchParams.get("editId");
		let consultation = url.searchParams.get("consultation");

		if (editId) {
			setFormEditId(editId);
			setIsEdit(true);
			props.getSingleReport(editId, props.permission.branchCode);
		} else {
			let objj = { ...obj };
			objj.relatedEntryNo = entryNo;
			objj.itemId = Number(id);
			objj.entry_date = dateFormat(new Date());
			objj.date = dateFormat(new Date());
			if (consultation === '1') {
				props.getSingleItemConsultation(id, props.permission.branchCode);
			} else {
				props.getSingleItem(id, props.permission.branchCode);
			}
			setobj(objj);
		}
	}, []);

	useEffect(() => {
		if (props.singleItemEntry) {
			var url = new URL(window.location);
			let objj = obj;
			objj.carat_weight = Number(props.singleItemEntry.weight);
			objj.color = props.singleItemEntry.color;
			if (props.singleItemEntry.report && props.singleItemEntry.report.length) {
				setIsReported(true);
			} else {
				setIsReported(false);
			}
			setRunOn(runOn ? false : true);
			setobj(objj);
		}
		if (props.singleReportEntry) {
			console.log(props.singleReportEntry);
			if (props.singleReportEntry.relatedEntryNo) {
				console.log(props.singleReportEntry);
				console.log("no old entry no");
				setobj(props.singleReportEntry);
			} else {
				console.log(props.singleReportEntry);
				props.singleReportEntry.relatedEntryNo = "oldData";
				setobj(props.singleReportEntry);
			}
		}
	}, [props.singleItemEntry, props.singleReportEntry]);

	function backFun() {
		props.history.goBack();
	}

	const save = () => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let data = new FormData();
		for (const property in obj) {
			if (obj[property]) {
				data.append(property, obj[property]);
			} else if (property === "relatedEntryNo" && !obj[property] && entryNo) {
				data.append(property, entryNo);
			}
		}
		let entryId = url.searchParams.get("id");
		data.append("itemId", Number(entryId));
		data.append("product_pic", imageUrl);
		props.setCertificate(data, props.permission.branchCode, clearState, backFun);
	};
	const update = () => {
		let data = new FormData();
		let imageFile = [];
		console.log(obj);
		for (const property in obj) {
			if ( property === "product_pic") {
				if(obj[property]){

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);
						
						data.append(property, result);
					});
				}
				else{
					
					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);
						
						data.append(property, result);
					});
				}				
			}
			 
			else if (property.toLowerCase().match("fpath")) {
				data.append(property, '')
				// if(obj[property]){

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }
				// else{

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }				// data.set(property, "");
			}
			else if (obj[property]) {
				data.append(property, obj[property]);
			}
		}

		props.updateCertificate(data, props.permission.branchCode, clearState, obj.id, backFun);
	};
	console.log(props.permission.branchCode, "branchC");

	const clearState = () => {
		let objj = obj;
		for (let keys in objj) {
			objj[keys] = "";
		}
		setobj(objj);
		setRunOn(runOn ? false : true);
	};

	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Shape is already exist')
			} else {
			console.log("addItem");
			setColorArr([...colorArr, { name: colorName }]);
			props.getColorFormDataAdd({ name: colorName })
			setColorName(false);
			}
		}
	};
	useEffect(() => {
		if (props.fields) {
			// setCutArr(props.fields.cuts)
			// setShapeArr(props.fields.shapes)
			setColorArr(props.fields.color)
			// setSpeciesArr(props.fields.species)
			// setObjectsArr(props.fields.objects)
			// setVarietyArr(props.fields.variety)
			// setOriginArr(props.fields.origion_gem)
		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])

	return (
		<div className="DiamondGradingFrom SmallJewelleryForm">
			{!isReported ? (
				<div className="innerGradingForm">
					<Row className="spaceBetween">
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>Date</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.date = e.target.value;
											setobj(obj2);
										}}
										value={dateFormat(obj.date ? obj.date : obj.entry_date)}
										type="date"
									/>
								</label>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>Number Of Diamonds</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.Number_Of_Diamonds = e.target.value;
											setobj(obj2);
										}}
										value={obj.Number_Of_Diamonds}
									/>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div
							className="inputDiv"
							style={{ display: "flex", alignItems: "flex-end" }}
						>
								<label>
									<h6>Total Dia Weight</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.Total_Dia_Weight = e.target.value;
											setobj(obj2);
										}}
										value={obj.Total_Dia_Weight}
									/>
								</label>
								
							<Select
								style={{ width: 100 }}
								onChange={(e) => {
									let obj2 = { ...obj };
									obj2.weight_unit = e;
									setobj(obj2);
								}}
								className="select"
								defaultValue="CT"
								value={obj.weight_unit}
							>
								<Option value="CT">Carats</Option>
								<Option value="Gm">Grams</Option>
							</Select>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>Color</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.color = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.color}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={colorName ? colorName : ""}
														onChange={onColorChange}
													/>
													{colorName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addColorOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{colorArr.map((a, i) => {
											return <Option value={a.name}>{a.name}</Option>;
										})}
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>Clarity</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.clarity = e.target.value;
											setobj(obj2);
										}}
										value={obj.clarity}
									/>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>
										Description <span className="maxWord">(Maximum characters: 220)</span>
									</h6>
									<TextArea
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.description = e.target.value;
											setobj(obj2);
										}}
										placeholder="Controlled autosize"
										autoSize={{ minRows: 3, maxRows: 5 }}
										value={obj.description}
									/>
								</label>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<label>
									<h6>Product Image</h6>
									<div className="imageBox">
										<div
											className="box"
											style={{
												backgroundImage: `url('${
													image
														? image
														: obj.fpath
														? obj.fpath
														: obj.product_pic
														? obj.product_pic
														: upload
												}')`
											}}
										></div>
										<Input onChange={(e) => handleChange(e)} type="file" placeholder="upload" />
									</div>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
					</Row>
					{isEdit ? (
						<Button onClick={() => update()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div class="loading1"></div> : "Update"}
						</Button>
					) : (
						<Button onClick={() => save()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div class="loading1"></div> : "Save"}
						</Button>
					)}

					<Button
						onClick={() => props.history.goBack()}
						type="primary"
						style={{ margin: "0px 10px" }}
					>
						Cancel
					</Button>
				</div>
			) : (
				<h2>Already Delivered</h2>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	singleItemEntry: store.dataEntry.singleItemEntry,
	singleReportEntry: store.dataEntry.singleReportEntry,
	managerPanelLoading: store.managerPanel.managerPanelLoading,
	fields: store.FormValues

});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCertificate,
			getSingleItem,
			updateCertificate,
			getSingleReport,
			getSingleItemConsultation,
			getColorFormDataAdd,
			getSingleItemSuccess,
getSingleReportSuccess,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SmallJewelleryForm);
