import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

export const ModalReport = (props) => {
	let { data, entries, modalType } = props;

	const [totalAmount, setTotalAmount] = useState(0);
	const [allData, setAllData] = useState({})
	const [allEntries, setAllEntries] = useState([])

	useEffect(() => {
		if (data) {
			console.log(data)
			setAllData(data)
		}
		if (entries) {
			setAllEntries(entries)
		}
	}, [data])

	useEffect(() => {
		console.log("heloooo entries", allData)
		if (allEntries && allEntries.length) {
		
			let t = 0;
			allEntries.map((a) => {
				let { sealRate, mineRate, urgentRate, goldTestingRate } = a;
				let getServiceRate =
					Number(a.serviceRate) +
					Number(sealRate) +
					Number(mineRate) +
					Number(urgentRate) +
					Number(goldTestingRate);

				t += Number(getServiceRate);
			});
			setTotalAmount(t);
		} else if (allEntries && !allEntries.length) {
			setTotalAmount(0);
		}
	}, [allEntries]);



	const expenseCalculate = (arr) => {
		let Amount = 0;
		if (arr.length) {
			arr.map((a, i) => {
				Amount += Number(a.expenseAmount);
			});
		}
		return Amount;
	};

	return (
		// <div className="itemReportModal">
		<Modal
			title="Details"
			className="itemReportModal"
			visible={props.isModalVisible}
			onOk={props.handleOk}
			onCancel={props.handleCancel}
		>
			<div className="modalBodyItem">
				{modalType === 'delivery' ?
					<>
						<div className="detail">
							<div className="entryDetail">
								<table>
									<tbody>
										<tr>
											<th onClick={() => { }}>Entered By:</th>
											<td>{allData.userName}</td>
										</tr>
										{allData.deliveryObj ? (
											<tr>
												<th>Delivered By:</th>
												<td>{allData.userName}</td>
											</tr>
										) : null}
										<tr>
											<th>{allData.selectBoxOrPacket}</th>
											<td>{allData.boxNo}</td>
										</tr>
									</tbody>
								</table>
								<div></div>
							</div>
							<div className="statusDetail">
								<div className="entryDiv">
									<h3 onClick={() => console.log(allData)}>Entry Onss</h3>
									{allData.date ?
										<>
											<p>{new Date(allData.date).toLocaleString().split(",")[0]}</p>
											<p>{new Date(allData.date).toLocaleString().split(",")[1]}</p>
										</>
										:
										<>
											{allData.entryDate}
										</>
									}
								</div>
								{allData.deliveryObj ? (
									<div className="deliveryDiv">
										<h3>Delivered</h3>
										<p>{new Date(allData.deliveryObj.deliveryDateStr).toLocaleString().split(",")[0]}</p>
										<p>{new Date(allData.deliveryObj.deliveryDateStr).toLocaleString().split(",")[1]}</p>
									</div>
								) : null}
							</div>
						</div>
						<div className="customerDetail">
							<h3>Customer Details:</h3>
							<table>
								<tbody>
									<tr>
										<th>Name</th>
										<th>ID</th>
										<th>Entry No</th>
										<th>Amount</th>
										<th>Req Discount</th>
										<th>Discount Reason</th>
									</tr>
									<tr>
										<td>{allData.customerName}</td>
										<td>{allData.customerId}</td>
										<td>{allData.entryNo}</td>
										<td>{totalAmount}</td>
										<td>{allData.discountAmount}</td>
										<td>{allData.discountReason}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="itemsDetail">
							<h3>Items Details:</h3>
							<table>
								<tbody>
									<tr>
										<th>S.No</th>
										<th>Service</th>
										<th>Color</th>
										<th>Qty</th>
										<th>Weight</th>
										<th>Seal</th>
										<th>Mine</th>
										<th>Urgent</th>
										<th>Gold Test</th>
										<th>Amount</th>
									</tr>
									{allEntries &&
										allEntries.map((a, i) => {
											let { sealRate, mineRate, urgentRate, goldTestingRate } = a;
											let getServiceRate =
												Number(a.serviceRate) +
												Number(sealRate) +
												Number(mineRate) +
												Number(urgentRate) +
												Number(goldTestingRate);

											return (
												<tr>
													<td>{`${i + 1}).`}</td>
													<td>{a.reportObj ||
														a.consultationDetailColor ||
														a.service == "No Decision" ? (
														<>
															<span onClick={() => console.log(a)}>•</span> {a.service}
														</>
													) : (
														` ${a.service}`
													)}</td>
													<td>{a.color}</td>
													<td>{a.quantity}</td>
													<td>{a.weight}</td>
													<td>{a.sealRate}</td>
													<td>{a.mineRate}</td>
													<td>{a.urgentRate}</td>
													<td>{a.goldTestingRate}</td>
													<td>{Number(getServiceRate)}</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
						<div className="totalSection">
							<div className="commentBox">
								<h6>Customer Comment</h6>
								<p>{allData.customerComments}</p>
								{/* <p>dsadas dasd sa dasd as sa dasd asd a as ds dasdas asdasasd</p> */}
							</div>
							<div>
								<table>
									<tbody>
										<tr>
											<th>Total Amount:</th>
											<td>{totalAmount}</td>
										</tr>
										<tr>
											<th>Advance:</th>
											<td>{allData.advance}</td>
										</tr>
										{allData.expenses && allData.expenses.length > 0 ? (
											<tr>
												<th>
													Expenses
													{allData.expenses &&
														allData.expenses.map((a) => (
															<span className="reasonTh">({a.expenseName})</span>
														))}{" "}
													:
												</th>
												<th>{expenseCalculate(allData.expenses)}</th>
											</tr>
										) : null}
										<tr>
											<th>Payment Recieved:</th>
											<td>{allData.deliveryObj ? allData.deliveryObj.paymentRecieved : null}</td>
										</tr>
										<tr style={{ borderTop: "1px solid black" }}>
											<th>Balance:</th>
											<td>
												{totalAmount + (allData.expenses ? expenseCalculate(allData.expenses) : 0) -
													(allData.deliveryObj ? allData.deliveryObj.paymentRecieved : 0) -
													allData.advance}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</>
					: modalType === 'membership' ?
						<>
							<div className="customerDetail">
								<h3 onClick={() => {
									console.log(allData, 'allData')
								}}>Customer Details:</h3>
								<div className="detailMembership">
									<div>
										<p><b>Name: </b>{allData.customerObj ? allData.customerObj.customerName : ''}</p>
										<p><b>ID: </b>{allData.customerObj ? allData.customerObj.customerId : ''}</p>
									</div>
									<div>
										<p><b>Membership Starts From: </b>{allData.entryDate}</p>
										<p><b>Membership Starts End: </b>{allData.endDate}</p>
									</div>
								</div>
								<table>
									<tbody>
										<tr>
											{/* <th>Name</th>
											<th>ID</th> */}
											<th>Entry No</th>
											<th>Amount</th>
											<th>Req Discount</th>
											<th>Payment</th>
											<th>Discount Reason</th>
										</tr>
										<tr>
											{/* <td>{allData.customerObj ? allData.customerObj.customerName : ''}</td>
											<td>{allData.customerObj ? allData.customerObj.customerId : ''}</td> */}
											<td>{allData.entryNo}</td>
											<td>{allData.membershipRate}</td>
											<td>{allData.discountAmount}</td>
											<td>{allData.membershipPayment}</td>
											<td>{allData.discountReason}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</>
						: modalType === 'directCash' ?
							<>
								<div className="customerDetail">
									<h3 onClick={() => {
										console.log(allData, 'allData')
									}}>Customer Details:</h3>
									<div className="detailMembership">
										<div>
											<p><b>Name: </b>{allData.customerObj ? allData.customerObj.customerName : ''}</p>
											<p><b>ID: </b>{allData.customerObj ? allData.customerObj.customerId : ''}</p>
										</div>
										<div>
											<p><b>Cash Recieved On: </b>{allData.date}</p>
										</div>
									</div>
									<table>
										<tbody>
											<tr>
												{/* <th>Name</th>
											<th>ID</th> */}
												<th>Entry No</th>
												<th>Amount</th>
											</tr>
											<tr>
												{/* <td>{allData.customerObj ? allData.customerObj.customerName : ''}</td>
											<td>{allData.customerObj ? allData.customerObj.customerId : ''}</td> */}
												<td>{allData.entryNo}</td>
												<td>{allData.CashReceive}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
							: null}
			</div>
		</Modal>
		// </div>/
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReport);
