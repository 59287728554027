import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Tag, Space, Button, DatePicker, Col, Row, Select } from "antd";
import TotalModal from "./TotalModal";
import ExpenseModal from "./ExpenseModal";
import RecievableModal from "./RecievableModal";
import NetIncomModal from "./NetIncomModal";
import MemberShip from "./membership";
import { EditOutlined, DeleteOutlined, FileProtectOutlined } from "@ant-design/icons";
import SummaryDetailModal from "./SummaryDetailModal";
import SummaryEducationModal from "./SummaryEducationModal";
import {
	get5yearReport,
	getMonthSummaryReport,
	getSummaryReport
} from "../../../modules/actions/summaryReportsAction";
import EduCollectionModal from "./EduCollectionModal";
import moment from "moment";
import logo from "./../../../Images/Logo 2.png";
import { getBranch } from "../../../modules/actions/branchActions";
import { AiOutlineEye } from 'react-icons/ai'

const { Column, ColumnGroup } = Table;
const { Option } = Select;
function SummaryReport(props) {
	const [selectedBranch, setSelectedBranch] = useState("KHI");

	useEffect(() => {
		if (props.userPermissions.userType != "Admin" && props.userPermissions.branchCode) {
			setSelectedBranch(props.userPermissions.branchCode);
		} else {
			setSelectedBranch("KHI");
		}
	}, [props.userPermissions.branchCode]);

	useEffect(() => {
		props.getBranch(props.permission.branchCode);
	}, [props.permission.branchCode]);

	const [isModal1Visible, setIsModal1Visible] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	useEffect(() => {
		console.log(startDate, 'testingg')
		console.log(endDate, 'testingg')
	}, [startDate,
		endDate])

	const showModal1 = () => {
		setIsModal1Visible(true);
	};

	const handleOk1 = () => {
		setIsModal1Visible(false);
	};

	const handleCancel1 = () => {
		setIsModal1Visible(false);
	};

	const [isModal2Visible, setIsModal2Visible] = useState(false);

	const showModal2 = () => {
		setIsModal2Visible(true);
	};

	const handleOk2 = () => {
		setIsModal2Visible(false);
	};

	const handleCancel2 = () => {
		setIsModal2Visible(false);
	};

	const [isModal3Visible, setIsModal3Visible] = useState(false);

	const showModal3 = () => {
		setIsModal3Visible(true);
	};

	const handleOk3 = () => {
		setIsModal3Visible(false);
	};

	const handleCancel3 = () => {
		setIsModal3Visible(false);
	};

	const [isModal4Visible, setIsModal4Visible] = useState(false);

	const showModal4 = () => {
		setIsModal4Visible(true);
	};

	const handleOk4 = () => {
		setIsModal4Visible(false);
	};

	const handleCancel4 = () => {
		setIsModal4Visible(false);
	};

	const [isModal5Visible, setIsModal5Visible] = useState(false);

	const showModal5 = () => {
		setIsModal5Visible(true);
	};

	const handleOk5 = () => {
		setIsModal5Visible(false);
	};

	const handleCancel5 = () => {
		setIsModal5Visible(false);
	};

	const [laboratoryModal, setLaboratoryModal] = useState(false);

	const showModalLab = () => {
		setLaboratoryModal(true);
	};

	const [educationModal, setEducationModal] = useState(false);

	const showModalEduc = () => {
		setEducationModal(true);
	};
	const [categorie, setCategorie] = useState("Laboratory");

	useEffect(() => {


		props.getSummaryReport(
			startDate ? new Date(startDate).toISOString().split("T")[0] : new Date().toISOString().split("T")[0],
			// '2022-03-24',
			endDate ? new Date(endDate).toISOString().split("T")[0] : new Date().toISOString().split("T")[0],
			// '2022-03-24',
			categorie,
			selectedBranch
		);
	}, [startDate, endDate, categorie, selectedBranch]);

	useEffect(() => {
		props.get5yearReport(selectedBranch, new Date().getFullYear(), 1)
	}, [selectedBranch])


	let data = [
		{
			year: 2017,
			laboratory: 0,
			education: 0,
			earn: 0
		},
		{
			year: 2018,
			laboratory: 5,
			education: 10,
			earn: 1000
		},
		{
			year: 2019,
			laboratory: 5,
			education: 10,
			earn: 1500
		},

		{
			year: 2020,
			laboratory: 10,
			education: 15,
			earn: 2000
		},
		{
			year: 2021,
			laboratory: 20,
			education: 25,
			earn: 2500
		}
	];
	const [fiveYearData, setFiveYearData] = useState([]);
	const [fiveYears, setFiveYears] = useState([]);
	const [fiveYearsArr, setFiveYearsArr] = useState("");
	const [months, setMonths] = useState([
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	]);
	// const [record, setRecord] = useState()
	// useEffect(() => {
	// 	var dt = new Date();

	// 	// var month = dt.getMonth() + 1;
	// 	let arr = [];
	// 	fiveYearData.map((a, i) => {
	// 		let monthDays = [];
	// 		let year = a.year;
	// 		a.months.map((b, j) => {
	// 			let indexMonth = months.indexOf(b);
	// 			var daysInMonth = new Date(year, indexMonth + 1, 0).getDate();
	// 			let days = [];
	// 			for (let i = 0; i < daysInMonth; i++) {
	// 				days.push(i + 1);
	// 			}
	// 			monthDays.push({ month: b, days: days, monthIndex: i + 1, year: year });
	// 		});
	// 		arr.push({ ...a, monthDays });
	// 	});
	// 	setFiveYears(arr);
	// }, [fiveYearData]);

	// useEffect(() => {
	// 	let currentYear = new Date().getFullYear();
	// 	let arr = [];
	// 	for (let i = 0; i < 5; i++) {
	// 		let year = currentYear - i;
	// 		arr.push({
	// 			year: year,
	// 			laboratory: 10,
	// 			education: 15,
	// 			earn: 2000,
	// 			months: months,
	// 			leapYear: check_leapyear(year)
	// 		});
	// 	}
	// 	setFiveYearData(arr);
	// }, [months]);

	function check_leapyear(year) {
		if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
			return true;
		} else {
			return false;
		}
	}

	const [dataYear, setDataYear] = useState("");

	const setData = (record) => {
		if (record) {

			props.getMonthSummaryReport("month", record.year, selectedBranch);
			setDataYear(record.year);
		}
	};
	const [montDataArr, setMonthDataArr] = useState([]);
	const [collectionData, setCollectionData] = useState({});
	useEffect(() => {
		// console.log(props.fiveYearData,"props.fiveYearData")
		// setFiveYearsArr(props.fiveYearData.last5yearTotal);
		setMonthDataArr(props.MonthData);
		setCollectionData(props.TotalLaboratoryCollection);
		console.log(props.Year1Data)
	}, [props]);


	useEffect(() => {
		console.log(collectionData, 'collectionDatacollectionData')

	}, [collectionData])

	const [totalAmount, setTotalAmount] = useState(0)
	useEffect(() => {
		let arr = []
		if (props.TotalExpenses && props.TotalExpenses.length) {
			// console.log(props.TotalExpenses, 'TotalExpenseTotalExpense')
			let totalAmountNumber = 0
			props.TotalExpenses.map((a, i) => {
				if (props.userPermissions.userType != "Admin") {
					if (a.private === "false") {
						// setTotalAmount()
						// console.log(a, 'TotalExpenseTotalExpense')
						totalAmountNumber += Number(a.amount)
						arr.push(a)
					}
				} else if (props.userPermissions.userType === "Admin") {
					// if (a.private === "false") {
					totalAmountNumber += Number(a.amount)
					arr.push(a)
					// }
				}
			})
			// setUpdatedTotalExpense(arr)
			setTotalAmount(totalAmountNumber)
		}
	}, [props.TotalExpenses, props.userPermissions])


	return (
		<div className="summaryReport">
			<div className="filterDiv">
				{props.userPermissions['summary_report_view'] && props.userPermissions.userType == "Admin" ?
					<Row className="spaceBetween">
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="inputFields">
								<Space direction="vertical">
									<DatePicker
										onChange={(ev, date) => setStartDate(date)}
										defaultValue={moment(new Date())}
									// value={moment(new Date())}
									/>
								</Space>
							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="inputFields">
								<Space direction="vertical">
									<DatePicker
										onChange={(ev, date) => setEndDate(date)}
										defaultValue={moment(new Date())}
									// value={moment(new Date())}
									/>
								</Space>
							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="inputFields">
								<Space direction="vertical">
									<Select defaultValue="Labortory" onChange={(ev) => setCategorie(ev)}>
										<Option value="Laboratory">Laboratory</Option>
										<Option value="education">Education</Option>
									</Select>
								</Space>
							</div>
						</Col>
						{props.userPermissions.admin_panel && (
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<Select
									defaultValue={selectedBranch}
									onChange={(e) => setSelectedBranch(e)}
									className="select w100P"
								>
									{props.branch
										? props.branch.map((a, i) => {
											return <Option value={a.branchCode}>{a.branchName}</Option>;
										})
										: null}
								</Select>
							</Col>
						)}
					</Row>
					: null}
			</div>
			<div className="fiveYearsReport">
				{props.userPermissions['summary_report_view'] && props.userPermissions.userType === "Admin" ?
					<>
						<h4>Last Five Years Report</h4>
						<div className="table tableDiv">
							{/* {props.Year1DataLoader ? (
						<div className="loaderDiv tableLoader">
							<div>
								<img src={logo} />
							</div>
						</div>
					) : null} */}
							{/* <Table
						className="itemsTable"
						dataSource={fiveYearsArr}
						onRow={(record, rowIndex) => {
							return {
								onClick: (event) => { },
								onDoubleClick: (event) => { },
								onContextMenu: (event) => { },
								onMouseEnter: (event) => { },
								onMouseLeave: (event) => { }
							};
						}}
					>
						<Column title="Year" dataIndex="year" key="year" />
						<Column
							title="Laboratory"
							dataIndex="totalLaboratory"
							key="totalLaboratory"
							render={(text, record, index) => {
								return (
									<div
										onClick={() => {
											showModalLab();
											setData(record);
										}}
									>
										{record.totalLaboratory}
									</div>
								);
							}}
						/>
						<Column
							title="Education"
							dataIndex="totalEducation"
							key="totalEducation"
							render={(text, record, index) => {
								return (
									<div
										onClick={() => {
											showModalEduc();
											setData(record);
										}}
									>
										{record.totalEducation}
									</div>
								);
							}}
						/>
						<Column title="Profit/Loss" dataIndex="profitLoss" key="profitLoss" />
					</Table> */}

							<table className="itemsTable">
								<thead>
									<tr>
										<th>Year</th>
										<th>Laboratory</th>
										<th>Education</th>
										<th>Profit/Loss</th>
									</tr>
								</thead>
								<tbody>
									<tr >
										<td ><div className="yearDiv">{props.Year1Data.year ? props.Year1Data.year : new Date().getFullYear()}</div></td>
										<td >{props.Year1DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalLab();
													setData(props.Year1Data);
												}}
											>
												{props.Year1Data.totalLaboratory}
											</div>


										}</td>
										<td >{props.Year1DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalEduc();
													setData(props.Year1Data);
												}}
											>
												{props.Year1Data.totalEducation}
											</div>
										}</td>
										<td >{props.Year1DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											: props.Year1Data.profitLoss}</td>
									</tr>
									<tr >
										<td ><div className="yearDiv">{props.Year2Data.year ? props.Year2Data.year : new Date().getFullYear() - 1}
											{!props.Year2Data ?
												<button onClick={() => {
													props.get5yearReport(selectedBranch, new Date().getFullYear() - 1, 2)
												}}>View Data</button>
												:
												null
											}</div></td>
										<td >{props.Year2DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalLab();
													setData(props.Year2Data);
												}}
											>
												{props.Year2Data.totalLaboratory ? props.Year2Data.totalLaboratory : `******`}
											</div>
										}
										</td>
										<td >{props.Year2DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalEduc();
													setData(props.Year2Data);
												}}
											>
												{props.Year2Data.totalEducation ? props.Year2Data.totalEducation : `******`}
											</div>
										}</td>
										<td >{props.Year2DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											: props.Year2Data.profitLoss ? props.Year2Data.profitLoss : "******"}</td>
										{/* <td ></td> */}
									</tr>
									<tr >
										<td ><div className="yearDiv">{props.Year3Data.year ? props.Year3Data.year : new Date().getFullYear() - 2}
											{!props.Year3Data ?
												<button onClick={() => {
													props.get5yearReport(selectedBranch, new Date().getFullYear() - 2, 3)
												}}>View Data</button>
												:
												null
											}</div></td>
										<td >{props.Year3DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalLab();
													setData(props.Year3Data);
												}}
											>
												{props.Year3Data.totalLaboratory ? props.Year3Data.totalLaboratory : `******`}
											</div>

										}</td>
										<td >{props.Year3DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalEduc();
													setData(props.Year3Data);
												}}
											>
												{props.Year3Data.totalEducation ? props.Year3Data.totalEducation : `******`}
											</div>
										}</td>
										<td >{props.Year3DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											: props.Year3Data.profitLoss ? props.Year3Data.profitLoss : "******"}</td>
										{/* <td ></td> */}
									</tr>
									<tr >
										<td ><div className="yearDiv">{props.Year4Data.year ? props.Year4Data.year : new Date().getFullYear() - 3}
											{!props.Year4Data ?
												<button onClick={() => {
													props.get5yearReport(selectedBranch, new Date().getFullYear() - 3, 4)
												}}>View Data</button>
												:
												null
											}</div></td>
										<td >{props.Year4DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalLab();
													setData(props.Year4Data);
												}}
											>
												{props.Year4Data.totalLaboratory ? props.Year4Data.totalLaboratory : `******`}
											</div>
										}
										</td>
										<td >{props.Year4DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalEduc();
													setData(props.Year4Data);
												}}
											>
												{props.Year4Data.totalEducation ? props.Year4Data.totalEducation : `******`}
											</div>
										}</td>
										<td >{props.Year4DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											: props.Year4Data.profitLoss ? props.Year4Data.profitLoss : "******"}</td>

									</tr>
									<tr >
										<td ><div className="yearDiv">{props.Year5Data.year ? props.Year5Data.year : new Date().getFullYear() - 4}
											{!props.Year5Data ?
												<button onClick={() => {
													props.get5yearReport(selectedBranch, new Date().getFullYear() - 4, 5)
												}}>View Data</button>
												:
												null
											}</div></td>
										<td >{props.Year5DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalLab();
													setData(props.Year5Data);
												}}
											>
												{props.Year5Data.totalLaboratory ? props.Year5Data.totalLaboratory : `******`}
											</div>
										}</td>
										<td >{props.Year5DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											:
											<div
												onClick={() => {
													showModalEduc();
													setData(props.Year5Data);
												}}
											>
												{props.Year5Data.totalEducation ? props.Year5Data.totalEducation : `******`}
											</div>
										}</td>
										<td >{props.Year5DataLoader ?
											<>
												<div class="snippet" data-title=".dot-pulse">
													<div class="stage">
														<div class="dot-pulse"></div>
													</div>
												</div>
											</>
											: props.Year5Data.profitLoss ? props.Year5Data.profitLoss : "******"}</td>
									</tr>
								</tbody >
							</table >
						</div >
					</>
					: null}
				<div className="reportsSec">
					<h4>Reports</h4>
					<Row className="spaceBetween">
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="reportsDiv">
								<div className="modalButton" onClick={showModal1}>
									<div className="iconDiv">
										{props.SummaryLoading ?
											<div className="loaderDiv" style={{ position: 'unset' }}>
												<div>
													<img src={logo} style={{ width: "80px", position: 'unset' }} />
												</div>
											</div>
											:
											<>
												<FileProtectOutlined />
											</>
										}
									</div>
									{categorie === "Laboratory" ? (
										<p>
											TOTAL COLLECTION ({collectionData ? collectionData.TotalCashRecieved : "0"}) +
											ADVANCE ({collectionData ? collectionData.TotalAdvanceRecieved : "0"})
										</p>
									) : (
										<p>
											TOTAL COLLECTION (
											{collectionData ? collectionData.TotalEducationalColletionAmt : "0"})
										</p>
									)}
								</div>

								{categorie === "Laboratory" ? (
									<TotalModal
										TotalCollection={collectionData ? collectionData : {}}
										isModalVisible={isModal1Visible}
										setIsModalVisible={setIsModal1Visible}
										showModal={showModal1}
										handleOk={handleOk1}
										handleCancel={handleCancel1}
									/>
								) : (
									<EduCollectionModal
										TotalCollection={
											props.TotalEducationalColletion ? props.TotalEducationalColletion : []
										}
										collectionData={collectionData}
										isModalVisible={isModal1Visible}
										setIsModalVisible={setIsModal1Visible}
										showModal={showModal1}
										handleOk={handleOk1}
										handleCancel={handleCancel1}
									/>
								)}
							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="reportsDiv">
								<div className="modalButton" onClick={showModal2}>
									<div className="iconDiv">
										{props.SummaryLoading ?
											<div className="loaderDiv" style={{ position: 'unset' }}>
												<div>
													<img src={logo} style={{ width: "80px", position: 'unset' }} />
												</div>
											</div>
											:
											<>
												<FileProtectOutlined />
											</>
										}
									</div>
									<p>TOTAL EXPENSES ({props.userPermissions.userType === "Admin" ? collectionData ? collectionData.TotalExpenses : "0" : totalAmount})</p>
								</div>
								<ExpenseModal
									TotalExpense={props.TotalExpenses}
									collectionData={collectionData}
									isModalVisible={isModal2Visible}
									setIsModalVisible={setIsModal2Visible}
									showModal={showModal2}
									handleOk={handleOk2}
									handleCancel={handleCancel2}
								/>

							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="reportsDiv">
								<div className="modalButton" onClick={showModal3}>
									<div className="iconDiv">
										{props.SummaryLoading ?
											<div className="loaderDiv" style={{ position: 'unset' }}>
												<div>
													<img src={logo} style={{ width: "80px", position: 'unset' }} />
												</div>
											</div>
											:
											<>
												<FileProtectOutlined />
											</>
										}
									</div>
									<p>TOTAL RECIEVABLES ({collectionData ? collectionData.TotalRecievable : "0"})</p>
								</div>
								<RecievableModal
									TotalRecievable={props.TotalRecievable}
									collectionData={collectionData}
									isModalVisible={isModal3Visible}
									setIsModalVisible={setIsModal3Visible}
									showModal={showModal3}
									handleOk={handleOk3}
									handleCancel={handleCancel3}
								/>
							</div>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<div className="reportsDiv">
								<div className="modalButton" onClick={showModal4}>
									<div className="iconDiv">
										{props.SummaryLoading ?
											<div className="loaderDiv" style={{ position: 'unset' }}>
												<div>
													<img src={logo} style={{ width: "80px", position: 'unset' }} />
												</div>
											</div>
											:
											<>
												<FileProtectOutlined />
											</>
										}
									</div>
									<p>NET INCOME ({Number(collectionData.TotalCashRecieved) +
										Number(collectionData.TotalAdvanceRecieved) +
										Number(collectionData.TotalMemberShipCollection) -
										Number(collectionData.TotalExpenses)})</p>
								</div>
								<NetIncomModal
									collectionData={collectionData}
									isModalVisible={isModal4Visible}
									setIsModalVisible={setIsModal4Visible}
									showModal={showModal4}
									handleOk={handleOk4}
									handleCancel={handleCancel4}
								/>
							</div>
						</Col>
						{categorie === "Laboratory" ? (
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<div className="reportsDiv">
									<div className="modalButton" onClick={showModal5}>
										<div className="iconDiv">
											{props.SummaryLoading ?
												<div className="loaderDiv" style={{ position: 'unset' }}>
													<div>
														<img src={logo} style={{ width: "80px", position: 'unset' }} />
													</div>
												</div>
												:
												<>
													<FileProtectOutlined />
												</>
											}
										</div>
										<p>TOTAL MEMBERSHIP COLLECTIONS ({collectionData ? collectionData.TotalMemberShipCollection : "0"})</p>
									</div>
									<MemberShip
										TotalMembershipCollection={props.TotalMembershipCollection}
										collectionData={collectionData}
										isModalVisible={isModal5Visible}
										setIsModalVisible={setIsModal5Visible}
										showModal={showModal5}
										handleOk={handleOk5}
										handleCancel={handleCancel5}
									/>
								</div>
							</Col>
						) : null}
					</Row>
					{props.reportsMonthLoading ? (
						<div className="loaderDiv">
							<div>
								<img src={logo} />
							</div>
						</div>
					) : (
						<SummaryEducationModal
							educationModal={educationModal}
							setEducationModal={setEducationModal}
							showModalEduc={showModalEduc}
							record={montDataArr}
							dataYear={dataYear}
							selectedBranch={selectedBranch}
						/>
					)}

					{props.reportsMonthLoading ? (
						<div className="loaderDiv">
							<div>
								<img src={logo} />
							</div>
						</div>
					) : (
						<SummaryDetailModal
							laboratoryModal={laboratoryModal}
							setLaboratoryModal={setLaboratoryModal}
							showModalLab={showModalLab}
							record={montDataArr}
							dataYear={dataYear}
							reportsLoading={props.reportsMonthLoading}
							selectedBranch={selectedBranch}
							history={props.history}
						/>
					)}
				</div>
			</div >
		</div >
	);
}

const mapStateToProps = (store) => ({
	TotalLaboratoryCollection: store.summaryReports.TotalLaboratoryCollection,
	last5yearTotal: store.summaryReports.last5yearTotal,
	MonthData: store.summaryReports.MonthData,
	TotalEducationalColletion: store.summaryReports.TotalEducationalColletion,
	TotalExpenses: store.summaryReports.TotalExpenses,
	TotalMembershipCollection: store.summaryReports.TotalMembershipCollection,
	TotalRecievable: store.summaryReports.TotalRecievable,
	Year1DataLoader: store.summaryReports.Year1DataLoader,
	Year1Data: store.summaryReports.Year1Data,
	Year2Data: store.summaryReports.Year2Data,
	Year3Data: store.summaryReports.Year3Data,
	Year4Data: store.summaryReports.Year4Data,
	Year5Data: store.summaryReports.Year5Data,
	permission: store.users.permission,
	reportsLoading: store.reports.reportsLoading,
	SummaryLoading: store.reports.SummaryLoading,
	reportsMonthLoading: store.reports.reportsMonthLoading,
	userPermissions: store.authentication.userPermissions,
	branch: store.branchStore.branch,
	Year1DataLoader: store.summaryReports.Year1DataLoader,
	Year2DataLoader: store.summaryReports.Year2DataLoader,
	Year3DataLoader: store.summaryReports.Year3DataLoader,
	Year4DataLoader: store.summaryReports.Year4DataLoader,
	Year5DataLoader: store.summaryReports.Year5DataLoader,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getSummaryReport,
			getMonthSummaryReport,
			getBranch,
			get5yearReport
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SummaryReport);
