import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Input,
	Select,
	Col,
	Row,
	Upload,
	message,
	InputNumber,
	Checkbox,
	Divider,
	Button
} from "antd";
import upload from "./../../../Images/upload.png";
import { addAppraisalReport, updateAppraisalReport } from "../../../modules/actions/reportsAction";
import {
	createMultipleImageFile,
	deleteAppraisalColorStone,
	deleteAppraisalDiamond,
	deleteAppraisalItem,
	getCertificatesSuccess,
	getSepAppraisalCertificates
} from "../../../modules/actions/managerPanelActions";
import logo from "./../../../Images/Logo 2.png";
import Axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { dateFormat } from "../../../common/common";
import { getColorFormDataAdd } from "../../../modules/actions/FormValues";

const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function AppraisalCertificateForm(props) {
	// let index = 0;
	const [itemArrOpt, setItemArrOpt] = useState([
		"Items",
		"Ring",
		"Earings",
		"Bracelet",
		"Necklace",
		"Nosepin",
		"Pendent"
		// "Other",
	]);
	const [name, setName] = useState(false);

	const onNameChange = (event) => {
		setName(event.target.value);
	};

	const addItemOpt = () => {
		if (name) {
			setItemArrOpt([...itemArrOpt, name]);
			setName(false);
		}
	};

	const [metalArrOpt, setMetalArrOpt] = useState([
		"Metal",
		"Gold",
		"Silver",
		"Platinum",
		"Pladium"
		// "Other",
	]);
	const [metalName, setMetalName] = useState(false);

	const onMetalChange = (event) => {
		setMetalName(event.target.value);
	};

	const addMetalOpt = () => {
		setMetalArrOpt([...metalArrOpt, metalName]);
		setMetalName(false);
	};

	const [stoneArrOpt, setStoneArrOpt] = useState([
		"Agate (Aqeeq)",
		"Apatite Cats Eye",
		"Aquamarine",
		"Bloodstone",
		"Chrysoberyl Cats Eye",
		"Citrine",
		"Coral",
		"Diamond",
		"Diopside",
		"Demantoid",
		"Emerald",
		"Epidot",
		"Fluorite",
		"Garnet",
		"Heliodor (Golden Beryl)",
		"Hessonite (Goumedak)",
		"Idocrase",
		"Iolite",
		"Morganite",
		"Nephrite Jade",
		"Opal",
		"Prehnite",
		"Rose Quartz",
		"Ruby",
		"Rutile Quartz",
		"Scapolite",
		"Scapolite Cats Eye",
		"Spinel",
		"Sunstone",
		"Tanzanite",
		"Tigers Eye",
		"Topaz",
		"Tsavorite",
		"Zircon",
		"Jadiete Jade",
		"Jasper",
		"Jet",
		"Malachite",
		"Moonstone",
		"Kunzite",
		"Combine",
		"Agate (Aqeeq)",
		"Alixanderite",
		"Amazonite",
		"Amber",
		"Amethyst",
		"Ammolite",
		"Ametrine",
		"Andalusite",
		"Annyolite",
		"Aragonite",
		"Aventurine Quartz",
		"Bixbite",
		"Calcite",
		"Carnelian",
		"Chalcedony (Aqeeq)",
		"Chrysocolla",
		"Chrysoprase",
		"Copal (Sundulus)",
		"Goshenite",
		"Hydrogrossular Garnet",
		"Indicolite",
		"Kornerupine",
		"Kyanite",
		"Labradorite",
		"Lapis Lazuli",
		"Peridot",
		"Sphene",
		"Tourmaline",
		"Turquoise"
	]);
	const [stoneName, setStoneName] = useState(false);

	const onStoneChange = (event) => {
		setStoneName(event.target.value);
	};

	const addStoneOpt = () => {
		setStoneArrOpt([...stoneArrOpt, stoneName]);
		setStoneName(false);
	};

	useEffect(() => {
		// props.getCertificatesSuccess(false)
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("editId");
		if (entryNo) {
			props.getSepAppraisalCertificates(entryNo);
		}
	}, []);

	function backFun() {
		props.history.goBack();
	}



	const [obj, setObj] = useState({
		certificate_no: "",
		image_1: "",
		image_2: "",
		image_3: "",
		date: dateFormat(new Date()),
		object: "",
		Companyname: "",
		weight: "",
		uploaded_by: "",
		status: "",
		added_on: "",
		appriasalItemArr: []
	});

	const [itemArr, setItemArr] = useState([
		{
			item: "",
			item_other: "",
			metal: "Shape",
			metal_other: "",
			weight: "",
			karatage: "",
			appriasalItemDiamondsArr: [
				{
					no_of_diamonds: "",
					weight: "",
					color1: "",
					color2: "",
					clarity1: "",
					clarity2: "",
					legend: "",
					legend_comment: ""
				}
			],
			appriasalItemColorStoneArr: [
				{
					stone_name: "",
					no_of_gems: "",
					stone_other_item: "",
					weight: "",
					legend: "",
					legend_comment: ""
				}
			]
		}
	]);

	const addItem = () => {
		let arr = [];
		if (itemArr) {
			arr = [...itemArr];
		}
		let objj = {
			item: "",
			item_other: "",
			metal: "Shape",
			metal_other: "",
			weight: "",
			karatage: "",
			appriasalItemDiamondsArr: [
				{
					no_of_diamonds: "",
					weight: "",
					color1: "",
					color2: "",
					clarity1: "",
					clarity2: "",
					legend: "",
					legend_comment: ""
				}
			],
			appriasalItemColorStoneArr: [
				{
					stone_name: "",
					no_of_gems: "",
					stone_other_item: "",
					weight: "",
					legend: "",
					legend_comment: ""
				}
			]
		};
		arr.push(objj);
		setItemArr(arr);
	};

	const removeItem = (i, id) => {
		let arr = [...itemArr];
		arr.splice(i, 1);
		setItemArr(arr);
		if (id) {
			props.deleteAppraisalItem(id);
		}
	};

	const addDiamondFeild = (itemIndex) => {
		let items = [...itemArr];
		let item = itemArr[itemIndex];
		let diamondArr = [...item.appriasalItemDiamondsArr];
		let objj = {
			no_of_diamonds: "",
			weight: "",
			color1: "",
			color2: "",
			clarity1: "",
			clarity2: "",
			legend: "",
			legend_comment: ""
		};
		diamondArr.push(objj);
		item.appriasalItemDiamondsArr = diamondArr;
		items.splice(itemIndex, 1, item);
		setItemArr(items);
	};

	const removeDiamondFeild = (itemIndex, diamondIndex, id) => {
		let items = [...itemArr];
		let item = itemArr[itemIndex];
		let diamondArr = [...item.appriasalItemDiamondsArr];
		diamondArr.splice(diamondIndex, 1);
		item.appriasalItemDiamondsArr = diamondArr;
		items.splice(itemIndex, 1, item);
		setItemArr(items);
		if (id) {
			props.deleteAppraisalDiamond(id);
		}
	};

	const addStoneFeild = (itemIndex) => {
		let items = [...itemArr];
		let item = itemArr[itemIndex];
		let diamondArr = [...item.appriasalItemColorStoneArr];
		let objj = {
			stone_name: "",
			no_of_gems: "",
			stone_other_item: "",
			weight: "",
			legend: "",
			legend_comment: ""
		};
		diamondArr.push(objj);
		item.appriasalItemColorStoneArr = diamondArr;
		items.splice(itemIndex, 1, item);
		setItemArr(items);
	};

	const removeStoneFeild = (itemIndex, diamondIndex, id) => {
		let items = [...itemArr];
		let item = itemArr[itemIndex];
		let colorStoneArr = [...item.appriasalItemColorStoneArr];
		colorStoneArr.splice(diamondIndex, 1);
		item.appriasalItemColorStoneArr = colorStoneArr;
		items.splice(itemIndex, 1, item);
		setItemArr(items);
		if (id) {
			props.deleteAppraisalColorStone(id);
		}
	};

	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	const [image1Url, setImage1Url] = useState("");
	const [image2Url, setImage2Url] = useState("");
	const [image3Url, setImage3Url] = useState("");

	function handleChange(e, name) {
		if (name === "image1") {
			setImage1Url(e.target.files[0]);
		}
		if (name === "image2") {
			setImage2Url(e.target.files[0]);
		}
		if (name === "image3") {
			setImage3Url(e.target.files[0]);
		}
		getBase64(e.target.files[0]).then((data) => {
			if (name === "image1") {
				setImage1(data);
			}
			if (name === "image2") {
				setImage2(data);
			}
			if (name === "image3") {
				setImage3(data);
			}
		});
	}

	const save = () => {
		let objj = obj;
		var url = new URL(window.location);
		let entryId = url.searchParams.get("id");
		let relatedEntryNo = url.searchParams.get("entryNo");
		let entryNo = url.searchParams.get("editId");
		if (entryNo) {
			let items = itemArr;
			objj.appriasalItemArr = items;
			obj.image_1 = image1Url ? image1Url : obj.image_1 ? obj.image_1 : "";
			obj.image_2 = image2Url ? image2Url : obj.image_2 ? obj.image_2 : "";
			obj.image_3 = image3Url ? image3Url : obj.image_3 ? obj.image_3 : "";

			let formData = new FormData();
			for (let key in objj) {
				if (objj[key]) {
					if (key === "appriasalItemArr") {
						formData.append(key, JSON.stringify(objj[key]));
					} else {
						formData.append(key, objj[key]);
					}
				} else {
					formData.append(key, "");
				}

			}
			console.log(obj)
			props.updateAppraisalReport(formData, entryNo, backFun);
		} else {
			let items = itemArr;
			objj.appriasalItemArr = items;
			objj.image_1 = image1Url;
			objj.image_2 = image2Url;
			objj.image_3 = image3Url;
			objj.uploaded_by = props.user.username;

			let formData = new FormData();
			for (let key in objj) {
				if (objj[key]) {
					if (key === "appriasalItemArr") {
						formData.append(key, JSON.stringify(objj[key]));
					} else {
						formData.append(key, objj[key]);
					}
				}
			}
			formData.append("itemId", Number(entryId));
			formData.append("relatedEntryNo", relatedEntryNo);
			// relatedEntryNo
			props.addAppraisalReport(formData, backFun);
		}
	};

	const extractFirstSlash = (string) => {
		if (string) {
			let str = string.split('/')
			str.shift()
			let correctUrl = str.join('/')
			return (correctUrl)
		} else {
			return ('')
		}
	}

	useEffect(() => {
		// console.log(props.certificates,'props.certificates')
		if (props.certificates) {

			if (props.certificates.certificate_no) {
				let data = props.certificates;
				let images = {}
				extractFirstSlash(data.image_1)
				console.log(`${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_1)}`, `${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_2)}`, `${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_3)}`)
				createMultipleImageFile(`${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_1)}`, `${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_2)}`, `${process.env.REACT_APP_BACKEND_URL + extractFirstSlash(data.image_3)}`)
					.then((result) => {
						images = result
						// setObj({ ...data })
						if (result.picture1 && data.image_1) {
							setImage1Url(result.picture1)
							getBase64(result.picture1).then((data) => {
								setImage1(data);
							});
						}

						if (result.picture2 && data.image_2) {
							setImage2Url(result.picture2)
							getBase64(result.picture2).then((data) => {
								setImage2(data);
							});
						}
						if (result.picture3 && data.image_3) {
							setImage3Url(result.picture3)
							getBase64(result.picture3).then((data) => {
								setImage3(data);
							});
						}
					})

				console.log({ ...data, ...images })

				setObj({
					certificate_no: data.certificate_no ? data.certificate_no : "",
					image_1: images.picture1,
					image_2: images.picture2,
					image_3: images.picture3,
					date: data.date ? dateFormat(data.date) : dateFormat(new Date()),
					object: data.object ? data.object : "",
					Companyname: data.Companyname ? data.Companyname : "",
					uploaded_by: data.uploaded_by ? data.uploaded_by : "",
					status: data.status ? data.status : "",
					added_on: data.added_on ? data.added_on : "",
					appriasalItemArr: data.appriasalItemArr ? data.appriasalItemArr : "",
					date: data.date ? dateFormat(data.date) : dateFormat(new Date())
				});
				setItemArr(data.appriasalItemArr);
			}
		}

	}, [props.certificates]);

	const clearState = () => {
		setObj({
			certificate_no: "",
			image_1: "",
			image_2: "",
			image_3: "",
			date: dateFormat(new Date()),
			object: "",
			Companyname: "",
			weight: "",
			uploaded_by: "",
			status: "",
			added_on: "",
			appriasalItemArr: []
		})
		setImage1("")
		setImage2("")
		setImage3("")
		setImage1Url("")
		setImage2Url("")
		setImage3Url("")
		setItemArr([
			{
				item: "",
				item_other: "",
				metal: "Shape",
				metal_other: "",
				weight: "",
				karatage: "",
				appriasalItemDiamondsArr: [
					{
						no_of_diamonds: "",
						weight: "",
						color1: "",
						color2: "",
						clarity1: "",
						clarity2: "",
						legend: "",
						legend_comment: ""
					}
				],
				appriasalItemColorStoneArr: [
					{
						stone_name: "",
						no_of_gems: "",
						stone_other_item: "",
						weight: "",
						legend: "",
						legend_comment: ""
					}
				]
			}
		])
	}

	useEffect(() => {
		setObj({
			...obj,
			picture1: image1Url ? image1Url : obj.picture1,
			picture2: image2Url ? image2Url : obj.picture2,
			picture2: image3Url ? image3Url : obj.picture2,
		})
	}, [image1Url,
		image2Url,
		image3Url])

	const testApi = () => {
		// var axios = require('axios');
		// var FormData = require('form-data');
		// var fs = require('fs');
		var data = new FormData();
		data.append(
			"appriasalItemArr",
			'"[{\\"item\\":\\"new Ring\\",\\"item_other\\":\\"21\\",\\"metal\\":\\"32\\",\\"metal_other\\":\\"12\\",\\"weight\\":\\"21\\",\\"karatage\\":\\"32\\",\\"appriasalItemColorStoneArr\\":[{\\"color_stoneID\\":523,\\"stone_name\\":\\"abc\\",\\"no_of_gems\\":\\"3\\",\\"stone_other_item\\":\\"dfs\\",\\"weight\\":\\"1ct\\",\\"itemID\\":\\"263\\",\\"appraisalID\\":\\"251\\",\\"legend\\":\\"jh\\",\\"legend_comment\\":\\"dgf\\"}],\\"appraisalID\\":251,\\"appriasalItemDiamondsArr\\":[]}]"'
		);
		data.append("object", "hello");
		data.append("image_1", "");
		data.append("image_2", "");
		data.append("image_3", "");
		data.append("Companyname", "hello");
		data.append("uploaded_by", "admin");
		data.append("status", "updated");
		data.append("added_on", "dsadas");
		data.append("appraisalID", "251");

		var config = {
			method: "post",
			url: "http://beckendchecking.softsyncdev.work/appraisalReportApi/",
			headers: {
				Authorization:
					"JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjM2NjA5NDEwLCJlbWFpbCI6IjEiLCJvcmlnX2lhdCI6MTYzNDAxNzQxMH0.dS0UJz5k-AzrXhrRbbyO_OBHorJrBPjWhA9__x1REOw"
				// ...data.getHeaders()
			},
			data: data
		};

		Axios(config)
			.then(function (response) {
				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				// console.log(error);
			});
	};


	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}
		}
	};


	useEffect(() => {
		if (props.fields) {
			// setCutArr(props.fields.cuts)
			// setShapeArr(props.fields.shapes)
			setColorArr(props.fields.color)
			// setSpeciesArr(props.fields.species)
			// setObjectsArr(props.fields.objects)
			// setVarietyArr(props.fields.variety)
			// setOriginArr(props.fields.origion_gem)

		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])



	return (
		<div className="DiamondGradingFrom SmallJewelleryForm jewelleryReport">
			<div className="innerGradingForm">
				{/* <button onClick={testApi}>test</button> */}
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="imageBox">
							<div
								className="box"
								style={{ backgroundImage: `url('${image1 ? image1 : upload}')` }}
							></div>
							<Input onChange={(e) => handleChange(e, "image1")} type="file" placeholder="upload" />
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="imageBox">
							<div
								className="box"
								style={{ backgroundImage: `url('${image2 ? image2 : upload}')` }}
							></div>
							<Input onChange={(e) => handleChange(e, "image2")} type="file" placeholder="upload" />
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="imageBox">
							<div
								className="box"
								style={{ backgroundImage: `url('${image3 ? image3 : upload}')` }}
							></div>
							<Input onChange={(e) => handleChange(e, "image3")} type="file" placeholder="upload" />
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Date</h6>
								<Input
									value={obj.date}
									type="date"
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.date = e.target.value;
										setObj(obj2);
									}}
								/>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>
									Object <span className="maxWord">(Max Character: 130)</span>
								</h6>
								<TextArea
									value={obj.object}
									// value={value}
									// onChange={this.onChange}
									placeholder="Controlled autosize"
									autoSize={{ minRows: 3, maxRows: 5 }}
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.object = e.target.value;
										setObj(obj2);
									}}
								/>
							</label>
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={9} sm={9} md={9} lg={9} xl={9}>
						<div className="inputDiv">
							<label>
								<h6>Company Name</h6>
								<Input
									value={obj.Companyname}
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.Companyname = e.target.value;
										setObj(obj2);
									}}
								/>
							</label>
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={9} sm={9} md={9} lg={9} xl={9}>
						<div className="inputDiv">
							<label>
								<h6>Purpose</h6>
								<Input
									value={obj.purpose}
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.purpose = e.target.value;
										setObj(obj2);
									}}
								/>
							</label>
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={9} sm={9} md={9} lg={9} xl={9}>
						<div className="inputDiv">
							<label>
								<h6>Article/s Weight</h6>
								<Input
									value={obj.weight}
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.weight = e.target.value;
										setObj(obj2);
									}}
								/>
							</label>
						</div>
					</Col>
				</Row>
				{itemArr &&
					itemArr.map((a, i) => {
						return (
							<div key={`main${i}`}>
								<div className="desFormJewellery">
									<h2>DESCRIPTION</h2>
								</div>
								<Row className="spaceBetween">
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<div className="inputDiv">
											<label>
												<h6>Items</h6>
												{/* <h6>Show</h6> */}
												<Select
													className="select"
													value={a.item}
													onChange={(e) => {
														let itemArray = [...itemArr];
														let item = itemArray[i];
														item.item = e;
														itemArray[i] = item;
														setItemArr(itemArray);
													}}
													dropdownRender={(menu) => (
														<div>
															{menu}
															<Divider style={{ margin: "4px 0" }} />
															<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
																<Input
																	style={{ flex: "auto" }}
																	value={name ? name : ""}
																	onChange={onNameChange}
																/>
																{name ? (
																	<a
																		style={{
																			flex: "none",
																			padding: "8px",
																			display: "block",
																			cursor: "pointer"
																		}}
																		onClick={addItemOpt}
																	>
																		<PlusOutlined /> Other Items
																	</a>
																) : null}
															</div>
														</div>
													)}
												>
													{itemArrOpt.map((op, j) => {
														return <Option value={op}>{op}</Option>;
													})}
												</Select>
											</label>
										</div>
									</Col>
									{/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <div className="inputDiv">
                                        <label>
                                            <h6>Other Items</h6>
                                            <Input value={a.item_other} disabled />
                                        </label>
                                    </div>
                                </Col> */}
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<div className="inputDiv">
											<label>
												<h6>Metal</h6>
												<Select
													className="select"
													value={a.metal}
													onChange={(e) => {
														let itemArray = [...itemArr];
														let item = itemArray[i];
														item.metal = e;
														itemArray[i] = item;
														setItemArr(itemArray);
													}}
													dropdownRender={(menu) => (
														<div>
															{menu}
															<Divider style={{ margin: "4px 0" }} />
															<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
																<Input
																	style={{ flex: "auto" }}
																	value={metalName ? metalName : ""}
																	onChange={onMetalChange}
																/>
																{metalName ? (
																	<a
																		style={{
																			flex: "none",
																			padding: "8px",
																			display: "block",
																			cursor: "pointer"
																		}}
																		onClick={addMetalOpt}
																	>
																		<PlusOutlined /> Other Items
																	</a>
																) : null}
															</div>
														</div>
													)}
												>
													{metalArrOpt.map((op, j) => {
														return <Option value={op}>{op}</Option>;
													})}
												</Select>
											</label>
										</div>
									</Col>
									{/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <div className="inputDiv">
                                        <label>
                                            <h6>Other Metal</h6>
                                            <Input value={a.metal_other} disabled />
                                        </label>
                                    </div>
                                </Col> */}
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<div className="inputDiv">
											<label>
												<h6>Weight</h6>
												<Input
													placeholder="Weight"
													value={a.weight}
													onChange={(e) => {
														let itemArray = [...itemArr];
														let item = itemArray[i];
														item.weight = e.target.value;
														itemArray[i] = item;
														setItemArr(itemArray);
													}}
												/>
											</label>
										</div>
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<div className="inputDiv">
											<label>
												<h6>Karatage</h6>
												<Input
													value={a.karatage}
													onChange={(e) => {
														let itemArray = [...itemArr];
														let item = itemArray[i];
														item.karatage = e.target.value;
														itemArray[i] = item;
														setItemArr(itemArray);
													}}
												/>
											</label>
										</div>
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<hr />
									</Col>

									{a.appriasalItemDiamondsArr.length > 0
										? a.appriasalItemDiamondsArr.map((b, j) => {
											return (
												<Row key={`item${j}`} className="spaceBetween">
													<Col xs={24} sm={24} md={24} lg={24} xl={24}>
														<h5 style={{ marginTop: 10 }}>Diamond</h5>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>No of Diamonds</h6>
																<Input
																	value={b.no_of_diamonds}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.no_of_diamonds = e.target.value;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Weight</h6>
																<Input
																	value={b.weight}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.weight = e.target.value;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Color 1</h6>
																<Select
																	showSearch
																	value={b.color1}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.color1 = e;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																	className="select"
																	defaultValue=""
																	dropdownRender={(menu) => (
																		<div>
																			{menu}
																			<Divider style={{ margin: "4px 0" }} />
																			<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
																				<Input
																					style={{ flex: "auto" }}
																					value={colorName ? colorName : ""}
																					onChange={onColorChange}
																				/>
																				{colorName ? (
																					<a
																						style={{
																							flex: "none",
																							padding: "8px",
																							display: "block",
																							cursor: "pointer"
																						}}
																						onClick={addColorOpt}
																					>
																						<PlusOutlined />
																					</a>
																				) : null}
																			</div>
																		</div>
																	)}
																>
																	{colorArr.map((a, i) => {
																		return <Option value={a.name}>{a.name}</Option>;
																	})}
																</Select>
															</label>
														</div>
													</Col>

													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Color 2</h6>

																<Select
																	showSearch
																	value={b.color2}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.color2 = e;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																	className="select"
																	defaultValue=""
																	dropdownRender={(menu) => (
																		<div>
																			{menu}
																			<Divider style={{ margin: "4px 0" }} />
																			<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
																				<Input
																					style={{ flex: "auto" }}
																					value={colorName ? colorName : ""}
																					onChange={onColorChange}
																				/>
																				{colorName ? (
																					<a
																						style={{
																							flex: "none",
																							padding: "8px",
																							display: "block",
																							cursor: "pointer"
																						}}
																						onClick={addColorOpt}
																					>
																						<PlusOutlined />
																					</a>
																				) : null}
																			</div>
																		</div>
																	)}
																>
																	{colorArr.map((a, i) => {
																		return <Option value={a.name}>{a.name}</Option>;
																	})}
																</Select>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Clarity 1</h6>

																<Select
																	className="select"
																	value={b.clarity1}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.clarity1 = e;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																>
																	<Option value="F">F</Option>
																	<Option value="IF">IF</Option>
																	<Option value="VVS1">VVS1</Option>
																	<Option value="VVS2">VVS2</Option>
																	<Option value="VS1">VS1</Option>
																	<Option value="VS2">VS2</Option>
																	<Option value="SI1">SI1</Option>
																	<Option value="SI2">SI2</Option>
																	<Option value="I1">I1</Option>
																	<Option value="I2">I2</Option>
																	<Option value="I3">I3</Option>
																</Select>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Clarity 2</h6>

																<Select
																	className="select"
																	value={b.clarity2}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.clarity2 = e;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																>
																	<Option value="F">F</Option>
																	<Option value="IF">IF</Option>
																	<Option value="VVS1">VVS1</Option>
																	<Option value="VVS2">VVS2</Option>
																	<Option value="VS1">VS1</Option>
																	<Option value="VS2">VS2</Option>
																	<Option value="SI1">SI1</Option>
																	<Option value="SI2">SI2</Option>
																	<Option value="I1">I1</Option>
																	<Option value="I2">I2</Option>
																	<Option value="I3">I3</Option>
																</Select>
															</label>
														</div>
													</Col>

													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Legend</h6>
																<Input
																	value={b.legend}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.legend = e.target.value;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Legend Comment</h6>
																<Input
																	value={b.legend_comment}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let diamond = item.appriasalItemDiamondsArr[j];
																		diamond.legend_comment = e.target.value;
																		itemArray[i].appriasalItemDiamondsArr[j] = diamond;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<button onClick={() => addDiamondFeild(i)} className="addFieldsBtn">
														+ Diamonds
													</button>
													<hr />
													{j > 0 ? (
														<button
															className="removeItemButton"
															onClick={() => removeDiamondFeild(i, j, b.diamondID)}
														>
															- Remove Diamond
														</button>
													) : null}
												</Row>
											);
										})
										: addDiamondFeild(0)}
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<hr />
									</Col>

									{a.appriasalItemColorStoneArr.length > 0
										? a.appriasalItemColorStoneArr.map((c, k) => {
											return (
												<Row key={`items${k}`}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24}>
														<h5 style={{ marginTop: 10 }}>Color Stone</h5>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Stone Name</h6>
																<Select
																	className="select"
																	value={c.stone_name}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let stone = item.appriasalItemColorStoneArr[k];
																		stone.stone_name = e;
																		itemArray[i].appriasalItemColorStoneArr[k] = stone;
																		setItemArr(itemArray);
																	}}
																	dropdownRender={(menu) => (
																		<div>
																			{menu}
																			<Divider style={{ margin: "4px 0" }} />
																			<div
																				style={{
																					display: "flex",
																					flexWrap: "nowrap",
																					padding: 8
																				}}
																			>
																				<Input
																					style={{ flex: "auto" }}
																					value={stoneName ? stoneName : ""}
																					onChange={onStoneChange}
																				/>
																				{stoneName ? (
																					<a
																						style={{
																							flex: "none",
																							padding: "8px",
																							display: "block",
																							cursor: "pointer"
																						}}
																						onClick={addStoneOpt}
																					>
																						<PlusOutlined /> Other Items
																					</a>
																				) : null}
																			</div>
																		</div>
																	)}
																>
																	<Option disabled="" selected="" value="">
																		Stone{" "}
																	</Option>
																	{stoneArrOpt.map((a, i) => {
																		return <Option value={a}>{a}</Option>;
																	})}
																</Select>
															</label>
														</div>
													</Col>
													{/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <div className="inputDiv">
                                                    <label>
                                                        <h6>Other Items</h6>
                                                        <Input value={c.stone_other_item} disabled />
                                                    </label>
                                                </div>
                                            </Col> */}
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Number of GEMS</h6>
																<Input
																	value={c.no_of_gems}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let stone = item.appriasalItemColorStoneArr[k];
																		stone.no_of_gems = e.target.value;
																		itemArray[i].appriasalItemColorStoneArr[k] = stone;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>

													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Weight</h6>
																<Input
																	value={c.weight}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let stone = item.appriasalItemColorStoneArr[k];
																		stone.weight = e.target.value;
																		itemArray[i].appriasalItemColorStoneArr[k] = stone;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>

													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Legend</h6>
																<Input
																	value={c.legend}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let stone = item.appriasalItemColorStoneArr[k];
																		stone.legend = e.target.value;
																		itemArray[i].appriasalItemColorStoneArr[k] = stone;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}>
														<div className="inputDiv">
															<label>
																<h6>Legend Comment</h6>
																<Input
																	value={c.legend_comment}
																	onChange={(e) => {
																		let itemArray = [...itemArr];
																		let item = itemArray[i];
																		let stone = item.appriasalItemColorStoneArr[k];
																		stone.legend_comment = e.target.value;
																		itemArray[i].appriasalItemColorStoneArr[k] = stone;
																		setItemArr(itemArray);
																	}}
																/>
															</label>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
													<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
													<button onClick={() => addStoneFeild(i, k)} className="addFieldsBtn">
														+ Color
													</button>
													<hr />
													{k > 0 ? (
														<button
															className="removeItemButton"
															onClick={() => removeStoneFeild(i, k, c.color_stoneID)}
														>
															- Remove Stone
														</button>
													) : null}
												</Row>
											);
										})
										: addStoneFeild(0)}
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<hr />
									</Col>
									{i > 0 ? (
										<button
											style={{ marginTop: 10, marginBottom: 10 }}
											className="removeItemButton"
											onClick={() => removeItem(i, a.itemID)}
										>
											- Remove Input Feild
										</button>
									) : null}
								</Row>
							</div>
						);
					})}
			</div>
			<div className="saveButton">
				<button
					className="addFieldsBtn"
					onClick={() => {
						addItem();
					}}
				>
					Add Fields
				</button>
				<button onClick={save} disabled={props.reportsLoading}>
					{props.reportsLoading ? <div className="loading1 reportLoder"></div> : "Save"}
				</button>

				<Button
					onClick={() => props.history.goBack()}
					type="primary"
					style={{ margin: "0px 10px" }}
				>
					Cancel
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => ({
	certificates: store.managerPanel.certificateList ? store.managerPanel.certificateList : [],
	user: store.authentication.user,
	reportsLoading: store.reports.reportsLoading,
	fields: store.FormValues

});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addAppraisalReport,
			getSepAppraisalCertificates,
			updateAppraisalReport,
			deleteAppraisalDiamond,
			deleteAppraisalColorStone,
			deleteAppraisalItem,
			getColorFormDataAdd,
			getCertificatesSuccess
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AppraisalCertificateForm);
