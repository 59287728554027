import React from 'react'
import { Modal, Button } from 'antd';
import { Table, Tag, Space, DatePicker, Col, Row, Select } from 'antd';

import { connect } from 'react-redux'

export const SummaryEducation = (props) => {
    let { isModalVisible,
        setIsModalVisible,
        showModal,
        handleOk,
        handleCancel,
        monthlyData
    } = props
    return (
        <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <table className="summarDetail">
                <thead>
                    <tr>
                        <th onClick={() => {}}>Date</th>
                        <th>Income</th>
                        <th>Expense</th>
                        <th>Profit/Loss</th>
                    </tr>
                </thead>
                <tbody>
                    {monthlyData&& monthlyData.map((a, i) => {
                        return (
                            <tr>
                                <th>{a.day}</th>
                                <td>{a.totalEducationalIncome}</td>
                                <td>{a.totalEducationalExpense}</td>
                                <td>{a.educationalTotal}</td>
                            </tr>

                        )
                    })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryEducation)
