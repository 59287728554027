import { DATA_ENTRY_LOADING, GET_CASH_RECEIVED_SINGLE_ITEM, GET_DATA_ENTRY, GET_PACKET_NUMBER, GET_SINGLE_CUSTOMER_PENDING_ENTRIES, GET_SINGLE_DATA_ENTRY, GET_SINGLE_ITEM, GET_SINGLE_ITEM_ENTRY, GET_SINGLE_REPORT_ENTRY, SAVE_DATA_ENTRY, GET_SINGLE_ITEM_COPY } from "../actions/dataEntryActions";

const initialState = {
    dataEntryLoading: false,
    dataEntry: [],
    entryToPrint: {},
    singleEntry: false,
    singleItemEntry: false,
    singleItemEntryCopy: false,
    cashReceivedSingleItem: false,
    packetNumber: false,
    total: 0,
    page: 1,
    pendingEntries: false,
    singleReportEntry: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_ENTRY_LOADING:
            return {
                ...state,
                dataEntryLoading: action.payload,
            };
        case SAVE_DATA_ENTRY:
            console.log("aaaaaaaaaaa", action.payload.obj)

            return {
                ...state,
                dataEntryLoading: false,
                entryToPrint: action.payload.obj,
            };
            
        case GET_DATA_ENTRY:
            return {
                ...state,
                dataEntryLoading: false,
                dataEntry: action.payload.entries
            };
        case GET_SINGLE_DATA_ENTRY:
            console.log("GET_SINGLE_DATA_ENTRY", action.payload)
            return {
                ...state,
                dataEntryLoading: false,
                singleEntry: action.payload.data
               
            };
        case GET_SINGLE_ITEM_ENTRY:
            return {
                ...state,
                dataEntryLoading: false,
                singleItemEntry: action.payload.data
            };
            case GET_SINGLE_ITEM_COPY:
                return {
                    ...state,
                    dataEntryLoading: false,
                    singleItemEntryCopy: action.payload.data
                };
        case GET_SINGLE_REPORT_ENTRY:
            return {
                ...state,
                dataEntryLoading: false,
                singleReportEntry: action.payload.data
            };
        case GET_SINGLE_ITEM:
            return {
                ...state,
                dataEntryLoading: false,
            };
        case GET_CASH_RECEIVED_SINGLE_ITEM:
            return {
                ...state,
                cashReceivedSingleItem: action.payload.data,
            };
        case GET_PACKET_NUMBER:
            return {
                ...state,
                packetNumber: action.payload.data,
            };
        case GET_SINGLE_CUSTOMER_PENDING_ENTRIES:
            return {
                ...state,
                pendingEntries: action.payload.data,
                page: action.payload.page,
                total: action.payload.total
            };
            // case PRINT_DATA:
            //     return {
            //         ...state,
            //         printData: action.payload,
             
            //     };
        default:
            return state
    }
}

