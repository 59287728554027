import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Input,
	Select,
	Col,
	Row,
	Upload,
	message,
	InputNumber,
	Checkbox,
	Button,
	Divider
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Bruise from "./../images/Bruise.jpg";
import Cavity from "./../images/Cavity.jpg";
import Chip from "./../images/Chip.jpg";
import Cleavage from "./../images/Cleavage.jpg";
import Cloud from "./../images/Cloud.jpg";
import Crystal from "./../images/Crystal.jpg";
import ExtraFacet from "./../images/Extra Facet.jpg";
import Feather from "./../images/Feather.jpg";
import GrainCenter from "./../images/Grain Center.jpg";
import IndentedNatural from "./../images/Indented Natural.jpg";
import InternalGraining from "./../images/Internal Graining.jpg";
import Knot from "./../images/Knot.jpg";
import LaserDrillHole from "./../images/Laser Drill Hole.jpg";
import Natural from "./../images/Natural.jpg";
import Needle from "./../images/Needle.jpg";
import PinPoint from "./../images/Pin Point.jpg";
import SurfaceGraining from "./../images/Surface Graining.jpg";
import TwinningWisp from "./../images/Twinning Wisp.jpg";
import {
	createImageFile,
	setCertificate,
	updateCertificate
} from "../../../modules/actions/managerPanelActions";
import { getSingleItem, getSingleReport, getSingleItemConsultation, getSingleItemSuccess, getSingleReportSuccess } from "../../../modules/actions/dataEntryActions";
import { dateFormat } from "../../../common/common";
import upload from "./../../../Images/upload.png";
import { getColorFormDataAdd, getCutFormDataAdd, getGridlesFormDataAdd, getShapesFormDatAdda } from "../../../modules/actions/FormValues";

const keyToSymbolsArr = [
	{ img: Bruise, name: "Bruise", key: "key_symbol1" },
	{ img: Cavity, name: "Cavity", key: "key_symbol2" },
	{ img: Chip, name: "Chip", key: "key_symbol3" },
	{ img: Cleavage, name: "Cleavage", key: "key_symbol4" },
	{ img: Cloud, name: "Cloud", key: "key_symbol5" },
	{ img: Crystal, name: "Crystal", key: "key_symbol6" },
	{ img: ExtraFacet, name: "ExtraFacet", key: "key_symbol7" },
	{ img: Feather, name: "Feather", key: "key_symbol8" },
	{ img: GrainCenter, name: "GrainCenter", key: "key_symbol9" },
	{ img: IndentedNatural, name: "IndentedNatural", key: "key_symbol10" },
	{ img: TwinningWisp, name: "TwinningWisp", key: "key_symbol11" },
	{ img: InternalGraining, name: "InternalGraining", key: "key_symbol12" },
	{ img: Knot, name: "Knot", key: "key_symbol13" },
	{ img: LaserDrillHole, name: "LaserDrillHole", key: "key_symbol14" },
	{ img: Natural, name: "Natural", key: "key_symbol15" },
	{ img: Needle, name: "Needle", key: "key_symbol16" },
	{ img: PinPoint, name: "PinPoint", key: "key_symbol17" },
	{ img: SurfaceGraining, name: "SurfaceGraining", key: "key_symbol18" }
];

const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function DiamondGradingFrom(props) {
	const [flourColorOpt, setFlourColorOpt] = useState([
		"None",
		"Green",
		"Blue",
		"Dark Blue",
		"Light Red",
		"Dark Red",
		"Dark Green"
		// "Other",
	]);

	// const [name, setName] = useState(false);

	// const onColorChange = (event) => {
	// 	setName(event.target.value);
	// };

	// const addColorOpt = () => {
	// 	if (name) {
	// 		console.log("addItem");
	// 		setColorArr([...flourColorOpt, name]);
	// 		setName(false);
	// 	}
	// };

	const [obj, setobj] = useState({
		branchCode: props.permission.branchCode,
		relatedEntryNo: "",
		itemId: "",
		Id_of_temp_data: "",
		serial_number: "",
		certificate_number: "",
		color_gem: "",
		weight: "",
		length_gem: "",
		width_gem: "",
		depth_gem: "",
		description: "",
		comments_gem: "",
		species_gem: "",
		variety_gem: "",
		picture_id: "",
		cart_id: "",
		path: "",
		ext: "",
		Fpath: "",
		columnname: "",
		path2: "",
		ext3: "",
		bpath: "",
		customername: "",
		treatment: "",
		Customer_Number: "",
		eptyp_temp: "",
		Number_Of_Diamonds: "",
		Total_Dia_Weight: "",
		Shap_And_Cut: "",
		DiamondCut: "",
		Diamond_Color: "",
		number: "",
		filename: "",
		date: new Date(),
		shape: "",
		cut: "",
		measurement_mm: "",
		measurements: "",
		measurements2: "",
		measurements3: "",
		measurement_unit: "",
		depth: "",
		table: "",
		gridle: "",
		gridle2: "",
		gridle3: "",
		crown_angle: "",
		pavilion_depth: "",
		culet: "",
		polish: "",
		symmetry: "",
		flourescence: "",
		flourescenceColor: "",
		carat_weight: "",
		weight_unit: "",
		color: "",
		clarity: "",
		cut_grade: "",
		entry_date: dateFormat(new Date()),
		comment: "",
		key_to_symbol: "",
		object: "",
		transparency: "",
		species: "",
		variety: "",
		report_id: 1,
		report_page: "",
		remarks: "",
		finish: "",
		diamond1: "",
		diamond2: "",
		diamond3: "",
		diamond4: "",
		diamond5: "",
		key_symbol1: "",
		key_symbol2: "",
		key_symbol3: "",
		key_symbol4: "",
		key_symbol5: "",
		key_symbol6: "",
		key_symbol7: "",
		key_symbol8: "",
		key_symbol9: "",
		key_symbol10: "",
		key_symbol11: "",
		key_symbol12: "",
		key_symbol13: "",
		key_symbol14: "",
		key_symbol15: "",
		key_symbol16: "",
		key_symbol17: "",
		key_symbol18: "",
		disabled_key_symbol: "",
		origion_gem: "",
		pavilion_angle_d: "",
		color_grade: "",
		clarity_grade: "",
		Enhancements: "",
		RI: "",
		RI1: "",
		Polariscope: "",
		uv_flourescence: "",
		micro_evidence: "",
		cutting_style: "",
		microscope: "",
		FTIR: "",
		UV: "",
		Refractometer: "",
		Lamp: "",
		XRF: "",
		uv_flourescence1: ""
	});

	const [runOn, setRunOn] = useState(false);

	const [isReported, setIsReported] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const [image, setImage] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	function backFun() {
		props.history.goBack();
	}

	console.log(
		obj.measurement_mm,
		typeof obj.measurements,
		obj.measurements,
		obj.measurements2,
		obj.measurements3,
		"obj.measurements"
	);

	function handleChange(e) {
		setImageUrl(e.target.files[0]);
		getBase64(e.target.files[0]).then((data) => {
			setImage(data);
		});
	}

	useEffect(() => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let id = url.searchParams.get("id");
		let editId = url.searchParams.get("editId");
		let consultation = url.searchParams.get("consultation");

		if (editId) {
			setIsEdit(true);
			props.getSingleReport(editId, props.permission.branchCode);
		} else {
			let objj = { ...obj };
			objj.relatedEntryNo = entryNo;
			objj.itemId = Number(id);
			objj.entry_date = dateFormat(new Date());
			if (consultation === '1') {
				props.getSingleItemConsultation(id, props.permission.branchCode);
			} else {
				props.getSingleItem(id, props.permission.branchCode);
			}
			setobj(objj);
		}
	}, []);

	useEffect(() => {
		console.log(obj, "obj test");
	}, [obj]);

	useEffect(() => {
		if (props.singleItemEntry) {
			var url = new URL(window.location);
			let objj = obj;
			objj.carat_weight = Number(props.singleItemEntry.weight);
			objj.color = props.singleItemEntry.color;
			if (props.singleItemEntry.report && props.singleItemEntry.report.length) {
				setIsReported(true);
			} else {
				setIsReported(false);
			}
			setRunOn(runOn ? false : true);
			setobj(objj);
		}
		if (props.singleReportEntry) {
			console.log(props.singleReportEntry);
			if (props.singleReportEntry.relatedEntryNo) {
				console.log("no old entry no");
				setobj(props.singleReportEntry);
			} else {
				props.singleReportEntry.relatedEntryNo = "oldData";
				setobj(props.singleReportEntry);
			}
		}
	}, [props.singleItemEntry, props.singleReportEntry]);

	const save = () => {
		const url = new URL(window.location);
		const entryId = url.searchParams.get("id");

		const entryNo = url.searchParams.get("entryNo");
		const data = new FormData();
		console.log(data, "Form Backend");
		for (const property in obj) {
			if (obj[property]) {
				data.append(property, obj[property]);
			} else if (property === "relatedEntryNo" && !obj[property] && entryNo) {
				data.append(property, entryNo);
			}
		}
		data.append("product_pic", imageUrl);
		data.append("itemId", Number(entryId));
		props.setCertificate(data, props.permission.branchCode, clearState, backFun);
	};
	const update = () => {
		const data = new FormData();
		const imageFile = [];
		console.log(data, "Form Backend");

		for (const property in obj) {
			if (property === "product_pic") {
				if (obj[property]) {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
				else {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
			}

			else if (property.toLowerCase().match("fpath")) {
				data.append(property, '')
				// if(obj[property]){

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }
				// else{

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }				// data.set(property, "");
			}
			else if (obj[property]) {
				data.append(property, obj[property]);
			}
		}

		props.updateCertificate(data, props.permission.branchCode, clearState, obj.id, backFun);
	};
	const clearState = () => {
		let objj = obj;
		for (let keys in objj) {
			objj[keys] = "";
		}
		setobj(objj);
		setRunOn(runOn ? false : true);
	};

	const [cutArr, setCutArr] = useState([])
	const [cutName, setCutName] = useState(false);
	const onCutChange = (event) => {
		setCutName(event.target.value);
	};

	const addCutOpt = () => {
		if (cutName) {
			if (cutArr.filter(obj => obj.value.toLowerCase() === cutName.toLowerCase()).length) {
				message.error('Cut is already exist')
			} else {
				console.log("addItem");
				setCutArr([...cutArr, { value: cutName }]);
				props.getCutFormDataAdd({ value: cutName })
				setCutName(false);
			}
		}
	};

	const [commentArr, setCommentArr] = useState([])
	const [commentName, setCommentName] = useState(false);
	const onCommentChange = (event) => {
		setCommentName(event.target.value);
		let obj2 = { ...obj };
		obj2.comment = event.target.value;
		setobj(obj2);
	};


	const addCommentOpt = () => {
		if (commentName) {
			if (colorArr.filter(obj => obj.value.toLowerCase() === commentName.toLowerCase()).length) {
				message.error('Comment is already exist')
			} else {
				console.log("addItem");
				setCommentArr([...commentArr, commentName]);
				setCommentName(false);
			}
		}
	};

	const [shapeArr, setShapeArr] = useState([])
	const [shapeName, setShapeName] = useState(false);
	const onShapeChange = (event) => {
		setShapeName(event.target.value);
	};

	const addShapeOpt = () => {
		if (shapeName) {
			if (shapeArr.filter(obj => obj.value.toLowerCase() === shapeName.toLowerCase()).length) {
				message.error('Shape is already exist')
			} else {
				console.log("addItem");
				setShapeArr([...shapeArr, { value: shapeName }]);
				props.getShapesFormDatAdda({ value: shapeName })
				setShapeName(false);
			}
		}
	};
	const [gridleArr, setGridleArr] = useState([])
	const [gridleName, setGridleName] = useState(false);
	const onGridleChange = (event) => {
		setGridleName(event.target.value);
	};

	const addGridleOpt = () => {
		if (gridleName) {
			if (gridleArr.filter(obj => obj.value.toLowerCase() === gridleName.toLowerCase()).length) {
				message.error('Gridle is already exist')
			} else {
				console.log("addItem");
				setGridleArr([...gridleArr, { value: gridleName }]);
				props.getGridlesFormDataAdd({ value: gridleName })
				setGridleName(false);
			}
		}
	};

	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}
		}
	};



	useEffect(() => {
		if (props.fields) {
			setCutArr(props.fields.cuts)
			setShapeArr(props.fields.shapes)
			setGridleArr(props.fields.gridle3)
			setColorArr(props.fields.color)
			setCommentArr(props.fields.comment)
		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])




	return (
		<div className="DiamondGradingFrom">
			{!isReported ? (
				<div className="innerGradingForm">
					<Row className="spaceBetween">
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Date</h6>
									<Input
										style={{ width: "100%" }}
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.date = e.target.value;
											setobj(obj2);
										}}
										type="date"
										value={dateFormat(obj.date ? obj.date : obj.entry_date)}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Measurements</h6>
									<div className="weightDiv">
										<div className="wieghtNumber" style={{ width: "65%" }}>
											<Input
												style={{ width: "33%" }}
												onChange={(e) => {
													let obj2 = { ...obj };
													obj2.measurements = e.target.value;
													setobj(obj2);
												}}
												value={obj.measurements}
											/>
											<span>*</span>
											<Input
												style={{ width: "33%" }}
												onChange={(e) => {
													let obj2 = { ...obj };
													obj2.measurements2 = e.target.value;
													setobj(obj2);
												}}
												value={obj.measurements2}
											/>
											<span>*</span>
											<Input
												style={{ width: "33%" }}
												onChange={(e) => {
													let obj2 = { ...obj };
													obj2.measurements3 = e.target.value;
													setobj(obj2);
												}}
												value={obj.measurements3}
											/>
										</div>
										<Select
											style={{ width: "30%" }}
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurement_unit = e;
												setobj(obj2);
											}}
											className="select"
											defaultValue="Milimeter"
											value={obj.measurement_unit}
										>
											<Option value="Milimeter">Milimeter</Option>
											<Option value="Centimeter">Centimeter</Option>
										</Select>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Shape</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.shape = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="N/A"
										value={obj.shape}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={shapeName ? shapeName : ""}
														onChange={onShapeChange}
													/>
													{shapeName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addShapeOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{shapeArr.length && shapeArr.map((a, i) => (
											<Option value={a.value}>{a.value}</Option>
										))}
									</Select>


								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Cut</h6>
									<div className="spaceBetween cutDiv">
										<Select
											showSearch
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.cut = e;
												setobj(obj2);
											}}
											className="select"
											defaultValue="N/A"
											value={obj.cut}
											dropdownRender={(menu) => (
												<div>
													{menu}
													<Divider style={{ margin: "4px 0" }} />
													<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
														<Input
															style={{ flex: "auto" }}
															value={cutName ? cutName : ""}
															onChange={onCutChange}
														/>
														{cutName ? (
															<a
																style={{
																	flex: "none",
																	padding: "8px",
																	display: "block",
																	cursor: "pointer"
																}}
																onClick={addCutOpt}
															>
																<PlusOutlined />
															</a>
														) : null}
													</div>
												</div>
											)}
										>

											{cutArr.length && cutArr.map((a, i) => (
												<Option value={a.value}>{a.value}</Option>
											))}
											<Option value="other">other</Option>
										</Select>
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.cut = e.target.value;
												setobj(obj2);
											}}
											placeholder="Other"
										/>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Carat Weights</h6>
									<div className="spaceBetween cutDiv">
										<Input
											style={{ width: "90%" }}
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.carat_weight = e.target.value;
												setobj(obj2);
											}}
											value={obj.carat_weight}
										/>
										<Select
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.weight_unit = e;
												setobj(obj2);
											}}
											className="select"
											defaultValue="Carats"
											value={obj.weight_unit}
										>
											<Option value="CT">Carats</Option>
											<Option value="Gm">Grams</Option>
										</Select>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Depth %</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.depth = e.target.value;
											setobj(obj2);
										}}
										value={obj.depth}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Table</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.table = e.target.value;
											setobj(obj2);
										}}
										value={obj.table}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Gridle</h6>
									<div className="spaceBetween griddle">
										<Select
											style={{ width: "40%" }}
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.gridle = e;
												setobj(obj2);
											}}
											className="select"
											defaultValue="Extremely Thin"
											value={obj.gridle}
										>
											<Option value="Extremely Thin">Extremely Thin</Option>
											<Option value="Very Thin">Very Thin</Option>
											<Option value="Thin">Thin</Option>
											<Option value="Medium">Medium</Option>
											<Option value="Slightly Thick">Slightly Thick</Option>
											<Option value="Thick">Thick</Option>
											<Option value="Very Thick">Very Thick</Option>
											<Option value="Extremely Thick">Extremely Thick</Option>
										</Select>
										<Select
											style={{ width: "40%" }}
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.gridle2 = e;
												setobj(obj2);
											}}
											className="select"
											defaultValue="Extremely Thin"
											value={obj.gridle2}
										>
											<Option value="Extremely Thin">Extremely Thin</Option>
											<Option value="Very Thin">Very Thin</Option>
											<Option value="Thin">Thin</Option>
											<Option value="Medium">Medium</Option>
											<Option value="Slightly Thick">Slightly Thick</Option>
											<Option value="Thick">Thick</Option>
											<Option value="Very Thick">Very Thick</Option>
											<Option value="Extremely Thick">Extremely Thick</Option>
										</Select>
										<Select
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.gridle3 = e;
												setobj(obj2);
											}}
											className="select"
											value={obj.gridle3}
											dropdownRender={(menu) => (
												<div>
													{menu}
													<Divider style={{ margin: "4px 0" }} />
													<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
														<Input
															style={{ flex: "auto" }}
															value={gridleName ? gridleName : ""}
															onChange={onGridleChange}
														/>
														{gridleName ? (
															<a
																style={{
																	flex: "none",
																	padding: "8px",
																	display: "block",
																	cursor: "pointer"
																}}
																onClick={addGridleOpt}
															>
																<PlusOutlined />
															</a>
														) : null}
													</div>
												</div>
											)}
										>
											{gridleArr.length && gridleArr.map((a, i) => (
												<Option value={a.value}>{a.value}</Option>
											))}
										</Select>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Crown Angle</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.crown_angle = e.target.value;
											setobj(obj2);
										}}
										value={obj.crown_angle}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Pavilion Depth</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.pavilion_depth = e.target.value;
											setobj(obj2);
										}}
										value={obj.pavilion_depth}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Culet</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.culet = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.culet}
									>
										<Option value="">None</Option>
										<Option value="Very Small">Very Small</Option>
										<Option value="Small">Small</Option>
										<Option value="Medium">Medium</Option>
										<Option value="Large">Large</Option>
										<Option value="Very Large">Very Large</Option>
									</Select>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Polish</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.polish = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="Excellent"
										value={obj.polish}
									>
										<Option value="Excellent">Excellent</Option>
										<Option value="Very Good">Very Good</Option>
										<Option value="Good">Good</Option>
										<Option value="Fair">Fair</Option>
										<Option value="Poor">Poor</Option>
									</Select>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Symmetry</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.symmetry = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="Excellent"
										value={obj.symmetry}
									>
										<Option value="Excellent">Excellent</Option>
										<Option value="Very Good">Very Good</Option>
										<Option value="Good">Good</Option>
										<Option value="Fair">Fair</Option>
										<Option value="Poor">Poor</Option>
									</Select>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "39%", marginTop: 10 }}>
									<h6>Flourescence</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.flourescence = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.flourescence}
									>
										<Option value="None">None</Option>
										<Option value="Faint">Faint</Option>
										<Option value="Medium">Medium</Option>
										<Option value="Strong">Strong</Option>
										<Option value="Very Strong">Very Strong</Option>
									</Select>
								</div>

								<div style={{ width: "59%", marginTop: 10 }}>
									<h6>Colour</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.flourescenceColor = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.flourescenceColor}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={colorName ? colorName : ""}
														onChange={onColorChange}
													/>
													{colorName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addColorOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{colorArr.map((a, i) => {
											return <Option value={a.name}>{a.name}</Option>;
										})}
									</Select>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv commnetInput">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Comment</h6>
									<TextArea
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.comment = e.target.value;
											setobj(obj2);
										}}
										placeholder="Controlled autosize"
										autoSize={{ minRows: 3, maxRows: 5 }}
										value={obj.comment}
									/>
								</div>

							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Key to Symbol</h6>
									<div className="keyToSymbol">
										{keyToSymbolsArr.map((a, i) => {
											return (
												<Checkbox
													onChange={(e) => {
														let obj2 = { ...obj };
														if (obj[a.key] == a.name) {
															obj2[a.key] = "";
															setobj(obj2);
															console.log(obj2, "if");
														} else {
															obj2[a.key] = a.name;
															setobj(obj2);
															console.log(obj2, "else");
														}
													}}
													key={i}
													value={a.name}
													checked={obj[a.key]}
													className="checkboxGroup"
												>
													<img src={a.img} />
												</Checkbox>
											);
										})}
									</div>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "49%", marginTop: 10 }}>
									<h6>Color</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.color = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.color}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={colorName ? colorName : ""}
														onChange={onColorChange}
													/>
													{colorName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addColorOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{colorArr.map((a, i) => {
											return <Option value={a.name}>{a.name}</Option>;
										})}
									</Select>
								</div>
								<div style={{ width: "49%", marginTop: 10 }}>
									<h6>Pavilion Angle</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.pavilion_angle_d = e.target.value;
											setobj(obj2);
										}}
										value={obj.pavilion_angle_d}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Clarity</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.clarity = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="F"
										value={obj.clarity}
									>
										<Option value="F">F</Option>
										<Option value="IF">IF</Option>
										<Option value="VVS1">VVS1</Option>
										<Option value="VVS2">VVS2</Option>
										<Option value="VS1">VS1</Option>
										<Option value="VS2">VS2</Option>
										<Option value="SI1">SI1</Option>
										<Option value="SI2">SI2</Option>
										<Option value="I1">I1</Option>
										<Option value="I2">I2</Option>
										<Option value="I3">I3</Option>
									</Select>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Cut Grade</h6>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.cut_grade = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="Excellent"
										value={obj.cut_grade}
									>
										<Option value="Excellent">Excellent</Option>
										<Option value="Very Good">Very Good</Option>
										<Option value="Good">Good</Option>
										<Option value="Fair">Fair</Option>
										<Option value="Poor">Poor</Option>

									</Select>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Remarks</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.remarks = e.target.value;
											setobj(obj2);
										}}
										value={obj.remarks}
									/>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Finish</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.finish = e.target.value;
											setobj(obj2);
										}}
										value={obj.finish}
									/>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv">
								<div style={{ width: "100%", marginTop: 10 }}>
									<h6>Product Image</h6>
									<div className="imageBox">
										<div
											className="box"
											style={{
												backgroundImage: `url('${image ? image : obj.product_pic ? obj.product_pic : upload
													}')`
											}}
										></div>
										<Input onChange={(e) => handleChange(e)} type="file" placeholder="upload" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
					{isEdit ? (
						<Button onClick={() => update()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div className="loading1"></div> : "Update"}
						</Button>
					) : (
						<Button onClick={() => save()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div className="loading1"></div> : "Save"}
						</Button>
					)}
					<Button
						onClick={() => props.history.goBack()}
						type="primary"
						style={{ margin: "0px 10px" }}
					>
						Cancel
					</Button>
				</div>
			) : (
				<h2>Already Delivered</h2>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	singleItemEntry: store.dataEntry.singleItemEntry,
	singleReportEntry: store.dataEntry.singleReportEntry,
	image: store.managerPanel.image,
	managerPanelLoading: store.managerPanel.managerPanelLoading,
	fields: store.FormValues,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCertificate,
			getSingleItem,
			getSingleReport,
			updateCertificate,
			getSingleItemConsultation,
			getCutFormDataAdd,
			getGridlesFormDataAdd,
			getShapesFormDatAdda,
			getColorFormDataAdd,
			getSingleItemSuccess,
			getSingleReportSuccess
			// createImageFile
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(DiamondGradingFrom);
