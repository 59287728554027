import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row, Select } from 'antd';
import { Button, Collapse, Modal, Input, InputNumber, Checkbox, Table } from 'antd';

function AddEducationRate({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    handleCancel }) {

    const { Option } = Select;
    const { Panel } = Collapse;
    const { Column, ColumnGroup } = Table;
    const { TextArea } = Input;


    return (
        <>

            <Modal title="Add Course" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Submit">
                <div className="addItemForm">
                    <div className="inputFields">
                        <label>
                            <h5>Main Category</h5>
                            <Select className="select w100P" defaultValue="Select One" >
                                <Option value="default">Select One</Option>
                                <Option value="colored stones">Diamond Grading Schedule (2021)</Option>
                                <Option value="diamond certificate">Gemstone Identification Schedule (2021)</Option>
                                <Option value="diamond card">Manual Jewellery Schedule (2021)</Option>
                                <Option value="pearls">CAD / CAM Schedule (2021)</Option>
                            </Select>
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Duration *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Sessions *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Classes per week *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Tuition *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Start Date *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>End Date *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Timing (Morning) *</h5>
                            <Input />
                        </label>
                    </div>   <div className="inputFields">
                        <label>
                            <h5>Timing (Evening) *</h5>
                            <Input />
                        </label>
                    </div>


                </div>
            </Modal>
        </>
    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEducationRate)