import Axios from 'axios'
import { MANAGER_PANEL_LOADING, GET_CERTIFICATES} from '../actions/managerPanelActions';

const initialState = {
    managerPanelLoading: false,
    certificateList: false,
    image:false,
    page: 1,
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MANAGER_PANEL_LOADING:
            return {
                ...state,
                managerPanelLoading: action.payload,
            };
        case GET_CERTIFICATES:
            return {
                ...state,
                certificateList: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
            };
        default:
            return state
    }
}

