import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Tag, Space, Button, Popconfirm, message, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getSingleCustomerPendingEntries } from "../../modules/actions/dataEntryActions";

const { Column, ColumnGroup } = Table;

function DataEntry(props) {
	const [page, setPage] = useState(1);

	useEffect(() => {
		props.getSingleCustomerPendingEntries(
			props.customer.customerId,
			props.permission.branchCode,
			page
		);
	}, [page]);

	return (
		<>
			<Table
				className="itemsTable"
				// expandedRowKeys="itemIndex"
				defaultExpandAllRows={true}
				dataSource={props.pendingEntries ? props.pendingEntries : []}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {} // click row
					};
				}}
			>
				<Column title="Date" dataIndex="entryDate" key="entryDate" />
				<Column title="Entry No" dataIndex="entryNo" key="entryNo" />
				<Column title="Customer Id" dataIndex="customerId" key="customerId" />
				<Column title="Customer Name" dataIndex="customerName" key="customerName" />
				<Column
					title="Delivered"
					dataIndex="deliveryObj"
					key="deliveryObj"
					render={(text, record, index) => {
						return record.deliveryObj && record.deliveryObj.id ? (
							<h1>
								<CheckOutlined style={{ color: "green", fontSize: 16 }} color="green" />
							</h1>
						) : (
							<h1>
								{" "}
								<CloseOutlined style={{ color: "red", fontSize: 16 }} color="red" />{" "}
							</h1>
						);
					}}
				/>
				<Column title="Total" dataIndex="totalAmount" key="totalAmount" />
			</Table>
			<Pagination
				onChange={(ev) => setPage(ev)}
				current={props.page}
				total={props.total}
				pageSize={5}
			/>
		</>
	);
}

const mapStateToProps = (store) => ({
	pendingEntries: store.dataEntry.pendingEntries,
	total: store.dataEntry.total,
	page: store.dataEntry.page,
	customer: store.customers.customer,
	permission: store.users.permission
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getSingleCustomerPendingEntries
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(DataEntry);
