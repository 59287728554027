import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'antd';
import { Table, Tag, Space } from 'antd';
const { Column, ColumnGroup } = Table;

const RecievableModal = ({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    TotalRecievable,
    collectionData,
    handleCancel }) => {

    let data = [
        {
            date: '3/22/2021',
            entryNo: 590140,
            name: "Saqib",
            description: 'ads asd asd asd asd ',
            debit: 2000,
            credit: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            name: "Saqib",
            description: 'ads asd asd asd asd ',
            debit: 2000,
            credit: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            name: "Saqib",
            description: 'ads asd asd asd asd ',
            debit: 2000,
            credit: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            name: "Saqib",
            description: 'ads asd asd asd asd ',
            debit: 2000,
            credit: 3000
        },
    ]

    return (
        <>
            <Modal title="Report ( TOTAL RECIVEABLE )" className="reportModal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Table pagination={false} className="itemsTable" dataSource={TotalRecievable} footer={() => {
                    return (
                        <div className="expenseTotal">
                            <h5>TOTAL</h5>
                            <div className="spaceBetween">
                                <p><b>Debit:</b> {collectionData.TotalDebit}</p>
                                <p><b>Credit:</b> {collectionData.TotalCredit}</p>
                            </div>
                        </div>
                    )
                }} >
                    <Column title="Date" dataIndex="date" key="date" />
                    <Column title="Entry No." dataIndex="code" key="code" />
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="Description" dataIndex="description" key="description" />
                    <Column title="Debit" render={(record) => {
                        return (
                            <div onClick={() => {console.log(record) }}>
                                {record.subHead.toLowerCase() === "received" ? record.amount : `0`}
                            </div>
                        )
                    }} />
                    <Column title="Credit" render={(record) => {
                        return (
                            <div onClick={() => { }}>
                                {record.subHead.toLowerCase() === "paid" ? record.amount : `0`}
                            </div>
                        )
                    }} />
                </Table>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecievableModal)