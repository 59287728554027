import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, Col, Row, } from 'antd';
import { NavLink } from 'react-router-dom';
import { Table, Tag, Space, Button } from 'antd';
import { Modal } from 'antd';
import AddRate from './addRate';


const { Column, ColumnGroup } = Table;

function EducationRate() {

    let data = [
        {
            courseName: 'CAD/CAM Schedule (2019)',
            duration: '3 months',
            session: '3-5',
            classesWeek: 4,
            tutionFees: '10000',
            startDate:'20 sept 2019',
            endDate:'30 dec 2019',
            timeMorning:'09:00 am',
            timeEvening:'04:00 pm',
        },
        {
            courseName: 'CAD/CAM Schedule (2019)',
            duration: '3 months',
            session: '3-5',
            classesWeek: 4,
            tutionFees: '10000',
            startDate:'20 sept 2019',
            endDate:'30 dec 2019',
            timeMorning:'09:00 am',
            timeEvening:'04:00 pm',
        },
        {
            courseName: 'CAD/CAM Schedule (2019)',
            duration: '3 months',
            session: '3-5',
            classesWeek: 4,
            tutionFees: '10000',
            startDate:'20 sept 2019',
            endDate:'30 dec 2019',
            timeMorning:'09:00 am',
            timeEvening:'04:00 pm',
        },
    ]
        

    const [isModalVisible, setIsModalVisible] = useState(false);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div className="diamondGrading labRate">
            <div className="innerDiamondGrading">
                <div className="filter">
                    <Row className="spaceBetween">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="filterButtons">
                                <button className="toFormBtn" onClick={() => setIsModalVisible(true)}>Add Course</button>
                                <AddRate isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Table className="itemsTable" style={{ border: "1px solid #0000001c" }} dataSource={data} >
                        <Column title="Course Name" dataIndex="courseName" key="courseName" />
                        <Column title="Session" dataIndex="session" key="session" />
                        <Column title="Classes Week" dataIndex="classesWeek" key="classesWeek" />
                        <Column title="Tution Fees" dataIndex="tutionFees" key="tutionFees" />
                        <Column title="Start Date" dataIndex="startDate" key="startDate" />
                        <Column title="End Date" dataIndex="endDate" key="endDate" />
                        <Column title="Time Morning" dataIndex="timeMorning" key="timeMorning" />
                        <Column title="Time Evening" dataIndex="timeEvening" key="timeEvening" />
                        <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn editButton'>Edit Product</button>
                                </Space>
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn DeleteBtn'>Delete Product</button>
                                </Space>
                            )}
                        />
                    </Table>

                </div>
            </div>
        </div>

    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EducationRate)