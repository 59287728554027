import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, Input, InputNumber } from "antd";
import { Table, Col, Row } from "antd";
import "./account.scss";
import {
	AddAccountDetail,
	getAllExpense,
	getAllRecievables,
	UpdateAcountDetail
} from "../../modules/actions/AccountActions";
import { getUsers } from "../../modules/actions/userAction";
import { dateFormat } from "../../common/common";
import Loader from "../../Layout/Loader";
import { getAllAccountRecieveble } from "../../modules/actions/reportsAction";

const { Column, ColumnGroup } = Table;

const { Option } = Select;

function AddAccount(props) {
	const [mainCategory, setMainCategory] = useState("Laboratory");
	const [activeArr, setActiveArr] = useState([]);
	const [subCategory, setSubCategory] = useState("Expense");
	const [labArr, setLabArr] = useState(["Expense", "Recievable"]);
	const [educArr, setEducArr] = useState(["Expense", "Income"]);
	const [name, setName] = useState("");
	const [nameArr, setNameArr] = useState([]);
	const [subHeads, setSubHeads] = useState([]);
	const [subHead, setSubHead] = useState([]);
	const [users, setUsers] = useState([]);
	const [amount, setAmount] = useState(0);
	const [description, setDescription] = useState("");
	const [selectedBranch, setSelectedBranch] = useState("KHI");
	const [privateEntry, setPrivateEntry] = useState('false');


	useEffect(() => {
		if (mainCategory === "Education") {
			setActiveArr(educArr);
		} else if (mainCategory === "Laboratory") {
			setActiveArr(labArr);
		}
	}, [mainCategory]);

	useEffect(() => {
		props.getUsers(selectedBranch, 'all', '', 1, 100000);
		// props.getAllAccountRecieveble()
	}, []);

	useEffect(() => {
		console.log(subCategory)
		if (selectedBranch) {
			if (subCategory === 'Expense') {
				props.getAllExpense(mainCategory, selectedBranch);
			} else if (subCategory === 'Recievable') {
				props.getAllRecievables(selectedBranch)
			}
		}
	}, [mainCategory, selectedBranch, subCategory]);

	useEffect(() => {
		if (props.allExpenses) {
			setNameArr(props.allExpenses);
			console.log(props.allExpenses);
		}
	}, [props.allExpenses]);

	useEffect(() => {
		if (props.users) {
			setUsers(props.users);
		}
	}, [props.users]);

	const clearState = () => {
		setMainCategory("Laboratory");
		setSubCategory("Expense");
		setName("");
		setSubHead([]);
		setAmount(0);
		setDescription("");
	};

	const add = () => {
		let data = new FormData();
		data.append("amount", amount);
		data.append("category", mainCategory);
		data.append("description", description);
		data.append("name", name);
		data.append("type", subCategory);
		data.append("userName", props.user.username);
		data.append("branchCode", selectedBranch);
		data.append("subHead", subHead);
		data.append("private", privateEntry);
		data.append("date", dateFormat(new Date()));
		props.AddAccountDetail(data, selectedBranch, clearState);
	};

	const update = () => {
		let data = new FormData();
		data.append("amount", amount);
		data.append("category", mainCategory);
		data.append("description", description);
		data.append("name", name);
		data.append("type", subCategory);
		data.append("userName", props.user.username);
		data.append("branchCode", selectedBranch);
		data.append("subHead", subHead);
		data.append("private", privateEntry);
		data.append("date", dateFormat(new Date()));
		if (selectedBranch) {
			props.UpdateAcountDetail(
				data,
				props.data.id,
				props.page,
				"Expenses",
				props.startDate,
				props.endDate,
				props.tabName,
				props.type,
				"",
				selectedBranch,
				props.pageSize,
				props.handleCancel
			);
		}
	};

	useEffect(() => {
		if (props.data) {
			setMainCategory(props.data.category);
			if (props.data.category === "Education") {
				setActiveArr(educArr);
			} else if (props.data.category === "Laboratory") {
				setActiveArr(labArr);
			}
			setSubCategory(props.data.type);
			setName(props.data.name);
			setSubHead(props.data.subHead);
			setUsers(props.data.userName);
			setAmount(props.data.amount);
			setDescription(props.data.description);
			setSelectedBranch(props.data.branchCode);
		}
	}, [props.data]);

	return (
		<div className="AddAccount">
			<h3 className="addHead">Add Accounts</h3>
			<div className="accountForm">
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>Education / Laboratory</h5>
							<Select onChange={(e) => setMainCategory(e)} defaultValue="Laboratory">
								<Option value="Education">Education</Option>
								<Option value="Laboratory">Laboratory</Option>
							</Select>
						</label>
					</div>
				</Col>

				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>{`${activeArr[0]} / ${activeArr[1]}`}</h5>
							<Select onChange={(e) => setSubCategory(e)} defaultValue="Expense">
								{activeArr.map((a, i) => {
									return (
										<Option key={i} value={a}>
											{a}
										</Option>
									);
								})}
							</Select>
						</label>
					</div>
				</Col>

				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>{subCategory} Name</h5>
							{subCategory === "Expense" ? (
								<Select
									onChange={(e) => {
										setName(JSON.parse(e).name);
										setSubHeads(JSON.parse(e).expenseSubHead);
										setPrivateEntry(JSON.parse(e).private);
									}}
									value={name}
								>
									{nameArr.map((a, i) => {
										return (a.category === "Expense" || a.category === "general expenses") || a.category === 'Laboratory' ? (
											<Option key={i} value={a.name && JSON.stringify(a)}>
												{a.name}
											</Option>
										) : null;
									})}
								</Select>
							) : subCategory === "Recievable" ? (
								<Select onChange={(e) => setName(e)} value={name}>
									{props.allAccountEntries.map((a, i) => {
										console.log(nameArr)
										return a.category === "Laboratory" ? (
											<Option key={i} value={a.name}>
												{a.name}
											</Option>
										) : null;
									})}
								</Select>
							) : (
								<Select onChange={(e) => setName(e)} value={name}>
									{users.map((a, i) => {
										return (
											<Option key={i} value={a.id}>
												{a.first_name + " " + a.last_name}
											</Option>
										);
									})}
								</Select>
							)}
						</label>
					</div>
				</Col>

				{subCategory !== "Income" && (
					<Col xs={12} sm={12} md={12} lg={12} xl={12}>
						<div className="inputFields">
							<label>
								<h5>Sub Head of {name}</h5>
								{subCategory === "Expense" ? (
									<Select value={subHead} onChange={(e) => setSubHead(e)}>
										{subHeads.map((a, i) => {
											return (
												<Option key={i} value={a.name}>
													{a.name}
												</Option>
											);
										})}
									</Select>
								) : (
									<Select value={subHead} onChange={(e) => setSubHead(e)}>
										<Option value="Paid">Paid</Option>
										<Option value="Received">Received</Option>
									</Select>
								)}
							</label>
						</div>
					</Col>
				)}
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>Branch</h5>
							<Select
								value={selectedBranch}
								onChange={(e) => setSelectedBranch(e)}
							>
								{props.branch
									? props.branch.map((a, i) => {
										return <Option value={a.branchCode}>{a.branchName}</Option>;
									})
									: null}
							</Select>
						</label>
					</div>
				</Col>

				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>Description</h5>
							<Input
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								placeholder="Description"
							/>
						</label>
					</div>
				</Col>

				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<div className="inputFields">
						<label>
							<h5>Amount</h5>
							<InputNumber value={amount ? amount : ''} type="number" onChange={(e) => setAmount(e)} />
						</label>
					</div>
				</Col>

				<div className="addAccBtn">
					{props.edit ? (
						<button onClick={update}>Update</button>
					) : (
						<button onClick={() => {
							// if(subCategory === "Recievable"){

							// }else{
							add()
							// }
						}}>Add</button>
					)}
				</div>
				{props.addAccountLoader ? <Loader /> : null}
			</div>
		</div>
	);
}
const mapStateToProps = (store) => ({
	allExpenses: store.accountStore.allExpenses,
	addAccountLoader: store.accountStore.addAccountLoader,
	users: store.users.users,
	permission: store.users.permission,
	user: store.authentication.user,
	allAccountEntries: store.reports.allAccountEntries ? store.reports.allAccountEntries : [],
	branch: store.branchStore.branch,

});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAllExpense,
			getUsers,
			AddAccountDetail,
			UpdateAcountDetail,
			getAllRecievables,
			getAllAccountRecieveble
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
