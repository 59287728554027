import { message } from 'antd';
import Axios from 'axios'
import { deleteAllCookies, getCookie, setCookie } from '../../common/common';
import store from '../store';


// consultation loader true
function authenticationLoadingBool(bool) {
    return dispatch => {
        dispatch(authenticationLoading(bool));
    };
}
export const AUTHENTICATION_LOADING = 'AUTHENTICATION_LOADING';
export const authenticationLoading = (bool) => {
    return {
        type: AUTHENTICATION_LOADING,
        payload: bool
    }
}

// user login
export function userLogin(obj, pushToNext) {
    return dispatch => {
        dispatch(authenticationLoadingBool(true))
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}login/`,
            data: obj
        }).then((res) => {
            message.success("Login Success")
            setCookie("authToken", res.data.token, 1)
            setCookie("user", JSON.stringify(res.data.user), 1)
            dispatch(userLoginSuccess(res.data.user, true))
            dispatch(authenticationLoadingBool(false))
            pushToNext()
        }).catch((error) => {
            dispatch(authenticationLoadingBool(false))
            if (error.response) {
                message.error(error.response.data.non_field_errors[0]);
            }
        });
    };
}
export const LOGIN_USER_DATA = 'LOGIN_USER_DATA';
export const userLoginSuccess = (data, isAuthenticated) => ({
    type: LOGIN_USER_DATA,
    payload: { data, isAuthenticated }
});

// check user
export function checkUser(obj) {
    return dispatch => {
        let isAuthenticated = getCookie("authToken")
        let user = getCookie("user") ? JSON.parse(getCookie("user")) : false
        dispatch(userLoginSuccess(user, isAuthenticated))
    };
}

// user logout
export function userLogout() {
    return dispatch => {
        message.success("Logout Success")
        deleteAllCookies()
        let isAuthenticated = false
        let user = false
        dispatch(userLoginSuccess(user, isAuthenticated))
    };
}

// get user permissions
export function getUserPermissions(id, branchCode) {
    return dispatch => {
        dispatch(authenticationLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}userCustomPermission/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getUserPermissionsSuccess(res.data))
            dispatch(authenticationLoadingBool(false))
        }).catch((res) => {
            dispatch(authenticationLoadingBool(false))
        });
    };
}
export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';
export const getUserPermissionsSuccess = (data) => ({
    type: GET_USER_PERMISSION,
    payload: { data }
});


// pending stone or reciepts
export function getPendingItems(branchCode) {
    return dispatch => {
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}getPendingStonesAndReceipt/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(getPendingItemsSuccess(res.data))
        }).catch((error) => {
            if (error.response) {
                if(error.response.data.non_field_errors.length){
                    message.error(error.response.data.non_field_errors[0]);
                }
            }
        });
    };
}
export const PENDING_ITEMS = 'PENDING_ITEMS';
export const getPendingItemsSuccess = (data) => ({
    type: PENDING_ITEMS,
    payload: { data }
});
