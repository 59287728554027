import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Divider, message, Popconfirm, Select } from "antd";
import { Table, Tag, Space } from "antd";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import AddStones from "./addstoneModal";
import {
  deleteStone,
  getSingleItem,
  updateSingleItem,
  updateSingleItemConsultant,
} from "../../modules/actions/dataEntryActions";
import { withRouter } from "react-router";
import { getColorFormDataAdd } from "../../modules/actions/FormValues";

const { Option } = Select;
const { Column, ColumnGroup } = Table;

function AddItems(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [item, setItem] = useState("Loose Stones");
  const [itemDes, setItemDes] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [weightDes, setWeightDes] = useState("ct");
  const [value, setValue] = useState("Above 5000");
  const [rate, setRate] = useState("");
  const [services, setServices] = useState("");
  const [weightRange, setWeightRange] = useState("");
  const [seal, setSeal] = useState({});
  const [mine, setMine] = useState({});
  const [urgent, setUrgent] = useState({});
  const [goldTest, setGoldTest] = useState({});
  const [goldTestingRate, setGoldTestingRate] = useState(0);
  const [mineRate, setMineRate] = useState(0);
  const [urgentRate, setUrgentRate] = useState(0);
  const [sealRate, setSealRate] = useState(0);
  const [stoneArr, setStoneArr] = useState([]);
  const [stoneIndex, setStoneIndex] = useState("");
  const [editStoneObj, setEditStoneObj] = useState("");
  const [isService, setIsService] = useState(false);

  const roundOffAmount = (number) => {
    // return number
    let lastNumber = number % 10;
    if (lastNumber >= 5) {
      return number - lastNumber + 10;
    } else {
      return number - lastNumber;
    }
  };

  const [cvdVarified, setCvdVarified] = useState(true);

  let formData = props.formData;

  function setModalVisibleFn(modalVisible) {
    setModalVisible(modalVisible);
  }

  const selectAfter = (
    <Select
      value={weightDes}
      onChange={(ev) => setWeightDes(ev)}
      className="select-after"
    >
      <Option value="g">g</Option>
      <Option value="ct">ct</Option>
      <Option value="kg">kg</Option>
    </Select>
  );

  useEffect(() => {
    console.log("service", props.editObj.service);
    // console.log('errorrrr')
    // setRate("")
    console.log(services);
    // if(services.name != props.editObj.service){
    // 	// console.log(services.name,props.editObj.service,"")
    // console.log('errorrrr')//
    if (isService) {
      setRate(true);
      // setIsService(false)
    }
    // 	setGoldTestingRate(0)
    // 	setMineRate(0)
    // 	setUrgentRate(0)
    // 	setSealRate(0)

    // }
  }, [isService]);
  useEffect(() => {
    console.log(urgentRate, "urgentRate");
  }, [urgentRate]);

  const cvdCalculation = () => {
    let toCheck = Number(weight) / Number(quantity);
    let serviceRate = 0;
    if (toCheck < 0.2) {
      if (props.membershipActive) {
        if (Number(weight) > 0.01 && Number(weight) <= 1) {
          serviceRate += Number(props.formData.CVDTesting[0].member_price);
        }
        if (Number(weight) > 1.01 && Number(weight) <= 5) {
          serviceRate +=
            Number(weight) * Number(props.formData.CVDTesting[1].member_price);
        }
        if (Number(weight) > 5) {
          serviceRate += 5 * Number(props.formData.CVDTesting[1].member_price);
          serviceRate +=
            (Number(weight) - 5) *
            Number(props.formData.CVDTesting[2].member_price);
        }
      } else {
        if (Number(weight) > 0.01 && Number(weight) <= 1) {
          serviceRate += Number(props.formData.CVDTesting[0].price);
        }
        if (Number(weight) > 1 && Number(weight) <= 5) {
          serviceRate +=
            Number(weight) * Number(props.formData.CVDTesting[1].price);
        }
        if (Number(weight) > 5) {
          serviceRate += 5 * Number(props.formData.CVDTesting[1].price);
          serviceRate +=
            (Number(weight) - 5) * Number(props.formData.CVDTesting[2].price);
        }
      }
      console.log("86");
      console.log(rate, "testError");
      console.log("errorrrr");
      setRate(roundOffAmount(Number(serviceRate)));
      setCvdVarified(true);
      return roundOffAmount(Number(serviceRate));
    } else {
      alert(
        "The Weight and Quantity does not meet the requirement of LOTS \n " +
          " Each Stone Avg Weight is : " +
          toCheck
      );
      setCvdVarified(false);
    }
  };

  useEffect(() => {
    if (item === "Jewellery") {
      setWeightDes("g");
    } else if (item === "XRF") {
      setServices({ name: "XRF", member_price: 2000, price: 2000 });
    } else {
      setWeightDes("ct");
    }
  }, [item]);

  useEffect(() => {
    if (value === "Below 5000") {
      console.log("112");

      // console.log(rate, "testError")
      // console.log('errorrrr')
      setRate(Number(props.formData.below5000) * Number(quantity));
    } else {
      // console.log("116")
      if (!props.editObj) {
        if (
          services.name !== "Jewellery Card" &&
          services.name !== "Jewellery Certificate" &&
          services.name !== "Gemstone Jewellery Card" &&
          services.name !== "Gemstone Jewellery Certificate"
        ) {
          // console.log(services, 'runnnnnn')
          // console.log(rate, "testError")
          // console.log('errorrrr')//
          setRate(0);
        }
      }
      if (services.name === "Apprasial Certificate") {
        if (quantity > 1) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(formData.LotApprasialCertificate[0].price) * Number(quantity)
          );
        } else {
          if (props.membershipActive) {
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(
              Number(services.member_price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          } else {
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(
              Number(services.price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          }
        }
      } else if (services.name === "Master Reports") {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(services.member_price) +
              Number(mineRate) +
              Number(sealRate) +
              Number(goldTestingRate) +
              Number(urgentRate)
          );
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(services.price) +
              Number(mineRate) +
              Number(sealRate) +
              Number(goldTestingRate) +
              Number(urgentRate)
          );
        }
      } else if (
        services.name === "Apprasial Card" ||
        services.name === "Consultation"
      ) {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(services.member_price) +
              Number(mineRate) +
              Number(sealRate) +
              Number(goldTestingRate) +
              Number(urgentRate)
          );
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(services.price) +
              Number(mineRate) +
              Number(sealRate) +
              Number(goldTestingRate) +
              Number(urgentRate)
          );
        }
      } else if (
        services.name === "Gemstone Card" ||
        services.name === "Gemstone Certificate"
      ) {
        // console.log('dsadas')
        if (quantity > 1) {
          if (props.membershipActive) {
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(
              Number(services.member_price) * Number(weight) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          } else {
            // console.log(
            // 	(Number(services.price) * Number(weight) +
            // 	sealRate +
            // 	mineRate +
            // 	urgentRate +
            // 	goldTestingRate), "=====>"
            // )
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(
              Number(services.price) * Number(weight) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );

            // PEW22019556
          }
        } else {
          if (props.membershipActive) {
            console.log(rate, "testError");
            console.log(
              Number(services.member_price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate),
              "testError"
            );
            console.log("errorrrr");
            setRate(
              Number(services.member_price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          } else {
            console.log("OK");
            console.log(
              Number(services.price) +
                "+" +
                sealRate +
                "+" +
                mineRate +
                "+" +
                urgentRate +
                "+" +
                goldTestingRate,
              "testError"
            );
            console.log(
              Number(services.price) * Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate),
              "testError"
            );
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(
              Number(services.price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          }
        }
      } else if (
        services.name === "Diamond Card" ||
        services.name === "Diamond Certificate"
      ) {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          if (weightRange) {
            setRate(
              Number(weightRange.member_price) +
                Number(mineRate) +
                Number(sealRate) +
                Number(goldTestingRate) +
                Number(urgentRate)
            );
          }
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(
            Number(weightRange.price) +
              Number(mineRate) +
              Number(sealRate) +
              Number(goldTestingRate) +
              Number(urgentRate)
          );
        }
      } else if (
        services.name === "Jewellery Card" ||
        services.name === "Jewellery Certificate" ||
        services.name === "Gemstone Jewellery Card" ||
        services.name === "Gemstone Jewellery Certificate"
      ) {
        if (props.editObj) {
          console.log(rate, "testError");
          let rateee = 0;
          if (stoneArr.length) {
            stoneArr.map((a, i) => {
              rateee += Number(a.sub_Rate);
            });
            rateee +=
              Number(props.editObj.mineRate) +
              Number(props.editObj.sealRate) +
              Number(props.editObj.urgentRate) +
              Number(props.editObj.goldTestingRate);
            console.log(rateee, "sdadasdss rate dekh  RAHE ");
            // console.log('errorrrr')
            setRate(rateee);
          } else {
            console.log("errorrrrService");
            setRate(0);
          }
          // if(props.editObj.service === services.name){
          console.log("errorrrr", rateee); //
          setRate(rateee);
          // }else{
          // }
        } else {
          // console.log(rate, "testError")
          // console.log('errorrrr')
          // setRate(services.price + Number(mineRate) + Number(sealRate) + Number(goldTestingRate) + Number(urgentRate));
          // console.log(props.editObj.serviceRate, 'serviceRate')
          // // console.log(rate,"testError")
          // console.log('errorrrrService')//
          // setRate(0);
        }
      } else if (
        services.name === "Gemstone Strand Card" ||
        services.name === "Gemstone Strand Certificate" ||
        services.name === "Pearl Strand Card" ||
        services.name === "Pearl Strand Certificate"
      ) {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      } else if (services.name === "CVD Testing (Lots)") {
        cvdCalculation();
      } else if (services.name === "XRF") {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      } else if (services.name === "Gold Test") {
        // console.log(services.name);
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      } else if (services.name === "Verbal") {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      } else if (services.name === "Master Pearl Report") {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      } else if (services.name === "Pearl Testing") {
        if (props.membershipActive) {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.member_price);
        } else {
          console.log(rate, "testError");
          console.log("errorrrr");
          setRate(services.price);
        }
      }

      // else if(services.name === )
    }
  }, [services, quantity, weight, weightRange, value]);

  useEffect(() => {
    // console.log(weight)
  }, [weight]);

  const setCheckBoxVal = (ev, name) => {
    if (name === "Seal") {
      setSeal({ ...JSON.parse(ev.target.value), checked: ev.target.checked });
      if (ev.target.checked) {
        console.log(rate, "testError");
        console.log("errorrrr");
        setRate(Number(rate) + Number(JSON.parse(ev.target.value).price));
        setSealRate(Number(JSON.parse(ev.target.value).price));
      } else {
        console.log(rate, "testError");
        console.log("errorrrr");
        setRate(Number(rate) - Number(JSON.parse(ev.target.value).price));
        setSealRate(0);
      }
    }
    if (name === "Mine") {
      setMine({ ...JSON.parse(ev.target.value), checked: ev.target.checked });
      if (ev.target.checked) {
        console.log(rate, "testError");
        console.log("errorrrr");
        setRate(Number(rate) + Number(JSON.parse(ev.target.value).price));
        setMineRate(Number(JSON.parse(ev.target.value).price));
      } else {
        console.log(rate, "testError");
        console.log("errorrrr");
        setRate(Number(rate) - Number(JSON.parse(ev.target.value).price));
        setMineRate(0);
      }
    }
    if (name === "Urgent") {
      if (item === "Jewellery") {
        console.log(services, "urgenttt");
        if (services.name === "Apprasial Certificate") {
          setUrgent({
            ...JSON.parse(ev.target.value),
            checked: ev.target.checked,
          });
          if (quantity > 1) {
            if (ev.target.checked) {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(
                Number(rate) +
                  Number(formData.LotApprasialCertificate[0].price) *
                    Number(quantity)
              );
              setUrgentRate(
                Number(formData.LotApprasialCertificate[0].price) *
                  Number(quantity)
              );
            } else {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(
                Number(rate) -
                  Number(formData.LotApprasialCertificate[0].price) *
                    Number(quantity)
              );
              setUrgentRate(0);
            }
          } else {
            if (ev.target.checked) {
              if (props.membershipActive) {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(
                  Number(rate) +
                    Number(services.member_price) +
                    Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
                setUrgentRate(
                  Number(services.member_price) +
                    Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
              } else {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(
                  Number(rate) +
                    Number(services.price) +
                    Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
                setUrgentRate(
                  Number(services.price) +
                    Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
              }
            } else {
              if (props.membershipActive) {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(
                  Number(rate) -
                    Number(services.member_price) +
					Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
                setUrgentRate(0);
              } else {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(
                  Number(rate) -
                    Number(services.price) +
                    Number(mineRate) +
                    Number(sealRate) +
                    Number(goldTestingRate) +
                    Number(urgentRate)
                );
                setUrgentRate(0);
              }
            }
          }
        } else if (
          services.name === "Jewellery Card" ||
          services.name === "Jewellery Certificate" ||
          services.name === "Gemstone Jewellery Card" ||
          services.name === "Gemstone Jewellery Certificate"
        ) {
          let ratee = 0;
          for (let i = 0; i < stoneArr.length; i++) {
            ratee += Number(stoneArr[i].sub_Rate);
          }
          // console.log(rate,'urgenttt')
          if (ev.target.checked) {
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(rate + ratee);
            setUrgentRate(Number(ratee));
          } else {
            console.log(rate, "testError");
            console.log("errorrrr");
            setRate(Number(rate) - Number(ratee));
            setUrgentRate(0);
          }
        } else {
          setUrgent({
            ...JSON.parse(ev.target.value),
            checked: ev.target.checked,
          });
          if (ev.target.checked) {
            if (props.membershipActive) {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) +Number(services.member_price));
              setUrgentRate(Number(services.member_price));
            } else {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) + services.price);
              setUrgentRate(Number(services.price));
            }
          } else {
            if (props.membershipActive) {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) -Number(services.member_price));
              setUrgentRate(0);
            } else {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) - Number(services.price));
              setUrgentRate(0);
            }
          }
        }
      } else {
        if (item === "CVD Testing (Lots)") {
          if (ev.target.checked) {
            let cvdPrice = cvdCalculation();
            // console.log(price )
            // console.log("line == > 301");
            if (cvdPrice) {
              // console.log(rate,"testError")
              console.log("errorrrr");
              setRate(0);
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) + Number(cvdPrice));
              // console.log("line == > 304", rate + cvdPrice);
              setUrgentRate(Number(cvdPrice));
            }
          } else {
            let cvdPrice = cvdCalculation();

            if (cvdPrice) {
              console.log(rate, "testError");
              console.log("errorrrr");
              setRate(Number(rate) - Number(cvdPrice));
              setUrgentRate(0);
            }
          }
        } else {
          if (
            services.name === "Diamond Card" ||
            services.name === "Diamond Certificate"
          ) {
            if (ev.target.checked) {
              if (props.membershipActive) {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(Number(rate) + Number(weightRange.member_price));
                setUrgentRate(Number(weightRange.member_price));
              } else {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(Number(rate) + Number(weightRange.price));
                setUrgentRate(Number(weightRange.price));
              }
            } else {
              if (props.membershipActive) {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(Number(rate) - Number(weightRange.member_price));
                setUrgentRate(0);
              } else {
                console.log(rate, "testError");
                console.log("errorrrr");
                setRate(Number(rate) - Number(weightRange.price));
                setUrgentRate(0);
              }
            }
          } else {
            setUrgent({
              ...JSON.parse(ev.target.value),
              checked: ev.target.checked,
            });
            if (ev.target.checked) {
              if (props.membershipActive) {
                if (value === "Above 5000") {
                  console.log(rate, "testError");
                  console.log("errorrrr");
                  setRate(Number(rate) +Number(services.member_price));
                  setUrgentRate(Number(services.member_price));
                } else {
                  console.log(rate, "testError");
                  console.log("errorrrr");
                  setRate(Number(rate) + 500);
                  setUrgentRate(500);
                }
              } else {
                if (value === "Above 5000") {
                  console.log(rate, "testError");
                  console.log("errorrrr");
                  setRate(Number(rate) + Number(services.price));
                  setUrgentRate(Number(services.price));
                } else {
                  console.log(rate, "testError");
                  console.log("errorrrr");
                  setRate(Number(rate) + 500);
                  setUrgentRate(500);
                }
              }
            } else {
              if (props.membershipActive) {
                if (value === "Above 5000") {
                  // console.log(rate, "testError")
                  // console.log('errorrrr')
                  setRate(Number(rate) -Number(services.member_price));
                  setUrgentRate(0);
                } else {
                  // console.log(rate, "testError")
                  // console.log('errorrrr')
                  setRate(Number(rate) - 500);
                  setUrgentRate(0);
                }
              } else {
                if (value === "Above 5000") {
                  // console.log(rate, "testError")
                  // console.log('errorrrr')
                  setRate(Number(rate) - Number(services.price));
                  setUrgentRate(0);
                } else {
                  // console.log(rate, "testError")
                  // console.log('errorrrr')
                  setRate(Number(rate) - 500);
                  setUrgentRate(0);
                }
              }
            }
          }
        }
      }
    }
    if (name === "Gold Test") {
      setGoldTest({
        ...JSON.parse(ev.target.value),
        checked: ev.target.checked,
      });
      if (ev.target.checked) {
        // console.log(rate, "testError")
        // console.log('errorrrr')
        setRate(
          Number(rate) +
            Number(JSON.parse(ev.target.value).price) * Number(quantity)
        );
        setGoldTestingRate(
          Number(JSON.parse(ev.target.value).price) * Number(quantity)
        );
      } else {
        // console.log(rate, "testError")
        // console.log('errorrrr')
        setRate(
          Number(rate) -
            Number(JSON.parse(ev.target.value).price) * Number(quantity)
        );
        setGoldTestingRate(0);
      }
    }
  };

  useEffect(() => {
    console.log(rate, "rate");
  }, [rate]);

  function addData(obj) {
    setStoneArr([...stoneArr, obj]);
    let arr = [...stoneArr, obj];
    let ratee = 0;
    for (let i = 0; i < arr.length; i++) {
      ratee += Number(arr[i].sub_Rate);
    }
    console.log("86");

    // console.log(rate, "testError")
    // console.log('errorrrr')
    setRate(ratee);
  }

  useEffect(() => {
    updateData(stoneArr);
  }, []);

  function updateData(arr, obj) {
    let ratee = 0;
    // console.log(arr, "testError")
    for (let i = 0; i < arr.length; i++) {
      console.log(arr[i].sub_Rate, "dsadsads");
      ratee += Number(arr[i].sub_Rate);
    }
    console.log(obj, "errorrrr");
    if (obj) {
      ratee +=
        Number(obj.mineRate) +
        Number(obj.sealRate) +
        Number(obj.goldTestingRate) +
        Number(obj.urgentRate);
    }
    console.log(ratee, "testError");
    console.log(ratee, "errorrrr");
    setRate(ratee);
    setStoneArr(arr);
  }

  const addItem = (type) => {
    let obj = {
      itemIndex: props.items.length,
      color: color,
      goldTestingRate: Number(goldTestingRate),
      item: item,
      jewelleryItems: itemDes,
      mineRate: Number(mineRate),
      quantity: quantity,
      sealRate: Number(sealRate),
      selectstoneValue: value,
      service: services.name,
      serviceRate: rate - sealRate - mineRate - urgentRate - goldTestingRate,
      urgentRate: Number(urgentRate),
      weight: weight,
      weightDescription: weightDes,
      weightRange: weightRange ? weightRange.name : "",
      cvdColorRate: 0,
      stoneArr: stoneArr.map((obj) => {
        return { ...obj, itemIndex: props.items.length };
      }),
    };
    console.log(obj);
    props.addData(obj);

    if (type === "save&close") {
      props.setModal3VisibleFn(false);
    }
  };

  document.onkeyup = function (e) {
    // console.log(e.which);
    if (e.altKey && e.which == 65) {
      addItem();
    } else if (e.altKey && e.which == 67) {
      props.setModal3VisibleFn(false);
    }
  };

  useEffect(() => {
    let obj = props.editObj;
    if (obj) {
      setQuantity(obj.quantity);
      setItem(obj.item);
      setItemDes(obj.jewelleryItems);
      setColor(obj.color);
      setWeight(obj.weight);
      setWeightDes(obj.weightDescription);
      setValue(obj.selectstoneValue);
      let { sealRate, mineRate, urgentRate, goldTestingRate } = obj;
      console.log(obj);

      let getServiceRate = 0;
      obj.stoneArr.map((a, i) => {
        getServiceRate += a.sub_Rate;
      });
      getServiceRate +=
        Number(mineRate) +
        Number(sealRate) +
        Number(goldTestingRate) +
        Number(urgentRate);
      console.log(getServiceRate, "errorrrr");
      setRate(Number(getServiceRate));
      setServices(
        obj.item === "Loose Stones"
          ? formData.LoseStoneService.find((a) => a.name === obj.service)
          : obj.item === "Jewellery"
          ? formData.JewelleryService.find((a) => a.name === obj.service)
          : obj.item === "Strand"
          ? formData.StrandService.find((a) => a.name === obj.service)
          : obj.item === "CVD Testing (Lots)"
          ? { name: "CVD Testing (Lots)" }
          : ""
      );

      if (obj.service === "Diamond Card") {
        let a = formData.DiamondCard.filter((a) => a.name === obj.weightRange);
        setWeightRange(a[0]);
      } else if (obj.service === "Diamond Certificate") {
        let a = formData.DiamondCertificate.filter(
          (a) => a.name === obj.weightRange
        );
        setWeightRange(a[0]);
      }
      setSeal({
        name: "Seal",
        price: Number(obj.sealRate),
        checked: Number(obj.sealRate) ? true : false,
      });
      setMine({
        name: "Mine",
        price: Number(obj.mineRate),
        checked: Number(obj.mineRate) ? true : false,
      });
      setUrgent({
        name: "Urgent",
        price: Number(obj.urgentRate),
        checked: Number(obj.urgentRate) ? true : false,
      });
      setGoldTest({
        name: "Gold Test",
        price: Number(obj.goldTestingRate),
        checked: Number(obj.goldTestingRate) ? true : false,
      });
      setGoldTestingRate(Number(obj.goldTestingRate));
      setMineRate(Number(obj.mineRate));
      setUrgentRate(Number(obj.urgentRate));
      setSealRate(Number(obj.sealRate));
      updateData(obj.stoneArr ? obj.stoneArr : [], obj);
    } else {
      setQuantity(1);
      setItem("Loose Stones");
      setItemDes("");
      setColor("");
      setWeight("");
      setWeightDes("ct");
      setValue("Above 5000");
      console.log(rate, "testError");
      console.log("errorrrr");
      setRate("");
      setServices("");
      setWeightRange("");
      setSeal({});
      setMine({});
      setUrgent({});
      setGoldTest({});
      setGoldTestingRate(0);
      setMineRate(0);
      setUrgentRate(0);
      setSealRate(0);
      setStoneArr([]);
    }
  }, [props.modal3Visible]);

  const updateItem = (bool) => {
    console.log("workinnnnnnnnnnnnnnnnnnnnnnnnnnnn", props);
    let items = props.items;
    let index = props.index;
    if (window.location.pathname === "/take-in-items") {
      let items = props.items;
      let index = props.index;

      items[index].itemIndex = props.index;
      items[index].color = color;
      items[index].goldTestingRate = goldTestingRate;
      items[index].item = item;
      items[index].jewelleryItems = itemDes;
      items[index].mineRate = mineRate;
      items[index].quantity = quantity;
      items[index].sealRate = sealRate;
      items[index].selectstoneValue = value;
      items[index].service = services.name;
      items[index].serviceRate =
        rate - sealRate - mineRate - urgentRate - goldTestingRate;
      items[index].urgentRate = urgentRate;
      items[index].weight = weight;
      items[index].weightDescription = weightDes;
      items[index].weightRange = weightRange ? weightRange.name : "";
      items[index].stoneArr = stoneArr;
      items[index].cvdColorRate = 0;
      if (bool) {
        if (items[index].service === "Consultation") {
          window.location.href = `/consultant?entryNo=${
            items[index].relatedEntryNo
              ? items[index].relatedEntryNo
              : items[index].entryNo
          }`;
          // props.history.push(`/consultant?entryNo=${items[index].relatedEntryNo ? items[index].relatedEntryNo : items[index].entryNo}`);
          console.log(items[index]);
          // props.history.push(`/consultant?entryNo=${entryNumber ? entryNumber : obj.entryNo}`);
        } else {
          props.updateItem(items, bool, pushToNext, items[index]);
          // console.log(items,'dasdads');
        }
      }
      console.log(items, "dasdads");
      // if (props.editItemBool) {

      // if (props.fromEdit || (props.userPermissions && props.userPermissions.userType == "Admin")) {
      // 	// console.log(items[index].itemId, "items[index]");
      // 	if (props.consultantBool) {
      // 		props.updateSingleItemConsultant(
      // 			items[index].id,
      // 			items[index],
      // 			pushToNext,
      // 			bool,
      // 			props.closeAddItemModel,
      // 			props.permission.branchCode,
      // 			items[index].itemId,
      // 			items[index].ItemrelatedEntryNo
      // 		);
      // 	} else {
      // 		console.log(items[index], '===1')
      // 		props.updateSingleItem(
      // 			items[index].id,
      // 			items[index],
      // 			pushToNext,
      // 			bool,
      // 			props.closeAddItemModel,
      // 			props.permission.branchCode,
      // 			items[index].itemId,
      // 			items[index].ItemrelatedEntryNo
      // 		);
      // 	}
      // } else {
      // console.log(items[index], "items[index]");
      // props.updateItem(items);
      props.closeAddItemModel();
      // }
      // }
    } else if (window.location.pathname === "/take-out-items") {
      if (props.entry.consultationArray.length) {
        let obj = {
          consultationDetailColor:items[index].consultationDetailColor,
          consultationDetailConclusion:items[index].consultationDetailConclusion,
          consultationDetailCut:items[index].consultationDetailCut,
          consultationDetailMeas1:items[index].consultationDetailMeas1,
          consultationDetailMeas2:items[index].consultationDetailMeas2,
          consultationDetailMeas3:items[index].consultationDetailMeas3,
          consultationDetailShape:items[index].consultationDetailShape,
          consultationDetailSpecies:items[index].consultationDetailSpecies,
          consultationDetailTreatment:items[index].consultationDetailTreatment,
          consultationDetailVariety:items[index].consultationDetailVariety,
          consultationDetailWeight:items[index].consultationDetailWeight,
          consultation: true,
          itemIndex: props.index,
          color: color,
          goldTestingRate: goldTestingRate,
          item: item,
          jewelleryItems: itemDes,
          mineRate: mineRate,
          quantity: quantity,
          sealRate: sealRate,
          selectstoneValue: value,
          service: services.name,
          serviceRate:
            rate - sealRate - mineRate - urgentRate - goldTestingRate,
          urgentRate: urgentRate,
          weight: weight,
          weightDescription: weightDes,
          weightRange: weightRange ? weightRange.name : "",
          stoneArr: stoneArr,
          cvdColorRate: 0,
        };

        if (obj.service === "consultation" && bool) {
          console.log("hihihhdihihffhdhfihfhdjfhdhfdjfhj");

          window.location.href = `/consultant?entryNo=${
            items[index].relatedEntryNo
              ? items[index].relatedEntryNo
              : obj.entryNo
          }`;
          // props.history.push(`/consultant?entryNo=${items[index].relatedEntryNo ? items[index].relatedEntryNo : items[index].entryNo}`);
          // console.log(items[index])
          // props.history.push(`/consultant?entryNo=${entryNumber ? entryNumber : obj.entryNo}`);
        } else {
          console.log("Update item//////", props.editObj);
          props.updateItem(obj, bool, pushToNext, props.editObj.id);
          // console.log(items,'dasdads');
        }
      } else {
        let obj = {
          itemIndex: props.index,
          color: color,
          goldTestingRate: goldTestingRate,
          item: item,
          jewelleryItems: itemDes,
          mineRate: mineRate,
          quantity: quantity,
          sealRate: sealRate,
          selectstoneValue: value,
          service: services.name,
          serviceRate:
            rate - sealRate - mineRate - urgentRate - goldTestingRate,
          urgentRate: urgentRate,
          weight: weight,
          weightDescription: weightDes,
          weightRange: weightRange ? weightRange.name : "",
          stoneArr: stoneArr,
          cvdColorRate: 0,
        };

        if (obj.service === "consultation" && bool) {
          console.log("hihihhdihihffhdhfihfhdjfhdhfdjfhj");

          window.location.href = `/consultant?entryNo=${
            items[index].relatedEntryNo
              ? items[index].relatedEntryNo
              : obj.entryNo
          }`;
          // props.history.push(`/consultant?entryNo=${items[index].relatedEntryNo ? items[index].relatedEntryNo : items[index].entryNo}`);
          // console.log(items[index])
          // props.history.push(`/consultant?entryNo=${entryNumber ? entryNumber : obj.entryNo}`);
        } else {
          console.log("Update item//////", props.editObj);
          props.updateItem(obj, bool, pushToNext, props.editObj.id);
          // console.log(items,'dasdads');
        }
      }
    }

    console.log(items, "dasdads");

    props.closeAddItemModel();
  };

  const pushToNext = (obj, id, entryNumber) => {
    console.log("what", props.editObj);

    if (
      obj.service === "Apprasial Card" ||
      obj.service === "Apprasial Certificate"
    ) {
      window.location.href = `/appraisal-certificate-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (
      obj.service === "Gemstone Card" ||
      obj.service === "Gemstone Strand Card" ||
      obj.service === "Pearl Strand Card"
    ) {
      window.location.href = `/small-gemstone-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (
      obj.service === "Gemstone Certificate" ||
      obj.service === "Gemstone Strand Certificate" ||
      obj.service === "Pearl Strand Certificate" ||
      obj.service === "Gemstone Jewellery Certificate"
    ) {
      window.location.href = `/gemstone-report-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Diamond Card") {
      console.log("pushed");
      window.location.href = `/small-diamond-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Diamond Certificate") {
      window.location.href = `/diamond-grading-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Jewellery Card") {
      window.location.href = `/small-jewellery-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Jewellery Certificate") {
      window.location.href = `/jewellery-report-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Gemstone Jewellery Card") {
      window.location.href = `/small-gemstone-jewellery-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Master Reports") {
      window.location.href = `/master-colored-stone-form?id=${
        id ? id : obj.id
      }&entryNo=${entryNumber ? entryNumber : obj.entryNo}&consultation=${
        props.entry && props.entry.consultationArray ? 1 : 0
      }`;
    } else if (obj.service === "Consultation") {
      window.location.href = `/consultant?entryNo=${
        entryNumber ? entryNumber : obj.entryNo
      }&consultation=${props.entry && props.entry.consultationArray ? 1 : 0}`;
    } else if (obj.service === "No Decision") {
      window.location.href = `/consultant?entryNo=${
        entryNumber ? entryNumber : obj.entryNo
      }&consultation=${props.entry && props.entry.consultationArray ? 1 : 0}`;
    }
  };

  const editStoneTrue = (obj, i) => {
    setStoneIndex(i);
    setEditStoneObj(obj);
    setModalVisibleFn(true);
  };

  const confirmDeleteStone = (e, i, record) => {
    let arr = [...stoneArr];
    arr.splice(i, 1);
    // setStoneArr(arr);
    props.deleteStone(record.id, arr, setStoneArr, updateData);
    // updateData(arr);
  };

  useEffect(() => {
    console.log("stoneArr", stoneArr);
  }, [stoneArr]);

  // useEffect(() => {
  // 	console.log(rate, "775")
  // 	let ratee = rate;
  // 	console.log(rate, "testError")
  // console.log('errorrrr')//
  // setRate(ratee);
  // }, [sealRate, mineRate, urgentRate, goldTestingRate]);

  const clearItemModal = () => {
    // setQuantity(1);
    // setItem("Loose Stones");
    // setItemDes("");
    // setColor("");
    // setWeight("");
    // setWeightDes("ct");
    // setValue("Above 5000");
    console.log(rate, "testError");
    console.log("errorrrr"); //
    setRate("");
    // setWeightRange("");
    // setSeal({});
    // setMine({});
    // setUrgent({});
    // setGoldTest({});
    setGoldTestingRate(0);
    setMineRate(0);
    setUrgentRate(0);
    setSealRate(0);

    if (
      services.name === "Jewellery Card" ||
      services.name === "Jewellery Certificate"
    ) {
      console.log("errorrrr");
      setRate(0);
      setStoneArr([]);
    }
  };
  const [colorArr, setColorArr] = useState([]);
  const [colorName, setColorName] = useState(false);
  const onColorChange = (event) => {
    setColorName(event.target.value);
  };

  const addColorOpt = () => {
    if (colorName) {
      if (
        colorArr.filter(
          (obj) => obj.name.toLowerCase() === colorName.toLowerCase()
        ).length
      ) {
        message.error("Color is already exist");
      } else {
        console.log("addItem");
        setColorArr([...colorArr, { name: colorName }]);
        props.getColorFormDataAdd({ name: colorName });
        setColorName(false);
      }
    }
  };

  useEffect(() => {
    if (props.fields) {
      setColorArr(props.fields.color);
    }
    console.log(props.fields, "props.fields");
  }, [props.fields]);

  return (
    <div className="entriesModal">
      {!props.fromTakeOut && (
        <Button
          disabled={!props.selectedCustomer.customerName}
          type="primary"
          className="customerModalButton highlight"
          onClick={() => props.setModal3VisibleFn(true)}
        >
          Add Items
        </Button>
      )}
      <Modal
        title="Entrie List"
        className="AddModal"
        style={{ top: 20, maxHeight: "90vh" }}
        visible={props.modal3Visible}
        onCancel={() => {
          props.closeAddItemModel();
          setIsService(false);
        }}
        footer={[
          <button
            type="button"
            className="ant-btn"
            style={{
              background: "#f50057",
              color: "white",
              borderColor: "#f50057",
            }}
            onClick={() => {
              props.closeAddItemModel();
              setIsService(false);
            }}
            // onClick={()=>console.log(props.editObj)}
          >
            <span>Cancel</span>
          </button>,
          !props.editObj && (
            <button
              type="button"
              className="ant-btn ant-btn-primary"
              style={{
                background: "#001529",
                color: "white",
                borderColor: "#001529",
              }}
              onClick={() => {
                props.editObj ? updateItem(true) : addItem("save&close");
                setIsService(false);
                props.setReCalculate(!props.reCalculate);
              }}
              disabled={!services || !quantity || !cvdVarified}
            >
              <span>Add & Close</span>
            </button>
          ),
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            style={{
              background: "#001529",
              color: "white",
              borderColor: "#001529",
            }}
            onClick={() => {
              props.editObj ? updateItem(true) : addItem("add");
              setIsService(false);
              props.setReCalculate(!props.reCalculate);
            }}
            disabled={!services || !quantity || !cvdVarified}
          >
            <span>Add</span>
          </button>,
          props.editObj &&
            props.editObj.entryNo &&
            item !== "CVD Testing (Lots)" && (
              <button
                type="button"
                className="ant-btn ant-btn-primary"
                style={{
                  background: "#001529",
                  color: "white",
                  borderColor: "#001529",
                }}
                onClick={() => {
                  updateItem(true);
                  setIsService(false);
                  props.setReCalculate(!props.reCalculate);
                }}
                disabled={
                  !services ||
                  !quantity ||
                  !cvdVarified ||
                  (props.editObj.reportObj.length ? true : false)
                }
              >
                <span>Update & continue</span>
              </button>
            ),
          <button
            type="button"
            className="ant-btn ant-btn-primary"
            style={{
              display: props.editObj ? "inline-block" : "none",
              background: "#001529",
              color: "white",
              borderColor: "#001529",
            }}
            onClick={() => {
              updateItem(false);
              props.setReCalculate(!props.reCalculate);
            }}
            disabled={!services || !quantity || !cvdVarified}
          >
            <span>Update</span>
          </button>,
        ]}
      >
        <div className="addItemForm">
          <div className="inputFields">
            <label>
              <h5>Quantity</h5>
              <InputNumber
                value={quantity ? quantity : ""}
                onChange={(ev) => setQuantity(ev)}
              />
            </label>
          </div>
          <div className="inputFields">
            <label>
              <h5>Item</h5>
              <Select
                className="select"
                value={item}
                onChange={(ev) => {
                  setServices("");
                  setWeightRange("");
                  setItem(ev);
                  setStoneArr([]);
                  setSeal({});
                  setMine({});
                  setGoldTest({});
                  setUrgent({});
                  setSealRate(0);
                  setMineRate(0);
                  setGoldTestingRate(0);
                  setUrgentRate(0);
                  console.log(rate, "testError");
                  console.log("errorrrr");
                  setRate(0);
                  if (ev != "CVD Testing (Lots)") {
                    setCvdVarified(true);
                  }
                }}
              >
                {[
                  { value: "Loose Stones", label: "Loose Stones" },
                  { value: "Jewellery", label: "Jewellery" },
                  { value: "Strand", label: "Strand" },
                  { value: "CVD Testing (Lots)", label: "CVD Testing (Lots)" },
                  { value: "XRF", label: "XRF" },
                  // { value: "Gold Test", label: "Gold Test" }
                ].map((a, i) => {
                  return (
                    <Option key={i} value={a.value}>
                      {a.label}
                    </Option>
                  );
                })}
              </Select>
            </label>
          </div>
          {item === "XRF" ? (
            <div className="inputFields">
              <label>
                <h5>Rate</h5>
                <InputNumber value={rate ? rate : ""} disabled />
              </label>
            </div>
          ) : (
            <>
              {item === "Jewellery" ? (
                <div className="inputFields">
                  <label>
                    <h5>Item Description</h5>
                    <Select
                      value={itemDes}
                      className="select"
                      onChange={(ev) => setItemDes(ev)}
                    >
                      {formData.JewelleryItems.map((a, i) => {
                        return (
                          <Option key={i} value={a.value}>
                            {a.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </label>
                </div>
              ) : null}
              {item === "Jewellery" ? (
                <div className="inputFields">
                  <label>
                    <h5>Color</h5>
                    <Select
                      value={color}
                      className="select"
                      onChange={(ev) => setColor(ev)}
                    >
                      {[
                        { label: "Yellow", value: "Yellow" },
                        { label: "White", value: "White" },
                        { label: "Two tone", value: "Two tone" },
                      ].map((a, i) => {
                        return (
                          <Option key={i} value={a.value}>
                            {a.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </label>
                </div>
              ) : (
                <div className="inputFields">
                  <label>
                    <h5>Color</h5>
                    <Select
                      showSearch
                      onChange={(ev) => setColor(ev)}
                      className="select"
                      defaultValue=""
                      value={color}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={colorName ? colorName : ""}
                              onChange={onColorChange}
                            />
                            {colorName ? (
                              <a
                                style={{
                                  flex: "none",
                                  padding: "8px",
                                  display: "block",
                                  cursor: "pointer",
                                }}
                                onClick={addColorOpt}
                              >
                                <PlusOutlined />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      )}
                    >
                      {colorArr.map((a, i) => {
                        return (
                          <Option value={a.name} key={i}>
                            {a.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </label>
                </div>
              )}
              <div className="inputFields">
                <label>
                  <h5>Weight</h5>
                </label>
                <Input
                  value={weight}
                  addonAfter={selectAfter}
                  onChange={(ev) => {
                    // let serviceValue = services
                    // setServices('')
                    setWeight(ev.target.value);
                    // setServices(serviceValue)
                  }}
                />
              </div>
              {item !== "Jewellery" ? (
                <div className="inputFields">
                  <label>
                    <h5>Value</h5>
                    <Select
                      className="select"
                      value={value}
                      onChange={(ev) => {
                        setValue(ev);
                        setServices("");
                        setUrgent({});
                        setUrgentRate(0);
                        console.log(rate, "testError");
                        console.log("errorrrr");
                        setRate(0);
                      }}
                    >
                      <Option value="Below 5000">Below 5000</Option>
                      <Option value="Above 5000">Above 5000</Option>
                    </Select>
                  </label>
                </div>
              ) : null}
              <div className="inputFields">
                <label>
                  <h5>Rate</h5>
                  <InputNumber value={rate ? rate : ""} disabled />
                </label>
              </div>
              <div
                className="inputFields w100P"
                onClick={() => {
                  console.log(stoneArr);
                }}
              >
                <label>
                  <h5>Service</h5>
                  <Select
                    value={services ? JSON.stringify(services) : ""}
                    className="select"
                    onChange={(ev) => {
                      // if(JSON.parse(ev))
                      if (props.editObj) {
                        if (stoneArr.length) {
                          alert("Please clear your stones to change service");
                        } else {
                          // setIsService(false)
                          clearItemModal();
                          setServices(JSON.parse(ev));
                          setWeightRange("");
                          setStoneArr([]);
                          setIsService(true);
                        }
                      } else {
                        // setIsService(false)
                        clearItemModal();
                        setServices(JSON.parse(ev));
                        setWeightRange("");
                        setStoneArr([]);
                        setIsService(true);
                      }
                    }}
                  >
                    {item === "Loose Stones"
                      ? formData.LoseStoneService.map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })
                      : null}

                    {item === "Jewellery"
                      ? formData.JewelleryService.map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })
                      : null}

                    {item === "Strand"
                      ? formData.StrandService.map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })
                      : null}

                    {item === "CVD Testing (Lots)"
                      ? [{ name: "CVD Testing (Lots)" }].map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                </label>
              </div>
              {services.name === "Diamond Card" ||
              services.name === "Diamond Certificate" ? (
                <div className="inputFields w100P">
                  <label>
                    <h5>Weight Range</h5>
                    <Select
                      value={weightRange ? JSON.stringify(weightRange) : ""}
                      className="select"
                      onChange={(ev) => setWeightRange(JSON.parse(ev))}
                    >
                      {services.name === "Diamond Card" &&
                        formData.DiamondCard.map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })}
                      {services.name === "Diamond Certificate" &&
                        formData.DiamondCertificate.map((a, i) => {
                          return (
                            <Option key={i} value={JSON.stringify(a)}>
                              {a.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </label>
                </div>
              ) : null}

              {(item === "Jewellery" && services.name === "Jewellery Card") ||
              services.name === "Jewellery Certificate" ||
              services.name === "Gemstone Jewellery Card" ||
              services.name === "Gemstone Jewellery Certificate" ? (
                <div className="inputFields w100P">
                  <h5>Stones</h5>
                  <AddStones
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    setModalVisibleFn={setModalVisibleFn}
                    addData={addData}
                    updateData={updateData}
                    services={services}
                    cardOrCerificates={services.name}
                    stoneArr={stoneArr}
                    setStoneArr={setStoneArr}
                    stoneIndex={stoneIndex}
                    setStoneIndex={setStoneIndex}
                    editStoneObj={editStoneObj}
                    setEditStoneObj={setEditStoneObj}
                    items={props.items}
                    index={props.index}
                    edit={modalVisible}
                    itemid={props.editObj.id}
                  />

                  {stoneArr.length > 0 ? (
                    <Table className="itemsTable" dataSource={stoneArr}>
                      <Column
                        title="St. Position"
                        dataIndex="StonePosition"
                        key="StonePosition"
                      />
                      <Column
                        title="Quantity"
                        dataIndex="Sub_quantity"
                        key="Sub_quantity"
                      />
                      <Column
                        title="stone"
                        dataIndex="selectStone"
                        key="selectStone"
                      />
                      <Column title="Color" dataIndex="color" key="color" />
                      <Column
                        title="Weight"
                        dataIndex="sub_weight"
                        key="sub_weight"
                      />
                      <Column
                        title="Rate"
                        dataIndex="sub_Rate"
                        key="sub_Rate"
                      />
                      <Column
                        title="Action"
                        key="action"
                        render={(text, record, index) => (
                          <Space size="middle">
                            <a>
                              <EditOutlined
                                onClick={() => editStoneTrue(record, index)}
                              />
                            </a>
                            <Popconfirm
                              onConfirm={(e) =>
                                confirmDeleteStone(e, index, record)
                              }
                              title="Are you sure you want to delete？"
                              okText="Yes"
                              cancelText="No"
                            >
                              <a>
                                <DeleteOutlined />
                              </a>
                            </Popconfirm>
                          </Space>
                        )}
                      />
                    </Table>
                  ) : null}
                </div>
              ) : null}
              <div
                className="inputFields w100P checboxes"
                style={{ marginTop: 20 }}
              >
                {formData.extraService.map((a, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={
                          a.name === "Seal"
                            ? sealRate
                            : a.name === "Mine"
                            ? mineRate
                            : a.name === "Urgent"
                            ? urgentRate
                            : a.name === "Gold Test"
                            ? goldTestingRate
                            : 0
                        }
                        key={i}
                        value={JSON.stringify(a)}
                        onChange={(ev) => setCheckBoxVal(ev, a.name)}
                        disabled={
                          !services ||
                          ((services.name === "Jewellery Card" ||
                            services.name === "Jewellery Certificate" ||
                            services.name === "Gemstone Jewellery Card" ||
                            services.name === "Gemstone Jewellery Card") &&
                            !stoneArr.length > 0)
                        }
                      >
                        {a.name}
                      </Checkbox>
                      <span style={{ color: "green" }}>
                        {a.name === "Seal"
                          ? sealRate
                          : a.name === "Mine"
                          ? mineRate
                          : a.name === "Urgent"
                          ? urgentRate
                          : a.name === "Gold Test"
                          ? goldTestingRate
                          : 0}
                      </span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (store) => {
  return {
    formData: store.FormData,
    membershipActive: store.customers.membershipActive,
    permission: store.users.permission,
    singleItemEntry: store.dataEntry.singleItemEntry,
    userPermissions: store.authentication.userPermissions,
    fields: store.FormValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSingleItem,
      updateSingleItemConsultant,
      getSingleItem,
      deleteStone,
      getColorFormDataAdd,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddItems));
