import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, message, Row, Select, Spin } from "antd";
import { Table, Tag, Popconfirm } from "antd";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AddMembershipModel from "./addMembershipModel";
import { setCustomer, updateCustomer, setMembership, getsingleMembership } from "../../modules/actions/customerActions";
import { deleteSingleMembership } from "../../modules/actions/customerReportActions";
import JsBarcode from "jsbarcode";
function AddCustomer(props) {
	const [memModel, setMemModel] = useState(false);
	const [memArr, setMemArr] = useState([]);

	const [customerId, setCustomerId] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [customerName, setCustomerName] = useState("");
	const [membershipDetail, setMembershipDetail] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [mobileNo2, setMobileNo2] = useState("");
	const [nicNo, setNicNo] = useState("");
	const [ntnNo, setNtnNo] = useState("");
	const [allowPrint, setAllowPrint] = useState(false)
	const [membershipBtn, setmembershipBtn] = useState(false)
	const [editObj, setEditObj] = useState("");
	const [ID, setID] = useState(0);

	const dateTakeIn = (deliveryDate) => {
		let month = new Date(deliveryDate).getMonth() + 1;
		let day = new Date(deliveryDate).getDate();
		let year = new Date(deliveryDate).getFullYear();

		return `${day}-${month}-${year}`;
	};
	useEffect(() => {
		if (props.customers) {
			setCustomerId(
				"Cu-" +
				(Number(this.props.customers[this.props.customers.length - 1].customerId.slice(7)) + 1)
			);
		} else {
			setCustomerId("Cu-1");
		}
	}, []);

	const clearState = () => {
		setMemModel(false);
		setMemArr([]);
		setCustomerId("");
		setAddress("");
		setCity("");
		setCompanyName("");
		setCustomerName("");
		setMembershipDetail("");
		setMobileNo("");
		setMobileNo2("");
		setNicNo("");
		setNtnNo("");
	};

	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [memeberReceptData, setMemeberReceptData] = useState(false)

	const showModal = (data) => {
		console.log(data, 'showModal')
		setMemeberReceptData(data)
		setIsModalOpen(true);
	};



	const handleOk = () => {
		// setIsModalOpen(false);
		setMemeberReceptData(false)
	};

	const handleCancel = () => {
		// setIsModalOpen(false);
		setMemeberReceptData(false)
	};

	const addCustomer = (print) => {
		let obj = {
			customerName: customerName,
			companyName: companyName,
			mobileNo: mobileNo ? Number(mobileNo) : mobileNo2 ? Number(mobileNo2) : '',
			mobileNo2: mobileNo2 ? Number(mobileNo2) : mobileNo ? Number(mobileNo) : '',
			NIC_NO: Number(nicNo),
			address: address,
			city: city,
			branchId: Number(1),
			// membershipDetail: memArr
		};
		console.log('setCustomer')
		if (obj.customerName,
			obj.mobileNo,
			obj.mobileNo2,
			obj.city,
			obj.address) {
			props.setCustomer(obj, props.permission.branchCode, props.onCancel, setSelectedCustomer, allowPrint && print ? showModal : false);
			setmembershipBtn(true);
		} else {
			message.error(`Customer Name, Mobile No, Mobile No 2, Address , City is required`)
		}

	};

	const updateCutomerr = (print) => {
		let obj = {
			customerName: customerName,
			companyName: companyName,
			mobileNo: mobileNo ? Number(mobileNo) : mobileNo2 ? Number(mobileNo2) : '',
			mobileNo2: mobileNo2 ? Number(mobileNo2) : mobileNo ? Number(mobileNo) : '',
			NIC_NO: Number(nicNo),
			address: address,
			city: city,
			branchId: Number(1),
			// membershipDetail: memArr,
			customerId: customerId
		};
		// console.log('updateCustomer',obj, props.page, props.permission.branchCode, props.pageSize, props.onCancel, allowPrint , print , showModal , false)
		props.updateCustomer(obj, props.page, props.permission.branchCode, props.pageSize, props.onCancel, allowPrint && print ? showModal : false, ID);

	};

	const deleteMember = (i) => {
		let arr = [...memArr];
		arr.splice(i, 1);
		setMemArr(arr);
	};

	const confirmDeleteMember = (e, i, id) => {
		if (!props.fromReports) {
			deleteMember(i);
		} else {
			props.deleteSingleMembership(id, deleteMember, i, props.page, props.permission.branchCode);
		}
	};

	useEffect(() => {
		if (props.editObj && props.editObj.customerId) {
			props.getsingleMembership({
				customerId: props.editObj.customerId
			})
		}
	}, [props.editObj])

	useEffect(() => {
		if (props.editObj) {
			setCustomerId(props.editObj.customerId);
			setAddress(props.editObj.address);
			setCity(props.editObj.city);
			setCompanyName(props.editObj.companyName);
			setCustomerName(props.editObj.customerName);
			setMobileNo(props.editObj.mobileNo);
			setMobileNo2(props.editObj.mobileNo2);
			setNicNo(props.editObj.NIC_NO);
			setID(props.editObj.id)
		}

		console.log("newe memeberReceptData", memeberReceptData)
	}, [props.editObj, memeberReceptData]);

	useEffect(() => {

		if (props.singlemembership) {
			console.log(props.singlemembership, 'asdasdasdas')
			setMemArr(props.singlemembership);

		}
	}, [props.singlemembership])

	useEffect(() => {
		if (memeberReceptData) {
			console.log(memeberReceptData, 'memeberReceptData')
			JsBarcode("#barcode", 23312312, {
				format: "CODE128",
				// lineColor: "#0aa",
				width: 1,
				height: 50,
				displayValue: false
			});
		}
	}, [memeberReceptData]);

	return (
		<>
			<Modal
				title={props.title}
				className="AddModal"
				style={props.style}
				visible={props.visible}
				footer={[
					<Button className="cancelButton" key="back" onClick={() => {
						props.onCancel();
						clearState();
					}}>
						Cancel
					</Button>,
					<Button className="submitButton" key="submit" type="primary"
						disabled={props.customerLoading}
						onClick={() => {
							// setmembershipBtn(true);
							if (!props.customerLoading) {
								if (!props.ViewCustomerModel) {
									if (!props.editObj) {
										addCustomer(false);
									} else {
										updateCutomerr(false);
									}
								} else {
									props.onCancel();
								}
							}
						}}>
						{props.customerLoading ? <div className="spinnerItemDiv">
							<Spin />
						</div> : 'Submit'}
					</Button>,
					<Button
						type="primary"
						className="submitButton"
						disabled={!memArr.length || props.customerLoading}
						onClick={() => {
							if (!props.customerLoading) {
								if (!props.ViewCustomerModel) {
									if (!props.editObj) {
										addCustomer(true);
									} else {
										updateCutomerr(true);
									}
								} else {
									props.onCancel();
								}
							}
						}}
					>
						{props.customerLoading ? <div className="spinnerItemDiv">
							<Spin />
						</div> : "Save & Print Membership"}
					</Button>,
				]}
				// onOk={() => {
				// 	if (!props.customerLoading) {
				// 		if (!props.ViewCustomerModel) {
				// 			if (!props.editObj) {
				// 				addCustomer();
				// 			} else {
				// 				updateCutomerr();
				// 			}
				// 		} else {
				// 			props.onCancel();
				// 		}
				// 	}
				// }}
				// onCancel={() => {
				// 	props.onCancel();
				// 	clearState();
				// }}
				okText={props.customerLoading ? 'loading' : !props.ViewCustomerModel ? props.okText : null}
				onCancel={() => {
					props.onCancel();
					clearState();
				}}
				width="1000px"
				okButtonProps={{ style: { background: "#001529", color: "white", borderColor: "#001529" }, disabled: props.customerLoading }}
				cancelButtonProps={{
					style: { background: "#f50057", color: "white", borderColor: "#f50057" }
				}}
			>
				<div className="addCustomerModelMain">
					<div className="addCustomerInputMain">
						{/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input value={customerId} size="large" disabled placeholder="Customer Id" />
                    </Col> */}

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<Input
								autoFocus={true}
								onChange={(ev) => setCustomerName(ev.target.value)}
								value={customerName}
								size="large"
								placeholder="Customer Name"
								style={{ textTransform: "capitalize" }}
								disabled={props.ViewCustomerModel}
							/>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<Input
								style={{ textTransform: "capitalize" }}
								onChange={(ev) => setCompanyName(ev.target.value)}
								value={companyName}
								size="large"
								placeholder="Company Name"
								disabled={props.ViewCustomerModel}
							/>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<div style={{ width: "100%" }}>
								<Input value="+92" disabled style={{ width: `60px` }} size="large" />
								<Input
									style={{ width: `calc(100% - 60px)` }}
									onChange={(ev) => {
										if (ev.target.value.toString().length <= 10) {
											setMobileNo(ev.target.value);
										}
									}}
									value={mobileNo}
									size="large"
									type="number"
									placeholder="Mobile No"
									disabled={props.ViewCustomerModel}
								/>
							</div>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<div style={{ width: "100%" }}>
								<Input
									value="+92"
									style={{ width: `60px` }}
									size="large"
									disabled
								// disabled={props.ViewCustomerModel}
								/>
								<Input
									style={{ width: `calc(100% - 60px)` }}
									onChange={(ev) => {
										if (ev.target.value.toString().length <= 10) {
											setMobileNo2(ev.target.value);
										}
									}}
									value={mobileNo2}
									size="large"
									type="number"
									placeholder="Mobile No"
									disabled={props.ViewCustomerModel}
								/>
							</div>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<Input
								onChange={(ev) => {
									if (ev.target.value.toString().length <= 13) {
										setNicNo(ev.target.value);
									}
								}}
								value={nicNo}
								size="large"
								type="number"
								placeholder="NIC No."
								disabled={props.ViewCustomerModel}
							/>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<Input
								onChange={(ev) => setNtnNo(ev.target.value)}
								value={ntnNo}
								size="large"
								type="number"
								placeholder="NTN No."
								disabled={props.ViewCustomerModel}
							/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<TextArea
								onChange={(ev) => setAddress(ev.target.value)}
								value={address}
								placeholder="Address"
								disabled={props.ViewCustomerModel}
							/>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							<Input
								onChange={(ev) => setCity(ev.target.value)}
								value={city}
								size="large"
								type="text"
								placeholder="City"
								style={{ textTransform: "capitalize" }}
								disabled={props.ViewCustomerModel}
							/>
						</Col>

						<Col xs={11} sm={11} md={11} lg={11} xl={11}>
							{!props.ViewCustomerModel && props.memberShipEditBtn ? (

								<Button
									// disabled={!customerName || !mobileNo || !mobileNo2}
									className="addMemBut"
									onClick={() =>
										setMemModel(true)
									}
								>
									Add Membership
								</Button>

							) :

								<div>

								</div>

							}
						</Col>
					</div>

					<div className="memTableDiv">
						<table>
							<tbody>
								<tr>
									{/* <th>Membership No</th> */}
									<th>Start Date</th>
									<th>End Date</th>
									<th>Membership</th>
									<th>Membership No</th>
									<th>Actions</th>
								</tr>

								{memArr.map((a, i) => {
									return (
										<tr key={i}>
											{/* <td>Cu-1-00{i}</td> */}
											<td>{new Date(a.startDate).toLocaleDateString()}</td>
											<td>{new Date(a.endDate).toLocaleDateString()}</td>
											<td>
												{/* {new Date(a.endDate).getTime() > (new Date(a.startDate).getFullYear() === new Date().getFullYear()) ? */}
												{new Date(a.endDate).getTime() >= new Date().getTime() &&
													new Date(a.startDate).getTime() <= new Date().getTime() ? (
													<Tag color="success">Active</Tag>
												) : (
													<Tag color="error">Expire</Tag>
												)}
											</td>
											<td>{a.membershipNo}</td>
											<td>
												{props.userPermissions.customer_edit ?
													<>
														{!props.ViewCustomerModel && (
															<i
																onClick={() => {
																	setEditObj({ ...a, index: i });
																	setMemModel(true);
																}}
																className="fas fa-pen"
															></i>
														)}

														{!props.ViewCustomerModel && (
															<Popconfirm
																onConfirm={(e) => confirmDeleteMember(e, i, a.id)}
																title="Are you sure you want to delete this membership？"
																okText="Yes"
																cancelText="No"
															>
																<i className="fas fa-trash"></i>
															</Popconfirm>
														)}
													</>
													: null}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>

				<AddMembershipModel
					title={"Add Membership"}
					className="AddModal"
					style={props.style}
					visible={memModel}
					onOk={() => {
						setMemModel(false);
						setEditObj("");
					}}
					onCancel={(clearState2) => {
						setMemModel(false);
						setEditObj("");
						clearState2();
					}}
					okText={editObj ? "Update Membership" : "Add Membership"}
					setMemArr={setMemArr}
					memArr={memArr}
					editObj={editObj}
					fromReports={props.fromReports}
					page={props.page}
					allowPrint={allowPrint}
					setAllowPrint={setAllowPrint}

					memeberReceptData={props.editObj}
				/>



			</Modal>

			<Modal title="Basic Modal" className="cashRecieveModalMain" visible={memeberReceptData} onOk={handleOk} onCancel={handleCancel}>
				{/* { memeberReceptData && memeberReceptData ?  */}
				<div className="cashRecieveModal">
					<div id="main">
						<div id="mainDiv">
							<div id="heading">Office Copy</div>
							<div id="headDiv">
								<table id="table1">
									<tbody>
										<tr>
											<td className="bold">Customer Name: </td>
											<td className="text1">
												{memeberReceptData && memeberReceptData.customerName}
											</td>
										</tr>
										<tr>
											<td className="bold">Customer Id: </td>
											<td className="text1">
												{memeberReceptData && memeberReceptData.customerId}
											</td>
										</tr>
										<tr>
											<td className="bold">Reciept no: </td>
											<td className="text1">
												{memeberReceptData && memeberReceptData.membershipDetail.length ? memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].entryNo : ''}
											</td>
										</tr>
										<tr>
											<td className="bold">Mobile No: </td>
											<td className="text1">
												{memeberReceptData && memeberReceptData.mobileNo
													? memeberReceptData.mobileNo
													: memeberReceptData.mobileNo2 ? memeberReceptData.mobileNo2 : ''}
											</td>
										</tr>
									</tbody>
								</table>
								<table id="table2">
									<tbody>
										<tr>
											<td className="bold">Print Date: </td>
											<td>{dateTakeIn(new Date())}</td>
										</tr>
										<tr>
											<td className="bold">Print Time: </td>
											<td>{`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}</td>
										</tr>
										<tr>
											<td colSpan="2">
												<svg id="barcode"></svg>
											</td>
										</tr>
										<tr>
											<td colspan="2" id="center"></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div id="div2">
								<table id="mainTable">
									<tbody>
										<tr className="color">
											<th className="tr">S.no</th>
											<th className="tr">Des</th>
											<th className="tr">Membership No</th>
											<th className="tr">Membership Start Date</th>
											<th className="tr">Membership End Date</th>
											<th className="tr">Amount</th>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td id="td">{1}</td>
											<td id="td">MemberShip</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipNo}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].startDate}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].endDate}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipRate}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div id="div3">
								<div id="divP"></div>
								<div id="tableDiv">
									<table id="bottomTable" className="bold">
										<tbody>
											<tr>
												<th>TotalAmount:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipRate}</td>
											</tr>
											<tr>
												<th>Discount:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].discountAmount}</td>
											</tr>
											<tr>
												<th>Cash Recieved:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipPayment}</td>
											</tr>
											<tr style={{ borderTop: "1px solid black" }}>
												<th>Balance:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipBalance}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div id="sign" className="h3">
								<h3>Customer Sign</h3>
							</div>
						</div>
						<div id="mainDiv2">
							<div id="heading">Customer Copy</div>
							<div id="headDiv">
								<table id="table1">

									<tbody>
										<tr>
											<td className="bold">Customer Name: </td>
											<td className="text1">{memeberReceptData && memeberReceptData.customerName}</td>
										</tr>
										<tr>
											<td className="bold">Customer Id: </td>
											<td className="text1">{memeberReceptData && memeberReceptData.customerId}</td>
										</tr>
										<tr>
											<td className="bold">Receipt No: </td>
											<td>{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].entryNo}</td>
										</tr>
										<tr>
											<td className="bold">Mobile No: </td>
											<td className="text1">{memeberReceptData && memeberReceptData.mobileNo ? memeberReceptData.mobileNo : memeberReceptData.mobileNo2 ? memeberReceptData.mobileNo2 : ''}</td>
										</tr>
									</tbody>


								</table>
								<table id="table2">
									<tbody>
										<tr>
											<th className="bold">Print Date:</th>
											<td className="text">{dateTakeIn(new Date())}</td>
										</tr>
										<tr>
											<th className="bold">Print Time:</th>
											<td className="text">{`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}</td>
										</tr>
										<tr>
											<td colSpan="2" id="center">
												<svg id="barcode"></svg>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div id="div2">
								<table id="mainTable">
									<tbody>
										<tr className="color">
											<th className="tr">S.no</th>
											<th className="tr">Des</th>
											<th className="tr">Membership No</th>
											<th className="tr">Membership Start Date</th>
											<th className="tr">Membership End Date</th>
											<th className="tr">Amount</th>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td id="td">{1}</td>
											<td id="td">MemberShip</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipNo}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].startDate}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].endDate}</td>
											<td id="td">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipRate}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div id="div3">
								<div id="divP"></div>
								<div id="tableDiv">
									<table id="bottomTable" className="bold">
										<tbody>
											<tr>
												<th>TotalAmount:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipRate}</td>
											</tr>
											<tr>
												<th>Discount:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].discountAmount}</td>
											</tr>
											<tr>
												<th>Cash Recieved:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipPayment}</td>
											</tr>
											<tr style={{ borderTop: "1px solid black" }}>
												<th>Balance:</th>
												<td className=" textI">{memeberReceptData && memeberReceptData.membershipDetail[memeberReceptData.membershipDetail.length - 1].membershipBalance}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div id="sign" className="h3">
								<h3>Customer Sign</h3>
							</div>
						</div>
					</div>
				</div>
				{/* :null}  */}
			</Modal>
		</>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission,
		customerLoading: store.customers.customerLoading,
		singlemembership: store.customers.singlemembership,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCustomer,
			updateCustomer,
			deleteSingleMembership,
			setMembership,
			getsingleMembership
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
