import React, { useEffect, useState } from "react";
import { Button, Collapse, Modal } from "antd";
import { connect } from "react-redux";
import JsBarcode from "jsbarcode";
import { dateFormat } from "../../common/common";

const CashRecievedModal = (props) => {
	let { isModalVisible, handleOk, handleCancel, data } = props;

	function printElem() {
		var sTable = document.getElementById("main").innerHTML;
		var win = window.open();

		var style = `<style>
        * {
        margin: 0;
        padding: 0;
    }
        #main {
            width: 100%;
            /* height: 100vh; */
            display: flex;
            justify-content: space-between;
            margin-top: 7%;
        }
        #mainDiv {
            width: 45%;
            height: 100%;
            margin-left: 20px;
        }
        #heading {
            width: 100%;
            text-align: center;
        }
        #headDiv {
            width: 100%;
            display: inline-block;
            font-size: 0.9em;
        }
        #table1 {
            margin-top: 10px;
            float: left;
            width: 60%;
        }
        #table1 .bold {
            font-size: 0.8em;
        }
        .bold {
            font-weight: bold;
        }
        .text1 {
            padding-left: 5px;
        }
        #table2 {
            margin-top: 10px;
            float: right;
            width: 39%;
        }
        .bold {
            font-weight: bold;
        }
        .bold {
            font-weight: bold;
        }
        #center {
            text-align: right;
        }
        #div2 {
            width: 100%;
            height: 55vh;
        }
        #mainTable {
            border-collapse: collapse;
        }
        #mainTable {
            border: 2px solid black;
            width: 100%;
            font-size: 0.84em;
        }
        .color {
            background: rgba(0, 0, 0, 0.3);
            -webkit-print-color-adjust: exact;
        }
        .tr {
            border-right: 1px solid black;
        }
        #div3 {
            width: 100%;
            display: flex;
            margin-top: 10px;
        }
        #divP {
            width: 50%;
            flex: 1;
            /* float: left; */
            font-size: 0.7em;
            font-style: italic;
        }
        #tableDiv {
            width: 50%;
            flex: 1;
            /* text-align: right; */
            /* float: right; */
        }
        #bottomTable {
            border-collapse: collapse;
        }
        #bottomTable {
            width: 78%;
            float: right;
            font-size: 0.8em;
        }
        .textI {
            font-style: italic;
            text-align: center;
        }
        #sign {
            margin-top: 20px;
            font-size: 0.9em;
            width: 50%;
            text-align: center;
        }
        #mainDiv2 {
            width: 47%;
            height: 100%;
            margin-right: 20px;
        }
        #heading {
            width: 100%;
            text-align: center;
        }
        #headDiv {
            width: 100%;
            display: inline-block;
            font-size: 0.9em;
        }
        #table1 {
            margin-top: 10px;
            float: left;
            width: 60%;
        }
        #table2 {
            margin-top: 10px;
            float: right;
            width: 39%;
        }
        #div2 {
            width: 100%;
            height: 55vh;
        }
        #mainTable1 {
            border-collapse: collapse;
        }
        #mainTable1 {
            border: 2px solid black;
            font-size: 0.84em;
            width: 100%;
        }   
        #div3 {
            width: 100%;
            display: flex;
            margin-top: 10px;
        }
        #divP {
            width: 50%;
            flex: 1;
            /* float: left; */
            font-size: 0.7em;
            font-style: italic;
        }
        #tableDiv {
            width: 50%;
            flex: 1;
            /* text-align: right; */
            /* float: right; */
        }
        #bottomTable {
            border-collapse: collapse;
        }
        #bottomTable {
            width: 78%;
            float: right;
            font-size: 0.8em;
        }
        .textI {
            font-style: italic;
            text-align: center;
        }
        #sign {
            margin-top: 20px;
            font-size: 0.9em;
            width: 50%;
            text-align: center;
        }
        </style>`;
		win.document.write("<html><head>");
		win.document.write("<title>Profile</title>");
		// win.document.write('<link href="./././print.css" rel="stylesheet">')
		win.document.write(style);
		win.document.write('<script src="JsBarcode.all.min.js"></script>');

		win.document.write("</head>");
		win.document.write("<body>");
		win.document.write('<div id="main">');
		win.document.write(sTable);
		win.document.write("</div>");

		win.document.write(`</body></html>`);

		win.document.close();
		setTimeout(() => {
			win.print();
		}, 700);
	}

	const [singleItem, setSingleItem] = useState({});

	useEffect(() => {
		if (props.cashReceivedSingleItem && isModalVisible) {
			setSingleItem(props.cashReceivedSingleItem);
			JsBarcode("#barcode", 21312312312, {
				format: "CODE128",
				width: 1,
				height: 50,
				displayValue: false
			});
		}
	}, [props.cashReceivedSingleItem]);

	return (
		<Modal
			className="cashRecieveModalMain"
			title="Basic Modal"
			visible={isModalVisible}
			onOk={() => {
				handleOk();
				printElem();
			}}
			onCancel={handleCancel}
		>
			<div className="cashRecieveModal">
				<div id="main">
					<div id="mainDiv">
						<div id="heading">Office Copy</div>
						<div id="headDiv">
							<table id="table1">
								<tbody>
									<tr>
										<td className="bold">Customer Name: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.customerName
												: singleItem.customerObj && singleItem.customerObj.customerName}
										</td>
									</tr>
									<tr>
										<td className="bold">Customer Id: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.customerId
												: singleItem.customerId}
										</td>
									</tr>
									<tr>
										<td className="bold">Recipt no: </td>
										<td className="text1">
											{props.edit && props.data ? props.data.entryNo : singleItem.entryNo}
										</td>
									</tr>
									<tr>
										<td className="bold">Mobile No: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.mobileNo
												: singleItem.customerObj && singleItem.customerObj.mobileNo}
										</td>
									</tr>
									<tr>
										<td className="bold">Entered By: </td>
										<td className="text1">
											{props.edit && props.data ? props.data.userName : singleItem.userName}
										</td>
									</tr>
								</tbody>
							</table>
							<table id="table2">
								<tbody>
									<tr>
										<td className="bold">Print Date: </td>
										<td>{dateFormat(new Date())}</td>
									</tr>
									<tr>
										<td className="bold">Print Time: </td>
										<td>{`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}</td>
									</tr>
									<tr>
										<td colSpan="2">
											<svg id="barcode"></svg>
										</td>
									</tr>
									<tr>
										<td colspan="2" id="center"></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div id="div2">
							<table id="mainTable">
								<tbody>
									<tr className="color">
										<th className="tr">S no</th>
										<th className="tr" colspan="6">
											Description
										</th>
										<th className="tr">Amount</th>
									</tr>
								</tbody>
								<tbody>
									<tr>
										<td id="td">1</td>
										<td id="td" colspan="6">
											Direct Cash Receive
										</td>
										<td id="td">
											{props.edit && props.data ? props.data.CashReceive : singleItem.CashReceive}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div id="div3">
							<div id="divP"></div>
							<div id="tableDiv">
								<table id="bottomTable" className="bold">
									<tbody>
										<tr>
											<th>Total Amount: </th>
											<td className="color textI">
												{props.edit && props.data ? props.data.CashReceive : singleItem.CashReceive}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div id="sign" className="h3">
							<h3>Customer Sign</h3>
						</div>
					</div>
					<div id="mainDiv2">
						<div id="heading">Customer Copy</div>
						<div id="headDiv">
							<table id="table1">
								<tbody>
									<tr>
										<td className="bold">Customer Name: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.customerName
												: singleItem.customerObj && singleItem.customerObj.customerName}
										</td>
									</tr>
									<tr>
										<td className="bold">Customer Id: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.customerId
												: singleItem.customerId}
										</td>
									</tr>
									<tr>
										<td
											className="bold"
											onClick={() => console.log(props.data, props.selectedCustomer)}
										>
											Recipt no:{" "}
										</td>
										<td className="text1">
											{props.edit && props.data ? props.data.entryNo : singleItem.entryNo}
										</td>
									</tr>
									<tr>
										<td className="bold">Mobile No: </td>
										<td className="text1">
											{props.edit && props.selectedCustomer
												? props.selectedCustomer.mobileNo
												: singleItem.customerObj && singleItem.customerObj.mobileNo}
										</td>
									</tr>
									<tr>
										<td className="bold">Entered By: </td>
										<td className="text1">
											{props.edit && props.data ? props.data.userName : singleItem.userName}
										</td>
									</tr>
								</tbody>
							</table>
							<table id="table2">
								<tbody>
									<tr>
										<td className="bold">Print Date: </td>
										<td>{dateFormat(new Date())}</td>
									</tr>
									<tr>
										<td className="bold">Print Time: </td>
										<td>{`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`}</td>
									</tr>
									<tr>
										<td colSpan="2">
											<svg id="barcode"></svg>
										</td>
									</tr>
									<tr>
										<td colspan="2" id="center"></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div id="div2">
							<table id="mainTable">
								<tbody>
									<tr className="color">
										<th className="tr">S no</th>
										<th className="tr" colspan="6">
											Description
										</th>
										<th className="tr">Amount</th>
									</tr>
								</tbody>
								<tbody>
									<tr>
										<td id="td">1</td>
										<td id="td" colspan="6">
											Direct Cash Receive
										</td>
										<td id="td">
											{props.edit && props.data ? props.data.CashReceive : singleItem.CashReceive}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div id="div3">
							<div id="divP"></div>
							<div id="tableDiv">
								<table id="bottomTable" className="bold">
									<tbody>
										<tr>
											<th>Total Amount: </th>
											<td className="color textI">
												{props.edit && props.data ? props.data.CashReceive : singleItem.CashReceive}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div id="sign" className="h3">
							<h3>Customer Sign</h3>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (store) => ({
	cashReceivedSingleItem: store.dataEntry.cashReceivedSingleItem
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CashRecievedModal);
