import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import store from '../store';


// Reports loader true
function CustomerReportsLoadingBool(bool) {
    return dispatch => {
        dispatch(CustomerReportsLoading(bool));
    };
}
export const CUSTOMER_REPORTS_LOADING = 'CUSTOMER_REPORTS_LOADING';
export const CustomerReportsLoading = (bool) => {
    return {
        type: CUSTOMER_REPORTS_LOADING,
        payload: bool
    }
}

//Get all customers
export function getAllCustomers(page, branchCode, searchText, activeness, pageSize) {
    return dispatch => {
        dispatch(CustomerReportsLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/?${page ? `page=${page}` : `page=${1}`}${branchCode ? `&branchCode=${branchCode}` : ''}${searchText ? `&searchText=${searchText}` : ''}${activeness ? `&memberShipType=${activeness}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getAllCustomersSuccess(res.data.results, res.data.page, res.data.total))
            dispatch(CustomerReportsLoadingBool(false))
        }).catch((res) => {
            dispatch(CustomerReportsLoadingBool(false))
        });
    };
}
export const GET_ALL_CUTOMERS = 'GET_ALL_CUTOMERS';
export const getAllCustomersSuccess = (data, page, total) => ({
    type: GET_ALL_CUTOMERS,
    payload: {
        data,
        page,
        total
    }
});


//Delete single membership
export function deleteSingleMembership(id, deleteItem, i, page, branchCode) {
    return dispatch => {
        // dispatch(CustomerReportsLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}membership/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            message.success("Membership Deleted")
            deleteItem(i)
            dispatch(getAllCustomers(page, branchCode))
            // dispatch(CustomerReportsLoadingBool(false))
        });
    };
}

//Edit single membership
export function editSingleMembership(id,obj, page, branchCode) {
    return dispatch => {
        // dispatch(CustomerReportsLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}membership/${id}/?`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Membership Updated")
            dispatch(getAllCustomers(page))
            // dispatch(CustomerReportsLoadingBool(false))
        });
    };
}

// delete customer
export function deleteCustomer(id, paged, branchCode,page, selectedBranch, searchText, activeness, pageSize) {
    return dispatch => {
        // dispatch(customerLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            message.success("Customer Deleted")
            dispatch(getAllCustomers(page, selectedBranch, searchText, activeness, pageSize));
        });
    };
}


// // get single membership
// export function singlemembership(id, paged, branchCode,page, selectedBranch, searchText, activeness, pageSize) {
//     return dispatch => {
//         // dispatch(customerLoadingBool(true))
//         let token = getCookie("authToken")
//         Axios({
//             method: 'delete',
//             url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/${id}/?branchCode=${branchCode}`,
//             headers: {
//                 'Authorization': `JWT ${token}`
//             },
//         }).then((res) => {
//             message.success("Customer Deleted")
//             dispatch(getAllCustomers(page, selectedBranch, searchText, activeness, pageSize));
//         });
//     };
// }