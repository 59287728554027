import Axios from 'axios'
import { CONSULTATION_LOADING, GET_SINGLE_DATA_ENTRY } from '../actions/consultationActions';

const initialState = {
    consultationLoading: false,
    singleEntry: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CONSULTATION_LOADING:
            return {
                ...state,
                consultationLoading: action.payload,
            };
        case GET_SINGLE_DATA_ENTRY:
            return {
                ...state,
                consultationLoading: false,
                singleEntry: action.payload.data
            };
        default:
            return state
    }
}

