import React, { useEffect, useState } from "react";
import { Input, Space, DatePicker } from "antd";
import { Col, Row, Select } from "antd";
import { connect } from "react-redux";
import ExpenseReport from "./ExpenseReport";
import Recievable from "./Recievable";
import { bindActionCreators } from "redux";
import { deleteAccountEntry, getAllAccountEntries, getAllAccountRecieveble } from "../../../modules/actions/reportsAction";
import { dateFormat } from "../../../common/common";
const { Search } = Input;

const { Option } = Select;

const AccountReport = (props) => {
	const [report, setReport] = useState("Expense");
	const [page, setPage] = useState(1);
	const [searchEnter, setSearchEnter] = useState(false);
	// const [keyword, setKeyWord] = useState("");
	const [startDate, setStartDate] = useState(new Date("1/1/1970, 12:00:00 AM"));
	const [endDate, setEndDate] = useState(new Date());
	const [keyword, setKeyword] = useState("");
	const [searchText, setSearchText] = useState("");
	const [type, setType] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [selectedBranch, setSelectedBranch] = useState("karachi");

	const confirmDeleteItem = (obj) => {
		if (selectedBranch) {
			let startDateF = dateFormat(new Date(startDate));
			let endDateF = dateFormat(new Date(endDate));
			props.deleteAccountEntry(
				obj.id,
				page,
				"Expenses",
				startDateF,
				endDateF,
				keyword,
				"Expense",
				"",
				selectedBranch,
				pageSize
			);
		}
	};

	useEffect(() => {
		console.log(props.userPermissions.userType)
		if (selectedBranch) {
			if (props.userPermissions.userType) {

				let startDateF = dateFormat(new Date(startDate));
				let endDateF = dateFormat(new Date(endDate));
				if (report === 'Expense') {
					props.getAllAccountEntries(
						page,
						"Expenses",
						startDateF,
						endDateF,
						keyword,
						report,
						"",
						selectedBranch === 'karachi' ? 'KHI' : selectedBranch,
						pageSize,
						props.userPermissions.userType.toLowerCase(),
						searchText

					);
				} else {
					props.getAllAccountRecieveble(
						page,
						"Expenses",
						startDateF,
						endDateF,
						keyword,
						report,
						"",
						selectedBranch === 'karachi' ? 'KHI' : selectedBranch,
						pageSize
					);
				}
			}
		}
	}, [page, "Expenses", startDate, endDate, report, props.multiVal, pageSize, selectedBranch,
		props.userPermissions.userType, searchEnter

	]);

	console.log(props.allAccountEntries);

	useEffect(() => {
		console.log(props.branch, "props.branch");
		if (props.permission.userType != "Admin" && props.permission.branchCode) {
			console.log('useEffectRunnn')
			setSelectedBranch(props.permission.branchCode);
		} else {
			console.log('useEffectRunnn')
			setSelectedBranch("KHI");
		}
	}, [props.permission.branchCode]);
	useEffect(() => {
		console.log(props.allAccountEntries, 'testPage')
	}, [props.allAccountEntries])

	// beckendchecking.softsyncdev.work/getDataEntry/?page=1&report_type=Expenses&startDate=1970-01-01&endDate=2021-09-17&searchText=Laboratory&type=Expense&name=undefined&branchCode=KHI&page_size=10
	// beckendchecking.softsyncdev.work/getDataEntry/?page=1&report_type=Expenses&startDate=1970-01-01&endDate=2021-09-17&searchText=Laboratory&type=Expense&name=&branchCode=KHI&page_size=10
	return (
		<div className="accountReport">
			<div className="innerAccountReport">
				<div className="filterDiv">
					<Row className="">
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<Space direction="vertical" className="w100P">
								<DatePicker onChange={(e) => setStartDate(e)} className="w100P" />
							</Space>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<Space direction="vertical" className="w100P">
								<DatePicker onChange={(e) => setReport(e)} className="w100P" />
							</Space>
						</Col>
						<Col xs={3} sm={3} md={3} lg={3} xl={3}>
							<Select
								onChange={(e) => setReport(e)}
								className="select w100P"
								defaultValue="Expenses"
							>
								<Option value="Expense">Expenses</Option>
								<Option value="Recievable">Recievable</Option>
							</Select>
						</Col>
						{report === "Expense" ?
							<>
								{props.userPermissions.admin_panel &&
									<Col xs={4} sm={4} md={4} lg={4} xl={4}>
										<Select
											defaultValue={selectedBranch}
											onChange={(e) => setSelectedBranch(e)}
											className="select w100P"
										>
											{props.branch
												? props.branch.map((a, i) => {
													return <Option value={a.branchCode}>{a.branchName}</Option>;
												})
												: null}
										</Select>
									</Col>
								}
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<Space direction="vertical" className="w100P">
										<Search onSearch={() => setSearchEnter(searchEnter ? false : true)} onChange={(e) => setSearchText(e.target.value)} placeholder="input search text" className="w100P" style={{ width: 2 }} />
									</Space>
								</Col>
							</>
							: null}
					</Row>
				</div>

				{report === "Expense" ? (
					<ExpenseReport
						confirmDeleteItem={confirmDeleteItem}
						setPage={setPage}
						pageNumber={page}
						setKeyword={setKeyword}
						keyword={keyword}
						setPageSize={setPageSize}
						pageSize={pageSize}
						allAccountEntries={props.allAccountEntries}
						startDate={startDate}
						endDate={endDate}
						total={props.total}
						reportsLoading={props.reportsLoading}
					/>
				) : (
					<Recievable
						setKeyword={setKeyword}
						data={props.allAccountEntries}
						reportsLoading={props.reportsLoading}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		permission: store.users.permission,
		allAccountEntries: store.reports.allAccountEntries ? store.reports.allAccountEntries : [],
		total: store.reports.total,
		reportsLoading: store.reports.reportsLoading,
		branch: store.branchStore.branch,
		page: store.reports.page,
		userPermissions: store.authentication.userPermissions,


	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAllAccountEntries,
			deleteAccountEntry,
			getAllAccountRecieveble
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(AccountReport);
