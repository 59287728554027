import { Modal, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EducationalExpenses from "./EducationalExpenses";
import LaboratoryExpenses from "./LaboratoryExpenses";
import AddAccount from "../../addAccount/Account";
import { dateFormat } from "../../../common/common";
import logo from "./../../../Images/Logo 2.png";

const ExpenseReport = (props) => {
	const [tabs, setTabs] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [data, setData] = useState(false);
	const [updatedTotalExpense,setUpdatedTotalExpense] = useState([])

	useEffect(() => {
		props.setKeyword(tabs ? "Laboratory" : "Education");
	}, [tabs]);
	console.log(props);

	const editExpenseModel = (a) => {
		setData(a);
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	// useEffect(() => {
	// 	let arr = []
	// 	if (props.allAccountEntries.length) {

	// 		props.allAccountEntries.map((a, i) => {
	// 			if (props.userPermissions.userType != "Admin") {
	// 				if (a.private === "false") {
	// 					arr.push(a)
	// 				}
	// 			} else if (props.userPermissions.userType === "Admin") {
	// 				// if (a.private === "false") {
	// 				arr.push(a)
	// 				// }
	// 			}
	// 		})
	// 		setUpdatedTotalExpense(arr)
	// 	}
	// }, [props.allAccountEntries])

	return (
		<div className="ExpenseReport">
			<div className="expensesBtn">
				<div>
					<button onClick={() => setTabs(true)} className={tabs ? "active" : ""}>
						LABORATORY EXPENSES
					</button>
					<button onClick={() => setTabs(false)} className={!tabs ? "active" : ""}>
						EDUCATIONAL EXPENSES
					</button>
				</div>
			</div>
			<div className="tableDiv">
				{props.reportsLoading ? (
					<div className="loaderDiv tableLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : null}
				{tabs ? (
					<LaboratoryExpenses
						editExpenseModel={editExpenseModel}
						confirmDeleteItem={props.confirmDeleteItem}
						setPage={props.setPage}
						data={props.allAccountEntries}
					/>
				) : (
					<EducationalExpenses
						editExpenseModel={editExpenseModel}
						confirmDeleteItem={props.confirmDeleteItem}
						setPage={props.setPage}
						data={props.allAccountEntries}
					/>
				)}
			</div>
			<Pagination
				onChange={(ev, size) => {
					props.setPage(ev);
					props.setPageSize(size);
				}}
				pageSize={props.pageSize}
				current={props.pageNumber}
				total={props.total}
			/>

			<Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<AddAccount
					handleCancel={handleCancel}
					edit={true}
					data={data}
					page={props.pageNumber}
					selectedReports={props.selectedReports}
					startDate={dateFormat(new Date(props.startDate))}
					endDate={dateFormat(new Date(props.endDate))}
					tabName={props.keyword}
					type="Expense"
					multiVal={props.multiVal}
					pageSize={props.pageSize}
				/>
			</Modal>
		</div>
	);
};

const mapStateToProps = (store) => ({
    userPermissions: store.authentication.userPermissions,
})

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReport);
