import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import blackLogo from "./../../../Images/Gemstone Report 2.jpg";
import chip from "./../../../Images/chip.jpg";
import sign from "./../../../Images/Sgnature.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import { Spin, Space } from "antd";
import logo from "./../../../Images/Gemstone-Report-2.jpg";
import line from "./../../../Images/symbols/line.png";
import signLogo from "./../../../Images/Gemstone-Report-2.jpg";
import signature from "./../../../Images/Sgnature.png";
import { dateFunction } from "../../../modules/actions/managerPanelActions";
import Column from "rc-table/lib/sugar/Column";
import "../diamond.scss";
import isoCertified from "./../../../Images/isoCeritifiedR.PNG";

function AppraisalPrint(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#main").clientWidth;
		let height = document.querySelector("#main").clientHeight;
		html2canvas(document.querySelector("#main"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}

	const [PrintArr, setPrintArr] = useState(false);
	useEffect(() => {
		setPrintArr(JSON.parse(localStorage.getItem("arr")));
	}, []);

	useEffect(() => {
		QRCode.toDataURL(`https://ggilab.com/Certificate/${PrintArr.certificate_no}`)
			.then((url) => {
				setQrUrl(url);
			})
			.catch((err) => {
				// console.error(err);
			});

		// With async/await
		const generateQR = async (text) => {
			try {
				// console.log(await QRCode.toDataURL(text));
			} catch (err) {
				// console.error(err);
			}
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, [PrintArr]);

	// console.log(PrintArr, "PrintArr Apraisal Certificate");
	return (
		<>
			{/* <div style={{
                display: capture ? "none" : "block"
            }} className="loader certificateLoader">
                <div className="innerLoader">
                    <Space>
                        <Spin size="large" />
                    </Space>
                    <h2 className="loadingText">Generating Certificate</h2>
                </div>
            </div> */}
			<div
				id="bigCertificate"
				style={{
					display: capture ? "none" : "block"
				}}
			>
				<div
					className="certificaateDiv appraCertificaateDiv"
					id="main"
					style={{
						display: "flex",
						alignItems: "center",
						overflow: "hidden"
						// justifyContent: "center"
					}}
				>
					{PrintArr ? (
						<div
							className="container appraContainer"
							style={{
								width: "1140px",
								padding: "0px 30px",
								display: "flex",
								flexDirection: "Column",
								justifyContent: "center",
								alignItems: "center",
								marginLeft: "100px",
								overflow: "hidden"
							}}
						>
							<div className="row" style={{ marginTop: "5px" }}>
								<div className="col-md-12" align="center">
									<br />
									<br />
									<img src={logo} style={{ marginRight: "81px", width: "350px" }} />
								</div>
								<div className="col-md-12" align="center" style={{ marginTop: "0px" }}>
									<p
										className="MsoNormal"
										style={{
											fontSize: "55px",
											marginLeft: "0px",
											marginLeft: "-66px",
											marginTop: "-4px",
											marginBottom: "5px",
											lineHeight: "56px"
										}}
									>
										<strong style={{ msoBidiFontWeight: "normal" }}>
											<span
												className="logoName"
												style={{
													fontSize: "55px",
													color: "#E00836"
												}}
											>
												<span>
													Galaxy Gemological Institute <p></p>
												</span>
											</span>
										</strong>
									</p>

									<p
										style={{
											fontSize: "40px",
											color: "#000",
											fontFamily: "cleric",
											textTransform: "uppercase",
											fontWeight: "bold",
											marginRight: "83px",
											fontStyle: "italic",
											marginTop: "-4px",
											lineHeight: "41px"
										}}
									>
										APPRAISAL REPORT
									</p>
								</div>
							</div>
							<table
								style={{
									width: "88%",
									textAlign: "left",
									fontFamily: "arial",
									fontSize: "20px",
									marginTop: "10px",
									fontWeight: "bold",
									marginLeft: "60px"
								}}
								align="center"
								cellPadding={0}
								cellSpacing={0}
							>
								<tbody>
									<tr style={{ height: "0px" }}>
										<td style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}>
											Date:
										</td>
										<td
											style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}
											colSpan={2}
										>
											{dateFunction(PrintArr.date != "0000-00-00" ? PrintArr.date : new Date(), true)} <br />
										</td>
										<td></td>
									</tr>
									<tr style={{ height: "0px" }}>
										<td style={{ textAlign: "left", paddingRight: "0px" }}></td>
										<td
											style={{ textAlign: "left", paddingRight: "10px", fontSize: "7px" }}
											colSpan={2}
										>
											<br />
										</td>
										<td></td>
									</tr>
									<tr style={{ height: "0px" }}>
										<td style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}>
											No.
										</td>
										<td
											style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}
											colSpan={2}
										>
											{PrintArr.certificate_no}{" "}
										</td>
									</tr>

									<tr style={{ height: "0px" }}>
										<td style={{ textAlign: "left", paddingRight: "0px" }}></td>
										<td
											style={{ textAlign: "left", paddingRight: "10px", fontSize: "7px" }}
											colSpan={2}
										>
											<br />
										</td>
										<td></td>
									</tr>
									<tr style={{ height: "40px" }}>
										<td
											style={{
												textAlign: "left",
												paddingRight: "10px",
												fontSize: "33px",
												verticalAlign: "top"
											}}
										>
											Object:
										</td>
										<td
											style={{
												textAlign: "left",
												fontWeight: 700,
												verticalAlign: "top",
												fontSize: "30px",
												textTransform: "none"
											}}
										>
											{PrintArr.object}
										</td>
									</tr>
									<tr style={{ height: "0px" }}>
										<td style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}>
											Issued to:
										</td>
										<td
											style={{ textAlign: "left", paddingRight: "10px", fontSize: "33px" }}
											colSpan={2}
										>
											{PrintArr.Companyname}{" "}
										</td>
									</tr>
								</tbody>
							</table>
							<div className="row" style={{ marginTop: "20px" }}>
								<div align="center" className="col-md-12">
									{PrintArr.image_1 ? (
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.image_1}`}
											alt=""
											className="img-thumbnail img-responsive"
											style={{
												width: "250px",
												height: "250px",
												padding: "5px",
												border: "1px solid #9C9C9C"
											}}
										/>
									) : null}
									{PrintArr.image_2 ? (
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.image_2}`}
											alt=""
											className="img-thumbnail img-responsive"
											style={{
												width: "250px",
												height: "200px",
												padding: "5px",
												border: "1px solid #9C9C9C",
												margin: "10px"
											}}
										/>
									) : null}
									{PrintArr.image_3 ? (
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.image_3}`}
											alt=""
											className="img-thumbnail img-responsive"
											style={{
												width: "250px",
												height: "200px",
												padding: "5px",
												border: "1px solid #9C9C9C"
											}}
										/>
									) : null}
								</div>
							</div>
							<div
								style={{
									marginTop: "20px",
									position: "relative",
									width: "100%",
									marginBottom: "50px"
								}}
							>
								<h1 style={{ position: "absolute", fontSize: "35px" }}>
									<b>Description</b>
								</h1>
							</div>
							<div
								className="col-md-12"
								style={{ marginBottom: "330px", fontSize: "25px", width: "90%" }}
							>
								{PrintArr.appriasalItemArr.map((a, i) => {
									return (
										<div className="col-md-9" style={{ marginTop: "25px", fontSize: "25px" }}>
											<div style={{ float: "left" }}>
												<strong>{i + 1}.</strong>
											</div>
											<div style={{ marginLeft: "30px", textTransform: "none" }}>
												<strong>
													<ins>Item:</ins>
												</strong>{" "}
												{a.item}
												<strong>
													&nbsp;&nbsp;<ins>Metal:</ins>
												</strong>{" "}
												{a.metal}
												<strong>
													&nbsp;&nbsp; <ins>Weight: </ins>
												</strong>{" "}
												{a.weight}
												<strong>
													&nbsp;&nbsp; <ins>Karatage: </ins>
												</strong>{" "}
												{a.karatage}
												<br />
												{a.appriasalItemDiamondsArr.map((b, j) => {
													return b.no_of_diamonds ? (
														<>
															<strong>
																<ins>Diamond: </ins>
															</strong>{" "}
															<strong>&nbsp;&nbsp; No of diamond:</strong> {b.no_of_diamonds}
															<strong>&nbsp;&nbsp; Weight:</strong> {b.weight};
															<strong>&nbsp;&nbsp; Color:</strong> {b.color1}-{b.color2}
															<strong>&nbsp;&nbsp; Clarity:</strong>
															{b.clarity1}-{b.clarity2}
															<span> &nbsp; &nbsp;</span>
															<br />
														</>
													) : null;
												})}
												{a.appriasalItemColorStoneArr.map((b, j) => {
													return b.no_of_gems ? (
														<>
															<strong>
																<ins>Colored Stone: </ins>
															</strong>
															{b.stone_name};<strong>No of Gems:</strong> {b.no_of_gems};
															<strong>Weight:</strong> {b.weight}; <span />
															<br />
														</>
													) : null;
												})}
												<br />

												{/* <p>
													<b>. </b> No evidence of enhancement found in Emerald. The estimated price
													of the ring is USD 25,500/-.
												</p> */}
											</div>
										</div>
									);
								})}
								<b>Comments</b>
								{PrintArr.appriasalItemArr.map((a, i) => {
									return (

										<>
											{a.appriasalItemColorStoneArr.map((b, j) => {
												return (
													<p>
														<b>{b.legend ? `${b.legend}:` : null}</b> {b.legend_comment}
													</p>
												)
											})}

											{a.appriasalItemDiamondsArr.map((b, j) => {
												return (
													<p>
														<b>{b.legend ? `${b.legend}:` : null}</b> {b.legend_comment}
													</p>
												)
											})}

										</>
									)
								})}
							</div>
							{/*  */}
							{/* 	<div class="col-md-3">
		<p style="font-size: 22px"><strong>Comments</strong></p>
	</div>
	<div class="col-md-12">
		<ul>
			<li><p> Grades, weight & measurements are approximate as permitted by mounting.</p>

			</li>
						
		</ul>
	</div> */}

							<div
								style={{
									position: "fixed",
									bottom: "100px",
									width: "auto",
									display: "flex",
									alignItems: "flex-end",
									left: "70px",
									fontSize: "20px"
								}}
							>
								<p
									align="justify"
									style={{
										fontFamily: "arial",
										fontSize: "11px",
										textAlign: "left",
										width: "560px",
										marginBottom: "0px",
										padding: "0px 10px"
									}}
								>
									GGI Galaxy Gemological Institute reports are issued under conditions and
									limitations stated on the reverse side of &nbsp;this report which form an integral
									part of this report.
								</p>

								<b>
									<b>
										<div style={{ clear: "both" }}></div>
									</b>
								</b>
							</div>
							<div style={{ position: "fixed", bottom: "70px", width: "574px", right: "60px" }}>
								<div
									style={{
										width: "578px",
										height: "auto",
										margin: "0 auto",
										marginTop: "190px"
									}}
								>
									<div
										style={{
											width: "254px",
											height: "100px",
											float: "left",
											paddingBottom: "0px",
											marginLeft: "7px",
											marginBottom: "40px"
										}}
									>
										<img src={signLogo} style={{ width: "270px" }} />
									</div>
									<div
										style={{
											width: "300px",
											height: "auto",
											float: "right",
											textAlign: "center",
											paddingBottom: "0px",
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
											justifyContent: "flex-end"
										}}
									>
										<p
											style={{
												color: "#000",
												fontFamily: "arial",
												fontWeight: "bold",
												fontSize: "15px",
												marginTop: "-19px",
												textDecoration: "underline"
											}}
										>
											{" "}
											<img width="260px" src={signature} />
										</p>

										<div
											style={{
												width: "270px",
												height: "auto",
												textAlign: "center",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<p
												style={{
													color: "#000",
													fontWeight: "bold",
													fontSize: "25px",
													marginBottom: "-5px",
													marginTop: "-28px",
													fontFamily: "cleric",
													fontStyle: "italic"
												}}
											>
												Mohammad Ali
											</p>
											<label
												style={{
													color: "#000",
													fontFamily: "cleric",
													fontStyle: "italic",
													fontSize: "21px",
													marginTop: "0px",
													fontWeight: "bold"
												}}
											>
												Gemologist, GIA (USA)
											</label>
										</div>
									</div>
								</div>
								<div style={{ clear: "both" }}></div>
								<div style={{ width: "502px", height: "auto", margin: "0 auto" }}>
									<div
										style={{
											width: "110px",
											height: "50px",
											float: "left",
											marginTop: "-5px",
											marginLeft: "7px",
											marginBottom: "30px"
										}}
									>
										<img src={chip} className="hollograme" />
									</div>
									<div className="isoImage" style={{

										left: "213px",
										width: "108px",
										position: "absolute",
										bottom: "155px",
										transform: "translateZ(-1px)",



									}}>
										<img src={isoCertified} style={{ width: "100%" }} />
									</div>
								</div>
								<div style={{ clear: "both" }}></div>
								<div
									style={{
										width: "524px",
										height: "auto",
										margin: "0 auto 0 27px",
										paddingTop: "10px",
										display: "flex",
										justifyContent: "space-around"
									}}
								>
									<div
										style={{
											width: "140px",
											height: "80px",
											float: "left",
											paddingRight: "50px"
										}}
									>
										<p
											style={{
												float: "left",
												marginTop: "-18px",
												position: "relative",
												transform: "rotate(270deg)",
												filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
												filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
												fontSize: "11px",
												fontWeight: "bold",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												marginLeft: "-50px"
											}}
										>
											{" "}
											<span
												style={{
													marginLeft: "25px",
													position: "absolute",
													top: "-8px",
													fontSize: "14px",
													fontWeight: "900"
												}}
											>
												Verification
											</span>{" "}
											<img
												src={qrUrl}
												alt="QR code"
												style={{ width: "170px", height: "150px", marginLeft: "50px" }}
											/>
										</p>
									</div>
									<div
										style={{
											width: "430px",
											height: "auto",
											float: "left",
											textAlign: "left",
											marginTop: "0px",
											marginLeft: "60px"
										}}
									>
										<p className="MsoNormal" style={{ marginLeft: "0px", height: "16px" }}>
											<strong style={{ fontWeight: "normal" }}>
												<span
													style={{
														color: "#e00837",
														fontSize: "25px",
														lineHeight: "26px",
														fontFamily: "&quot,Bookman Old Style,quot,&quot,serif&quot",
														fontFamily: "&quot,Adobe Arabic,quot"
													}}
												>
													Galaxy Gemological Institute <p></p>
												</span>
											</strong>
										</p>

										<img
											src={line}
											style={{
												marginTop: "-6px",
												height: "6px",
												width: "330px",
												position: "absolute"
											}}
										/>
										<p
											style={{
												color: "#000",
												fontFamily: "arial",
												fontSize: "15px",
												marginTop: "0px",
												fontWeight: "bold"
											}}
										>
											{props.permission.branchCode === "PEW" ? (
												<>
													2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar <br />
													Tel: (91) 256-8456,{" "}
													<b>
														<strong
															style={{
																fontWeight: "900",
																fontSize: "15px",
																textTransform: "lowercase"
															}}
														>
															www.ggilab.com
														</strong>
														<b></b>
													</b>
												</>
											) : (
												<>
													Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-
													<span style={{ textTransform: "lowercase" }}>e</span>-Iraq, Saddar,
													Karachi <br />
													Tel: +92-21-35216044,{" "}
													<b>
														<strong
															style={{
																fontWeight: "900",
																fontSize: "15px",
																textTransform: "lowercase"
															}}
														>
															www.ggilab.com
														</strong>
														<b></b>
													</b>
												</>
											)}
										</p>
										<b>
											<b></b>
										</b>
									</div>
									<b>
										<b></b>
									</b>
								</div>
								<b>
									<b>
										<div style={{ clear: "both" }}></div>
									</b>
								</b>
							</div>
							<div style={{ clear: "both" }} />
						</div>
					) : null}
				</div>
			</div>

			<div id="div2"></div>

			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppraisalPrint));
