import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import "./userPermissions.scss";
import { Button, Checkbox, Switch } from "antd";
import {
	clearUserPermissions,
	getSingleUser,
	getSingleUserPermissions,
	getUserPermissions,
	postUserPermissions,
	updateUserPermissions
} from "../../modules/actions/userAction";

function UserPermissions(props) {
	const [value, setValue] = useState("");
	const [obj, setObj] = useState({
		user_id: 1,
		taken_in: false,
		take_out: false,
		dashboard: false,
		reports: false,
		consultation: false,
		account: false,
		admin_panel: false,
		manager_panel: false,
		customer_add: false,
		customer_view: false,
		customer_edit: false,
		customer_delete: false,
		reciept_generated_view: false,
		reciept_generated_add: false,
		reciept_generated_edit: false,
		reciept_generated_delete: false,
		reciept_pending_view: false,
		reciept_pending_add: false,
		reciept_pending_edit: false,
		reciept_pending_delete: false,
		reciept_number_view: false,
		reciept_number_add: false,
		reciept_number_edit: false,
		reciept_number_delete: false,
		customer_report_view: false,
		customer_report_add: false,
		customer_report_edit: false,
		customer_report_delete: false,
		direct_cash_recievd_view: false,
		direct_cash_recievd_add: false,
		direct_cash_recievd_edit: false,
		direct_cash_recievd_delete: false,
		services_report_view: false,
		services_report_add: false,
		services_report_edit: false,
		services_report_delete: false,
		cash_recieved_view: false,
		cash_recieved_add: false,
		cash_recieved_edit: false,
		cash_recieved_delete: false,
		expenses_report_view: false,
		expenses_report_add: false,
		expenses_report_edit: false,
		expenses_report_delete: false,
		summary_report_view: false,
		summary_report_add: false,
		summary_report_edit: false,
		summary_report_delete: false,
		user_report_view: false,
		user_report_add: false,
		user_report_edit: false,
		user_report_delete: false,
		account_report_view: false,
		account_report_add: false,
		account_report_edit: false,
		account_report_delete: false,
		admin_panel_add_prices_view: false,
		admin_panel_add_prices_add: false,
		admin_panel_add_prices_edit: false,
		admin_panel_add_prices_delete: false,
		admin_panel_add_branch_view: false,
		admin_panel_add_branch_add: false,
		admin_panel_add_branch_edit: false,
		admin_panel_add_branch_delete: false,
		admin_panel_add_user_view: false,
		admin_panel_add_user_add: false,
		admin_panel_add_user_edit: false,
		admin_panel_add_user_delete: false,
		admin_panel_add_expense_name_view: false,
		admin_panel_add_expense_name_add: false,
		admin_panel_add_expense_name_edit: false,
		admin_panel_add_expense_name_delete: false,
		admin_panel_add_recievable_name_view: false,
		admin_panel_add_recievable_name_add: false,
		admin_panel_add_recievable_name_edit: false,
		admin_panel_add_recievable_name_delete: false,
		manager_panel_diamond_grading_view: false,
		manager_panel_diamond_grading_add: false,
		manager_panel_diamond_grading_edit: false,
		manager_panel_diamond_grading_delete: false,
		manager_panel_gemstone_report_view: false,
		manager_panel_gemstone_report_add: false,
		manager_panel_gemstone_report_edit: false,
		manager_panel_gemstone_report_delete: false,
		manager_panel_small_jewellery_view: false,
		manager_panel_small_jewellery_add: false,
		manager_panel_small_jewellery_edit: false,
		manager_panel_small_jewellery_delete: false,
		manager_panel_small_gemstone_view: false,
		manager_panel_small_gemstone_add: false,
		manager_panel_small_gemstone_edit: false,
		manager_panel_small_gemstone_delete: false,
		manager_panel_small_gemstone_jewellery_view: false,
		manager_panel_small_gemstone_jewellery_add: false,
		manager_panel_small_gemstone_jewellery_edit: false,
		manager_panel_small_gemstone_jewellery_delete: false,
		manager_panel_small_diamond_view: false,
		manager_panel_small_diamond_add: false,
		manager_panel_small_diamond_edit: false,
		manager_panel_small_diamond_delete: false,
		manager_panel_jewellery_report_view: false,
		manager_panel_jewellery_report_add: false,
		manager_panel_jewellery_report_edit: false,
		manager_panel_jewellery_report_delete: false,
		manager_panel_master_colored_stone_view: false,
		manager_panel_master_colored_stone_add: false,
		manager_panel_master_colored_stone_edit: false,
		manager_panel_master_colored_stone_delete: false,
		manager_panel_appraisal_certificate_view: false,
		manager_panel_appraisal_certificate_add: false,
		manager_panel_appraisal_certificate_edit: false,
		manager_panel_appraisal_certificate_delete: false
	});

	useEffect(() => {
		props.clearUserPermissions();
	}, []);

	useEffect(() => {
		if (props.singleUserPermission) {
			setObj(props.singleUserPermission);
		}
	}, [props.singleUserPermission]);

	useEffect(() => {
		if (obj.userType === "Admin") {
			setValue("AllRight");
		} else if (obj.userType === "Admin") {
			setValue("AdminRight");
		} else if (obj.userType === "Manager") {
			setValue("ManagerRight");
		} else {
			setValue("UserRight");
		}
	}, [obj]);

	useEffect(() => {
		if (props.auth.userPermissions) {
			var url = new URL(window.location);
			let id = url.searchParams.get("id");
			if (id) {
				let objj = { ...obj };
				objj.user_id = id;
				setObj(objj);
				props.getSingleUserPermissions(id, props.auth.userPermissions.branchCode);
				props.getSingleUser(id, props.auth.userPermissions.branchCode);
			}
		}
	}, [props.auth.userPermissions]);

	const save = () => {
		props.postUserPermissions(obj, props.auth.userPermissions.branchCode);
	};

	const update = () => {
		props.updateUserPermissions(obj, props.auth.userPermissions.branchCode);
	};

	const options = [
		{ label: "All Rights true", value: "AllRight" },
		{ label: "Admin Rights", value: "AdminRight" },
		{ label: "Manager Rights", value: "ManagerRight" },
		{ label: "User Rights", value: "UserRight" }
	];

	const onChange1 = (e) => {
		console.log("radio1 checked", e.target.value);
		let radioValue = e.target.value;
		setValue(e.target.value);
		if (radioValue === "AllRight") {
			let objj = { ...obj, userType: "Admin" };
			for (const property in objj) {
				if (property !== "user_id" && property !== "userType" && property !== "branchCode") {
					objj[property] = true;
				}
			}
			setObj(objj);
		} else if (radioValue === "AdminRight") {
			let objj = { ...props.userStore.adminPermissions };
			objj.user_id = obj.user_id;
			objj.userType = "Admin";
			objj.branchCode = obj.branchCode;
			setObj(objj);
		} else if (radioValue === "ManagerRight") {
			let objj = { ...props.userStore.managerPermissions };
			objj.user_id = obj.user_id;
			objj.userType = "Manager";
			objj.branchCode = obj.branchCode;
			setObj(objj);
		} else if (radioValue === "UserRight") {
			let objj = { ...props.userStore.userPermissions };
			objj.user_id = obj.user_id;
			objj.userType = "User";
			objj.branchCode = obj.branchCode;
			setObj(objj);
		}
	};

	return (
		<div className="UserPermissions">
			<div className="buttonDiv">
				<div>
					<h3 onClick={() => console.log(props.singleUser)}>
						{props.singleUser &&
							`${props.singleUser.first_name} ${props.singleUser.last_name} (${props.singleUser.email})`}
					</h3>
				</div>
				<div>
					<Radio.Group options={options} onChange={onChange1} value={value} />
					{/* (<Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            let objj = { ...obj }
                            for (const property in objj) {
                                if (property !== "user_id" && property !== "userType" && property !== "branchCode") {
                                    objj[property] = true
                                }
                            }
                            setObj(objj)
                        } else {
                            let objj = { ...obj }
                            for (const property in objj) {
                                if (property !== "user_id" && property !== "userType" && property !== "branchCode") {
                                    objj[property] = false
                                } else {
                                }
                            }
                            setObj(objj)
                        }
                    }}>All Rights true</Checkbox>)

                    (<Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            let objj = { ...props.userStore.adminPermissions }
                            objj.user_id = obj.user_id
                            objj.userType = obj.userType
                            objj.branchCode = obj.branchCode
                            setObj(objj)
                        } else {
                            let objj = { ...obj }
                            for (const property in objj) {
                                if (property !== "user_id" || property !== "userType" || property !== "branchCode") {

                                } else {
                                    objj[property] = false
                                }
                            }
                            setObj(objj)
                        }
                    }}>Admin Rights</Checkbox>)

                    (<Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            let objj = { ...props.userStore.managerPermissions }
                            objj.user_id = obj.user_id
                            objj.userType = obj.userType
                            objj.branchCode = obj.branchCode
                            setObj(objj)
                        } else {
                            let objj = { ...obj }
                            for (const property in objj) {
                                if (property !== "user_id" || property !== "userType" || property !== "branchCode") {

                                } else {
                                    objj[property] = false
                                }
                            }
                            setObj(objj)
                        }
                    }}>Manager Rights</Checkbox>)

                    ( <Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            let objj = { ...props.userStore.userPermissions }
                            objj.user_id = obj.user_id
                            objj.userType = obj.userType
                            objj.branchCode = obj.branchCode
                            setObj(objj)
                        } else {
                            let objj = { ...obj }
                            for (const property in objj) {
                                if (property !== "user_id" || property !== "userType" || property !== "branchCode") {

                                } else {
                                    objj[property] = false
                                }
                            }
                            setObj(objj)
                        }
                    }}>User Rights</Checkbox>) */}

					{props.permission ? (
						<Button onClick={update} type="primary">
							Update
						</Button>
					) : (
						<Button onClick={save} type="primary">
							Save
						</Button>
					)}
				</div>
			</div>
			<table>
				<tbody>
					<tr>
						<th>Permission Name</th>
						<th>Checked</th>
					</tr>
					{Object.keys(obj).map((a, i) => {
						return (
							i >= 0 &&
							i < Object.keys(obj).length / 3 &&
							a !== "user_id" &&
							a !== "userType" &&
							a !== "branchCode" && (
								<tr key={i}>
									<td>{a.split("_").join(" ")}</td>
									<td>
										<Switch
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={obj[a]}
											onChange={(e) => {
												let objj = { ...obj };
												objj[a] = e;
												setObj(objj);
											}}
										/>
									</td>
								</tr>
							)
						);
					})}
				</tbody>
			</table>

			<div className="seperater"></div>

			<table>
				<tbody>
					<tr>
						<th>Permission Name</th>
						<th>Checked</th>
					</tr>
					{Object.keys(obj).map((a, i) => {
						return (
							i > Object.keys(obj).length / 3 &&
							i < Number(Object.keys(obj).length / 3) + Number(Object.keys(obj).length / 3) && (
								<tr key={i}>
									<td>{a.split("_").join(" ")}</td>
									<td>
										<Switch
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={obj[a]}
											onChange={(e) => {
												let objj = { ...obj };
												objj[a] = e;
												setObj(objj);
											}}
										/>
									</td>
								</tr>
							)
						);
					})}
				</tbody>
			</table>

			<div className="seperater"></div>

			<table>
				<tbody>
					<tr>
						<th>Permission Name</th>
						<th>Checked</th>
					</tr>
					{Object.keys(obj).map((a, i) => {
						return (
							i > Number(Object.keys(obj).length / 3) + Number(Object.keys(obj).length / 3) &&
							i < Object.keys(obj).length && (
								<tr key={i}>
									<td>{a.split("_").join(" ")}</td>
									<td>
										<Switch
											checkedChildren={<CheckOutlined />}
											unCheckedChildren={<CloseOutlined />}
											checked={obj[a]}
											onChange={(e) => {
												let objj = { ...obj };
												objj[a] = e;
												setObj(objj);
											}}
										/>
									</td>
								</tr>
							)
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		users: store.users.users,
		singleUserPermission: store.users.singleUserPermission,
		permission: store.users.permission,
		singleUser: store.users.singleUser,
		userStore: store.users,
		auth: store.authentication
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserPermissions,
			getSingleUserPermissions,
			postUserPermissions,
			updateUserPermissions,
			clearUserPermissions,
			getSingleUser
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
