import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function ManagerPanelLayout(props) {
	const [arr, setArr] = useState([
		{ name: "Diamond Grading", route: "/diamond-grading" },
		{ name: "Gemstone Report", route: "/gemstone-report" },
		{ name: "Small Jewellery", route: "/small-jewellery" },
		{ name: "Small Gemstone", route: "/small-gemstone" },
		{ name: "Small Gemstone Jewellery", route: "/small-gemstone-jewellery" },
		{ name: "Small Diamond", route: "/small-diamond" },
		{ name: "Jewellery Report", route: "/jewellery-report" },
		{ name: "Master Colored Stone", route: "/master-colored-stone" },
		{ name: "Appraisal Certificate", route: "/appraisal-certificate" }
	]);

	const [whichTrue, setWhichTrue] = useState("");

	return (
		<div className="dashboardMain limiter ">
			{arr.map((a, i) => {
				return (
					<div key={i} className="div">
						<div className="wrap-login100-form-btn full">
							<div className="login100-form-bgbtn"></div>
							<button onClick={() => props.history.push(a.route)} className="login100-form-btn">
								{a.name}
							</button>
						</div>
					</div>
				);
			})}
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPanelLayout);
