import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import store from '../store';
import { getAllCustomers } from './customerReportActions';


// product loader true

function customerLoadingBool(bool) {
    return dispatch => {
        dispatch(customerLoading(bool));
    };
}
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const customerLoading = (bool) => {
    return {
        type: CUSTOMER_LOADING,
        payload: bool
    }
}

// set customer
export function setCustomer(obj, branchCode, cancelModal, setSelectedCustomer, showModal) {
    return dispatch => {
        dispatch(customerLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Customer Added")
            dispatch(allCustomers())
            dispatch(setCustomerSuccess(obj));
            dispatch(customerLoadingBool(false))
            if (showModal) {
                // console.log(res.data)
                showModal(res.data)
            }
            setSelectedCustomer(obj)
            if (cancelModal) {
                cancelModal()
            }
        }).catch((res) => {
            console.log(res.response)
            // res?.response?.data?.mobileNo?.join
            Object.values(res.response.data).map((a, i) => {
                if (a.length) {
                    message.error(a[0])
                }
                // message.error(a[0])
                // console.log(a)
            })

            dispatch(customerLoadingBool(false))
        });
    };
}

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const setCustomerSuccess = (obj) => ({
    type: SET_CUSTOMER,
    payload: { obj }
});

// update customer
export function updateCustomer(obj, page, branchCode, pageSize, cancelModal, showModal, id) {
    return dispatch => {

        dispatch(customerLoadingBool(true))
        let token = getCookie("authToken")
        console.log("im checking whats in it???????", obj)
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}customers/${id}/?`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Customer Updated")
            dispatch(getAllCustomers(page, branchCode, "", "", pageSize))
            dispatch(customerLoadingBool(false))
            if (showModal) {
                // console.log('showModal')
                showModal(res.data.data)
            }
            console.log(cancelModal, 'cancelModal')
            if (cancelModal) {
                console.log(cancelModal, 'cancelModal')
                cancelModal()
            }
        }).catch((res) => {
            dispatch(customerLoadingBool(false))
        });
    };
}


// Filter customer
export function filteredCustomer(val, allCustomers, getCustomerr) {
    return dispatch => {
        if (val && allCustomers) {
            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

            if (format.test(val)) {
                // alert here
            } else {
                let arr = [...allCustomers]
                arr = arr.filter((a) => {
                    if (
                        a.customerId && a.customerId.toLowerCase().match(val.toLowerCase())
                        || a.customerName && a.customerName.toLowerCase().match(val.toLowerCase())
                        || a.mobileNo && a.mobileNo.toLowerCase().match(val.toLowerCase())
                        || a.mobileNo2 && a.mobileNo2.toLowerCase().match(val.toLowerCase())
                    ) {
                        return true
                    } else {
                        return false
                    }
                })
                console.log(arr)
                dispatch(filteredCustomerSuccess(arr));
            }
        } else {
            dispatch(filteredCustomerSuccess([]));
        }
    };
}
export const FILTERED_CUSTOMER = 'FILTERED_CUSTOMER';
export const filteredCustomerSuccess = (arr) => ({
    type: FILTERED_CUSTOMER,
    payload: { data: arr }
});


export function activeOrNorActive(start, end, name, text) {
    return dispatch => {
        if (!name === "reports") {
            if (start !== undefined && end !== undefined) {
                let endDate = new Date(end)
                let currentDate = new Date().getTime()
                let endMili = endDate.getTime()
                if (currentDate < endMili) {
                    if (text) {
                        return "Active"
                    } else {
                        dispatch(membershipActiveText("Active"))
                        dispatch(membershipActive(true))
                    }
                } else {
                    if (text) {
                        return "Expired"
                    } else {
                        dispatch(membershipActiveText("Expired"))
                        dispatch(membershipActive(false))
                    }
                }
            } else {
                dispatch(membershipActiveText("Not Acquired Yet"))
                dispatch(membershipActive(false))
            }
        } else {
            if (start !== undefined && end !== undefined) {
                let endDate = new Date(end)
                let currentDate = new Date().getTime()
                let endMili = endDate.getTime()
                if (currentDate < endMili) {
                    dispatch(membershipActiveText("Active"))
                    dispatch(membershipActive(true))
                } else {
                    dispatch(membershipActiveText("Expired"))
                    dispatch(membershipActive(false))
                }
            } else {
                dispatch(membershipActiveText("Not Acquired Yet"))
                dispatch(membershipActive(false))
            }
        }
    }
}

export const MEMBERSHIP_ACTIVE_TEXT = 'MEMBERSHIP_ACTIVE_TEXT';
export const membershipActiveText = (text) => ({
    type: MEMBERSHIP_ACTIVE_TEXT,
    payload: { data: text }
});

export const MEMBERSHIP_ACTIVE = 'MEMBERSHIP_ACTIVE';
export const membershipActive = (bool) => ({
    type: MEMBERSHIP_ACTIVE,
    payload: { data: bool }
});


// All customers
export function allCustomers(branchCode) {
    return dispatch => {
        dispatch(customerLoadingBool(true))
        let data = new FormData()
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/all/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(allCustomersSuccess(res.data));
            dispatch(customerLoadingBool(false))
        }).catch((res) => {
            dispatch(customerLoadingBool(false))
        });
    };
}
export const All_CUSTOMERS = 'All_CUSTOMERS';
export const allCustomersSuccess = (arr) => ({
    type: All_CUSTOMERS,
    payload: { data: arr }
});

export function getsingleMembership(obj) {
    console.log("objjjjjjjjj", obj);

    return dispatch => {
        // dispatch(customerLoadingBool(true))
        // let data = new FormData();
        // console.log("form", data);
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}membership/${obj.customerId}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(getsinglemembership(res.data));
            // dispatch(customerLoadingBool(false))
        }).catch((res) => {
            // dispatch(customerLoadingBool(false))
        });
    };
}
export const GET_SINGLE_MEMBERSHIP = 'GET_SINGLE_MEMBERSHIP';
export const getsinglemembership = (arr) => ({
    type: GET_SINGLE_MEMBERSHIP,
    payload: arr 
});


function getCustomerLoading(bool) {
    return dispatch => {
        dispatch(getCustomerLoading(bool));
    };
}
export const GET_CUSTOMER_LOADING = 'GET_CUSTOMER_LOADING';
export const getCustomerLoader = (bool) => {
    return {
        type: GET_CUSTOMER_LOADING,
        payload: bool
    }
}


// get customer
export function getCustomer(id, branchCode, modal, setEditObj) {
    return dispatch => {
        console.log(id, "customeerFilterrrrrrr")
        dispatch(getCustomerLoader(true))
        dispatch(getCustomerSuccess(false));
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}membershipAndcustomer/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            console.log(res.data[0], "customeerFilterrrrrrr")
            console.count()
            dispatch(getCustomerSuccess(res.data[0]));
            if (modal) {
                modal(true)
            }
            if (setEditObj) {
                setEditObj(res.data[0])
            }
            dispatch(getCustomerLoader(false))
        }).catch((res) => {
            dispatch(getCustomerLoader(false))
        });
    };
}
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const getCustomerSuccess = (obj) => {
    // console.log(obj, 'customeerFilterrrrrrr')
    return {
        type: GET_CUSTOMER,
        payload: obj
    }
}


// Clear customer
export function clearCustomer() {
    return dispatch => {
        dispatch(getCustomerSuccess(false));
    };
}

export function checkActiveOrNorActive(start, end) {
    if (start !== undefined && end !== undefined) {
        let endDate = new Date(end)
        let currentDate = new Date().getTime()
        let endMili = endDate.getTime()
        if (currentDate < endMili) {
            return "Active"

        } else {
            return "Expired"
        }
    }
}


//membership of customers

export function setMembership(obj, branchCode, cancelModal, setSelectedCustomer, showModal) {
    return dispatch => {
        dispatch(customerLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}membership/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("MemberShip Added")
            console.log("memeberhsipp", res);
            // dispatch(allCustomers())
            // dispatch(setCustomerSuccess(obj));
            dispatch(customerLoadingBool(false));
            dispatch(getsingleMembership(obj));
          
            if (showModal) {
                // console.log(res.data)
                showModal(res.data)
            }
            setSelectedCustomer(obj)
            if (cancelModal) {
                cancelModal()
            }
        }).catch((res) => {
            console.log(res.response)
            // res?.response?.data?.mobileNo?.join
            if(res.response){
                Object.values(res.response.data).map((a, i) => {
                    if (a.length) {
                        message.error(a[0])
                    }
                    // message.error(a[0])
                    // console.log(a)
                })
    
            }
         
            dispatch(customerLoadingBool(false))
        });
    };
}
