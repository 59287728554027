import Axios from 'axios'
import { ALL_FIELDS_DATA } from '../actions/FieldsAction';

const initialState = {
    fields: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ALL_FIELDS_DATA:
            return {
                ...state,
                fields: action.payload,
            };
        default:
            return state
    }
}

