import Axios from "axios";
import { below500Price, GET_BELOW_500_PRICE, GET_CVD_PRICE, GET_DIAMOND_CARD_PRICE, GET_DIAMOND_CERTIFICATE_PRICE, GET_EXTRA_SERVICE, GET_JEWELLERY_CERTIFICATE, GET_JEWELLERY_GEMSTONE_LOT, GET_LOT_APPRAISAL_PRICE, GET_SELECT_GEMSTONE_LOT, GET_SMALL_GEMSTONE_LOT_PRICE, GET_STRAND_SERVICE_PRICE, LOOSE_STONE_SERVICE } from "../actions/AddPricesAction";
import { CVDTesting, extraService } from "../../containers/constant/constant";


const initialState = {
	service: [],
	LoseStoneService: [],
	JewelleryService: [],
	StrandService: [],
	CVDTesting: [],
	DiamondCertificate: [],
	DiamondCard: [],
	StonePosition: [
		{ name: "Center" },
		{ name: "Sides" },
		{ name: "Extra" }
	],
	SelectStone: [],
	SelectStoneForCertificates: [],
	SmallGemStonesLot: [],
	LotApprasialCertificate: [],
	DeliveryService: [
		{ name: "No Decision" },
		{ name: "Apprasial Card" },
		{ name: "Apprasial Certificate" },
		{ name: "Consultation" },
		{ name: "Gemstone Card" },
		{ name: "Gemstone Certificate" },
		{ name: "Diamond Card" },
		{ name: "Diamond Certificate" },
		{ name: "Jewellery Card" },
		{ name: "Jewellery Certificate" },
		{ name: "Gemstone Jewellery Card" },
		{ name: "Gemstone Jewellery Certificate" },
		{ name: "Gemstone Strand Card" },
		{ name: "Gemstone Strand Certificate" },
		{ name: "Pearl Strand Card" },
		{ name: "Pearl Strand Certificate" }
	],
	// DeliveryService: [
	// 	{ name: "No Decision", price: 0 },
	// 	{ name: "Apprasial Card", price: 500, member_price: 500 },
	// 	{ name: "Apprasial Certificate", price: 1000, member_price: 1000 },
	// 	{ name: "Consultation", price: 300, member_price: 300 },
	// 	{ name: "Gemstone Card", price: 800, member_price: 600 },
	// 	{ name: "Gemstone Certificate", price: 1500, member_price: 1200 },
	// 	{ name: "Diamond Card", price: 0, member_price: 0 },
	// 	{ name: "Diamond Certificate", price: 0, member_price: 0 },
	// 	{ name: "Jewellery Card", price: 0, member_price: 0 },
	// 	{ name: "Jewellery Certificate", price: 0, member_price: 0 },
	// 	{ name: "Gemstone Jewellery Card", price: 0, member_price: 0 },
	// 	{ name: "Gemstone Jewellery Certificate", price: 0, member_price: 0 },
	// 	{ name: "Gemstone Strand Card", price: 3000, member_price: 2250 },
	// 	{ name: "Gemstone Strand Certificate", price: 4000, member_price: 3000 },
	// 	{ name: "Pearl Strand Card", price: 6000, member_price: 4000 },
	// 	{ name: "Pearl Strand Certificate", price: 7000, member_price: 5000 }
	// ],
	JewelleryItems: [
		{ value: "Ring", label: "Ring" },
		{ value: "Pendent", label: "Pendent" },
		{ value: "Earing", label: "Earing" },
		{ value: "Set", label: "Set" },
		{ value: "Bangle", label: "Bangle" },
		{ value: "Kara", label: "Kara" },
		{ value: "Bracelet", label: "Bracelet" },
		{ value: "Nose Pin", label: "Nose Pin" },
		{ value: "Neckless", label: "Neckless" }
	],
	extraService: [],
	serviceReport: [
		"All",
		"No Decision",
		"Consultation",
		// "Apprasial Card",
		// "Apprasial Certificate",
		"Gemstone Card",
		"Gemstone Certificate",
		"Diamond Card",
		"Diamond Certificate",
		"Jewellery Card",
		"Jewellery Certificate",
		"Gemstone Jewellery Card",
		"Gemstone Jewellery Certificate",
		"Gemstone Strand Card",
		"Gemstone Strand Certificate",
		"Pearl Strand Card",
		"Pearl Strand Certificate",
		"Apprasial",
		"Seals",
		"Mine",
		"Urgent"
	],
	below5000: 0,
	below5000Obj: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOOSE_STONE_SERVICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				LoseStoneService: [...action.payload],
			}
		}
		case GET_DIAMOND_CARD_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				DiamondCard: [...action.payload],
			}
		}
		case GET_DIAMOND_CERTIFICATE_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				DiamondCertificate: [...action.payload],
			}
		}
		case GET_STRAND_SERVICE_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				StrandService: [...action.payload],
			}
		}
		case GET_BELOW_500_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				below5000: action.payload.length ? action.payload[0].price : 0,
				below5000Obj: action.payload.length ? action.payload[0] : {}

			}
		}
		case GET_EXTRA_SERVICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				extraService: [...action.payload]

			}
		}
		case GET_JEWELLERY_CERTIFICATE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				JewelleryService: [...action.payload]

			}
		}
		case GET_CVD_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				CVDTesting: [...action.payload]

			}
		}
		case GET_SELECT_GEMSTONE_LOT: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				SelectStone: [...action.payload]

			}
		}
		case GET_JEWELLERY_GEMSTONE_LOT: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				SelectStoneForCertificates: [...action.payload]

			}
		}
		case GET_SMALL_GEMSTONE_LOT_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				SmallGemStonesLot: [...action.payload]

			}
		}
		case GET_LOT_APPRAISAL_PRICE: {
			console.log(action.payload, 'asdasdasdasda')
			return {
				...state,
				LotApprasialCertificate: [...action.payload]

			}
		}

		case "GET_FORM_DATA":
			return {
				...state
			};
		default:
			return state;
	}
};

// import Axios from 'axios'
// import { GET_DIAMOND_CARD_PRICE, GET_DIAMOND_CERTIFICATE_PRICE, GET_JEWELLERY_CERTIFICATE, LOOSE_STONE_SERVICE, GET_STRAND_SERVICE_PRICE, GET_BELOW_500_PRICE, GET_EXTRA_SERVICE, GET_CVD_PRICE, GET_SMALL_GEMSTONE_LOT } from '../actions/AddPricesAction';

// const initialState = {
//     LoseStoneService: [
//         {
//             "member_price": "",
//             "name": "consultation",
//             "price": "",
//         },
//         {
//             "member_price": "",
//             "name": "Appraisal",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "Gemstone Card",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "Gemstone Certificate",
//             "price": ""
//         },
//         {
//             "name": "Apprasial Card",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Apprasial Certificate",
//             "price": "",
//             "member_price": ""
//         },
//     ],
//     JewelleryService: [

//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Apprasial Card",
//             "p1": "",
//             "p2": ""
//         },
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Apprasial Certificate",
//             "p1": "",
//             "p2": ""
//         },
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "jewellery Card",
//             "p1": "",
//             "p2": ""
//         },
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "jewellery Certificate",
//             "p1": "",
//             "p2": "",
//         },
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Gemstone Jewellery Card",
//             "p1": "",
//             "p2": ""
//         },
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Gemstone Jewellery Certificate",
//             "p1": "",
//             "p2": ""
//         }
//     ],
//     StrandService: [
//         {
//             "name": "Apprasial Card",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Apprasial Certificate",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Gemstone Strand Card",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Gemstone Strand Certificate",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Pearl Strand Card",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Pearl Strand Certificate",
//             "price": "",
//             "member_price": ""
//         },

//     ],
//     CVDTesting: [
//         {
//             "name": "Between 0.01 to 1",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Between 1.01 to 5",
//             "price": "",
//             "member_price": ""
//         },

//         {
//             "name": "Extra More than 5",
//             "price": "",
//             "member_price": ""
//         },

//     ],
//     DiamondCertificate: [
//         {
//             "member_price": "",
//             "name": "0.01-0.49ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "0.50-0.99ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "1.00-1.49ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "1.50-1.99ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "2.00-2.99ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "3.00-3.99ct",
//             "price": ""
//         },
//         {
//             "member_price": "",
//             "name": "4.00-up",
//             "price": ""
//         }
//     ],
//     DiamondCard: [{
//         "member_price": "",
//         "name": "0.01-0.49ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "0.50-0.99ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "1.00-1.49ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "1.50-1.99ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "2.00-2.99ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "3.00-3.99ct",
//         "price": ""
//     },
//     {
//         "member_price": "",
//         "name": "4.00-up",
//         "price": ""
//     }],
//     below5000: false,
//     extraService: [
//         {
//             "name": "Seal",
//             "price": ""
//         },
//         {
//             "name": "Mine",
//             "price": ""
//         },
//         {
//             "name": "Urgent",
//             "price": ""
//         },
//         {
//             "name": "Gold Test",
//             "price": ""
//         }

//     ],
//     SmallGemStonesLot: [
//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Card",
//             "p1": "",
//             "p2": ""

//         },

//         {
//             "member_price": "",
//             "member_price_p2": "",
//             "name": "Ceritificate",
//             "p1": "",
//             "p2": ""
//         }
//     ],
//     StonePosition: [
//         { name: "Center" },
//         { name: "Sides" },
//         { name: "Extra" },
//     ],
//     JewelleryItems: [
//         { value: "Ring", label: "Ring" },
//         { value: "Pendent", label: "Pendent" },
//         { value: "Earing", label: "Earing" },
//         { value: "Set", label: "Set" },
//         { value: "Bangle", label: "Bangle" },
//         { value: "Kara", label: "Kara" },
//         { value: "Bracelet", label: "Bracelet" },
//         { value: "Nose Pin", label: "Nose Pin" },
//         { value: "Neckless", label: "Neckless" },
//     ],
//     serviceReport: [
//         "All",
//         "No Decision",
//         "Consultation",
//         "Gemstone Certificate",
//         "Diamond Card",
//         "Diamond Certificate",
//         "Jewellery Card",
//         "Jewellery Certificate",
//         "Gemstone Jewellery Card",
//         "Gemstone Jewellery Certificate",
//         "Gemstone Strand Card",
//         "Gemstone Strand Certificate",
//         "Pearl Strand Card",
//         "Pearl Strand Certificate",
//         "Apprasial",
//         "Seals",
//         "Mine",
//         "Urgent"
//     ],
//     service: [
//         { name: "Consultation", price: 300, member_price: 300 },
//         { name: "Apprasial Card", price: 500, member_price: 500 },
//         { name: "Apprasial Certificate", price: 1000, member_price: 1000 },
//         { name: "Gemstone Card", price: 800, member_price: 600 },
//         { name: "Gemstone Certificate", price: 1500, member_price: 1200 },
//         { name: "Diamond Card", price: 0, member_price: 0 },
//         { name: "Diamond Certificate", price: 0, member_price: 0 },
//         { name: "Jewellery Card", price: 0, member_price: 0 },
//         { name: "Jewellery Certificate", price: 0, member_price: 0 },
//         { name: "Gemstone Jewellery Card", price: 0, member_price: 0 },
//         { name: "Gemstone Jewellery Certificate", price: 0, member_price: 0 },
//         { name: "Gemstone Strand Card", price: 3000, member_price: 2250 },
//         { name: "Gemstone Strand Certificate", price: 4000, member_price: 3000 },
//         { name: "Pearl Strand Card", price: 6000, member_price: 4000 },
//         { name: "Pearl Strand Certificate", price: 7000, member_price: 5000 },
//     ],
//     // allSericeCheck: initialState.LoseStoneService,
//     SelectStone: [
//         { name: "Diamond", p1: 1500, p2: 750, member_price: 1200, member_price_p2: 600 },
//         { name: "Gemstone", p1: 800, p2: 500, member_price: 600, member_price_p2: 400 },
//         { name: "Small Gemstone", p1: 800, member_price: 600 },
//     ],
//     SelectStoneForCertificates: [
//         { name: "Diamond", p1: 2500, p2: 1250, member_price: 2000, member_price_p2: 1000 },
//         { name: "Gemstone", p1: 1500, p2: 800, member_price: 1200, member_price_p2: 600 },
//         { name: "Small Gemstone", p1: 1500, member_price: 1200 },

//     ],
//     LotApprasialCertificate: [
//         { name: "Apprasial Certificate", price: 600 }
//     ],

//     DeliveryService: [
//         { name: "No Decision", price: 0 },
//         { name: "Apprasial Card", price: 500, member_price: 500 },
//         { name: "Apprasial Certificate", price: 1000, member_price: 1000 },
//         { name: "Consultation", price: 300, member_price: 300 },
//         { name: "Gemstone Card", price: 800, member_price: 600 },
//         { name: "Gemstone Certificate", price: 1500, member_price: 1200 },
//         { name: "Diamond Card", price: 0, member_price: 0 },
//         { name: "Diamond Certificate", price: 0, member_price: 0 },
//         { name: "Jewellery Card", price: 0, member_price: 0 },
//         { name: "Jewellery Certificate", price: 0, member_price: 0 },
//         { name: "Gemstone Jewellery Card", price: 0, member_price: 0 },
//         { name: "Gemstone Jewellery Certificate", price: 0, member_price: 0 },
//         { name: "Gemstone Strand Card", price: 3000, member_price: 2250 },
//         { name: "Gemstone Strand Certificate", price: 4000, member_price: 3000 },
//         { name: "Pearl Strand Card", price: 6000, member_price: 4000 },
//         { name: "Pearl Strand Certificate", price: 7000, member_price: 5000 },
//     ],

// }

// export default (state = initialState, action) => {
//     switch (action.type) {
//         case "GET_FORM_DATA":
//             console.log(action.type, "GET_FORM_DATA")
//             return {
//                 ...state,
//             };
//         case LOOSE_STONE_SERVICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 LoseStoneService: [...action.payload],
//                 // allSericeCheck:[...initialState.allSericeCheck,...action.payload]
//             }
//         }
//         case GET_DIAMOND_CARD_PRICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 DiamondCard: [...action.payload],
//             }
//         }
//         case GET_DIAMOND_CERTIFICATE_PRICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 DiamondCertificate: [...action.payload],
//             }
//         }
//         case GET_JEWELLERY_CERTIFICATE: {
//             console.log(action.payload)
//             let arr = []
//             action.payload.map((a, i) => {
//                 arr.push({
//                     ...a,
//                     price: a.p1, member_price: a.member_price
//                 })
//             })
//             return {
//                 ...state,
//                 JewelleryService: arr,
//                 // allSericeCheck:[...initialState.allSericeCheck,...action.payload]

//             }
//         }
//         case GET_STRAND_SERVICE_PRICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 StrandService: [...action.payload],
//                 // allSericeCheck:[...initialState.allSericeCheck,...action.payload]
//             }
//         }
//         case GET_BELOW_500_PRICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 below5000: action.payload,
//             }
//         }
//         case GET_EXTRA_SERVICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 extraService: action.payload,
//             }
//         }
//         case GET_CVD_PRICE: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 CVDTesting: action.payload,
//             }
//         }
//         case GET_SMALL_GEMSTONE_LOT: {
//             console.log(action.payload)
//             return {
//                 ...state,
//                 SmallGemStonesLot: action.payload,
//             }
//         }

//         default:
//             return state
//     }
// }
