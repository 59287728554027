import { message } from 'antd';
import Axios from 'axios'
import { checkImageType, getCookie } from '../../common/common';
import store from '../store';


// managerPanel loader true
function managerPanelLoadingBool(bool) {
    return dispatch => {
        dispatch(managerPanelLoading(bool));
    };
}
export const MANAGER_PANEL_LOADING = 'MANAGER_PANEL_LOADING';
export const managerPanelLoading = (bool) => {
    return {
        type: MANAGER_PANEL_LOADING,
        payload: bool
    }
}

// setting certificate
export function setCertificate(obj, branchCode, clearState, back) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}report/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            dispatch(managerPanelLoadingBool(false))
            clearState()
            back()
            getSingleReportSuccess()
            // dispatch(setCertificateSuccess(res.data.user, true))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// setting certificate
export function updateCertificate(obj, branchCode, clearState, id, back) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}report/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Certificate Updated")
            dispatch(managerPanelLoadingBool(false))
            clearState()
            back()
            getSingleReportSuccess()
            // dispatch(setCertificateSuccess(res.data.user, true))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

export const GET_SINGLE_REPORT_ENTRY = 'GET_SINGLE_REPORT_ENTRY';
export const getSingleReportSuccess = (data) => ({
    type: GET_SINGLE_REPORT_ENTRY,
    payload: {}
});

export function deleteCertificate(id, branchCode, startDate, endDate, reportId, searchText, page, pageSize) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}report/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            message.success("Certificate Deleted")
            dispatch(getCertificates(branchCode, startDate, endDate, reportId, searchText, page, pageSize))
            // dispatch(setCertificateSuccess(res.data.user, true))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}


// getting certificates
export function getCertificates(branchCode, startDate, endDate, reportId, searchText, page, pageSize) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}GetSeprateReports/?branchCode=${branchCode}&startDate=${startDate}&endDate=${endDate}&report_id=${reportId}&searchText=${searchText}&page=${page}&page_size=${pageSize}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(getCertificatesSuccess(res.data.results, res.data.page, res.data.total))
            dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

export const GET_CERTIFICATES = 'GET_CERTIFICATES';
export const getCertificatesSuccess = (data, page, total) => ({
    type: GET_CERTIFICATES,
    payload: { data, page, total }
});

// getting jewellery certificates
export function getJewelleryCertificates(branchCode, startDate, endDate, searchText, page, pageSize) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryReportApi/?branchCode=${branchCode}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&page=${page}&page_size=${pageSize}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {

            dispatch(getCertificatesSuccess(res.data.results, res.data.page, res.data.total))
            dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

export function deleteJewelleryCertificates(id, branchCode, startDate, endDate, searchText, page, pageSize) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryReportApi/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            message.success("Jewellery Certificate Deleted")
            dispatch(getJewelleryCertificates(branchCode, startDate, endDate, searchText, page, pageSize))
            // dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// getting jewellery certificates
export function getSepJewelleryCertificates(id) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryReportApi/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(getCertificatesSuccess(res.data[0]))
            dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// getting appraisal certificates
export function getAppraisalCertificates(branchCode, startDate, endDate, searchText, page, pageSize) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisalReportApi/?branchCode=${branchCode}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&page=${page}&page_size=${pageSize}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(getCertificatesSuccess(res.data.results, res.data.page, res.data.total))
            dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}
// getting appraisal certificates
export function deleteAppraisalCertificates(id, branchCode, startDate, endDate, searchText, page, pageSize) {
    return dispatch => {
        // dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisalReportApi/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            message.success("Appraisal Certificate Deleted")
            dispatch(getAppraisalCertificates(branchCode, startDate, endDate, searchText, page, pageSize))
            // dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}
// getting appraisal certificates
export function getSepAppraisalCertificates(id) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisalReportApi/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            console.log(res)
            dispatch(getCertificatesSuccess(res.data[0]))
            dispatch(managerPanelLoadingBool(false))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// delete appraisal Diamond 
export function deleteAppraisalDiamond(id) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisal_items_diamond/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            // console.log(res)
            // dispatch(getCertificatesSuccess(res.data[0]))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// delete appraisal color stone
export function deleteAppraisalColorStone(id) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisal_items_colorstone/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(managerPanelLoadingBool(false))
            // console.log(res)
            // dispatch(getCertificatesSuccess(res.data[0]))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}

// delete appraisal color stone
export function deleteAppraisalItem(id) {
    return dispatch => {
        dispatch(managerPanelLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BACKEND_URL}appraisal_items/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(managerPanelLoadingBool(false))
            // console.log(res)
            // dispatch(getCertificatesSuccess(res.data[0]))
        }).catch((res) => {
            dispatch(managerPanelLoadingBool(false))
        });
    };
}




export async function createImageFile(image) {
    if (image && typeof image === 'object' && typeof image.name == 'string') {

        return (image)
    } else {
        if(checkImageType(image)){
            console.log(image,'sdadsadsa')
try{

        let response = await fetch(image);
        console.log(response,'datadatadata')
        let data = await response.blob();
        let metadata = {
            type: 'image/jpg'
        };
        let file = new File([data], "test.jpg", metadata);
        return (file)
}catch{
    console.log('errorrr')
}
}
}
}


export async function createMultipleImageFile(images1, image2,image3) {
    let imageobj = {
        picture1: '',
        picture2: '',
        picture3: '',
    }

    if (images1 && typeof images1 === 'object' && typeof images1.name == 'string') {
        imageobj = {
            ...imageobj,
            picture1: images1
        }
    } else {
        let response = await fetch(images1,{mode:'no-cors'});
        console.log(response,'datadatadata')
        let data = await response.blob();
        let metadata = {
            type: 'image/jpg'
        };
        let file = new File([data], "test.jpg", metadata);
        console.log(file,'datadatadata')
        imageobj = {
            ...imageobj,
            picture1: file
        }
    }

    if (image2 && typeof image2 === 'object' && typeof image2.name == 'string') {
        imageobj = {
            ...imageobj,
            picture2: image2
        }
    } else {
        let response = await fetch(image2,{mode:'no-cors'});
        let data = await response.blob();
        let metadata = {
            type: 'image/jpg'
        };
        let file = new File([data], "test.jpg", metadata);
        imageobj = {
            ...imageobj,
            picture2: file
        }

    }

    if (image3 && typeof image3 === 'object' && typeof image3.name == 'string') {
        imageobj = {
            ...imageobj,
            picture3: image3
        }
    } else {
        let response = await fetch(image3,{mode:'no-cors'});
        let data = await response.blob();
        let metadata = {
            type: 'image/jpg'
        };
        let file = new File([data], "test.jpg", metadata);
        imageobj = {
            ...imageobj,
            picture3: file
        }
    }


    console.log(imageobj,'datadatadata')
    return (imageobj)


}


export const dateFunction = (a, b) => {
    if (a !== "0000-00-00") {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const d = new Date(a);
        let month = monthNames[d.getMonth()]
        let date = d.getDate()
        let year = d.getFullYear()

        return `${date} ${month} ${year}`
    } else {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const d = new Date(b);
        let month = monthNames[d.getMonth()]
        let date = d.getDate()
        let year = d.getFullYear()

        return `${date} ${month} ${year}`
    }
}

// export const dateFunctionB = (a) => {
//     const monthNames = ["January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December"
//     ];
//     const d = new Date(a);
//     let month = monthNames[d.getMonth()]
//     let date = d.getDate()
//     let year = d.getFullYear()

//     return `${date} ${month?.slice(0,3)} ${year}`
// }

