import { message } from "antd";
import Axios from "axios";
import { getCookie } from "../../common/common";
import store from "../store";
import { getAllAccountEntries } from "./reportsAction";

// user loader true
function accountLoader(bool) {
	return (dispatch) => {
		dispatch(AccountLoaderBool(bool));
	};
}
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const AccountLoaderBool = (bool) => {
	return {
		type: ACCOUNT_LOADING,
		payload: bool
	};
};

//Get Expense
export function getExpense(page, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}expenses/?page=${page ? page : 1
				}&branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				dispatch(getExpenseSuccess(res.data, page));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}
export const GET_EXPENSE = "GET_EXPENSE";
export const getExpenseSuccess = (data, page) => ({
	type: GET_EXPENSE,
	payload: { data, page }
});

//Add Expense
export function addExpense(obj, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}expenses/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Expense Added");
				dispatch(getExpense());
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}


//delete Expense
export function deleteExpenseSingle(id) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "delete",
			url: `${process.env.REACT_APP_BACKEND_URL}expenses/${id}`,
			headers: {
				Authorization: `JWT ${token}`
			},
		})
			.then((res) => {
				message.success("Expense Deleted");
				dispatch(getExpense());
				// dispatch(accountLoader(false));
			})
			.catch(() => {
				// dispatch(accountLoader(false));
			});
	};
}



//edit Expense
export function editExpense(obj, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}expenses/${obj.id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		}).then((res) => {
			message.success("Expense Updated");
			dispatch(getExpense());
			dispatch(accountLoader(false));
		});
	};
}
// export const ADD_EXPENSE = 'ADD_EXPENSE';
// export const addExpenseSuccess = (data) => ({
//     type: ADD_EXPENSE,
//     payload: { data }
// });

//add Expense SubHead
export function addExpenseSubHead(obj, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}expensesSubHead/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Expense Subhead Added");
				dispatch(getExpense());
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

//update Expense
export function updateExpense(obj, id, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}expensesSubHead/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Expense Subhead Updated");
				dispatch(getExpense());
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

//delete Expense
export function deleteExpense(id, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "delete",
			url: `${process.env.REACT_APP_BACKEND_URL}expensesSubHead/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				message.success("Expense Subhead Deleted");
				dispatch(getExpense());
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

// all expenses
export function getAllExpense(cat, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}Allexpenses/?category=${cat}&branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				dispatch(getAllExpenseSuccess(res.data));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}
export const GET_ALL_EXPENSE = "GET_ALL_EXPENSE";
export const getAllExpenseSuccess = (data) => ({
	type: GET_ALL_EXPENSE,
	payload: { data }
});

export const ADD_ACCOUNT_LOADING = "ADD_ACCOUNT_LOADING";
export const addAccountLoader = (bool) => {
	return {
		type: ADD_ACCOUNT_LOADING,
		payload: bool
	};
};

// add account
export function AddAccountDetail(obj, branchCode, clearState) {
	return (dispatch) => {
		dispatch(addAccountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}expensesEntry/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Added Account Detail");
				dispatch(addAccountLoader(false));
				clearState();
			})
			.catch(() => {
				dispatch(addAccountLoader(false));
			});
	};
}

// add account
export function UpdateAcountDetail(
	obj,
	id,
	page,
	value,
	startDate,
	endDate,
	keyword,
	type,
	name,
	branchCode,
	pageSize,
	handleCancel
) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}expensesEntry/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Updated Account Detail");
				dispatch(
					getAllAccountEntries(
						page,
						value,
						startDate,
						endDate,
						keyword,
						type,
						name,
						branchCode,
						pageSize
					)
				);
				handleCancel();
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

// all expenses
export function getNotification() {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}api/getNotificationData/`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				console.log(res, "getNotification");
				dispatch(getNotificationSuccess(res.data));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				console.log("err");
				dispatch(accountLoader(false));
			});
	};
}
export const GET_ALL_NOTFICATION = "GET_ALL_NOTFICATION";
export const getNotificationSuccess = (data) => ({
	type: GET_ALL_NOTFICATION,
	payload: { data }
});

// AcceptDiscount
export function AcceptDiscount(obj, branchCode, modalClose) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/${obj.entryNo}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				dispatch(accountLoader(false));
				if (modalClose) {
					modalClose();
				}
				dispatch(getNotification());
			})
			.catch((res) => {
				if (modalClose) {
					modalClose();
				}
				dispatch(accountLoader(false));
			});
	};
}

//Edit single membership
export function acceptMemberShipDiscount(id, obj, branchCode) {
	return (dispatch) => {
		// dispatch(CustomerReportsLoadingBool(true))
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}membership/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				dispatch(accountLoader(false));
				dispatch(getNotification());
				message.success("Membership Updated");
				// dispatch(CustomerReportsLoadingBool(false))
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}



// recievables
export function getRecieveable(page, branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}recievable/?page=${page ? page : 1
				}&branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				dispatch(getExpenseSuccess(res.data, page));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

//Add recievables
export function addrecievables(obj, branchCode, page, clearStates) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}recievable/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				// dispatch(clearStates())
				message.success("Recievable Added");
				dispatch(getRecieveable(page, branchCode));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}

//delete recieveable
export function deleteRecievable(id, branchCode, page) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "delete",
			url: `${process.env.REACT_APP_BACKEND_URL}recievable/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				message.success("Expense Subhead Deleted");
				dispatch(getRecieveable(page, branchCode));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}


//edit Recieveables
export function editRecievables(obj, branchCode, page) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}recievable/${obj.id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		}).then((res) => {
			message.success("Expense Updated");
			dispatch(getRecieveable(page, branchCode));
			dispatch(accountLoader(false));
		});
	};
}
// all expenses
export function getAllRecievables(branchCode) {
	return (dispatch) => {
		dispatch(accountLoader(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}recievable/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				dispatch(getAllExpenseSuccess(res.data.results));
				dispatch(accountLoader(false));
			})
			.catch(() => {
				dispatch(accountLoader(false));
			});
	};
}
