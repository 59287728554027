import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'antd';
import { Table, Tag, Space } from 'antd';
const { Column, ColumnGroup } = Table;

const EduCollectionModal = ({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    TotalCollection,
    collectionData,
    handleCancel }) => {

    let data = [
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },

    ]

    return (
        <>

            <Modal title="Education Collection" className="reportModal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <Table className="itemsTable" dataSource={TotalCollection} footer={() => {
                    return (
                        <div className="expenseTotal">
                            <h5>TOTAL</h5>
                            <p>{collectionData ? collectionData.TotalEducationalColletionAmt : '0'}</p>
                        </div>
                    )
                }} >
                    <Column title="Date" dataIndex="date" key="date" render={(record) => {
                        return (
                            <div onClick={() => {}}>
                                {new Date(record).toLocaleDateString()}
                            </div>
                        )
                    }} />
                    <Column title="Entry No." dataIndex="code" key="code" />
                    <Column title="Income Name" dataIndex="name" key="name" />
                    <Column title="Description" dataIndex="description" key="description" />
                    <Column title="Entered By" dataIndex="userName" key="userName" />
                    <Column title="Amount" dataIndex="amount" key="amount" />
                </Table>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EduCollectionModal)