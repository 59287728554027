import Axios from 'axios'
import { GET_USER_PERMISSION } from '../actions/authenticationActions';
import { USER_LOADING, GET_USERS, GET_SINGLE_USER, GET_SINGLE_USER_PERMISSION } from '../actions/userAction';

const initialState = {
    userLoading: false,
    users: [],
    page: 1,
    total: 0,
    permission: false,
    singleUser: false,
    adminPermissions: {
        user_id: "",
        branchCode: "",
        userType: "",
        taken_in: true,
        take_out: true,
        dashboard: true,
        reports: true,
        consultation: true,
        account: true,
        admin_panel: true,
        manager_panel: true,
        customer_add: true,
        customer_view: true,
        customer_edit: true,
        customer_delete: true,
        reciept_generated_view: true,
        reciept_generated_add: true,
        reciept_generated_edit: true,
        reciept_generated_delete: true,
        reciept_pending_view: true,
        reciept_pending_add: true,
        reciept_pending_edit: true,
        reciept_pending_delete: true,
        reciept_number_view: true,
        reciept_number_add: true,
        reciept_number_edit: true,
        reciept_number_delete: true,
        customer_report_view: true,
        customer_report_add: true,
        customer_report_edit: true,
        customer_report_delete: true,
        direct_cash_recievd_view: true,
        direct_cash_recievd_add: true,
        direct_cash_recievd_edit: true,
        direct_cash_recievd_delete: true,
        services_report_view: true,
        services_report_add: true,
        services_report_edit: true,
        services_report_delete: true,
        cash_recieved_view: true,
        cash_recieved_add: true,
        cash_recieved_edit: true,
        cash_recieved_delete: true,
        expenses_report_view: true,
        expenses_report_add: true,
        expenses_report_edit: true,
        expenses_report_delete: true,
        summary_report_view: true,
        summary_report_add: true,
        summary_report_edit: true,
        summary_report_delete: true,
        user_report_view: true,
        user_report_add: true,
        user_report_edit: true,
        user_report_delete: true,
        account_report_view: true,
        account_report_add: true,
        account_report_edit: true,
        account_report_delete: true,
        admin_panel_add_prices_view: true,
        admin_panel_add_prices_add: true,
        admin_panel_add_prices_edit: true,
        admin_panel_add_prices_delete: true,
        admin_panel_add_branch_view: true,
        admin_panel_add_branch_add: true,
        admin_panel_add_branch_edit: true,
        admin_panel_add_branch_delete: true,
        admin_panel_add_user_view: true,
        admin_panel_add_user_add: true,
        admin_panel_add_user_edit: true,
        admin_panel_add_user_delete: true,
        admin_panel_add_expense_name_view: true,
        admin_panel_add_expense_name_add: true,
        admin_panel_add_expense_name_edit: true,
        admin_panel_add_expense_name_delete: true,
        admin_panel_add_recievable_name_view: true,
        admin_panel_add_recievable_name_add: true,
        admin_panel_add_recievable_name_edit: true,
        admin_panel_add_recievable_name_delete: true,
        manager_panel_diamond_grading_view: true,
        manager_panel_diamond_grading_add: true,
        manager_panel_diamond_grading_edit: true,
        manager_panel_diamond_grading_delete: true,
        manager_panel_gemstone_report_view: true,
        manager_panel_gemstone_report_add: true,
        manager_panel_gemstone_report_edit: true,
        manager_panel_gemstone_report_delete: true,
        manager_panel_small_jewellery_view: true,
        manager_panel_small_jewellery_add: true,
        manager_panel_small_jewellery_edit: true,
        manager_panel_small_jewellery_delete: true,
        manager_panel_small_gemstone_view: true,
        manager_panel_small_gemstone_add: true,
        manager_panel_small_gemstone_edit: true,
        manager_panel_small_gemstone_delete: true,
        manager_panel_small_gemstone_jewellery_view: true,
        manager_panel_small_gemstone_jewellery_add: true,
        manager_panel_small_gemstone_jewellery_edit: true,
        manager_panel_small_gemstone_jewellery_delete: true,
        manager_panel_small_diamond_view: true,
        manager_panel_small_diamond_add: true,
        manager_panel_small_diamond_edit: true,
        manager_panel_small_diamond_delete: true,
        manager_panel_jewellery_report_view: true,
        manager_panel_jewellery_report_add: true,
        manager_panel_jewellery_report_edit: true,
        manager_panel_jewellery_report_delete: true,
        manager_panel_master_colored_stone_view: true,
        manager_panel_master_colored_stone_add: true,
        manager_panel_master_colored_stone_edit: true,
        manager_panel_master_colored_stone_delete: true,
        manager_panel_appraisal_certificate_view: true,
        manager_panel_appraisal_certificate_add: true,
        manager_panel_appraisal_certificate_edit: true,
        manager_panel_appraisal_certificate_delete: true,
    },
    managerPermissions: {
        user_id: "",
        branchCode: "",
        userType: "",
        taken_in: true,
        take_out: true,
        dashboard: true,
        reports: true,
        consultation: true,
        account: true,
        admin_panel: false,
        manager_panel: true,
        customer_add: true,
        customer_view: true,
        customer_edit: true,
        customer_delete: false,
        reciept_generated_view: true,
        reciept_generated_add: true,
        reciept_generated_edit: true,
        reciept_generated_delete: false,
        reciept_pending_view: true,
        reciept_pending_add: true,
        reciept_pending_edit: true,
        reciept_pending_delete: false,
        reciept_number_view: true,
        reciept_number_add: true,
        reciept_number_edit: true,
        reciept_number_delete: false,
        customer_report_view: true,
        customer_report_add: true,
        customer_report_edit: true,
        customer_report_delete: false,
        direct_cash_recievd_view: false,
        direct_cash_recievd_add: false,
        direct_cash_recievd_edit: false,
        direct_cash_recievd_delete: false,
        services_report_view: false,
        services_report_add: false,
        services_report_edit: false,
        services_report_delete: false,
        cash_recieved_view: false,
        cash_recieved_add: false,
        cash_recieved_edit: false,
        cash_recieved_delete: false,
        expenses_report_view: false,
        expenses_report_add: false,
        expenses_report_edit: false,
        expenses_report_delete: false,
        summary_report_view: false,
        summary_report_add: false,
        summary_report_edit: false,
        summary_report_delete: false,
        user_report_view: false,
        user_report_add: false,
        user_report_edit: false,
        user_report_delete: false,
        account_report_view: false,
        account_report_add: false,
        account_report_edit: false,
        account_report_delete: false,
        admin_panel_add_prices_view: false,
        admin_panel_add_prices_add: false,
        admin_panel_add_prices_edit: false,
        admin_panel_add_prices_delete: false,
        admin_panel_add_branch_view: false,
        admin_panel_add_branch_add: false,
        admin_panel_add_branch_edit: false,
        admin_panel_add_branch_delete: false,
        admin_panel_add_user_view: false,
        admin_panel_add_user_add: false,
        admin_panel_add_user_edit: false,
        admin_panel_add_user_delete: false,
        admin_panel_add_expense_name_view: false,
        admin_panel_add_expense_name_add: false,
        admin_panel_add_expense_name_edit: false,
        admin_panel_add_expense_name_delete: false,
        admin_panel_add_recievable_name_view: false,
        admin_panel_add_recievable_name_add: false,
        admin_panel_add_recievable_name_edit: false,
        admin_panel_add_recievable_name_delete: false,
        manager_panel_diamond_grading_view: true,
        manager_panel_diamond_grading_add: true,
        manager_panel_diamond_grading_edit: true,
        manager_panel_diamond_grading_delete: true,
        manager_panel_gemstone_report_view: true,
        manager_panel_gemstone_report_add: true,
        manager_panel_gemstone_report_edit: true,
        manager_panel_gemstone_report_delete: true,
        manager_panel_small_jewellery_view: true,
        manager_panel_small_jewellery_add: true,
        manager_panel_small_jewellery_edit: true,
        manager_panel_small_jewellery_delete: true,
        manager_panel_small_gemstone_view: true,
        manager_panel_small_gemstone_add: true,
        manager_panel_small_gemstone_edit: true,
        manager_panel_small_gemstone_delete: true,
        manager_panel_small_gemstone_jewellery_view: true,
        manager_panel_small_gemstone_jewellery_add: true,
        manager_panel_small_gemstone_jewellery_edit: true,
        manager_panel_small_gemstone_jewellery_delete: true,
        manager_panel_small_diamond_view: true,
        manager_panel_small_diamond_add: true,
        manager_panel_small_diamond_edit: true,
        manager_panel_small_diamond_delete: true,
        manager_panel_jewellery_report_view: true,
        manager_panel_jewellery_report_add: true,
        manager_panel_jewellery_report_edit: true,
        manager_panel_jewellery_report_delete: true,
        manager_panel_master_colored_stone_view: true,
        manager_panel_master_colored_stone_add: true,
        manager_panel_master_colored_stone_edit: true,
        manager_panel_master_colored_stone_delete: true,
        manager_panel_appraisal_certificate_view: true,
        manager_panel_appraisal_certificate_add: true,
        manager_panel_appraisal_certificate_edit: true,
        manager_panel_appraisal_certificate_delete: true,
    },
    userPermissions: {
        user_id: "",
        branchCode: "",
        userType: "",
        taken_in: true,
        take_out: true,
        dashboard: true,
        reports: true,
        consultation: false,
        account: true,
        admin_panel: false,
        manager_panel: false,
        customer_add: true,
        customer_view: true,
        customer_edit: false,
        customer_delete: false,
        reciept_generated_view: true,
        reciept_generated_add: true,
        reciept_generated_edit: false,
        reciept_generated_delete: false,
        reciept_pending_view: true,
        reciept_pending_add: true,
        reciept_pending_edit: false,
        reciept_pending_delete: false,
        reciept_number_view: true,
        reciept_number_add: true,
        reciept_number_edit: false,
        reciept_number_delete: false,
        customer_report_view: true,
        customer_report_add: true,
        customer_report_edit: false,
        customer_report_delete: false,
        direct_cash_recievd_view: false,
        direct_cash_recievd_add: false,
        direct_cash_recievd_edit: false,
        direct_cash_recievd_delete: false,
        services_report_view: false,
        services_report_add: false,
        services_report_edit: false,
        services_report_delete: false,
        cash_recieved_view: false,
        cash_recieved_add: false,
        cash_recieved_edit: false,
        cash_recieved_delete: false,
        expenses_report_view: false,
        expenses_report_add: false,
        expenses_report_edit: false,
        expenses_report_delete: false,
        summary_report_view: false,
        summary_report_add: false,
        summary_report_edit: false,
        summary_report_delete: false,
        user_report_view: false,
        user_report_add: false,
        user_report_edit: false,
        user_report_delete: false,
        account_report_view: false,
        account_report_add: false,
        account_report_edit: false,
        account_report_delete: false,
        admin_panel_add_prices_view: false,
        admin_panel_add_prices_add: false,
        admin_panel_add_prices_edit: false,
        admin_panel_add_prices_delete: false,
        admin_panel_add_branch_view: false,
        admin_panel_add_branch_add: false,
        admin_panel_add_branch_edit: false,
        admin_panel_add_branch_delete: false,
        admin_panel_add_user_view: false,
        admin_panel_add_user_add: false,
        admin_panel_add_user_edit: false,
        admin_panel_add_user_delete: false,
        admin_panel_add_expense_name_view: false,
        admin_panel_add_expense_name_add: false,
        admin_panel_add_expense_name_edit: false,
        admin_panel_add_expense_name_delete: false,
        admin_panel_add_recievable_name_view: false,
        admin_panel_add_recievable_name_add: false,
        admin_panel_add_recievable_name_edit: false,
        admin_panel_add_recievable_name_delete: false,
        manager_panel_diamond_grading_view: false,
        manager_panel_diamond_grading_add: false,
        manager_panel_diamond_grading_edit: false,
        manager_panel_diamond_grading_delete: false,
        manager_panel_gemstone_report_view: false,
        manager_panel_gemstone_report_add: false,
        manager_panel_gemstone_report_edit: false,
        manager_panel_gemstone_report_delete: false,
        manager_panel_small_jewellery_view: false,
        manager_panel_small_jewellery_add: false,
        manager_panel_small_jewellery_edit: false,
        manager_panel_small_jewellery_delete: false,
        manager_panel_small_gemstone_view: false,
        manager_panel_small_gemstone_add: false,
        manager_panel_small_gemstone_edit: false,
        manager_panel_small_gemstone_delete: false,
        manager_panel_small_gemstone_jewellery_view: false,
        manager_panel_small_gemstone_jewellery_add: false,
        manager_panel_small_gemstone_jewellery_edit: false,
        manager_panel_small_gemstone_jewellery_delete: false,
        manager_panel_small_diamond_view: false,
        manager_panel_small_diamond_add: false,
        manager_panel_small_diamond_edit: false,
        manager_panel_small_diamond_delete: false,
        manager_panel_jewellery_report_view: false,
        manager_panel_jewellery_report_add: false,
        manager_panel_jewellery_report_edit: false,
        manager_panel_jewellery_report_delete: false,
        manager_panel_master_colored_stone_view: false,
        manager_panel_master_colored_stone_add: false,
        manager_panel_master_colored_stone_edit: false,
        manager_panel_master_colored_stone_delete: false,
        manager_panel_appraisal_certificate_view: false,
        manager_panel_appraisal_certificate_add: false,
        manager_panel_appraisal_certificate_edit: false,
        manager_panel_appraisal_certificate_delete: false,
    },
    singleUserPermission: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                userLoading: action.payload,
            };
        case GET_USERS:
            return {
                ...state,
                userLoading: false,
                users: action.payload.data,
                page: action.payload.page,
                total: action.payload.total
            };
        case GET_USER_PERMISSION:
            return {
                ...state,
                userLoading: false,
                permission: action.payload.data,
            };
        case GET_SINGLE_USER_PERMISSION:
            return {
                ...state,
                userLoading: false,
                singleUserPermission: action.payload.data,
            };
        case GET_SINGLE_USER:
            return {
                ...state,
                userLoading: false,
                singleUser: action.payload.data,
            };
        default:
            return state
    }
}

