import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Collapse, Modal, Tag } from "antd";
import { Col, Row, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";


const { Option } = Select;
const { Panel } = Collapse;

function CustomerDetails(props) {
	let membershipDetail =
		props.selectedCustomer && props.selectedCustomer.membershipDetail
			? props.selectedCustomer.membershipDetail
			: [];

	const getDate = (date) => {
		let year = new Date(date).getFullYear();
		let month =
			(new Date(date).getMonth() + 1).toString().length > 1
				? new Date(date).getMonth() + 1
				: `0${new Date(date).getMonth() + 1}`;
		let day =
			new Date(date).getDate().toString().length > 1
				? new Date(date).getDate()
				: `0${new Date(date).getDate()}`;

		return `${day}-${month}-${year}`;
	};

	return (
		<div className="customerDetailSec">
			{/* {props.selectedCustomer && props.selectedCustomer.customerName && (
				<Button
					disabled={props.selectedCustomer && !props.selectedCustomer.customerName}
					type="primary"
					className="customerModalButton"

				>
					Customer Detail
				</Button>
			)} */}
			&nbsp;&nbsp;
			{props.selectedCustomer && props.selectedCustomer.customerName && (
				<span style={{ fontSize: 14 }}>
					<b onClick={() => console.log(props.selectedCustomer)}>Customer Name:&nbsp; </b>
					<span className="infoButton" onClick={() =>
						props.selectedCustomer && props.selectedCustomer.customerName && (
							props.setModal1VisibleFn(true)
						)

					}> {props.selectedCustomer && props.selectedCustomer.customerName}&nbsp;&nbsp;

						<InfoCircleOutlined /></span>

					<b>|</b>{" "}
					&nbsp;&nbsp;
					<b>Customer ID:&nbsp; </b>
					{props.selectedCustomer && props.selectedCustomer.customerId}&nbsp;&nbsp; <b>|</b>{" "}
					&nbsp;&nbsp;
					<b>
						{props.selectedCustomer && props.selectedCustomer.TotalAdvanceorBalance < 0
							? "Advance"
							: "Balance"}
						:&nbsp;{" "}
					</b>
					{props.selectedCustomer && props.selectedCustomer.TotalAdvanceorBalance < 0
						? props.selectedCustomer.TotalAdvanceorBalance.toString().slice(1)
						: props.selectedCustomer.TotalAdvanceorBalance}
					&nbsp;&nbsp; <b>|</b> &nbsp;&nbsp;
					<b>MemberShip:&nbsp; </b>
					{!props.selectedCustomer.membershipDetail.length ? (
						<b>
							<Tag color="error">Not Acquire yet</Tag>
						</b>
					) : (
						<b>
							{props.membershipActive ? (
								<Tag color="success">Active</Tag>
							) : (
								<Tag color="error">Expire</Tag>
							)}
						</b>
					)}
				</span>
			)
			}
			{
				!props.selectedCustomer && !props.selectedCustomer.customerName && !props.takeOut && (
					<b>Select Customer</b>
				)
			}
			<Modal
				title="Customer Detail"
				className="custDetModal"
				style={{ top: 20 }}
				visible={props.modal1Visible}
				onOk={() => props.setModal1VisibleFn(false)}
				onCancel={() => props.setModal1VisibleFn(false)}
				okButtonProps={{ style: { background: "#001529", color: "white", borderColor: "#001529" } }}
				cancelButtonProps={{
					style: { background: "#f50057", color: "white", borderColor: "#f50057" }
				}}
			>
				<div className="customerDetail">
					<Collapse accordion defaultActiveKey="1">
						<Panel
							header={
								<table className="CustomerDetTable">
									<tbody>
										<tr>
											<th>Customer Detail</th>
											<th>
												Customer ID: {props.selectedCustomer && props.selectedCustomer.customerId}
											</th>
											<th>
												Customer Name:{" "}
												{props.selectedCustomer && props.selectedCustomer.customerName}
											</th>
											<th>Advance: {props.preBalanceOrAdvance}</th>
											<td>
												<div>
													{props.selectedCustomer &&
														props.selectedCustomer.membershipDetail &&
														!props.selectedCustomer.membershipDetail.length ? (
														<b>
															<Tag color="error">Not Acquire yet</Tag>
														</b>
													) : (
														<b>
															{props.membershipActive ? (
																<Tag color="success">Active</Tag>
															) : (
																<Tag color="error">Expire</Tag>
															)}
														</b>
													)}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							}
							key="1"
						>
							<table className="custInnerTable">
								<tbody>
									<tr>
										<th>Customer Name</th>
										<td>{props.selectedCustomer && props.selectedCustomer.customerName}</td>
										<td></td>
									</tr>
									<tr>
										<th>Address</th>
										<td>{props.selectedCustomer && props.selectedCustomer.address}</td>
										<td></td>
									</tr>
									<tr>
										<th>Company Name</th>
										<td>{props.selectedCustomer && props.selectedCustomer.companyName}</td>
										<td></td>
									</tr>
									<tr>
										<th>Phones Number</th>
										<td>{props.selectedCustomer && props.selectedCustomer.mobileNo}</td>
										<td>{props.selectedCustomer && props.selectedCustomer.mobileNo2}</td>
									</tr>
									{props.selectedCustomer.membershipDetail &&
										!props.selectedCustomer.membershipDetail
											.length ? null : props.membershipActive ? (
												<tr>
													<th>Membership</th>
													<td>
														<b>Start Date:</b>{" "}
														{membershipDetail.length &&
															getDate(
																new Date(membershipDetail[membershipDetail.length - 1].startDate)
															)}
													</td>
													<td>
														<b>End Date:</b>{" "}
														{membershipDetail.length &&
															getDate(new Date(membershipDetail[membershipDetail.length - 1].endDate))}
													</td>
												</tr>
											) : (
										!props.membershipActive && (
											<tr>
												<th>Membership</th>
												<td>
													<b>Start Date:</b>{" "}
													{membershipDetail.length &&
														getDate(
															new Date(membershipDetail[membershipDetail.length - 1].startDate)
														)}
												</td>
												<td>
													<b>End Date:</b>{" "}
													{membershipDetail.length &&
														getDate(
															new Date(membershipDetail[membershipDetail.length - 1].endDate)
														)}
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</Panel>
					</Collapse>
				</div>
			</Modal>
		</div >
	);
}

const mapStateToProps = (store) => {
	return {
		membershipActive: store.customers.membershipActive
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
