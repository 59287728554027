import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Space } from 'antd';
import {
    EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined,
    PrinterFilled
} from '@ant-design/icons';
import logo from './../../../Images/Logo 2.png'
import RecievableModal from './RecievableModal';
const { Column } = Table;

const Recievable = (props) => {
    console.log(props.data)
    let data = [
        {
            name: 'fardeen',
            balance: 3500,

        },
        {
            name: 'fardeen',
            balance: 3500,

        },
        {
            name: 'fardeen',
            balance: 3500,

        },
        {
            name: 'fardeen',
            balance: 3500,

        },
    ]
    useEffect(() => {
        props.setKeyword('Laboratory')
    }, [props])

    const [isModalVisible, setIsModalVisible] = useState(false);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="Recievable">
            <div className="tableDiv">
                {props.reportsLoading ?
                    <div className="loaderDiv tableLoader">
                        <div>
                            <img src={logo} />
                        </div>
                    </div>
                    : null
                }
                <Table pagination={false} className="itemsTable" dataSource={props.data} 
                // onRow={(record) => ({
                //     onClick: () => {
                //         showModal(record);
                //     },
                // })}
                >
                    <Column title="S.No" render={(text, record, index) => {
                        return (
                            <>
                                {index + 1}
                            </>
                        )
                    }} />
                    <Column title="Category" dataIndex="category" key="category" />
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="Total" dataIndex="total" key="total" />

                </Table>
            </div>
            <RecievableModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Recievable)
