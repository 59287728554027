import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Input, Popconfirm, message } from 'antd';
import {
    EditOutlined
    , DeleteOutlined
} from '@ant-design/icons';
import './account.scss'
import { addExpenseSubHead, deleteExpense, updateExpense } from '../../../modules/actions/AccountActions';
import { bindActionCreators } from 'redux';

const AccountModal = (props) => {
    let { isModalVisible,
        setIsModalVisible,
        showModal,
        handleOk,
        handleCancel,
        expenseDelete,
        addSubHead,
        subHeadArr,
        setSubHeadArr,
        subHeadName,
        setSubHeadName,
        id,
        setId,
        edit,
        setEdit,
        mainCategory,
        setMainCategory,
        editStates,
        clearStates,
        confirm,
        cancel
    } = props







    useEffect(() => {
        if (props.item.expenseSubHead) {
            setSubHeadArr(props.item.expenseSubHead)
        }
    }, [props.item, props])








    useEffect(() => {
    }, [subHeadArr])

    return (
        <div>
            <Modal title="Basic Modal" className="accountModal" visible={isModalVisible} onCancel={handleCancel}
                footer={[
                    <button type="button" onClick={handleCancel} class="ant-btn" ant-click-animating-without-extra-node="false"><span>Cancel</span></button>
                ]
                }
            >
                <div className="addDiv">
                    <div className="inputFields">
                        <label>
                            <h5>Add Expense Subhead</h5>
                            <div className="inputDiv">
                                <Input value={subHeadName} onChange={(ev) => setSubHeadName(ev.target.value)} /><button onClick={() => addSubHead()}>ADD</button>
                            </div>
                        </label>
                    </div>                </div>
                <table className="accountModalTable" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Expense Name</th>
                            <th>Acton</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subHeadArr.length ? subHeadArr.map((a, i) => {
                            return (

                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{a.name}</td>
                                    <td>
                                        <a onClick={() => editStates(a.id, a.name, a.mainExpenses)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#001529" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg></a>
                                        <Popconfirm
                                            title="Are you sure to delete this task?"
                                            onConfirm={(e)=>{confirm(e,a.id, a)}}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <a > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(245, 0, 87)" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg></a>
                                        </Popconfirm>,
                                    </td>

                                </tr>
                            )
                        }) : null
                        }
                    </tbody>
                </table>
            </Modal>
        </div>
    )
}

const mapStateToProps = (store) => ({
    expenses: store.accountStore.expenses,
    permission: store.users.permission
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addExpenseSubHead,
            updateExpense,
            deleteExpense
        },
        dispatch
    )


export default connect(mapStateToProps, mapDispatchToProps)(AccountModal)
