import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Popconfirm, Row, Select } from "antd";
import { Button, Collapse, Input, InputNumber, Checkbox, DatePicker, Space } from "antd";
import "./takeIn.scss";
import CustomerDetails from "./customerDetail";
import {
	SaveOutlined,
	PlusOutlined,
	FileDoneOutlined,
	FileAddOutlined,
	DeleteOutlined
} from "@ant-design/icons";
import Items from "./entries";
import EntrieModal from "./entrieModal";
import AddItems from "./addItemModal";
import AddCustomerModal from "./addCustomerModal";
import {
	activeOrNorActive,
	clearCustomer,
	filteredCustomer,
	getCustomer
} from "../../modules/actions/customerActions";
import {
	getPacketNumber,
	getSingleCustomerPendingEntries,
	saveDataEntry
} from "../../modules/actions/dataEntryActions";
import AddAndViewExpense from "./addAndViewExpense";
import CashRecieved from "./CashRecieved";
import { dateFormat } from "../../common/common";
import moment from "moment";
import logo from "./../../Images/Logo 2.png";

const { Option } = Select;

function TakeIn(props) {
	const [modal1Visible, setModal1Visible] = useState(false);
	const [modal2Visible, setModal2Visible] = useState(false);
	const [modal3Visible, setModal3Visible] = useState(false);
	const [cantDraft, setCantDraft] = useState(true);
	const [popOver, setPopOver] = useState(true);

	useEffect(() => {
		props.clearCustomer();
	}, []);

	useEffect(() => {
		if (props.customer) {
			setSelectedCustomer(props.customer);
			// console.log(props.customer);
		} else {
			setSelectedCustomer(false);
		}
	}, [props.customer]);

	function setModal1VisibleFn(modal1Visible) {
		setModal1Visible(modal1Visible);
	}
	function setModal2VisibleFn(modal2Visible) {
		setModal2Visible(modal2Visible);
	}
	function setModal3VisibleFn(modal2Visible) {
		setModal3Visible(modal2Visible);
	}

	function addData(obj) {
		setItems([...items, obj]);
	}

	const [addCustomerModel, setAddCustomerModel] = useState(false);
	const [MembershipBtn, setMembershipBtn] = useState(false);
	const [inputVal, setInputVal] = useState("");

	const [search, setSearch] = useState(false);

	const onchange = (ev) => {
		setInputVal(ev);
		props.filteredCustomer(ev, props.allCustomers, getCustomerr);
		setSearch(true);
	};

	const getCustomerr = (id) => {

		props.getCustomer(id, props.permission.branchCode);
	};

	const [selectedCustomer, setSelectedCustomer] = useState(false);

	useEffect(() => {
		if (props.customer) {
			setPreBalanceOrAdvance(props.customer.TotalAdvanceorBalance);
			setSelectedCustomer(props.customer);
			// setCantDraft(true)
			let membershipDetail = props.customer.membershipDetail;
			// if (membershipDetail.length) {
			props.activeOrNorActive(
				membershipDetail && membershipDetail.length ? membershipDetail[membershipDetail.length - 1].startDate : false,
				membershipDetail && membershipDetail.length ? membershipDetail[membershipDetail.length - 1].endDate : false
			);
			// }
		}
	}, [props.customer]);

	function tommorrowDate() {
		const today = new Date();
		const tomorrow = new Date(today);
		return tomorrow.setDate(tomorrow.getDate() + 1);
	}

	const [expenseModel, setExpenseModel] = useState(false);

	const [items, setItems] = useState([]);
	const [comments, setComments] = useState("");
	const [totalAmount, setTotalAmount] = useState(0);
	const [advance, setAdvance] = useState(0);
	const [preBalanceOrAdvance, setPreBalanceOrAdvance] = useState("");
	const [balance, setBalance] = useState("");
	const [discountRequest, setDiscountRequest] = useState("");
	const [discountAmount, setDiscountAmount] = useState(0);
	const [discountReason, setDiscountReason] = useState("");
	const [deliveryDate, setDeliveryDate] = useState(tommorrowDate());
	const [boxOrPacket, setBoxOrPacket] = useState("Box");
	const [boxOrPacketNo, setBoxOrPacketNo] = useState("");
	const [addedExpenses, setAddedExpenses] = useState([]);
	const [editObj, setEditObj] = useState("");
	const [index, setIndex] = useState("");
	const [draftId, setDraftId] = useState("");
	const [activeDraft, setActiveDraft] = useState("");
	const [reCalculate, setReCalculate] = useState(false)

	// console.log(items, "items");
	const jumpPrintPage = (entryNo) => {
		
		props.history.push(`/Take-In-Print/${entryNo}`)
		// window.location.href = (`/Take-In-Print/${entryNo}`);
	};
	// console.log(selectedCustomer, "selectedCustomer");
	const saveItem = () => {
		let obj = {
			entryNo: "200odesfrsdtgfdgfghyx",
			consultationArray:[],
			id: 60,
			entryDate: dateFormat(new Date()),
			date: new Date(),
			totalAmount: totalAmount,
			deliveryDate: deliveryDate
				? dateFormat(new Date(deliveryDate))
				: dateFormat(new Date(tomorrow)),
			advance: advance? advance: 0,
			boxNo: boxOrPacketNo,
			branchCode: props.permission.branchCode,
			selectBoxOrPacket: boxOrPacket,
			checkBoxAskForDiscount: discountRequest ? "true" : "false",
			customerComments: comments,
			userName: props.user.username,
			discountApproved:
				props.permission.userType.toLowerCase() == "admin" ? "Approved" : "InProcess",
			discountAmount: discountAmount ? discountAmount : 0,
			discountReason: discountReason,
			customerId: selectedCustomer.customerId,
			customerName: selectedCustomer.customerName,
			items: items,
			expenses: addedExpenses
		};

		props.saveDataEntry(obj, clearState, jumpPrintPage, props.permission.branchCode);
		if (draftID) {
			draftDelete();
		}
	};

	useEffect(() => {
		let getServiceRate = 0;
		let expenseAmount = 0;
		if (items) {
			for (let i = 0; i < items.length; i++) {
				let { sealRate, mineRate, urgentRate, goldTestingRate } = items[i];
				getServiceRate +=
					Number(items[i].serviceRate) +
					Number(sealRate) +
					Number(mineRate) +
					Number(urgentRate) +
					Number(goldTestingRate);
				// console.log(getServiceRate, 'ccccccccccccc');
				console.log(Number(items[i].serviceRate),
					Number(sealRate),
					Number(mineRate),
					Number(urgentRate),
					Number(goldTestingRate), 'ccccccccccccc');
			}
			if (getServiceRate) {
				setTotalAmount(getServiceRate + expenseAmount);
				setBalance(getServiceRate - advance);
			}
		}
		if (addedExpenses) {
			// console.log(addedExpenses);
			for (let i = 0; i < addedExpenses.length; i++) {
				expenseAmount += Number(addedExpenses[i].expenseAmount);
			}
			if (expenseAmount) {
				// console.log(getServiceRate);
				setTotalAmount(getServiceRate + Number(expenseAmount));
				setBalance(getServiceRate + Number(expenseAmount) - advance);
			}
		}
	}, [items, addedExpenses, reCalculate]);

	useEffect(() => {
		setBalance(totalAmount - advance);
	}, [advance]);

	const clearState = () => {
		setItems([]);
		setComments("");
		setTotalAmount(0);
		setAdvance(0);
		setPreBalanceOrAdvance("");
		setBalance("");
		setDiscountRequest("");
		setDiscountAmount(0);
		setDiscountReason("");
		setDeliveryDate("");
		setBoxOrPacket("");
		setBoxOrPacketNo("");
	};

	const editItem = (obj, index) => {
		console.log("check editttt", obj.serviceRate);
		let getServiceRate = Number(obj.serviceRate);
		setEditObj({ ...obj, serviceRate: getServiceRate });
		setModal3VisibleFn(true);
		setIndex(index);
	};

	const closeAddItemModel = () => {
		setEditObj("");
		setModal3VisibleFn(false);
	};

	const updateItem = (arr) => {
		let arrr = [...arr];
		setItems([]);
		console.log("check arrr", arrr);
		setItems(arrr);
	};

	const deleteItem = (arr) => {
		setItems(arr);
		if (!arr.length) {
			setTotalAmount(0);
			setBalance(0 - advance);
		}
	};
	useEffect(() => {

		console.log("entryyyyyyy", props.entryToPrint);
	 }, [props.entryToPrint]);

	useEffect(() => {
		if (props.packetNumber && boxOrPacket === "Packet") {
			setBoxOrPacketNo(props.packetNumber);
		}
	}, [props]);

	useEffect(() => {
		// console.log(items, "items");
	}, [items]);

	const dateFormatt = "YYYY/MM/DD";

	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	let delDate = `${tomorrow.getFullYear()}/${(tomorrow.getMonth().toString().length + 1) > 1 ? (tomorrow.getMonth() + 1) : "0" + tomorrow.getMonth() + 1
		}/${tomorrow.getDate().toString().length > 1 ? tomorrow.getDate() : "0" + tomorrow.getDate()}`;
	const [customerName, setCustomerName] = useState(false);

	const selectCustomer = (e) => {
		let a = JSON.parse(e);
		getCustomerr(a.customerId);
		props.filteredCustomer("", props.allCustomers);
		// console.log(a);
		setCustomerName(
			`${a.customerName} ${a.mobileNo ? `(${a.mobileNo})` : a.mobileNo2 ? `(${a.mobileNo2})` : null
			}`
		);
	};

	// Draft

	const [draftArr, setDraftArr] = useState([]);
	const [draftID, setDraftID] = useState(false);
	function draft(id, bool) {
		if (bool) {
			setActiveDraft("");

			// setCantDraft(false)
			let item = {
				draftID: draftID ? draftID : id,
				id: 60,
				entryDate: dateFormat(new Date()),
				date: new Date(),
				totalAmount: totalAmount,
				deliveryDate: deliveryDate
					? dateFormat(new Date(deliveryDate))
					: dateFormat(new Date(tomorrow)),
				advance: advance,
				boxNo: boxOrPacketNo,
				branchCode: props.permission.branchCode,
				selectBoxOrPacket: boxOrPacket,
				// "checkBoxAskForDiscount": discountRequest ? "true" : "false",
				customerComments: comments,
				userName: props.user.username,
				discountApproved: "InProcess",
				discountAmount: discountAmount,
				discountReason: discountReason,
				customerId: selectedCustomer.customerId,
				customerName: selectedCustomer.customerName,
				items: items,
				expenses: addedExpenses,
				customerNameWithNumber: customerName
			};
			let existItem = JSON.parse(localStorage.getItem("draft"));
			if (existItem) {
				let matchObj = existItem.filter((a, i) => a.draftID === draftId || a.draftID === id)[0]
					? existItem.filter((a, i) => a.draftID === draftId || a.draftID === id)[0]
					: false;
				if (matchObj) {
					existItem.splice(existItem.indexOf(matchObj), 1, item);
					localStorage.setItem("draft", JSON.stringify([...existItem]));
				} else {
					localStorage.setItem("draft", JSON.stringify([...existItem, item]));
				}
			} else {
				localStorage.setItem("draft", JSON.stringify([item]));
			}
			updateDraft();
			clearState();
			setSelectedCustomer(false);
			setDraftId(false);
			setCustomerName("");
			setDraftID(false);
		} else {
			clearState();
			setSelectedCustomer(false);
			setDraftId(false);
			setCustomerName("");
			setDraftID(false);
		}
	}

	function updateDraft() {
		let item = JSON.parse(localStorage.getItem("draft"));
		// checkChanges()
		if (item) {
			setDraftArr(item);
		} else {
			setDraftArr([]);
		}
	}
	useEffect(() => {
		updateDraft();
	}, []);
	const [draftItemObj, setDraftItemObj] = useState({});
	const addDraftData = (id, draftIDs) => {
		setDraftID(draftIDs);
		// console.log(draftIDs);
		let draftItems = JSON.parse(localStorage.getItem("draft"));
		let item = {};
		if (draftItems) {
			item = draftItems.filter((a, i) => a.draftID === id)[0];
			setDraftItemObj(item);
			if (item) {
				setItems(item.items);
				setComments(item.customerComments);
				setTotalAmount(item.totalAmount);
				setAdvance(item.advance);
				setBalance(item.totalAmount - item.advance);
				setDiscountRequest(item.discountApproved);
				setDiscountAmount(item.discountAmount);
				setDiscountReason(item.discountReason);
				setDeliveryDate(item.deliveryDate);
				setBoxOrPacket(item.selectBoxOrPacket);
				setBoxOrPacketNo(item.boxNo);
				setAddedExpenses(item.expenses);
				getCustomerr(item.customerId);
				setCustomerName(item.customerNameWithNumber);
				setDraftId(draftIDs);
				setActiveDraft(item.draftID);
			}
		}
	};

	const checkChanges = () => {
		if (!selectedCustomer) {
			return false;
		}
		let obj = {
			draftID: draftID,
			id: 60,
			// "entryDate": dateFormat(new Date()),
			// "date": new Date(),
			totalAmount: totalAmount,
			// "deliveryDate": deliveryDate ? dateFormat(new Date(deliveryDate)) : dateFormat(new Date(tomorrow)),
			advance: advance,
			boxNo: boxOrPacketNo,
			branchCode: props.permission.branchCode,
			selectBoxOrPacket: boxOrPacket,
			// "checkBoxAskForDiscount": discountRequest,
			customerComments: comments,
			userName: props.user.username,
			discountApproved: "InProcess",
			discountAmount: discountAmount,
			discountReason: discountReason,
			customerId: selectedCustomer.customerId,
			customerName: selectedCustomer.customerName,
			items: items,
			expenses: addedExpenses,
			customerNameWithNumber: customerName
		};
		delete draftItemObj.entryDate;
		delete draftItemObj.date;
		delete draftItemObj.deliveryDate;
		// console.log(draftItemObj);
		// console.log(obj);
		if (JSON.stringify(draftItemObj) === JSON.stringify(obj)) {
			console.log("matched");
			// setCantDraft(false)
			return false;
		} else {
			console.log("not Matched");
			// setCantDraft(true)
			return true;
		}
	};

	const draftDelete = () => {
		let draftItems = JSON.parse(localStorage.getItem("draft"));
		let filteredItem = draftItems.filter((a, i) => a.draftID === draftID)[0];
		// console.log(draftID);
		draftItems.splice(draftItems.indexOf(filteredItem), 1);
		let updatedArr = [];
		if (draftItems.length) {
			draftItems.map((a, i) => {
				updatedArr.push({
					...a,
					draftID: i + 1
				});
			});
		}
		// console.log(updatedArr);
		localStorage.setItem("draft", JSON.stringify(updatedArr));
		updateDraft();
		clearState();
		setSelectedCustomer(false);
		setDraftId(false);
		setCustomerName("");
		setDraftID(false);
		// setCantDraft(false)
	};

	useEffect(() => {
		if (selectedCustomer) {
			setCantDraft(false);
			// console.log(cantDraft);
		}
	}, [selectedCustomer]);

	return (
		<div className="takeInItem">
			<Row className="spaceBetween">
				<Col xs={19} sm={19} md={19} lg={19} xl={19}>
					<div className="selectCustomer">
						{/* <Input
              autoComplete="off"
              value={inputVal}
              className="select"
              onChange={(e) => onchange(e.target.value)}
            />
            {props.filteredCustomerArr.length ? inputVal &&
              <div className="filteredDropDown">
                {props.filteredCustomerArr.map((a, i) => {
                  return (
                    <div onClick={() => {
                      setInputVal(a.customerName)
                      getCustomerr(a.customerId)
                      props.filteredCustomer("", props.allCustomers)
                    }} key={i}>{a.customerName} {Number(a.mobileNo) ? `(${a.mobileNo})` : Number(a.mobileNo2) ? `(${a.mobileNo2})` : null}</div>
                  )
                })}
              </div>
              : null} */}

						<Select
							autoFocus={true}
							className="selectCustomerSelect"
							showSearch
							style={{ width: "100%", height: "40px" }}
							placeholder="Select a person"
							optionFilterProp="children"
							onChange={(e) => {
								selectCustomer(e);
							}}
							// onFocus={onFocus}
							// onBlur={onBlur}
							value={customerName}
							onSearch={(e) => onchange(e)}
						// filterOption={(input, option) =>
						//   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						// }
						>
							{props.filteredCustomerArr.length
								? inputVal &&
								props.filteredCustomerArr.map((a, i) => {
									return (
										<Option
											onClick={() => {
												// setInputVal(a.customerName)
												// getCustomerr(a.customerId)
												// props.filteredCustomer("", props.allCustomers)
											}}
											value={JSON.stringify(a)}
											key={i}
										>
											{a.customerName}{" "}
											{Number(a.mobileNo)
												? `(${a.mobileNo})`
												: null}

											{Number(a.mobileNo2) && Number(a.mobileNo2) !== Number(a.mobileNo)
											? `(${a.mobileNo2})`
											: null}
										</Option>
									);
								})
								: null}
						</Select>
					</div>
				</Col>
				<Col xs={5} sm={5} md={5} lg={5} xl={5}>
					<div className="takeinAddDeleteButton">
						{props.userPermissions.customer_add && (
							<Button className="addCustomer" onClick={() => {setAddCustomerModel(true);
							setMembershipBtn(false);}
							}>
								Add New Customer
							</Button>
						)}
						<Button
							type="primary"
							onClick={() => {
								clearState();
								setSelectedCustomer(false);
								setCustomerName(false);
							}}
							style={{ background: "#f50057", borderColor: "#f50057" }}
						>
							Clear
						</Button>
					</div>
					<AddCustomerModal
						title="Add New Customer"
						className="AddModal"
						style={{ top: 20, maxHeight: "90vh" }}
						visible={addCustomerModel}
						membershipBtn={MembershipBtn}
						onOk={() => {
							setAddCustomerModel(false);
						}}
						onCancel={() => setAddCustomerModel(false)}
						okText="Add"
					/>
				</Col>
				{props.getCustomerLoading ? (
					<div className="loaderDiv customerLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : Object.keys(selectedCustomer).length ? (
					<div className="addItemSec">
						<div className="spaceBetween customerPortion">
							<CustomerDetails
								modal1Visible={modal1Visible}
								setModal1Visible={setModal1Visible}
								setModal1VisibleFn={setModal1VisibleFn}
								selectedCustomer={selectedCustomer}
								preBalanceOrAdvance={preBalanceOrAdvance}
							/>
							{/* {items.length ? <h3 className="addItemHead">All Entries</h3> : null} */}
							<div className="entrieButtonDiv">
								<CashRecieved selectedCustomer={selectedCustomer} edit={false} preBalanceOrAdvance={preBalanceOrAdvance}/>
								<EntrieModal
									modal2Visible={modal2Visible}
									setModal2Visible={setModal2Visible}
									setModal2VisibleFn={setModal2VisibleFn}
									selectedCustomer={selectedCustomer}
								/>
							</div>
						</div>
						<Row className="spaceBetween notAligned">
							<Col xs={10} sm={10} md={10} lg={10} xl={10}>
								<div className="formDiv">
									<div className="innerForm">
										<div className="addEnteryDiv">
											<AddItems
												modal3Visible={modal3Visible}
												setModal3Visibl={setModal3Visible}
												setModal3VisibleFn={setModal3VisibleFn}
												addData={addData}
												items={items}
												selectedCustomer={selectedCustomer}
												editObj={editObj}
												closeAddItemModel={closeAddItemModel}
												index={index}
												updateItem={updateItem}
												editItemBool={false}
												reCalculate={reCalculate}
												setReCalculate={setReCalculate}
											/>
										</div>
										<>
											<div className="inputFields w100P">
												<label>
													<h5>Comments</h5>
													<div>
														<Input
															type="text"
															value={comments}
															onChange={(ev) => setComments(ev.target.value)}
														/>
													</div>
												</label>
											</div>

											<div className="inputFields">
												<label>
													<h5>Total Amount</h5>
													<InputNumber defaultValue={null} disabled value={totalAmount ? totalAmount : ''} />
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>Advance</h5>
													<InputNumber value={advance ? advance : ''} onChange={(ev) => setAdvance(ev)} />
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>Pre Balance Or Advance</h5>
													<InputNumber disabled value={preBalanceOrAdvance ? preBalanceOrAdvance : ''} />
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>Balance</h5>
													<InputNumber disabled value={balance ? balance : ''} />
												</label>
											</div>
											<div className="inputFields w64p">
												<label className="w100P">
													<h5>Expenses</h5>
													<AddAndViewExpense
													type={"takeIn"}
														expenseModel={expenseModel}
														setExpenseModel={setExpenseModel}
														selectedCustomer={selectedCustomer}
														addedExpenses={addedExpenses}
														setAddedExpenses={setAddedExpenses}
													/>
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>Discount</h5>
													<Checkbox
														value={discountRequest}
														onChange={(ev) => setDiscountRequest(ev.target.checked)}
													>
														Discount
													</Checkbox>
												</label>
											</div>

											{discountRequest && (
												<>
													<div className="inputFields">
														<label>
															<h5>Discount Expected</h5>
															<InputNumber
																value={discountAmount ? discountAmount : ''}
																onChange={(ev) => setDiscountAmount(ev)}
															/>
														</label>
													</div>
													<div className="inputFields">
														<label>
															<h5>Reason For Discount</h5>
															<Input
																value={discountReason}
																onChange={(ev) => setDiscountReason(ev.target.value)}
															/>
														</label>
													</div>
												</>
											)}

											<div className="inputFields">
												<label>
													<h5 onClick={() => console.log(delDate)}>Delivery Date</h5>
													<Space direction="vertical">
														<DatePicker
															defaultValue={moment(delDate, dateFormatt)}
															format={dateFormatt}
															onChange={(ev) => { console.log(ev); setDeliveryDate(ev) }}
														/>
													</Space>
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>Box / Packet</h5>
													<Select
														className="select"
														defaultValue="Box"
														value={boxOrPacket}
														onChange={(ev) => {
															setBoxOrPacket(ev);
															if (ev === "Packet") {
																props.getPacketNumber(props.permission.branchCode);
															} else {
																setBoxOrPacketNo("");
															}
														}}
													>
														<Option value="Box">Box</Option>
														<Option value="Packet">Packet</Option>
													</Select>
												</label>
											</div>
											<div className="inputFields">
												<label>
													<h5>{boxOrPacket} Number</h5>
													<InputNumber
														disabled={boxOrPacket === "Packet"}
														value={boxOrPacketNo ? boxOrPacketNo : ''}
														onChange={(ev) => setBoxOrPacketNo(ev)}
													/>
												</label>
											</div>
											<div className="inputFields saveDelete saveButton w100P">
												{/* <NavLink to="/Take-In-Print"> */}
												<Button
													onClick={saveItem}
													className={draftID ? `w65p ${!(!selectedCustomer.customerName ||
														!boxOrPacketNo ||
														props.dataEntryLoading) ? 'highlight' : ''}` : `w100P ${!(!selectedCustomer.customerName ||
															!boxOrPacketNo ||
															props.dataEntryLoading) ? 'highlight' : ''}`}
													disabled={
														!selectedCustomer.customerName ||
														!boxOrPacketNo ||
														props.dataEntryLoading
													}
												>
													<SaveOutlined />
													{props.dataEntryLoading ? <div className="loading1"></div> : "Save"}
												</Button>
												{draftID ? (
													<Button onClick={draftDelete} className={`w134p DeleteDraft  ${!(!selectedCustomer.customerName ||
														!boxOrPacketNo ||
														props.dataEntryLoading) ? 'highlight' : ''}`}>
														<DeleteOutlined />
														Delete
													</Button>
												) : null}
												{/* </NavLink> */}
											</div>
											{/* <Button className="newForm" >
                        <PlusOutlined /> Open New Form
                      </Button> */}
										</>
									</div>
								</div>
							</Col>
							<Col xs={14} sm={14} md={14} lg={14} xl={14}>
								{items.length ? (
									<div className="itemDiv">
										<Items
											data={items}
											editDelete={true}
											editItem={editItem}
											deleteItem={deleteItem}
										/>
									</div>
								) : null}
							</Col>
						</Row>
					</div>
				) : null}
			</Row>
			<div className="draftTabs">
				<Row>
					{draftArr.length > 0
						? draftArr.map((a, i) => {
							return draftId != a.draftID && checkChanges() && popOver ? (
								<Popconfirm
									onConfirm={() => {
										draft(draftArr.length + 1, true);
										addDraftData(i + 1, a.draftID);
										setPopOver(true);
									}}
									onCancel={() => {
										draft(draftArr.length + 1, false);
										addDraftData(i + 1, a.draftID);
										setPopOver(true);
									}}
									title="You Have Unsaved Changes"
									okText="Save Changes"
									cancelText="Discard Changes"
								>
									<button className={a.draftID === activeDraft ? "dTab active" : "dTab"}>
										<FileDoneOutlined />
										<div className="tabNumber">{i + 1}</div>
									</button>
								</Popconfirm>
							) : (
								<button
									onClick={() => {
										addDraftData(i + 1, a.draftID);
										console.log(a.draftID, activeDraft, a.draftID === activeDraft);
									}}
									className={a.draftID === activeDraft ? "dTab active" : "dTab"}
								>
									<FileDoneOutlined />
									<div className="tabNumber">{i + 1}</div>
								</button>
							);
						})
						: null}
					{/* {cantDraft ?
            <Popconfirm
              onConfirm={() => draft(draftArr.length + 1, true)}
              onCancel={() => draft(draftArr.length + 1, false)}
              title="You Have Unsaved Changes"
              okText="Save Changes"
              cancelText="Discard Changes"
            >
              <button className="dTab"  >
                <FileAddOutlined />
              </button>
            </Popconfirm>
            : */}
					<button
						className="dTab"
						onClick={() => {
							draft(draftArr.length + 1, true);
						}}
						disabled={cantDraft}
					>
						<FileAddOutlined />
					</button>
					{/* } */}
				</Row>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => ({
	customers: store.customers.customers,
	filteredCustomerArr: store.customers.filteredCustomer,
	allCustomers: store.customers.allCustomers,
	customer: store.customers.customer,
	entryToPrint: store.dataEntry.entryToPrint,
	userPermissions: store.authentication.userPermissions,
	permission: store.users.permission,
	packetNumber: store.dataEntry.packetNumber,
	dataEntryLoading: store.dataEntry.dataEntryLoading,
	getCustomerLoading: store.customers.getCustomerLoading,
	user: store.authentication.user
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			filteredCustomer,
			activeOrNorActive,
			saveDataEntry,
			getCustomer,
			getPacketNumber,
			clearCustomer,
			getSingleCustomerPendingEntries
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(TakeIn);
