import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalReportNoti from "../containers/reports/ItemReport/Reports/ModalReportNoti";
import CustomerModalNoti from "../containers/TakeIn/CustomerModalNoti";
import {
	AcceptDiscount,
	acceptMemberShipDiscount,
	getNotification
} from "../modules/actions/AccountActions";
import { getCustomer } from "../modules/actions/customerActions";
import { deleteUser, updateUserObj } from "../modules/actions/userAction";
import logo from "./../Images/Logo 2.png";
import Pusher from "pusher-js";

function Notifications(props) {
	const [notiTab, setNotiTab] = useState("userNotification");
	const [notificationsUser, setNotificationsUser] = useState([]);
	const [notificationsDiscount, setNotificationsDiscount] = useState([]);
	const [notificationsMember, setNotificationsMember] = useState([]);
	const [notiLimit, setNotiLimit] = useState(10);
	const [data, setData] = useState({});

	const update = (decision) => {
		let obj = {
			entryNo: props.singleEntry.entryNo,
			entryDate: props.singleEntry.entryDate,
			totalAmount: props.singleEntry.totalAmount,
			deliveryDate: props.singleEntry.deliveryDate,
			advance: props.singleEntry.advance,
			boxNo: props.singleEntry.boxNo,
			branchCode: props.singleEntry.branchCode,
			selectBoxOrPacket: props.singleEntry.selectBoxOrPacket,
			checkBoxAskForDiscount: props.singleEntry.checkBoxAskForDiscount,
			customerComments: props.singleEntry.customerComments,
			userName: props.singleEntry.userName,
			discountApproved: decision,
			discountAmount: props.singleEntry.discountAmount,
			discountReason: props.singleEntry.discountReason,
			customerId: props.singleEntry.customerId,
			customerName: props.singleEntry.customerName,
			items: props.singleEntry.items,
			expenses: props.singleEntry.expenses
			// "customerId"
		};
		// console.log(props.permission.branchCode)
		props.AcceptDiscount(obj, props.permission.branchCode);
	};


	const updateButton = (decision, a) => {
		console.log(a)
		let obj = {
			entryNo: a.entryNo,
			entryDate: a.entryDate,
			totalAmount: a.totalAmount,
			deliveryDate: a.deliveryDate,
			advance: a.advance,
			boxNo: a.boxNo,
			branchCode: a.branchCode,
			selectBoxOrPacket: a.selectBoxOrPacket,
			checkBoxAskForDiscount: a.checkBoxAskForDiscount,
			customerComments: a.customerComments,
			userName: a.userName,
			discountApproved: decision,
			discountAmount: a.discountAmount,
			discountReason: a.discountReason,
			customerId: a.customerId,
			customerName: a.customerName,
			items: a.items.map((b, i) => {
				return {
					...b,
					entryNo: a.entryNo
				}
			}),
			expenses: a.expenses
			// "customerId"
		};
		// // console.log(props.permission.branchCode)
		props.AcceptDiscount(obj, props.permission.branchCode, props.handleCancel);
	};

	useEffect(() => {
		if (props.notificationArr) {
			setNotificationsUser(props.notificationArr["userNotification"]);
			setNotificationsDiscount(props.notificationArr["customerDiscount"]);
			setNotificationsMember(props.notificationArr["memberShipDiscount"]);
		}
	}, [props.notificationArr]);
	var pusher = new Pusher("16fd44227dfc1c2e0214", {
		cluster: "ap2"
	});
	useEffect(() => {
		if (props.notificationArr) {
			let arr = [...notificationsDiscount, ...props.notificationArr["customerDiscount"]];
			let arr2 = [...notificationsMember, ...props.notificationArr["memberShipDiscount"]];
			let arr3 = [...notificationsUser, ...props.notificationArr["userNotification"]];
			var channel = pusher.subscribe("my-channel");
			channel.bind("my-event", function (data) {
				if (data) {
					let key = Object.keys(data)[0];
					let obj = JSON.parse(data[key]);
					// console.log(obj)
					if (key === "dataEntryNotification") {
						arr.push(obj);
						setNotificationsDiscount(arr);
						console.log(arr);
					} else if (key === "membershipNotification") {
						arr2.push(obj);
						setNotificationsMember(arr2);
						// console.log(arr)
					} else if (key === "newUserNotification") {
						arr3.push(obj);
						setNotificationsUser(arr3);
					}
				}
			});
		}
	}, [props.notificationArr]);

	useEffect(() => {
		console.log(notificationsDiscount.length);
	}, [notificationsDiscount]);

	useEffect(() => {
		props.getNotification();
	}, []);

	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {
		console.log(props.customer);
	}, [props]);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const [editObj, setEditObj] = useState("");

	const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);

	useEffect(() => {
		console.log(data);
	}, [data]);

	const showMemberModal = (a) => {

		props.getCustomer(
			a.customerId,
			props.permission.branchCode,
			setIsMemberModalVisible,
			setEditObj
		);
		console.log(a);
		// setIsModalVisible(true);
	};

	const handleMemberOk = () => {
		setIsMemberModalVisible(false);
	};

	const handleMemberCancel = () => {
		setIsMemberModalVisible(false);
	};

	const acceptMemberShipDiscountFn = (decision, a) => {
		let obj = {
			...a,
			customerId:a.customerObj.customerId,
			discountApproved: decision
		};
		console.log(obj);
		props.acceptMemberShipDiscount(obj.id, obj, props.permission.branchCode);
	};

	const approveUser = (a) => {
		let obj = {
			email: a.email,
			first_name: a.first_name,
			last_name: a.last_name,
			username: a.username,
			password: a.password,
			is_superuser: a.is_superuser,
			id: a.id,
			is_staff: a.is_staff,
			is_active: true
		};
		console.log(obj);
		props.updateUserObj(obj, "", props.permission.branchCode);
	};

	const deleteUserFn = (a) => {
		// console.log(a)
		props.deleteUser(a);
	};

	return (
		<div
			className="notifications"
			style={props.NotiOn ? { display: "block" } : { display: "none" }}
		>
			<div className="innerNoti">
				<div className="notiheadDiv">
					<h4 className="notHead">NOTIFCATIONS</h4>
				</div>
				<div className="TabsNoti">
					<Badge className="tabBadge" count={notificationsUser.length}>
						<div
							className="tab"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setNotiTab("userNotification");
								setNotiLimit(10);
							}}
						>
							User
						</div>
					</Badge>
					<Badge className="tabBadge" count={notificationsDiscount.length}>
						<div
							className="tab"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setNotiTab("customerDiscount");
								setNotiLimit(10);
							}}
						>
							Discount
						</div>
					</Badge>
					{/* <Badge className="tabBadge" count={5}>

                        <div className='tab' style={{ cursor: 'pointer' }}>No decision</div>
                    </Badge> */}
					<Badge className="tabBadge" count={notificationsMember.length}>
						<div
							className="tab"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setNotiTab("memberShipDiscount");
								setNotiLimit(10);
							}}
						>
							Memebership Dicount
						</div>
					</Badge>
				</div>

				{props.notificationArr ? (
					// notifications.map((a, i) => {
					//     return i < notiLimit ? (
					<>
						{notiTab === "customerDiscount" ? (
							notificationsDiscount.length &&
							notificationsDiscount.map((a, i) => {
								return i < notiLimit ? (
									<>
										<div
											className="notiDiv"

										>
											<div className="NotiInfo">
												<div className="notiName">
													<h5>{a.customerName}</h5>
												</div>
												<div className="notiDate">
													<h6>{a.entryDate}</h6>
													<p>Discount</p>
												</div>
											</div>
											<div className="notiDetail">
												<div className="innerNotiDetail">
													<p>
														<span>Entry No: </span>
														{a.entryNo}
													</p>
													<p>
														<span>Entry by </span>
														{a.userName}
													</p>
													<p>
														<span>Total Amount </span>
														{a.totalAmount}
													</p>
													<p>
														<span>Discount Amount </span>
														{a.discountAmount}
													</p>
												</div>
											</div>
											<div className="notiButtons">
												<button style={{ width: "31%" }}
													className={props.accountLoading ? "loadButton" : ""}
													onClick={() => updateButton("Approved", a)
													}
													disabled={props.accountLoading}
												>
													{props.accountLoading ? (
														<div className="loading1 notiLoader"></div>
													) : (
														"Accept Discount"
													)}
												</button>
												<button style={{ width: "31%" }} onClick={() => updateButton("Decline", a)} disabled={props.accountLoading}> 	{props.accountLoading ? (
													<div className="loading1 notiLoader"></div>
												) : (
													"Decline user"
												)}</button>
												<button style={{ width: "31%" }} onClick={() => {
													setData(a);
													showModal();
												}}>
													View
												</button>
											</div>
										</div>
									</>
								) : null;
							})
						) : notiTab === "memberShipDiscount" ? (
							notificationsMember.length ? (
								notificationsMember.map((a, i) => {
									return i < notiLimit ? (
										<>
											<div className="notiDiv">
												<div className="NotiInfo">
													<div className="notiName">
														<h5>
															{a.customerObj && a.customerObj.customerName} (
															{a.customerObj && a.customerObj.customerId})
														</h5>
													</div>
													<div className="notiDate">
														<h6>{a.entryDate}</h6>
														<p>Membership Discount</p>
													</div>
												</div>
												<div className="notiDetail">
													<div className="innerNotiDetail">
														<p>
															<span>Membership No: </span>
															{a.membershipNo}
														</p>

														<p>
															<span>Membership Amount </span>
															{a.membershipRate}
														</p>
														<p>
															<span>Recieved Payment</span>
															{a.membershipPayment}
														</p>
														<p>
															<span>Discount Amount </span>
															{a.discountAmount}
														</p>
														<p>
															<span>Discount Reason </span>
															{a.discountReason}
														</p>
														<p>
															<span>From - To </span>
															{a.startDate} - {a.endDate}
														</p>
													</div>
													<div className="notiButtons">
														<button
															className={props.accountLoading ? "loadButton" : ""}
															onClick={() => acceptMemberShipDiscountFn("Approved", a)}
															disabled={props.accountLoading}
														>
															{props.accountLoading ? (
																<div className="loading1 notiLoader"></div>
															) : (
																"Approved"
															)}
														</button>
														<button onClick={() => acceptMemberShipDiscountFn("Decline", a)}>
															Decline
														</button>
													</div>
												</div>
											</div>
										</>
									) : null;
								})
							) : (
								<span className="NoDataText">NO DATA</span>
							)
						) : notiTab === "userNotification" ? (
							notificationsUser.length ? (
								notificationsUser.map((a, i) => {
									return i < notiLimit ? (
										<>
											<div className="notiDiv">
												<div className="NotiInfo">
													<div className="notiName">
														<h5>{a.first_name}</h5>
													</div>
													<div className="notiDate">
														{/* <h6>{a.entryDate}</h6> */}
														<p>User Approval</p>
													</div>
												</div>
												<div className="notiDetail">
													<div className="innerNotiDetail">
														<p>
															<span>Email: </span>
															{a.email}
														</p>
														<p>
															<span>Joined Date </span>
															{a.date_joined}
														</p>
													</div>
													<div className="notiButtons">
														<button
															className={props.accountLoading ? "loadButton" : ""}
															onClick={() => approveUser(a)}
															disabled={props.accountLoading}
														>
															{props.accountLoading ? (
																<div className="loading1 notiLoader"></div>
															) : (
																"Accept User"
															)}
														</button>
														<button onClick={() => deleteUserFn(a)}>Decline user</button>
													</div>
												</div>
											</div>
										</>
									) : null;
								})
							) : (
								<span className="NoDataText">NO DATA</span>
							)
						) : null}
						<hr />
					</>
				) : (
					//     ) : null
					// })

					<div className="loaderDiv notificationloader">
						<div>
							<img src={logo} />
						</div>
					</div>
				)}
			</div>
			<div className="loaderMore" onClick={() => setNotiLimit(notiLimit + 10)}>
				Load More
			</div>
			<ModalReportNoti
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				showModal={showModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				data={data}
				entries={data.items}
			/>

			{/* <CustomerModalNoti
                title="Accept Discouunt"
                className="AddModal"
                style={{ top: 20, maxHeight: "90vh", }}
                visible={isMemberModalVisible}
                onOk={() => { handleMemberOk(false) }}
                onCancel={() => {
                    handleMemberCancel(false)
                    setEditObj("")
                }}
                editObj={editObj}
            /> */}
		</div>
	);
}

const mapStateToProps = (store) => ({
	notificationArr: store.accountStore.notificationArr,
	accountLoading: store.accountStore.accountLoading,
	permission: store.users.permission,
	customer: store.customers.customer
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			AcceptDiscount,
			getNotification,
			getCustomer,
			acceptMemberShipDiscount,
			updateUserObj,
			deleteUser
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
