import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Select } from "antd";
import { Table, Tag, Popconfirm } from "antd";
import Items from "./entries";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AddMembershipModel from "./addMembershipModel";
import { setCustomer, updateCustomer, setMembership } from "../../modules/actions/customerActions";
import { deleteSingleMembership } from "../../modules/actions/customerReportActions";

const { Option } = Select;
const { Panel } = Collapse;
const { Column, ColumnGroup } = Table;

function CustomerModalNoti(props) {
	const [memModel, setMemModel] = useState(false); 
	const [memArr, setMemArr] = useState([]);

	const [customerId, setCustomerId] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [customerName, setCustomerName] = useState("");
	const [membershipDetail, setMembershipDetail] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [mobileNo2, setMobileNo2] = useState("");
	const [nicNo, setNicNo] = useState("");
	const [ntnNo, setNtnNo] = useState("");

	const [editObj, setEditObj] = useState("");

	useEffect(() => {
		if (props.customers) {
			setCustomerId(
				"Cu-" +
					(Number(this.props.customers[this.props.customers.length - 1].customerId.slice(7)) + 1)
			);
		} else {
			setCustomerId("Cu-1");
		}
	}, []);

	const clearState = () => {
		setMemModel(false);
		setMemArr([]);
		setCustomerId("");
		setAddress("");
		setCity("");
		setCompanyName("");
		setCustomerName("");
		setMembershipDetail("");
		setMobileNo("");
		setMobileNo2("");
		setNicNo("");
		setNtnNo("");
	};

	const addCustomer = () => {
		let obj = {
			customerName: customerName,
			companyName: companyName,
			mobileNo: Number(mobileNo),
			mobileNo2: Number(mobileNo2),
			NIC_NO: Number(nicNo),
			address: address,
			city: city,
			branchId: Number(1),
			membershipDetail: memArr
		};

		props.setCustomer(obj, props.permission.branchCode);
		props.onCancel();
	};

	const updateCutomerr = () => {
		let obj = {
			customerName: customerName,
			companyName: companyName,
			mobileNo: Number(mobileNo),
			mobileNo2: Number(mobileNo2),
			NIC_NO: Number(nicNo),
			address: address,
			city: city,
			branchId: Number(1),
			membershipDetail: memArr,
			customerId: customerId
		};

		props.updateCustomer(obj, props.page, props.permission.branchCode, props.pageSize);
		props.onCancel();
	};

	const deleteMember = (i) => {
		let arr = [...memArr];
		arr.splice(i, 1);
		setMemArr(arr);
	};

	const confirmDeleteMember = (e, i, id) => {
		if (!props.fromReports) {
			deleteMember(i);
		} else {
			props.deleteSingleMembership(id, deleteMember, i, props.page, props.permission.branchCode);
		}
	};

	useEffect(() => {
		if (props.editObj) {
			setMemArr(props.editObj.membershipDetail);
			setCustomerId(props.editObj.customerId);
			setAddress(props.editObj.address);
			setCity(props.editObj.city);
			setCompanyName(props.editObj.companyName);
			setCustomerName(props.editObj.customerName);
			setMobileNo(props.editObj.mobileNo);
			setMobileNo2(props.editObj.mobileNo2);
			setNicNo(props.editObj.NIC_NO);
		}
	}, [props.editObj]);

	return (
		<Modal
			title={props.title}
			className="AddModal"
			style={props.style}
			visible={props.visible}
			onOk={() => {
				if (!props.editObj) {
					addCustomer();
				} else {
					updateCutomerr();
				}
			}}
			onCancel={() => {
				props.onCancel();
				clearState();
			}}
			okText={props.okText}
			width="1000px"
			okButtonProps={{ style: { background: "#001529", color: "white", borderColor: "#001529" } }}
			cancelButtonProps={{
				style: { background: "#f50057", color: "white", borderColor: "#f50057" }
			}}
		>
			<div className="addCustomerModelMain">
				<div className="addCustomerInputMain">
					{/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Input value={customerId} size="large" disabled placeholder="Customer Id" />
                    </Col> */}

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Input
							style={{ textTransform: "capitalize" }}
							autoFocus={true}
							onChange={(ev) => setCustomerName(ev.target.value)}
							value={customerName}
							size="large"
							placeholder="Customer Name"
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Input
							style={{ textTransform: "capitalize" }}
							onChange={(ev) => setCompanyName(ev.target.value)}
							value={companyName}
							size="large"
							placeholder="Company Name"
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<div style={{ width: "100%" }}>
							<Input value="+92" disabled style={{ width: `60px` }} size="large" />
							<Input
								style={{ width: `calc(100% - 60px)` }}
								onChange={(ev) => {
									if (ev.target.value.toString().length <= 10) {
										setMobileNo(ev.target.value);
									}
								}}
								value={mobileNo}
								size="large"
								type="number"
								placeholder="Mobile No"
							/>
						</div>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<div style={{ width: "100%" }}>
							<Input value="+92" disabled style={{ width: `60px` }} size="large" />
							<Input
								style={{ width: `calc(100% - 60px)` }}
								onChange={(ev) => {
									if (ev.target.value.toString().length <= 10) {
										setMobileNo2(ev.target.value);
									}
								}}
								value={mobileNo2}
								size="large"
								type="number"
								placeholder="Mobile No"
							/>
						</div>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Input
							onChange={(ev) => {
								if (ev.target.value.toString().length <= 13) {
									setNicNo(ev.target.value);
								}
							}}
							value={nicNo}
							size="large"
							type="number"
							placeholder="NIC No."
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Input
							onChange={(ev) => setNtnNo(ev.target.value)}
							value={ntnNo}
							size="large"
							type="number"
							placeholder="NTN No."
						/>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<TextArea
							onChange={(ev) => setAddress(ev.target.value)}
							value={address}
							placeholder="Address"
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Input
							onChange={(ev) => setCity(ev.target.value)}
							value={city}
							size="large"
							type="text"
							placeholder="City"
							style={{ textTransform: "capitalize" }}
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11}>
						<Button
							disabled={!customerName || !mobileNo}
							className="addMemBut"
							onClick={() => setMemModel(true)}
						>
							Add Membershipppp
						</Button>
					</Col>
				</div>

				<div className="memTableDiv">
					<table>
						<tbody>
							<tr>
								{/* <th>Membership No</th> */}
								<th>Start Date</th>
								<th>End Date</th>
								<th>Membership</th>
								<th>Actions</th>
							</tr>

							{memArr.map((a, i) => {
								return (
									<tr key={i}>
										{/* <td>Cu-1-00{i}</td> */}
										<td>{new Date(a.startDate).toLocaleDateString()}</td>
										<td>{new Date(a.endDate).toLocaleDateString()}</td>
										<td>
											{/* {new Date(a.endDate).getTime() > (new Date(a.startDate).getFullYear() === new Date().getFullYear()) ? */}
											{new Date(a.endDate).getTime() >= new Date().getTime() &&
											new Date(a.startDate).getTime() <= new Date().getTime() ? (
												<Tag color="success">Active</Tag>
											) : (
												<Tag color="error">Expire</Tag>
											)}
										</td>
										<td>
											<i
												onClick={() => {
													setEditObj({ ...a, index: i });
													setMemModel(true);
												}}
												className="fas fa-pen"
											></i>

											<Popconfirm
												onConfirm={(e) => confirmDeleteMember(e, i, a.id)}
												title="Are you sure you want to delete this membership？"
												okText="Yes"
												cancelText="No"
											>
												<i className="fas fa-trash"></i>
											</Popconfirm>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<AddMembershipModel
				title={"Add Membership"}
				className="AddModal"
				style={props.style}
				visible={memModel}
				onOk={() => {
					setMemModel(false);
					setEditObj("");
				}}
				onCancel={(clearState2) => {
					setMemModel(false);
					setEditObj("");
					clearState2();
				}}
				okText={editObj ? "Update Membership" : "Add Membership"}
				setMemArr={setMemArr}
				memArr={memArr}
				editObj={editObj}
				fromReports={props.fromReports}
				page={props.page}
			/>
		</Modal>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCustomer,
			updateCustomer,
			deleteSingleMembership,
		
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModalNoti);
