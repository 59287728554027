import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table } from "antd";
import { Components } from "antd/lib/date-picker/generatePicker";
import { NavLink } from "react-router-dom";
import { Input, Select, Col, Row, Button, Space } from "antd";

const { Column, ColumnGroup } = Table;

// const columns = [
//     {
//         title: 'Image',
//         dataIndex: 'name',
//     },
//     {
//         title: 'Age',
//         dataIndex: 'age',
//     },
//     {
//         title: 'Address',
//         dataIndex: 'address',
//     },
// ];

const data = [];
for (let i = 0; i < 5; i++) {
	data.push({
		shape: "Round",
		caratWeight: `1.00`,
		color: `D`,
		clarity: `F`,
		cut: "Excellent",
		polish: "Very Good",
		symmetry: "Excellent",
		certification: `A`,
		rapPrice: `34.00`,
		discount: `2.00`,
		price: `35.00`,
		status: `Available`,
		city: `ISL`,
		member: `38180`,
		key: i
	});
}

function Diamond() {
	const [selectedRowKeys, setSelectedRowKeys] = useState("");

	function onSelectChange(selectedRowKeys) {
		setSelectedRowKeys(selectedRowKeys);
	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
			{
				key: "odd",
				text: "Select Odd Row",
				onSelect: (changableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changableRowKeys.filter((key, index) => {
						if (index % 2 !== 0) {
							return false;
						}
						return true;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				}
			},
			{
				key: "even",
				text: "Select Even Row",
				onSelect: (changableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changableRowKeys.filter((key, index) => {
						if (index % 2 !== 0) {
							return true;
						}
						return false;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				}
			}
		]
	};

	return (
		<div className="diamondGrading smallJewellery">
			<div className="innerDiamondGrading">
				<div>
					<Table className="itemsTable" rowSelection={rowSelection} dataSource={data}>
						<Table dataSource={data}>
							<Column title="Shape" dataIndex="shape" />
							<Column title="Carat Weight" dataIndex="caratWeight" />
							<Column title="Color" dataIndex="color" />
							<Column title="Clarity" dataIndex="clarity" />
							<Column title="Cut" dataIndex="cut" />
							<Column title="Polish" dataIndex="polish" />
							<Column title="Symmetry" dataIndex="symmetry" />
							<Column title="Certification" dataIndex="certification" />
							<Column title="Rap Price $/Ct" dataIndex="rapPrice" />
							<Column title="Discount %" dataIndex="discount" />
							<Column title="Price" dataIndex="price" />
							<Column title="Status" dataIndex="status" />
							<Column title="City" dataIndex="city" />
							<Column title="Member" dataIndex="member" />
						</Table>
					</Table>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ({ store }) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Diamond);
