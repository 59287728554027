import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Table, Tag, Space, DatePicker, Col, Row, Select } from "antd";
import { connect } from "react-redux";
import SummaryDaily from "./SummaryDaily";
import { bindActionCreators } from "redux";
import {
  getDaySummaryReport,
  SeenFn,
  unSeenFn,
} from "../../../modules/actions/summaryReportsAction";
import { userLoginSuccess } from "../../../modules/actions/authenticationActions";
import { getBranch } from "../../../modules/actions/branchActions";
import logo from "./../../../Images/Logo 2.png";
const { Column, ColumnGroup } = Table;

export const SummaryDetailDaily = (props) => {
  let {
    laboratoryModal,
    setLaboratoryModal,
    showModalLab,
    dataYear,
    record,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMonthName, setCurrentMonthName] = useState(false);
  const [months, setMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [branchCode, setBranchCode] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [daysDataArr, setDaysDataArr] = useState([]);
  useEffect(() => {
    // if (record) {
    console.log("props", props);
    // setMonthlyData(record);
    setDaysDataArr(props.dayData);
    // }
  }, [props.dayData]);

  const [currentMonth, setCurrentMonth] = useState();
  const selectMonth = (data) => {
    props.getDaySummaryReport(
      "days",
      Number(data.year),
      Number(data.month),
      data.selectedBranch
    );
  };
  useEffect(() => {
    setBranchCode(props.permission.branchCode);
    if (props.permission.userType !== "Admin") {
      window.open("/");
    } else {
      showModal();
      selectMonth({
        month: window.location.pathname.split("/")[3],
        year: window.location.pathname.split("/")[4],
        selectedBranch: window.location.pathname.split("/")[5],
      });
    }
  }, [props.permission]);
  return props.reportsDayLoading ? (
    <div className="loaderDiv dayLoader">
      <div>
        <img src={logo} />
      </div>
    </div>
  ) : props.permission.userType !== "Admin" ? (
    <h1 style={{textAlign:"center",color:"red"}}> you are not allowed to see this data</h1>
  ) : (
    <SummaryDaily
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      showModal={showModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      monthlyData={daysDataArr}
      data={currentMonth}
      dataYear={window.location.pathname.split("/")[4]}
      selectedBranch={props.selectedBranch}
      selectMonth={selectMonth}
      SeenFn={props.SeenFn}
      unSeenFn={props.unSeenFn}
      setDaysDataArr={setDaysDataArr}
      currentMonthName={
        months[Number(window.location.pathname.split("/")[3]) - 1]
      }
    />
  );
};

const mapStateToProps = (store) => ({
  permission: store.users.permission,
  dayData: store.summaryReports.dayData,
  reportsDayLoading: store.reports.reportsDayLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getMonthSummaryReport
      getDaySummaryReport,
      SeenFn,
      unSeenFn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDetailDaily);
