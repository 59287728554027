import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./../customerReport.scss";
import { Input, Space, Pagination, Popconfirm } from "antd";
import { AudioOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Select } from "antd";
import { Table, Tag } from "antd";
import { deleteCustomer, getAllCustomers } from "../../../modules/actions/customerReportActions";
import AddCustomerModal from "../../TakeIn/addCustomerModal";
import {
	activeOrNorActive,
	checkActiveOrNorActive
} from "../../../modules/actions/customerActions";
import logo from "./../../../Images/Logo 2.png";
import { getBranch } from "../../../modules/actions/branchActions";

const { Column, ColumnGroup } = Table;

const { Search } = Input;

const suffix = (
	<AudioOutlined
		style={{
			fontSize: 16,
			color: "#1890ff"
		}}
	/>
);
const onSearch = (value) => { };
const { Option } = Select;

function CustomerReport(props) {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [addCustomerModel, setAddCustomerModel] = useState(false);
	const [editObj, setEditObj] = useState("");

	const [searchText, setSearchText] = useState("");
	const [activeness, setActiveness] = useState("");
	const [searchBool, setSearchBool] = useState(false);
	const [memberShipEditBtn, setmemberShipEditBtn] = useState(false);

	const [ViewCustomer, setViewCustomer] = useState(false);
	const [BranchCode, setBranchCode] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState("");

	useEffect(() => {
		// console.log(props.permission.branchCode, "props.branch");
		if (props.userPermissions.branchCode) {
			setSelectedBranch(props.userPermissions.branchCode);
		} else {
			setSelectedBranch(props.userPermissions.branchCode);
		}
	}, [props.permission.branchCode])

	useEffect(() => {
		props.getBranch(props.permission.branchCode);
	}, [props.permission.branchCode]);

	useEffect(() => {
		if(selectedBranch){
			props.getAllCustomers(page, selectedBranch, searchText, activeness, pageSize);
		}
	}, [page, searchBool, activeness, pageSize, selectedBranch]);

	const confirmDeleteCustomer = (obj) => {
		props.deleteCustomer(obj.customerId, page, props.permission.branchCode,page, selectedBranch, searchText, activeness, pageSize);
	};

	return (
		<div className="CustomerReport">
			<div>
				<Row className="spaceBetween">
					<Col xs={12} sm={12} md={12} lg={12} xl={12}>
						<Space direction="vertical" className="w100P">
							<Search
								placeholder="input search text"
								className="w100P"
								onSearch={() => setSearchBool(searchBool ? false : true)}
								style={{ width: 2 }}
								onChange={(e) => setSearchText(e.target.value)}
								value={searchText}
							/>
						</Space>
					</Col>
					<Col xs={6} sm={6} md={6} lg={6} xl={6}>
						<Select
							className="select w100P"
							defaultValue="All"
							onChange={(e) => setActiveness(e)}
							value={activeness}
						>
							<Option value="">All</Option>
							<Option value="NotAcquired">Not Acquired</Option>
							<Option value="expired">Member Expired</Option>
							<Option value="active">Member Active</Option>
						</Select>
					</Col>
					{props.userPermissions.admin_panel && (
						<Col xs={6} sm={6} md={6} lg={6} xl={6}>
							<Select
								// defaultValue={selectedBranch}
								onChange={(e) => setSelectedBranch(e)}
								className="w100P"
								placeholder="Select Branch"
							>
								<Option value={0} disabled>Select Branch</Option>
								{props.branch
									? props.branch.map((a, i) => {
										return <Option value={a.branchCode}>{a.branchName}</Option>;
									})
									: null}
							</Select>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<div className="tableDiv">
							{props.customerReportsLoading ? (
								<div className="loaderDiv tableLoader">
									<div>
										<img src={logo} />
									</div>
								</div>
							) : null}
							<Table pagination={false} className="itemsTable" dataSource={props.customers}>
								<Column title="Customer ID" dataIndex="customerId" key="customerId" />
								<Column title="Customer Name" dataIndex="customerName" key="customerName" />
								<Column title="Company Name" dataIndex="companyName" key="companyName" />
								<Column title="Mobile No" dataIndex="mobileNo" key="mobileNo" />
								<Column title="Mobile No 2" dataIndex="mobileNo2" key="mobileNo2" />
								<Column title="Address" dataIndex="address" key="address" />
								<Column
									title="Membership"
									dataIndex="memberShip"
									key="memberShip"
									render={(text, record) =>
										record.membershipDetail.length
											? checkActiveOrNorActive(
												record.membershipDetail[record.membershipDetail.length - 1].startDate,
												record.membershipDetail[record.membershipDetail.length - 1].endDate
											)
											: "Not acquired yet"
									}
								/>
								<Column title="MemberShip No" render={(text, record) => {
									return (
										<>
											{
												record.membershipDetail.length ?
													<>
														{record.membershipDetail[record.membershipDetail.length - 1].membershipNo}
													</>
													: "Not acquired yet"}
										</>
									)
								}} />
								<Column
									title="Action"
									key="action"
									render={(text, record, index) => (
										<Space size="middle">
											<a
												onClick={() => {
													setEditObj(record);
													setAddCustomerModel(true);
													setViewCustomer(true);
												}}
											>
												View
											</a>
											{/* {props.userPermissions.customer_edit && ( */}
												<a
													onClick={() => {
														setEditObj(record);
														setAddCustomerModel(true);
														setViewCustomer(false);
														setmemberShipEditBtn(true);
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="#001529"
														className="bi bi-pencil-fill"
														viewBox="0 0 16 16"
													>
														<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
													</svg>
												</a>
											{/* )} */}

											{props.userPermissions.customer_delete && (
												<Popconfirm
													onConfirm={() => confirmDeleteCustomer(record)}
													title="Are you sure you want to delete this customer？"
													okText="Yes"
													cancelText="No"
												>
													<a>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="rgb(245, 0, 87)"
															className="bi bi-trash-fill"
															viewBox="0 0 16 16"
														>
															<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
														</svg>
													</a>
												</Popconfirm>
											)}
										</Space>
									)}
								/>
							</Table>
						</div>
					</Col>
				</Row>
				<Pagination
					onChange={(ev, size) => {
						setPage(ev);
						setPageSize(size);
					}}
					pageSize={pageSize}
					current={props.page}
					total={props.total}
				/>
			</div>
			<AddCustomerModal
				title="Add New Customer"
				className="AddModal"
				style={{ top: 20, maxHeight: "90vh" }}
				visible={addCustomerModel}
				memberShipEditBtn={memberShipEditBtn}
				onOk={() => {
					setAddCustomerModel(false);
				}}
				onCancel={() => {
					setAddCustomerModel(false);
					setEditObj("");
				}}
				editObj={editObj}
				okText="Update"
				page={page}
				fromReports={true}
				pageSize={pageSize}
				ViewCustomerModel={ViewCustomer}
			/>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		customers: store.customerReports.customers,
		customerReportsLoading: store.customerReports.customerReportsLoading,
		page: store.customerReports.page,
		total: store.customerReports.total,
		permission: store.users.permission,
		branch: store.branchStore.branch,

	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAllCustomers,
			deleteCustomer,
			getBranch

		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReport);
