import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined,
    PrinterFilled, RightOutlined
} from '@ant-design/icons';
import './addPrice.scss'
import { addBelow500Price, addCVDTesting, addDiamondCardPrice, addDiamondCertificatePrice, addExtraService, addJewelleryCeritificatePrice, addJewelleryCertificateLot, addLoseStoneServicePrice, addLotApprasialCertificate, addSelectStoneLot, addSmallGemstonesLot, addStrandServicePrice, putBelow500Price, putCVDTesting, putDiamondCardPrice, putDiamondCertificatePrice, putExtraService, putJewelleryCeritificatePrice, putJewelleryCertificateLot, putLoseStoneServicePrice, putLotApprasialCertificate, putSelectStoneLot, putSmallGemstonesLot, putStrandServicePrice } from '../../../modules/actions/AddPricesAction';
import { bindActionCreators } from 'redux';

const AddPrices = (props) => {
    const [tabs, setTabs] = useState("LOSE STONE SERVICE")
    const [LoseStoneService, setLoseStoneService] = useState([
        { name: "Apprasial Card", price: 0, member_price: 0 },
        { name: "Apprasial Certificate", price: 0, member_price: 0 },
        { name: "Consultation", price: 0, member_price: 0 },
        { name: "Gemstone Card", price: 0, member_price: 0 },
        { name: "Gemstone Certificate", price: 0, member_price: 0 },
        { name: "Diamond Card", price: 0, member_price: 0 },
        { name: "Diamond Certificate", price: 0, member_price: 0 },
        { name: "Master Reports", price: 0, member_price: 0 },
        { name: "Verbal", price: 0, member_price: 0 },
        { name: "Master Pearl Report", price: 0, member_price: 0 },
        { name: "Pearl Testing", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [DiamondCard, setDiamondCard] = useState([
        { name: "0.01 - 0.49 ct", price: 0, member_price: 0 },
        { name: "0.50 - 0.99 ct", price: 0, member_price: 0 },
        { name: "1.00 - 1.49 ct", price: 0, member_price: 0 },
        { name: "1.50 - 1.99 ct", price: 0, member_price: 0 },
        { name: "2.00 - 2.99 ct", price: 0, member_price: 0 },
        { name: "3.00 - 3.99 ct", price: 0, member_price: 0 },
        { name: "4.00 - up", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [DiamondCertificate, setDiamondCertificate] = useState([
        { name: "0.01 - 0.49 ct", price: 0, member_price: 0 },
        { name: "0.50 - 0.99 ct", price: 0, member_price: 0 },
        { name: "1.00 - 1.49 ct", price: 0, member_price: 0 },
        { name: "1.50 - 1.99 ct", price: 0, member_price: 0 },
        { name: "2.00 - 2.99 ct", price: 0, member_price: 0 },
        { name: "3.00 - 3.99 ct", price: 0, member_price: 0 },
        { name: "4.00 - up", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [StrandService, setStrandService] = useState([
        { name: "Apprasial Card", price: 0, member_price: 0 },
        { name: "Apprasial Certificate", price: 0, member_price: 0 },
        { name: "Gemstone Strand Card", price: 0, member_price: 0 },
        { name: "Gemstone Strand Certificate", price: 0, member_price: 0 },
        { name: "Pearl Strand Card", price: 0, member_price: 0 },
        { name: "Pearl Strand Certificate", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [JewelleryService, setJewelleryService] = useState([
        { name: "Apprasial Card", price: 0, member_price: 0 },
        { name: "Apprasial Certificate", price: 0, member_price: 0 },
        { name: "Consultation", price: 0, member_price: 0 },
        { name: "Jewellery Card", price: 0 },
        { name: "Jewellery Certificate", price: 0 },
        { name: "Gemstone Jewellery Card", price: 0 },
        { name: "Gemstone Jewellery Certificate", price: 0 },
        { name: "Gold Test", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [CVDTesting, setCVDTesting] = useState([
        { name: "Between 0.01 to 1", price: 0, member_price: 0 },
        { name: "Between 1.01 to 5", price: 0, member_price: 0 },
        { name: "Extra More than 5", price: 0, member_price: 0 },
        { name: "No Decision", price: 0, member_price: 0 }
    ])
    const [SelectStone, setSelectStone] = useState([
        { name: "Diamond", p1: 0, p2: 0, member_price: 0, member_price_p2: 0 },
        { name: "Gemstone", p1: 0, p2: 0, member_price: 0, member_price_p2: 0 },
        { name: "Small Gemstone", p1: 0, member_price: 0 }
    ])
    const [SelectStoneForCertificates, setSelectStoneForCertificates] = useState([
        { name: "Diamond", p1: 0, p2: 0, member_price: 0, member_price_p2: 0 },
        { name: "Gemstone", p1: 0, p2: 0, member_price: 0, member_price_p2: 0 },
        { name: "Small Gemstone", p1: 0, member_price: 0 }
    ])
    const [SmallGemStonesLot, setSmallGemStonesLot] = useState([
        { name: "Card", p1: 0, member_price: 0 },
        { name: "Ceritificate", p1: 0, member_price: 0 }
    ])
    const [LotApprasialCertificate, setLotApprasialCertificate] = useState([
        { name: "Apprasial Certificate", price: 0 }
    ])
    const looseStonePrice = () => {
        console.log(LoseStoneService)
        if (props.LoseStoneService && props.LoseStoneService.length) {
            props.putLoseStoneServicePrice(LoseStoneService)
        } else {
            props.addLoseStoneServicePrice(LoseStoneService)
        }
    }


    const [extraService, setExtraService] = useState([
        { name: "Seal", price: 0 },
        { name: "Mine", price: 0 },
        { name: "Urgent", price: 0 },
        { name: "Gold Test", price: 0 }
    ])

    useEffect(() => {
        console.log(props.LoseStoneService, 'LoseStoneServiceLoseStoneService')
        if (props.LoseStoneService && props.LoseStoneService.length) {
            setLoseStoneService(props.LoseStoneService)
        }
    }, [props.LoseStoneService])

    const diamondCardPrice = () => {
        console.log(DiamondCard)
        if (props.DiamondCard && props.DiamondCard.length) {
            props.putDiamondCardPrice(DiamondCard)
        } else {
            props.addDiamondCardPrice(DiamondCard)
        }
    }

    useEffect(() => {
        console.log(props.DiamondCard, 'DiamondCardDiamondCard')
        if (props.DiamondCard && props.DiamondCard.length) {
            setDiamondCard(props.DiamondCard)
        }
    }, [props.DiamondCard])

    const diamondCertificatePrice = () => {
        console.log(props.DiamondCertificate)
        if (props.DiamondCertificate && props.DiamondCertificate.length) {
            props.putDiamondCertificatePrice(DiamondCertificate)
        } else {
            props.addDiamondCertificatePrice(DiamondCertificate)
        }
    }

    useEffect(() => {
        console.log(props.DiamondCertificate, 'DiamondCertificateDiamondCertificate')
        if (props.DiamondCertificate && props.DiamondCertificate.length) {
            setDiamondCertificate(props.DiamondCertificate)
        }
    }, [props.DiamondCertificate])

    const strandServicePrice = () => {
        console.log(props.StrandService)
        if (props.StrandService && props.StrandService.length) {
            props.putStrandServicePrice(StrandService)
        } else {
            props.addStrandServicePrice(StrandService)
        }
    }

    useEffect(() => {
        console.log(props.StrandService, 'StrandServiceStrandService')
        if (props.StrandService && props.StrandService.length) {
            setStrandService(props.StrandService)
        }
    }, [props.StrandService])

    const extraServicePrice = () => {
        console.log(props.extraService)
        if (props.extraService && props.extraService.length) {
            props.putExtraService(extraService)
        } else {
            props.addExtraService(extraService)
        }
    }
    useEffect(() => {
        console.log(props.extraService, 'extraServiceextraService')
        if (props.extraService && props.extraService.length) {
            setExtraService(props.extraService)
        }
    }, [props.extraService])

    const SelectStoneForCertificatesPrice = () => {
        console.log(props.SelectStoneForCertificates)
        if (props.SelectStoneForCertificates && props.SelectStoneForCertificates.length) {
            props.putJewelleryCertificateLot(SelectStoneForCertificates)
        } else {
            props.addJewelleryCertificateLot(SelectStoneForCertificates)
        }
    }
    useEffect(() => {
        console.log(props.SelectStoneForCertificates, 'SelectStoneForCertificatesSelectStoneForCertificates')
        if (props.SelectStoneForCertificates && props.SelectStoneForCertificates.length) {
            setSelectStoneForCertificates(props.SelectStoneForCertificates)
        }
    }, [props.SelectStoneForCertificates])
    const addJeweleryPrice = () => {
        console.log(props.JewelleryService)
        if (props.JewelleryService && props.JewelleryService.length) {
            props.putJewelleryCeritificatePrice(JewelleryService)
        } else {
            props.addJewelleryCeritificatePrice(JewelleryService)
        }

    }
    useEffect(() => {
        console.log(props.JewelleryService, 'JewelleryServiceJewelleryService')
        if (props.JewelleryService && props.JewelleryService.length) {
            setJewelleryService(props.JewelleryService)
        }
    }, [props.JewelleryService])

    const selectStonePrice = () => {
        console.log(props.SelectStone)
        if (props.SelectStone && props.SelectStone.length) {
            props.putSelectStoneLot(SelectStone)
        } else {
            props.addSelectStoneLot(SelectStone)
        }

    }
    useEffect(() => {
        console.log(props.SelectStone, 'SelectStoneSelectStone')
        if (props.SelectStone && props.SelectStone.length) {
            setSelectStone(props.SelectStone)
        }
    }, [props.SelectStone])

    const SmallGemStonesLotPrice = () => {
        console.log(props.SmallGemStonesLot)
        if (props.SmallGemStonesLot && props.SmallGemStonesLot.length) {
            props.putSmallGemstonesLot(SmallGemStonesLot)
        } else {
            props.addSmallGemstonesLot(SmallGemStonesLot)
        }

    }
    useEffect(() => {
        console.log(props.SmallGemStonesLot, 'SmallGemStonesLotSmallGemStonesLot')
        if (props.SmallGemStonesLot && props.SmallGemStonesLot.length) {
            setSmallGemStonesLot(props.SmallGemStonesLot)
        }
    }, [props.SmallGemStonesLot])

    const addCVDTestingPrice = () => {
        console.log(props.CVDTesting)
        if (props.CVDTesting && props.CVDTesting.length) {
            props.putCVDTesting(CVDTesting)
        } else {
            props.addCVDTesting(CVDTesting)
        }

    }
    useEffect(() => {
        console.log(props.CVDTesting, 'CVDTestingCVDTesting')
        if (props.CVDTesting && props.CVDTesting.length) {
            setCVDTesting(props.CVDTesting)
        }
    }, [props.CVDTesting])

    const appraisalLotPrice = () => {
        console.log(props.LotApprasialCertificate)
        if (props.LotApprasialCertificate && props.LotApprasialCertificate.length) {
            props.putLotApprasialCertificate(LotApprasialCertificate)
        } else {
            props.addLotApprasialCertificate(LotApprasialCertificate)
        }

    }
    useEffect(() => {
        console.log(props.LotApprasialCertificate, 'LotApprasialCertificateLotApprasialCertificate')
        if (props.LotApprasialCertificate && props.LotApprasialCertificate.length) {
            setLotApprasialCertificate(props.LotApprasialCertificate)
        }
    }, [props.LotApprasialCertificate])

    const [below500, setBelow500] = useState("")

    const belowPrice = () => {
        console.log(props.below5000Obj, 'props.Obj')
        if (props.below5000Obj && props.below5000Obj.id) {
            props.putBelow500Price(
                {
                    "id": props.below5000Obj.id,
                    "name": "below500",
                    "price": below500
                }, props.below5000Obj.id
            )
        } else {
            props.addBelow500Price(
                {
                    "name": "below500",
                    "price": below500
                }
            )
        }
    }
    useEffect(() => {
        console.log(props.below5000, 'props.below5000props.below5000')
        setBelow500(props.below5000)
    }, [props.below5000])


    return (
        <div className="AddPrices">
            <div className="innerAddPrice">
                <div className="tabsDiv">
                    <div onClick={() => setTabs('LOSE STONE SERVICE')} className="tab">
                        <p>LOSE STONE SERVICE</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('DIAMOND CARD')} className="tab">
                        <p>DIAMOND CARD</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('DIAMOND CERTIFICATE')} className="tab">
                        <p>DIAMOND CERTIFICATE</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('JEWELLERY SERVICE')} className="tab">
                        <p>JEWELLERY SERVICE</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('STRAND SERVICE')} className="tab">
                        <p>STRAND SERVICE</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('BELOW 5000')} className="tab">
                        <p>BELOW 5000</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('EXTRA SERVICE')} className="tab">
                        <p>EXTRA SERVICE</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('CVD Testing')} className="tab">
                        <p>CVD Testing</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('Select Stone For Card')} className="tab">
                        <p>Select Stone For Card</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('Select Stone For Certificates')} className="tab">
                        <p>Select Stone For Certificates</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('Small GemStones Lot')} className="tab">
                        <p>Small GemStones Lot</p>
                        <RightOutlined />
                    </div>
                    <div onClick={() => setTabs('Lot Appraisal Certificates')} className="tab">
                        <p>Lot Appraisal Certificates</p>
                        <RightOutlined />
                    </div>
                </div>
                {tabs === "LOSE STONE SERVICE" ?
                    <div className="priceForm">
                        <h3>{tabs}</h3>
                        {LoseStoneService.map((a, i) => {
                            return (
                                <div className="fieldPrice">
                                    <h3>{a.name}</h3>
                                    <div className="inputField">
                                        <input placeholder="price" value={a.price} disabled={!Number(a.price)} style={{    cursor: "not-allowed"}} onChange={(ev) => {
                                            let arr = [...LoseStoneService]
                                            let obj = { ...a }
                                            obj.price = ev.target.value
                                            arr.splice(i, 1, obj)
                                            setLoseStoneService(arr)
                                        }} />
                                        <input placeholder="member price" value={a.member_price} disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}} onChange={(ev) => {
                                            let arr = [...LoseStoneService]
                                            let obj = { ...a }
                                            obj.member_price = ev.target.value
                                            arr.splice(i, 1, obj)
                                            setLoseStoneService(arr)
                                        }} />
                                    </div>
                                </div>
                            )
                        }
                        )}
                        <button onClick={() => looseStonePrice()}>Submit</button>
                    </div>
                    :
                    tabs === 'DIAMOND CARD' ?
                        <div className="priceForm">
                            <h3>{tabs}</h3>
                            {DiamondCard.map((a, i) => (

                                <div className="fieldPrice">
                                    <h3>{a.name}:</h3>
                                    <div className="inputField">
                                        <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                            let arr = [...DiamondCard]
                                            let obj = { ...a }
                                            obj.price = ev.target.value
                                            arr.splice(i, 1, obj)
                                            setDiamondCard(arr)
                                        }} />
                                        <input placeholder="member price"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                            let arr = [...DiamondCard]
                                            let obj = { ...a }
                                            obj.member_price = ev.target.value
                                            arr.splice(i, 1, obj)
                                            setDiamondCard(arr)
                                        }} />
                                    </div>
                                </div>
                            ))}
                            <button onClick={() => diamondCardPrice()}>Submit</button>
                        </div>
                        :
                        tabs === 'DIAMOND CERTIFICATE' ?
                            <div className="priceForm">
                                <h3>{tabs}</h3>
                                {DiamondCertificate.map((a, i) => (
                                    <div className="fieldPrice">
                                        <h3>{a.name}:</h3>
                                        <div className="inputField">
                                            <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                let arr = [...DiamondCertificate]
                                                let obj = { ...a }
                                                obj.price = ev.target.value
                                                arr.splice(i, 1, obj)
                                                setDiamondCertificate(arr)
                                            }} />
                                            <input placeholder="member price"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                let arr = [...DiamondCertificate]
                                                let obj = { ...a }
                                                obj.member_price = ev.target.value
                                                arr.splice(i, 1, obj)
                                                setDiamondCertificate(arr)
                                            }} />
                                        </div>
                                    </div>
                                ))}
                                <button onClick={() => diamondCertificatePrice()}>Submit</button>
                            </div>
                            :
                            tabs === 'JEWELLERY SERVICE' ?
                                <div className="priceForm">
                                    <h3>{tabs}</h3>
                                    {JewelleryService.map((a, i) => (

                                        <div className="fieldPrice">
                                            <h3>{a.name}:</h3>
                                            <div className="inputField">
                                                <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                    let arr = [...JewelleryService]
                                                    let obj = { ...a }
                                                    obj.price = ev.target.value
                                                    arr.splice(i, 1, obj)
                                                    setJewelleryService(arr)
                                                }} />
                                                <input placeholder="member price"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                    let arr = [...JewelleryService]
                                                    let obj = { ...a }
                                                    obj.member_price = ev.target.value
                                                    arr.splice(i, 1, obj)
                                                    setJewelleryService(arr)
                                                }} />
                                            </div>
                                        </div>
                                    ))}

                                    <button onClick={() => addJeweleryPrice()}>Submit</button>
                                </div>
                                :
                                tabs === 'STRAND SERVICE' ?
                                    <div className="priceForm">
                                        <h3>{tabs}</h3>
                                        {StrandService.map((a, i) => (
                                            <div className="fieldPrice">
                                                <h3>{a.name}:</h3>
                                                <div className="inputField">
                                                    <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                        let arr = [...StrandService]
                                                        let obj = { ...a }
                                                        obj.price = ev.target.value
                                                        arr.splice(i, 1, obj)
                                                        setStrandService(arr)
                                                    }} />
                                                    <input placeholder="member price"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                        let arr = [...StrandService]
                                                        let obj = { ...a }
                                                        obj.member_price = ev.target.value
                                                        arr.splice(i, 1, obj)
                                                        setStrandService(arr)
                                                    }} />
                                                </div>
                                            </div>
                                        ))}

                                        <button onClick={() => strandServicePrice()}>Submit</button>
                                    </div>
                                    :
                                    tabs === 'BELOW 5000' ?
                                        <div className="priceForm">
                                            <h3>{tabs}</h3>
                                            <div className="fieldPrice">
                                                <h3>Below 5000:</h3>
                                                <div className="inputField">
                                                    <input placeholder="price" value={below500} onChange={(ev) => setBelow500(ev.target.value)} />
                                                </div>
                                            </div>

                                            <button onClick={() => belowPrice()}>Submit</button>
                                        </div>
                                        :
                                        tabs === 'EXTRA SERVICE' ?
                                            <div className="priceForm">
                                                <h3>{tabs}</h3>
                                                {extraService.map((a, i) => (

                                                    <div className="fieldPrice">
                                                        <h3>{a.name}:</h3>
                                                        <div className="inputField">
                                                            <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                                let arr = [...extraService]
                                                                let obj = { ...a }
                                                                obj.price = ev.target.value
                                                                arr.splice(i, 1, obj)
                                                                setExtraService(arr)
                                                            }} />
                                                        </div>
                                                    </div>
                                                ))}
                                                <button onClick={() => extraServicePrice()}>Submit</button>
                                            </div>
                                            :
                                            tabs === 'CVD Testing' ?
                                                <div className="priceForm">
                                                    <h3>{tabs}</h3>
                                                    {CVDTesting.map((a, i) => {
                                                        return (
                                                            <div className="fieldPrice">
                                                                <h3>{a.name}</h3>
                                                                <div className="inputField">
                                                                    <input placeholder="price"  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                                        let arr = [...CVDTesting]
                                                                        let obj = { ...a }
                                                                        obj.price = ev.target.value
                                                                        arr.splice(i, 1, obj)
                                                                        setCVDTesting(arr)
                                                                    }} />
                                                                    <input placeholder="member price"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                                        let arr = [...CVDTesting]
                                                                        let obj = { ...a }
                                                                        obj.member_price = ev.target.value
                                                                        arr.splice(i, 1, obj)
                                                                        setCVDTesting(arr)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                                    <button onClick={() => addCVDTestingPrice()}>Submit</button>
                                                </div>
                                                :
                                                tabs === 'Select Stone For Card' ?
                                                    <div className="priceForm">
                                                        <h3>{tabs}</h3>
                                                        {SelectStone.map((a, i) => {
                                                            return (
                                                                <div className="fieldPrice">
                                                                    <h3>{a.name}</h3>
                                                                    <div className="inputField">
                                                                        <input placeholder="Price 1" disabled={!Number(a.p1)} style={{    cursor: "not-allowed"}}  value={a.p1} onChange={(ev) => {
                                                                            let arr = [...SelectStone]
                                                                            let obj = { ...a }
                                                                            obj.p1 = ev.target.value
                                                                            arr.splice(i, 1, obj)
                                                                            setSelectStone(arr)
                                                                        }} />
                                                                        <input placeholder="Price 2"  disabled={!Number(a.p2)} style={{    cursor: "not-allowed"}} value={a.p2} onChange={(ev) => {
                                                                            let arr = [...SelectStone]
                                                                            let obj = { ...a }
                                                                            obj.p2 = ev.target.value
                                                                            arr.splice(i, 1, obj)
                                                                            setSelectStone(arr)
                                                                        }} />
                                                                        <input placeholder="member price 1"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}} value={a.member_price} onChange={(ev) => {
                                                                            let arr = [...SelectStone]
                                                                            let obj = { ...a }
                                                                            obj.member_price = ev.target.value
                                                                            arr.splice(i, 1, obj)
                                                                            setSelectStone(arr)
                                                                        }} />
                                                                        <input placeholder="member price 2"  disabled={!Number(a.member_price_p2)} style={{    cursor: "not-allowed"}} value={a.member_price_p2} onChange={(ev) => {
                                                                            let arr = [...SelectStone]
                                                                            let obj = { ...a }
                                                                            obj.member_price_p2 = ev.target.value
                                                                            arr.splice(i, 1, obj)
                                                                            setSelectStone(arr)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                        <button onClick={() => selectStonePrice()}>Submit</button>
                                                    </div>
                                                    :
                                                    tabs === 'Select Stone For Certificates' ?
                                                        <div className="priceForm">
                                                            <h3>{tabs}</h3>
                                                            {SelectStoneForCertificates.map((a, i) => {
                                                                return (
                                                                    <div className="fieldPrice">
                                                                        <h3>{a.name}</h3>
                                                                        <div className="inputField">
                                                                            <input placeholder="Price 1"  disabled={!Number(a.p1)} style={{    cursor: "not-allowed"}}  value={a.p1} onChange={(ev) => {
                                                                                let arr = [...SelectStoneForCertificates]
                                                                                let obj = { ...a }
                                                                                obj.p1 = ev.target.value
                                                                                arr.splice(i, 1, obj)
                                                                                setSelectStoneForCertificates(arr)
                                                                            }} />
                                                                            <input placeholder="Price 2" disabled={!Number(a.p2)} style={{    cursor: "not-allowed"}}  value={a.p2} onChange={(ev) => {
                                                                                let arr = [...SelectStoneForCertificates]
                                                                                let obj = { ...a }
                                                                                obj.p2 = ev.target.value
                                                                                arr.splice(i, 1, obj)
                                                                                setSelectStoneForCertificates(arr)
                                                                            }} />
                                                                            <input placeholder="member price 1" disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                                                let arr = [...SelectStoneForCertificates]
                                                                                let obj = { ...a }
                                                                                obj.member_price = ev.target.value
                                                                                arr.splice(i, 1, obj)
                                                                                setSelectStoneForCertificates(arr)
                                                                            }} />
                                                                            <input placeholder="member price 2" disabled={!Number(a.member_price_p2)} style={{    cursor: "not-allowed"}}  value={a.member_price_p2} onChange={(ev) => {
                                                                                let arr = [...SelectStoneForCertificates]
                                                                                let obj = { ...a }
                                                                                obj.member_price_p2 = ev.target.value
                                                                                arr.splice(i, 1, obj)
                                                                                setSelectStoneForCertificates(arr)
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            )}
                                                            <button onClick={() => SelectStoneForCertificatesPrice()}>Submit</button>
                                                        </div>
                                                        :
                                                        tabs === 'Small GemStones Lot' ?
                                                            <div className="priceForm">
                                                                <h3>{tabs}</h3>
                                                                {SmallGemStonesLot.map((a, i) => {
                                                                    return (
                                                                        <div className="fieldPrice">
                                                                            <h3>{a.name}</h3>
                                                                            <div className="inputField">
                                                                                <input placeholder="Price 1"  disabled={!Number(a.p1)} style={{    cursor: "not-allowed"}}  value={a.p1} onChange={(ev) => {
                                                                                    let arr = [...SmallGemStonesLot]
                                                                                    let obj = { ...a }
                                                                                    obj.p1 = ev.target.value
                                                                                    arr.splice(i, 1, obj)
                                                                                    setSmallGemStonesLot(arr)
                                                                                }} />
                                                                                <input placeholder="Price 2"  disabled={!Number(a.p2)} style={{    cursor: "not-allowed"}}  value={a.p2} onChange={(ev) => {
                                                                                    let arr = [...SmallGemStonesLot]
                                                                                    let obj = { ...a }
                                                                                    obj.p2 = ev.target.value
                                                                                    arr.splice(i, 1, obj)
                                                                                    setSmallGemStonesLot(arr)
                                                                                }} />
                                                                                <input placeholder="member price 1"  disabled={!Number(a.member_price)} style={{    cursor: "not-allowed"}}  value={a.member_price} onChange={(ev) => {
                                                                                    let arr = [...SmallGemStonesLot]
                                                                                    let obj = { ...a }
                                                                                    obj.member_price = ev.target.value
                                                                                    arr.splice(i, 1, obj)
                                                                                    setSmallGemStonesLot(arr)
                                                                                }} />
                                                                                <input placeholder="member price 2"  disabled={!Number(a.member_price_p2)} style={{    cursor: "not-allowed"}}  value={a.member_price_p2} onChange={(ev) => {
                                                                                    let arr = [...SmallGemStonesLot]
                                                                                    let obj = { ...a }
                                                                                    obj.member_price_p2 = ev.target.value
                                                                                    arr.splice(i, 1, obj)
                                                                                    setSmallGemStonesLot(arr)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                )}
                                                                <button onClick={() => SmallGemStonesLotPrice()}>Submit</button>
                                                            </div>
                                                            :
                                                            tabs === 'Lot Appraisal Certificates' ?
                                                                <div className="priceForm">
                                                                    <h3>{tabs}</h3>
                                                                    {LotApprasialCertificate.map((a, i) => {
                                                                        return (
                                                                            <div className="fieldPrice">
                                                                                <h3>{a.name}</h3>
                                                                                <div className="inputField">
                                                                                    <input placeholder="Price "  disabled={!Number(a.price)} style={{    cursor: "not-allowed"}}  value={a.price} onChange={(ev) => {
                                                                                        let arr = [...LotApprasialCertificate]
                                                                                        let obj = { ...a }
                                                                                        obj.price = ev.target.value
                                                                                        arr.splice(i, 1, obj)
                                                                                        setLotApprasialCertificate(arr)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )}
                                                                    <button onClick={() => appraisalLotPrice()}>Submit</button>
                                                                </div>
                                                                : null
                }

            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    LoseStoneService: state.FormData.LoseStoneService,
    DiamondCard: state.FormData.DiamondCard,
    DiamondCertificate: state.FormData.DiamondCertificate,
    JewelleryService: state.FormData.JewelleryService,
    StrandService: state.FormData.StrandService,
    below5000: state.FormData.below5000,
    below5000Obj: state.FormData.below5000Obj,
    extraService: state.FormData.extraService,
    CVDTesting: state.FormData.CVDTesting,
    SmallGemStonesLot: state.FormData.SmallGemStonesLot,
    SelectStone: state.FormData.SelectStone,
    SelectStoneForCertificates: state.FormData.SelectStoneForCertificates,
    LotApprasialCertificate: state.FormData.LotApprasialCertificate,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            // getMonthSummaryReport

            addLoseStoneServicePrice,
            putLoseStoneServicePrice,
            addDiamondCardPrice,
            putDiamondCardPrice,
            addDiamondCertificatePrice,
            putDiamondCertificatePrice,
            addStrandServicePrice,
            putStrandServicePrice,
            addJewelleryCeritificatePrice,
            putJewelleryCeritificatePrice,
            addBelow500Price,
            putBelow500Price,
            addExtraService,
            putExtraService,
            addCVDTesting,
            putCVDTesting,
            addSelectStoneLot,
            putSelectStoneLot,
            addJewelleryCertificateLot,
            putJewelleryCertificateLot,
            addSmallGemstonesLot,
            putSmallGemstonesLot,
            putLotApprasialCertificate,
            addLotApprasialCertificate
        },
        dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(AddPrices)