import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Space, Pagination } from "antd";
import { Modal, Button } from "antd";
import { Table } from "antd";
import {
  deleteGeneratedEntry,
  getRecieptGeneratedEntries,
} from "../../../../../modules/actions/reportsAction";
import { bindActionCreators } from "redux";
import logo from "./../../../../../Images/Logo 2.png";
import { ModalReport } from "../ModalReport";
const { Column } = Table;

export const CustomerBalanceModal = (props) => {
  let {
    isModalVisibleBal,
    setIsModalVisibleBalance,
    showModalBal,
    handleOkBal,
    handleCancelBal,
    singleCustomerObj,
    insidePage,
    setInsidePage,
    insidePageSize,
    setInsidePageSize,
  } = props;

  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState({});
  const [modalType, setModalType] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setData({});
  };

  // useEffect(() => {
  //     console.log(props.summaryObj,'props.summaryObj')
  // }, [props.summaryObj])

  const [dataEntry, setDataEntry] = useState([]);

  useEffect(() => {
    setDataEntry([]);
    console.log(props.singleCustomerEntries, "sdasdas");
    setDataEntry(props.singleCustomerEntries);
  }, [props.singleCustomerEntries, insidePageSize]);

  const [sortedDataEntry, setSortedDataEntry] = useState([]);
  useEffect(() => {
    let arr = dataEntry.sort(
      (a, b) =>
        (a.entryDate
          ? new Date(a.entryDate).getTime()
          : new Date(a.date).getTime()) -
        (b.entryDate
          ? new Date(b.entryDate).getTime()
          : new Date(b.date).getTime())
    );
    console.log(arr, "dataEntry");
    setSortedDataEntry(arr);
  }, [dataEntry]);

  const totalBilled = (consultationArray) => {
    let tA = 0;
    for (let i = 0; i < consultationArray.length; i++) {
      let {
        sealRate,
        mineRate,
        urgentRate,
        goldTestingRate,
      } = consultationArray[i];
      let getServiceRate =
        Number(consultationArray[i].serviceRate) +
        Number(consultationArray[i].sealRate) +
        Number(consultationArray[i].mineRate) +
        Number(consultationArray[i].urgentRate) +
        Number(consultationArray[i].goldTestingRate);

      tA += Number(getServiceRate);
    }
    return tA;
  };

  let totalAmount = 0;
  const totalHistory = (a) => {
    console.log(a);
    totalAmount += a;
    return totalAmount;
  };

  let totalBillAmount = 0;
  const totalBillAmountFn = (a) => {
      let totalexpnseAmont=0
    if (a.length) {
      console.log(a, "dsadsa");
      for (let i = 0; i < a.length; i++) {
        totalBillAmount += Number(a[i].expenseAmount);
        totalexpnseAmont += Number(a[i].expenseAmount);
      }
    }
    console.log(totalexpnseAmont);
    return totalexpnseAmont;
  };

  // useEffect(() => {
  // }, [dataEntry])

  const certificateNumbers = (record) => {
    let totalCertificates = record.consultationArray.length
      ? record.consultationArray.length
      : record.items.length;
    let arr = record.consultationArray.length
      ? record.consultationArray
      : record.items;
    let generatedNumber = 0;
    arr.map((a, i) => {
      if (
        a.reportObj ||
        a.consultationDetailColor ||
        a.service == "No Decision"
      ) {
        generatedNumber += 1;
      }
    });
    return `${generatedNumber} / ${totalCertificates}`;
  };

  const deleteAll = () => {
    console.log(dataEntry);
    dataEntry.map((a, i) => {
      if (!a.deliveryObj) {
        // let totalCertificates = a.consultationArray.length ? a.consultationArray.length : a.items.length
        if (a.consultationArray) {
          let arr = a.consultationArray.length ? a.consultationArray : a.items;
          let generatedNumber = 0;
          arr.map((b, j) => {
            if (
              b.reportObj ||
              b.consultationDetailColor ||
              b.service == "No Decision"
            ) {
              generatedNumber += 1;
            }
          });
          if (Number(generatedNumber) === 0) {
            props.deleteGeneratedEntry(a.id);
          }
        }
      }
    });
  };

  return (
    <div>
      <Modal
        className="CustomerBalanceModal"
        title="Details"
        visible={isModalVisibleBal}
        onOk={handleOkBal}
        onCancel={handleCancelBal}
      >
        <div className="innerCustomerSumModal">
          <div className="tableDiv">
            {props.userPermissions.userType === "Admin" ? (
              <button
                className="deleteAllEntryButton"
                onClick={() => {
                  deleteAll();
                }}
              >
                Delete all entries of this Customer
              </button>
            ) : null}
            {props.reportsLoading ? (
              <div className="loaderDiv tableLoader">
                <div>
                  <img src={logo} />
                </div>
              </div>
            ) : null}
            <div className="ant-table-wrapper itemsTable">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        <table style={{ tableLayout: "auto" }}>
                          <colgroup></colgroup>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Dates</th>
                              <th className="ant-table-cell">Entry No.</th>
                              <th className="ant-table-cell">Description</th>
                              <th className="ant-table-cell">Customer ID</th>
                              <th className="ant-table-cell">Customer Name</th>
                              <th className="ant-table-cell">Delivered</th>
                              <th className="ant-table-cell">Total Billed</th>
                              <th className="ant-table-cell">Total Expenses</th>
                              <th className="ant-table-cell">Advance</th>
                              <th className="ant-table-cell">Discount</th>
                              <th className="ant-table-cell">Payment</th>
                              <th className="ant-table-cell">Net Balance</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            {sortedDataEntry.map((record, i) => {
                              return (
                                <tr className="ant-table-row ant-table-row-level-0">
                                  <td className="ant-table-cell">
                                    {record.date
                                      ? new Date(record.date)
                                          .toISOString()
                                          .split("T")[0]
                                      : record.entryDate}
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.entryNo}
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.description}
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.customerId}
                                  </td>
                                  <td className="ant-table-cell">
                                    <div>
                                      {record.customerName
                                        ? record.customerName
                                        : record.customerObj
                                        ? record.customerObj.customerName
                                        : ""}
                                    </div>
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.deliveryObj ||
                                    record.CashReceive ||
                                    record.membershipRate ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="green"
                                        className="bi bi-check-lg"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="red"
                                        className="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                      </svg>
                                    )}
                                  </td>
                                  <td className="ant-table-cell">
                                    <div>
                                      {Number(
                                        record.deliveryObj &&
                                          record.deliveryObj.length
                                          ? record.deliveryObj[0].totalAmount
                                          : record.consultationArray &&
                                            record.consultationArray.length
                                          ? totalBilled(
                                              record.consultationArray
                                            )
                                          : record.membershipRate
                                          ? record.membershipRate
                                          : record.totalAmount
                                          ? record.totalAmount
                                          : 0
                                      ) 
                                        }
                                    </div>
                                  </td>
                                  <td>{totalBillAmountFn(
                                          record.expenses ? record.expenses : 0
                                        )}</td>
                                  <td className="ant-table-cell">
                                    <>{record.advance ? record.advance : 0}</>
                                  </td>
                                  <td className="ant-table-cell">
                                    <div>
                                      {record.discountAmount > 0 ? (
                                        <>
                                          {record.discountAmount}{" "}
                                          <span>
                                            ({record.discountApproved})
                                          </span>
                                        </>
                                      ) : (
                                        <>0</>
                                      )}
                                    </div>
                                  </td>
                                  <td className="ant-table-cell">
                                    <>
                                      {record.deliveryObj
                                        ? record.deliveryObj[0].paymentRecieved
                                        : record.deliveryObj
                                        ? record.deliveryObj[0].paymentRecieved
                                        : record.CashReceive
                                        ? record.CashReceive
                                        : record.membershipRate
                                        ? record.membershipPayment
                                        : 0}
                                    </>
                                  </td>
                                  <td className="ant-table-cell">
                                    <div>
                                      {totalHistory(
                                        (record.deliveryObj
                                          ? record.deliveryObj[0].totalAmount
                                          : record.consultationArray &&
                                            record.consultationArray.length
                                          ? totalBilled(
                                              record.consultationArray
                                            )
                                          : record.membershipRate
                                          ? record.membershipRate
                                          : 0) -
                                          ((record.deliveryObj ||
                                            record.CashReceive ||
                                            record.membershipRate) &&
                                          record.discountApproved &&
                                          record.discountApproved.toLowerCase() ===
                                            "approved"
                                            ? record.discountAmount
                                            : 0) -
                                          (record.CashReceive
                                            ? record.CashReceive
                                            : record.membershipRate
                                            ? record.membershipPayment
                                            : 0) -
                                          (record.advance
                                            ? record.advance
                                            : 0) -
                                          (record.deliveryObj
                                            ? record.deliveryObj[0]
                                                .paymentRecieved
                                            : record.deliveryObj
                                            ? record.deliveryObj[0]
                                                .paymentRecieved
                                            : 0) +
                                          (record.membershipBalance
                                            ? record.membershipBalance
                                            : 0)
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="ant-table-footer">
                      <div className="ant-table-wrapper itemsTable footertable">
                        <div className="ant-spin-nested-loading">
                          <div className="ant-spin-container">
                            <div className="ant-table">
                              <div className="ant-table-container">
                                <div className="ant-table-content">
                                  <table style={{ tableLayout: "auto" }}>
                                    <colgroup></colgroup>
                                    <thead className="ant-table-thead">
                                      <tr>
                                        <th className="ant-table-cell">
                                          No. Data Entry
                                        </th>
                                        <th className="ant-table-cell"></th>
                                        <th className="ant-table-cell"></th>
                                        <th className="ant-table-cell"></th>
                                        <th className="ant-table-cell"></th>
                                        <th className="ant-table-cell">
                                          Total Billed
                                        </th>
                                        <th className="ant-table-cell">
                                          Total Advance
                                        </th>
                                        <th className="ant-table-cell">
                                          Total Discount
                                        </th>
                                        <th className="ant-table-cell">
                                          Total Payment
                                        </th>
                                        <th className="ant-table-cell">
                                          Balance
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                      {/* Summary Row */}
                                      {[props.summaryObj].map(
                                        (record, rowIndex) => {
                                          return (
                                            <tr className="ant-table-row ant-table-row-level-0">
                                              <td className="ant-table-cell">
                                                <div>{props.total}</div>
                                              </td>
                                              <td className="ant-table-cell"></td>
                                              <td className="ant-table-cell"></td>
                                              <td className="ant-table-cell"></td>
                                              <td className="ant-table-cell"></td>
                                              <td className="ant-table-cell">
                                                <div>
                                                  {record.totalAmount
                                                    ? record.totalAmount
                                                    : 0}
                                                </div>
                                              </td>
                                              <td className="ant-table-cell">
                                                <div>
                                                  {record.advance
                                                    ? record.advance
                                                    : 0}
                                                </div>
                                              </td>
                                              <td className="ant-table-cell">
                                                <div>
                                                  {record.discount
                                                    ? record.discount
                                                    : 0}
                                                </div>
                                              </td>
                                              <td className="ant-table-cell">
                                                <div>
                                                  {record.paymentRecieved
                                                    ? record.paymentRecieved
                                                    : 0}
                                                </div>
                                              </td>
                                              <td className="ant-table-cell">
                                                <div>
                                                  {record.balance
                                                    ? record.balance
                                                    : 0}
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ... Other nested components go here */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* <Table pagination={false} className="itemsTable" dataSource={sortedDataEntry}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        console.log(record)
                                        if (record.description === 'Direct Cash Rec.') {
                                            setModalType('directCash')
                                            setIsModalVisible(true)
                                            setData(record);
                                        } else if (record.membershipRate && record.membershipRate >= 0) {
                                            setModalType('membership')
                                            setIsModalVisible(true)
                                            setData(record);
                                        } else {
                                            setModalType('delivery')
                                            setData(record);
                                            setIsModalVisible(true)
                                        }
                                    }, 

                                };
                            }}
                            footer={() => {
                                return (
                                    <Table className="itemsTable footertable" dataSource={[props.summaryObj]}>
                                        <Column title="No. Data Entry" render={(text, record) => {
                                            return (
                                                <div>
                                                    {props.total}
                                                </div>
                                            )
                                        }} />
                                        <Column dataIndex="" key="" />
                                        <Column dataIndex="" key="" />
                                        <Column dataIndex="" key="" />
                                        <Column key="" dataIndex="" />
                                        <Column title="Total Billed" dataIndex="totalAmount" key="totalAmount" render={(text, record) => {
                                            return (
                                                <div>
                                                    {record.totalAmount ? record.totalAmount : 0}
                                                </div>
                                            )
                                        }} />
                                        <Column title="Total Advance" dataIndex="advance" key="advance" render={(text, record) => {
                                            return (
                                                <div>
                                                    {record.advance ? record.advance : 0}
                                                </div>
                                            )
                                        }} />
                                        <Column title="Total Discount" key="discount" dataIndex="discount" render={(text, record) => {
                                            return (
                                                <div>
                                                    {record.discount ? record.discount : 0}
                                                </div>
                                            )
                                        }} />
                                        <Column title="Total Payment" key="paymentRecieved" dataIndex="paymentRecieved" render={(text, record) => {
                                            return (
                                                <div>
                                                    {record.paymentRecieved ? record.paymentRecieved : 0}
                                                </div>
                                            )
                                        }} />
                                        <Column title="Balance" key="balance" dataIndex="balance" render={(text, record) => {
                                            return (
                                                <div>
                                                    {record.balance ? record.balance : 0}
                                                </div>
                                            )
                                        }} />
                                    </Table>
                                )
                            }}


                        >
                            <Column title="Dates" render={(text, record) => {
                                return (
                                    <>
                                      
                                        {record.date ?
                                            new Date(record.date).toISOString().split('T')[0]
                                            :
                                            record.entryDate}
                                    </>
                                )
                            }} />
                            <Column title="Entry No." dataIndex="entryNo" key="entryNo" />
                            <Column title="Description" dataIndex="description" key="description" />
                            <Column title="Customer ID" dataIndex="customerId" key="customerId" />
                            <Column title="Customer Name" dataIndex="customerName" key="customerName" render={(text, record) => {
                                return (
                                    <div>
                                        {record.customerName ? record.customerName : record.customerObj ? record.customerObj.customerName : ''}
                                    </div>
                                )
                            }} />
                            <Column title="Delivered" key="delivered" dataIndex="delivered" render={(text, record) => {
                                return (
                                
                                    record.deliveryObj || record.CashReceive || record.membershipRate ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                                        </svg>
                      
                                )
                            }} />
                            <Column title="Total Billed" dataIndex="billed" key="billed" render={(text, record) => {
                                return (
                                    <div >
                                        {Number(record.deliveryObj ? record.deliveryObj.totalAmount : record.consultationArray && record.consultationArray.length ? totalBilled(record.consultationArray) : record.membershipRate ? record.membershipRate : record.totalAmount ? record.totalAmount : 0) + totalBillAmountFn(record.expenses ? record.expenses : 0)}
                                    </div>
                                )
                            }} />
                            <Column title="Advance" dataIndex="advance" key="advance" render={(text, record) => {
                                return (
                                    <>
                                        {record.advance ? record.advance : 0}
                                    </>
                                )
                            }} />
                            <Column title="Discount" key="discount" dataIndex="discount" render={(text, record) => {
                                return (
                                    <div >

                                        {record.discountAmount > 0 ?
                                            <>
                                                {record.discountAmount} <span>({record.discountApproved})</span>
                                            </>
                                            :
                                            <>
                                                0
                                            </>
                                        }
                                    </div>
                                )
                            }} />
                            <Column title="Payment" key="payment" dataIndex="payment" render={(text, record) => {
                                return (
                                    <>
                                        {record.deliveryObj ? record.deliveryObj.paymentRecieved : record.deliveryObj ? record.deliveryObj.paymentRecieved : record.CashReceive ? record.CashReceive : record.membershipRate ? record.membershipPayment : 0}
                                    </>
                                )
                            }} />
                            <Column title="Net Balance" render={(text, record) => {
                                return (
                                    <div>
                                        {
                                            totalHistory(
                                                (record.deliveryObj ? record.deliveryObj.totalAmount : record.consultationArray && record.consultationArray.length ? totalBilled(record.consultationArray) : record.membershipRate ? record.membershipRate : 0)

                                                - ((record.deliveryObj || record.CashReceive || record.membershipRate) && record.discountApproved && record.discountApproved.toLowerCase() === "approved" ? record.discountAmount : 0)
                                                - (record.CashReceive ? record.CashReceive : record.membershipRate ? record.membershipPayment : 0)
                                                - (record.advance ? record.advance : 0)
                                                - (record.deliveryObj ? record.deliveryObj.paymentRecieved : record.deliveryObj ? record.deliveryObj.paymentRecieved : 0)
                                                + (record.membershipBalance ? record.membershipBalance : 0)

                                            )
                                        }
                                    </div>
                                )
                            }} />
                        </Table> */}
          </div>
          <Pagination
            onChange={(ev, size) => {
              setInsidePage(ev);
              setInsidePageSize(size);
            }}
            current={props.page}
            total={props.total}
            pageSize={insidePageSize}
          />
        </div>
        <ModalReport
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          data={data}
          entries={
            data.consultationArray && data.consultationArray.length
              ? data.consultationArray
              : data.items
          }
          modalType={modalType}
        />
      </Modal>
    </div>
  );
};

// let a = 0
// let b = 0
// let arr = [1,2,3,4,5]
// for (let i = 0 ; i < arr.length; i ++ ){
//     if(arr[i] > a){
//         a = arr[i]
//     }
// }
// for (let i = 0 ; i < arr.length; i ++ ){
//     if(arr[i] < a){
//         b = arr[i]
//     }
// }
const mapStateToProps = (store) => {
  return {
    singleCustomerEntries: store.reports.singleCustomerEntries
      ? store.reports.singleCustomerEntries
      : [],
    page: store.reports.page2,
    total: store.reports.total2,
    summaryObj: store.reports.summaryObj,
    reportsLoading: store.reports.reportsLoading,
    userPermissions: store.authentication.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRecieptGeneratedEntries,
      deleteGeneratedEntry,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerBalanceModal);
