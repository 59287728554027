import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Table, Tag, Space, DatePicker, Col, Row, Select } from "antd";
import { connect } from "react-redux";
import SummaryDaily from "./SummaryDaily";
import { bindActionCreators } from "redux";
import { getDaySummaryReport, SeenFn, unSeenFn } from "../../../modules/actions/summaryReportsAction";
import { userLoginSuccess } from "../../../modules/actions/authenticationActions";
import { getBranch } from "../../../modules/actions/branchActions";
import logo from "./../../../Images/Logo 2.png";
import redirect from '../../../assets/redirect.svg'
const { Column, ColumnGroup } = Table;

export const SummaryDetailModal = (props) => {
	let { laboratoryModal, setLaboratoryModal, showModalLab, dataYear, record } = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentMonthName, setCurrentMonthName] = useState(false);
	const [months, setMonths] = useState([
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	]);
	const [branchCode, setBranchCode] = useState("");
	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const handleOklab = () => {
		setLaboratoryModal(false);
	};

	const handleCancelLab = () => {
		setLaboratoryModal(false);
	};

	const [monthlyData, setMonthlyData] = useState("");
	useEffect(() => {
		if (record) {
			setMonthlyData(record);
		}
	}, [record]);

	const [daysDataArr, setDaysDataArr] = useState([]);
	const [currentMonth, setCurrentMonth] = useState()
	const selectMonth = (data) => {
		props.getDaySummaryReport("days", dataYear, Number(data.month), props.selectedBranch);
	};
	useEffect(() => {
		setDaysDataArr(props.dayData);
	}, [props]);
	useEffect(() => {
		setBranchCode(props.permission.branchCode);
		
	}, [props.permission]);
	return (
		<Modal
			title={`Summary of ${dataYear}`}
			visible={laboratoryModal}
			onOk={handleOklab}
			onCancel={handleCancelLab}
			className="montSumaryModal"
		>
			<table className="summarDetail">
				<thead>
					<tr>
						{/* <th>Checked</th> */}
						<th>Months</th>
						<th>Income</th>
						<th>Membership</th>
						<th>Expense</th>
						<th>Profit/Loss</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{monthlyData &&
						monthlyData.map((a, i) => {
							return (
								<tr
									// onClick={() => {
									// 	// showModal();
									// 	// setCurrentMonth(a)
									// 	// selectMonth(a);
									// 	// console.log(i)
									// 	// setCurrentMonthName(months[i])
									// 	window.open(`/summary-report/daily/${a.month}/${dataYear}/${props.selectedBranch}`,"_blank",)
									// 	// props.history.push()
									// }}
								>
									{/* <td><input type='checkbox'/></td> */}
									<th>
										{months[Number(a.month) - 1]} ({dataYear})
									</th>
									<td>{a.totalLaboratoryCashRecieved}</td>
									<td>{a.totalLaboratoryMemberShipCollection}</td>
									<td>{a.totalLaboratoryExpenses}</td>
									<td>{a.totalLaboratoryCashRecieved + a.totalLaboratoryMemberShipCollection - a.totalLaboratoryExpenses}</td>
									<td>
										{a.leftDates > 0 ?
											<>
											{a.leftDates} Days left to verify
											</>
											: <>
											All Entries Verified
											</>} <img onClick={() => {
										// showModal();
										// setCurrentMonth(a)
										// selectMonth(a);
										// console.log(i)
										// setCurrentMonthName(months[i])
										window.open(`/summary-report/daily/${a.month}/${dataYear}/${props.selectedBranch}`,"_blank",)
										// props.history.push()
									}} src={redirect}/>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			{/* {props.reportsDayLoading ? (
				<div className="loaderDiv dayLoader">
					<div>
						<img src={logo} />
					</div>
				</div>
			) : (
				<SummaryDaily
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					showModal={showModal}
					handleOk={handleOk}
					handleCancel={handleCancel}
					monthlyData={daysDataArr}
					data={currentMonth}
					dataYear={dataYear}
					selectedBranch={props.selectedBranch}
					selectMonth={selectMonth}
					SeenFn={props.SeenFn}
					unSeenFn={props.unSeenFn}
					setDaysDataArr={setDaysDataArr}
					currentMonthName={currentMonthName}

				/>
			)} */}
		</Modal>
	);
};

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	dayData: store.summaryReports.dayData,
	reportsDayLoading: store.reports.reportsDayLoading,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			// getMonthSummaryReport
			getDaySummaryReport,
			SeenFn,
			unSeenFn
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDetailModal);
