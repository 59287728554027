import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Divider, Row, Select } from "antd";
import { Table, Tag, Space } from "antd";
import Items from "./entries";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox } from "antd";
import { message } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getColorFormDataAdd } from "../../modules/actions/FormValues";


const { Option } = Select;

function AddStones({
	modalVisible,
	setModalVisible,
	setModalVisibleFn,
	addData,
	updateData,
	manager,
	index,
	...props
}) {
	const [stonePos, setStonePos] = useState("");
	const [stone, setStone] = useState("");
	const [stoneQuantity, setStoneQuantity] = useState(1);
	const [stoneColor, setStoneColor] = useState("");
	const [stoneWeight, setStoneWeight] = useState("");
	const [stoneRate, setStoneRate] = useState("");
	const [value, setValue] = useState("Above 5000");

	const roundOffAmount = (number) => {
		// return number
		let lastNumber = number % 10;
		if (lastNumber >= 5) {
			return number - lastNumber + 10
		} else {
			return number - lastNumber
		}
	}

	useEffect(() => {

		console.log("checking the length of stone arr", props.stoneArr);
		// setStonePos("");
		// setStone("");
		// if(props.stoneIndex === 0 || props.stoneIndex ){
		// setStoneQuantity(1);
		// setStoneColor("");
		// setStoneWeight("");
		setStoneRate("");
		setValue("Above 5000");
		// }
	}, [props.stoneArr])

	const diamondCalc = () => {
		let sub_Rate = 0;
		let price = "price";

		let p1 = "p1";
		let p2 = "p2";
		if (props.membershipActive) {
			p1 = "member_price";
			p2 = "member_price_p2";
		}
		if (props.membershipActive) {
			price = "member_price";
		}
		if (
			props.services.name === "Jewellery Certificate" ||
			props.services.name === "Gemstone Jewellery Certificate"
		) {
			if (Number(stoneQuantity) === 1) {
				if (Number(stoneWeight < 0.2)) {
					sub_Rate = Number(props.formData.DiamondCertificate[0][price]);
				}
				if (Number(stoneWeight >= 0.2) && Number(stoneWeight <= 0.49)) {
					sub_Rate = Number(props.formData.DiamondCertificate[0][price]);
				}
				if (Number(stoneWeight >= 0.5) && Number(stoneWeight <= 0.99)) {
					sub_Rate = Number(props.formData.DiamondCertificate[1][price]);
				}
				if (Number(stoneWeight >= 1) && Number(stoneWeight <= 1.49)) {
					sub_Rate = Number(props.formData.DiamondCertificate[2][price]);
				}
				if (Number(stoneWeight >= 1.5) && Number(stoneWeight <= 1.99)) {
					sub_Rate = Number(props.formData.DiamondCertificate[3][price]);
				}
				if (Number(stoneWeight >= 2) && Number(stoneWeight <= 2.99)) {
					sub_Rate = Number(props.formData.DiamondCertificate[4][price]);
				}
				if (Number(stoneWeight >= 3) && Number(stoneWeight <= 3.99)) {
					sub_Rate = Number(props.formData.DiamondCertificate[5][price]);
				}
				if (Number(stoneWeight >= 4)) {
					sub_Rate = Number(props.formData.DiamondCertificate[6][price]);
				}
			} else if (Number(stoneQuantity) > 1 && stoneWeight <= 1) {
				sub_Rate = Number(props.formData.SelectStoneForCertificates[0][p1]);
			} else if (Number(stoneQuantity) > 1 && stoneWeight > 5) {
				sub_Rate = Number(props.formData.SelectStoneForCertificates[0][p1]) * 5;

				sub_Rate += (Number(stoneWeight) - 5) * Number(props.formData.SelectStoneForCertificates[0][p2]);
			} else {
				sub_Rate = Number(props.formData.SelectStoneForCertificates[0][p1]) * stoneWeight;
			}
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate.toFixed(0)));
		} else {
			if (Number(stoneQuantity) === 1) {
				if (Number(stoneWeight < 0.2)) {
					sub_Rate = Number(props.formData.DiamondCard[0][price]);
				}
				if (Number(stoneWeight >= 0.2) && Number(stoneWeight <= 0.49)) {
					sub_Rate = Number(props.formData.DiamondCard[0][price]);
				}
				if (Number(stoneWeight >= 0.5) && Number(stoneWeight <= 0.99)) {
					sub_Rate = Number(props.formData.DiamondCard[1][price]);
				}
				if (Number(stoneWeight >= 1) && Number(stoneWeight <= 1.49)) {
					sub_Rate = Number(props.formData.DiamondCard[2][price]);
				}
				if (Number(stoneWeight >= 1.5) && Number(stoneWeight <= 1.99)) {
					sub_Rate = Number(props.formData.DiamondCard[3][price]);
				}
				if (Number(stoneWeight >= 2) && Number(stoneWeight <= 2.99)) {
					sub_Rate = Number(props.formData.DiamondCard[4][price]);
				}
				if (Number(stoneWeight >= 3) && Number(stoneWeight <= 3.99)) {
					sub_Rate = Number(props.formData.DiamondCard[5][price]);
				}
				if (Number(stoneWeight >= 4)) {
					sub_Rate = Number(props.formData.DiamondCard[6][price]);
				}
			} else if (Number(stoneQuantity) > 1 && stoneWeight <= 1) {
				sub_Rate = Number(props.formData.SelectStone[0][p1]);
			} else if (Number(stoneQuantity) > 1 && stoneWeight > 5) {
				sub_Rate = Number(props.formData.SelectStone[0][p1] * 5);

				sub_Rate += (Number(stoneWeight) - 5) * Number(props.formData.SelectStone[0][p2]);
			} else {
				sub_Rate = Number(props.formData.SelectStone[0][p1] * stoneWeight);
			}
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate.toFixed(0)));
		}
	};

	const gemstoneCalcualtion = (noOftime) => {
		console.log("checking lenghtttt")
		let sub_Rate = 0;
		let p1 = "p1";
		let p2 = "p2";
		if (props.membershipActive) {
			p1 = "member_price";
			p2 = "member_price_p2";
		}
		if ((props.cardOrCerificates === "Jewellery Certificate" ||
				props.cardOrCerificates === "Gemstone Jewellery Certificate"))

		{
			console.log(noOftime, props.editStoneObj.itemIndex, 'ssssssqqasa')
			console.log(props.editStoneObj, 'ssssssqqasa')
			if (noOftime === 0 || props.editStoneObj.itemIndex === 0) {
				console.log("ssssssqqasa")
				console.log(props.stoneIndex)
				// if(props.stoneIndex === 0 ){
				if (props.stoneArr && props.stoneArr.filter((a) => a.selectStone === "Gemstone").length && props.stoneArr.filter((a) => a.selectStone === "Gemstone").indexOf(props.editStoneObj) !== 0) {
					console.log(props.stoneArr.filter((a) => a.selectStone === "Gemstone"), 'dasdsa')
					console.log(props.edit, 'dasdsa')
					console.log(props.stoneArr.filter((a) => a.selectStone === "Gemstone").indexOf(props.editStoneObj), 'dasdsa')

					console.log('exist')
					sub_Rate +=
						(Number(stoneQuantity)) * Number(props.formData.SelectStoneForCertificates[1][p2]);

				} else {
					console.log(Number(props.formData.SelectStoneForCertificates[1][p1]), 'dasdsa')
					sub_Rate = Number(props.formData.SelectStoneForCertificates[1][p1]);
					if (Number(stoneQuantity) > 1) {
						sub_Rate +=
							(Number(stoneQuantity) - 1) * Number(props.formData.SelectStoneForCertificates[1][p2]);
					}
					console.log(sub_Rate, 'dasdsa')
				}
				// }
			} else if (noOftime > 0 || props.editStoneObj.itemIndex > 0) {
				if (props.stoneArr.filter((a) => a.selectStone === "Gemstone").length) {

					console.log(sub_Rate, 'dasdsa')

					sub_Rate +=
						(Number(stoneQuantity)) * Number(props.formData.SelectStoneForCertificates[1][p2]);

					// sub_Rate=5;
				} else {
					sub_Rate = Number(props.formData.SelectStoneForCertificates[1][p1]);
					if (Number(stoneQuantity) > 1) {
						sub_Rate +=
							(Number(stoneQuantity) - 1) * Number(props.formData.SelectStoneForCertificates[1][p2]);
					}
					// console.log(sub_Rate, 'dasdsa')
					console.log(sub_Rate, 'dasdsa sub rate mila 2')
				}
			}
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate))
		}


		else {
			console.log(noOftime, props.editStoneObj.itemIndex, 'ssssssqqasa checing lenght')
			console.log(props.editStoneObj, 'ssssssqqasa')
			if (noOftime === 0 || props.editStoneObj.itemIndex === 0) {
				console.log("ssssssqqasa")
				console.log(props.stoneIndex)
				// if(props.stoneIndex === 0 ){
				//if gemstine is there than if will r

				if (props.stoneArr && props.stoneArr.filter((a) => a.selectStone === "Gemstone").length && props.stoneArr.filter((a) => a.selectStone === "Gemstone").indexOf(props.editStoneObj) !== 0) {
					console.log(props.stoneArr.filter((a) => a.selectStone === "Gemstone"), 'dasdsa')
					console.log(props.edit, 'dasdsa')
					console.log(props.stoneArr.filter((a) => a.selectStone === "Gemstone").indexOf(props.editStoneObj), 'dasdsa')

					console.log('exist')
					sub_Rate+=(Number(stoneQuantity)) * Number(props.formData.SelectStone[1][p2]);
				} else {
					console.log(Number(props.formData.SelectStone[1][p1]))
					sub_Rate = Number(props.formData.SelectStone[1][p1]); // 80)0

					if (Number(stoneQuantity) > 1) {
						console.log("if quanatity is")
						sub_Rate+=(Number(stoneQuantity) - 1) * Number(props.formData.SelectStone[1][p2]);
					}
					console.log(sub_Rate, 'dasdsa')
				}

console.log("1st condiiton working");
				//}

			} else if (noOftime > 0 || props.editStoneObj.itemIndex > 0) {
				console.log("Working", noOftime);
				if (props.stoneArr.filter((a) => a.selectStone === "Gemstone").length >= 1) {
console.log("checkig GAI IF", (Number(stoneQuantity)) * Number(props.formData.SelectStone[1][p2]));

					sub_Rate +=
						(Number(stoneQuantity)) * Number(props.formData.SelectStone[1][p2]);
					console.log(sub_Rate, 'dasdsa')
				} else {
console.log("checkig GAI ELSE", Number(props.formData.SelectStone[1][p1]));

					sub_Rate = Number(props.formData.SelectStone[1][p1]);
					if (Number(stoneQuantity) > 1) {
						
						console.log("checkig GAI ELSE IF", (Number(stoneQuantity) - 1) * Number(props.formData.SelectStone[1][p2]));
						
						sub_Rate +=
							(Number(stoneQuantity) - 1) * Number(props.formData.SelectStone[1][p2]);
					}
					console.log(sub_Rate, 'dasdsa sub rate mila 2')
				}
			}
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate))
		}
	};

	const smallGemstoneCalculation = () => {
		let sub_Rate = 0;
		let p1 = "p1";
		if (props.membershipActive) {
			p1 = "member_price";
		}
		if (
			props.cardOrCerificates === "Jewellery Certificate" ||
			props.cardOrCerificates === "Gemstone Jewellery Certificate"
		) {
			sub_Rate = Number(props.formData.SelectStoneForCertificates[2][p1]) * Number(stoneWeight);
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate));
		} else {
			sub_Rate = Number(props.formData.SelectStone[2][p1]) * Number(stoneWeight);
			console.log('this Line run')
			setStoneRate(roundOffAmount(sub_Rate));
		}
	};

	useEffect(() => {
		if (stone === "Diamond") {
			console.log('this Line run')
			setStoneRate(0);
			diamondCalc();
		} else if (stone === "Gemstone") {
			if (value === "Below 5000") {
				console.log('this Line run')
				setStoneRate(roundOffAmount(Number(props.formData.below5000) * Number(stoneQuantity)));
			} else {
				console.log('this Line run and stone array', stoneRate, props.stoneArr? props.stoneArr.length: 0);
				// setStoneRate(800 * Number(stoneQuantity));
				gemstoneCalcualtion(props.stoneArr ? props.stoneArr.length : 0);
			}
		} else if (stone === "Small Gemstone") {
			smallGemstoneCalculation();
		}
	}, [stone, props.stoneArr, stoneQuantity, stoneWeight, value]);

	const addStone = (bool) => {
		let obj = {
			itemIndex: index === 0 ? index : index ? index : props.items.length,
			StonePosition: stonePos,
			Sub_quantity: stoneQuantity,
			color: stoneColor,
			selectStone: stone,
			sub_Rate: stoneRate,
			sub_weight: Number(stoneWeight),
			entryNo: props.itemid
		};
		console.log(index, 'indexx')
		addData(obj);
		if (bool) {
			setModalVisibleFn(false);
			setStonePos("");
			setStone("");
			setStoneQuantity("");
			setStoneColor("");
			setStoneWeight("");
			console.log('this Line run')
			setStoneRate("");
			setValue("Above 5000");
		}
	};

	useEffect(() => {
		let obj = props.editStoneObj;
		console.log("heloooo eeee", props.editStoneObj);
		if (obj) {
			setStonePos(obj.StonePosition);
			setStone(obj.selectStone);
			setStoneQuantity(obj.Sub_quantity);
			setStoneColor(obj.color);
			setStoneWeight(obj.sub_weight);
			console.log('this Line run', obj.sub_Rate)
			setStoneRate(roundOffAmount(obj.sub_Rate));
			console.log(obj.sub_Rate, "obj.sub_Rate")
			setValue("Above 5000");
		}
	}, [props.editStoneObj]);

	const updateStone = () => {
		let arr = props.stoneArr;
		console.log("update stone", arr, props.stoneIndex);
		arr[props.stoneIndex].StonePosition = stonePos;
		arr[props.stoneIndex].Sub_quantity = stoneQuantity;
		arr[props.stoneIndex].color = stoneColor;
		arr[props.stoneIndex].selectStone = stone;
		arr[props.stoneIndex].sub_Rate = stoneRate;
		arr[props.stoneIndex].sub_weight = stoneWeight;
		updateData(arr);
		props.setStoneIndex("");
		props.setEditStoneObj("");
		setStonePos("");
		setStone("");
		setStoneQuantity(1);
		setStoneColor("");
		setStoneWeight("");
		console.log('this Line run')
		setStoneRate("");
		setValue("Above 5000");
		setModalVisibleFn(false);
	};

	const clearState = () => {
		props.setStoneIndex("");
		props.setEditStoneObj("");
		setStonePos("");
		setStone("");
		setStoneQuantity(1);
		setStoneColor("");
		setStoneWeight("");
		console.log('this Line run')
		setStoneRate("");
		setValue("Above 5000");
	};


	useEffect(() => {
		console.log(stoneRate, "this Line run	")
	}, [stoneRate])


	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}

		}
	};

	useEffect(() => {
		if (props.fields) {
			setColorArr(props.fields.color)

		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])

	return (
		<>
			<Button onClick={() => setModalVisibleFn(true)} disabled={manager}>
				ADD STONES
			</Button>
			<Modal
				title="Add Stone"
				className="StoneModal"
				style={{ top: 20, width: "50vw" }}
				visible={modalVisible}
				onOk={() => (props.editStoneObj ? updateStone() : addStone())}
				okText={props.editStoneObj ? "Update Stone" : "Add Stone"}
				onCancel={() => {
					setModalVisibleFn(false);
					clearState();
					props.setStoneIndex("")
				}}
				footer={[
					<button
						type="button"
						className="ant-btn"
						style={{ background: "#f50057", color: "white", borderColor: "#f50057" }}
						onClick={() => {
							setModalVisibleFn(false);
							clearState();
						}}
					>
						<span>Cancel</span>
					</button>,
					<button
						type="button"
						className="ant-btn ant-btn-primary"
						style={{ background: "#001529", color: "white", borderColor: "#001529" }}
						onClick={() => {
							if (stoneColor) {
								(props.editStoneObj ? updateStone() : addStone())

							} else {
								message.error("Color Fields Is Required")
							}

						}}
					>
						<span>{props.editStoneObj ? "Update Stone" : "Add Stone"}</span>
					</button>,
					!props.editStoneObj && (
						<button
							type="button"
							className="ant-btn ant-btn-primary"
							style={{ background: "#001529", color: "white", borderColor: "#001529" }}
							onClick={() => {
								if (stoneColor) {
									// (props.editStoneObj ? updateStone() : addStone())
									addStone(true)

								} else {
									message.error("Color Fields Is Required")
								}
							}}
						>
							<span>Add Stone & Close</span>
						</button>
					)
				]}
			>
				<div className="stoneFiedls">
					<div className="inputFields">
						<label>
							<h5>Stone Position</h5>
							<Select value={stonePos} className="select" onChange={(ev) => setStonePos(ev)}>
								{props.formData.StonePosition.map((a, i) => {
									return (
										<Option key={i} value={a.name}>
											{a.name}
										</Option>
									);
								})}
							</Select>
						</label>
					</div>
					<div className="inputFields">
						<label>
							<h5>Select Stone</h5>
							<Select value={stone} className="select" onChange={(ev) => setStone(ev)}>
								{props.formData.SelectStone.map((a, i) => {
									return (
										<Option key={i} value={a.name}>
											{a.name}
										</Option>
									);
								})}
							</Select>
						</label>
					</div>
					<div className="inputFields">
						<label>
							<h5>Quantity</h5>
							<InputNumber value={stoneQuantity ? stoneQuantity : 0} onChange={(ev) => setStoneQuantity(ev)} />
						</label>
					</div>
					<div className="inputFields">
						<label>
							<h5>Color</h5>
							<div>
								{/* <Input
									style={{ textTransform: "capitalize" }}
									value={stoneColor}
									onChange={(ev) => setStoneColor(ev.target.value)}
								/> */}
								<Select
									showSearch
									onChange={(ev) => setStoneColor(ev)}
									className="select"
									defaultValue=""
									value={stoneColor}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={colorName ? colorName : ""}
													onChange={onColorChange}
												/>
												{colorName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addColorOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{colorArr.map((a, i) => {
										return <Option value={a.name}>{a.name}</Option>;
									})}
								</Select>
							</div>
						</label>
					</div>
					<div className="inputFields">
						<label>
							<h5>Weight</h5>
							<Input value={stoneWeight} onChange={(ev) => setStoneWeight(ev.target.value)} />
						</label>
					</div>
					<div className="inputFields">
						<label>
							<h5>Rate</h5>
							<InputNumber disabled value={stoneRate ? stoneRate : 0} onChange={(ev) => setStoneRate(ev)} />
						</label>
					</div>
					{stone === "Gemstone" && (
						<div className="inputFields">
							<label>
								<h5>Rate</h5>
								<Select
									className="select"
									defaultValue="Above 5000"
									onChange={(ev) => setValue(ev)}
								>
									<Option value="Below 5000">Below 5000</Option>
									<Option value="Above 5000">Above 5000</Option>
								</Select>
							</label>
						</div>
					)}
				</div>
			</Modal>
		</>
	);
}

const mapStateToProps = (store) => {
	return {
		formData: store.FormData,
		membershipActive: store.customers.membershipActive,
		fields: store.FormValues

	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
	getColorFormDataAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddStones);
