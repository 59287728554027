import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Collapse, Modal } from "antd";
import { Col, Row, Select } from "antd";
import { Table, Tag, Space } from "antd";
import Items from "./entries";

import DataEntry from "./dataEntryModel";

const { Option } = Select;
const { Panel } = Collapse;

function EntrieModal(props) {
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		if (props.selectedCustomer) {
			setEntries(props.selectedCustomer.dataEntry);
		}
	}, [props.selectedCustomer]);

	return (
		<div className="entriesModal">
			<Button
				disabled={props.selectedCustomer && !props.selectedCustomer.customerName}
				type="primary"
				className="customerModalButton"
				onClick={() => props.setModal2VisibleFn(true)}
			>
				Entrie List
			</Button>
			<Modal
				title="Entrie List"
				className="custDetModal"
				style={{ top: 20, maxHeight: "90vh", overflow: "auto" }}
				visible={props.modal2Visible}
				onOk={() => props.setModal2VisibleFn(false)}
				onCancel={() => props.setModal2VisibleFn(false)}
			>
				<DataEntry data={entries} editDelete={false} />
			</Modal>
		</div>
	);
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EntrieModal);
