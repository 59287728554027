import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import blackLogo from "./../../../Images/Logo 2.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import { Spin, Space } from "antd";
import { dateFunction } from "../../../modules/actions/managerPanelActions";
import isoCertified from "./../../../Images/isoCeritifiedG.PNG";

function smallGemJewPrint(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#main").clientWidth;
		let height = document.querySelector("#main").clientHeight;
		html2canvas(document.querySelector("#main"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}

	useEffect(() => {
		QRCode.toDataURL("I am a pony!")
			.then((url) => {
				setQrUrl(url);
			})
			.catch((err) => {});

		// With async/await
		const generateQR = async (text) => {
			try {
			} catch (err) {}
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, []);

	function printElem() {
		var sTable = document.getElementById("main").innerHTML;
		var win = window.open();

		var style = `<style>
        body{
            -webkit-print-color-adjust:exact;
          }
     
         
          .certificaateDiv .report-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
       }
        .certificaateDiv .frontside, .certificaateDiv .backside {
            width: 337px !important;
            height: 212.31px !important;
            border-radius: 15px;
            margin: 0 auto;
            padding: 6px 6px 6px 7px;
            background-image: url(./../../Images/certificate-gradient.png);
       }
        .certificaateDiv .top {
            display: flex;
            align-items: center;
            border-bottom: 2px solid rgba(0, 0, 0, 1);
       }
        .certificaateDiv .top .logo {
            float: left;
            width: 20%;
            margin: 0px;
            margin-left: 10px;
            margin-right: -15px;
            height: 100%;
       }
        .certificaateDiv .top .logo img {
            width: 64%;
       }
        .certificaateDiv .top h1 {
            font-size: 17px;
            margin-top: 1px;
            font-family: "Bookman Old Style", "serif";
            margin-bottom: 1px;
       }
        .certificaateDiv .top h2 {
            font-family: Arial;
            font-size: 11px;
            font-style: italic;
            float: left;
            margin-top: -4px;
            font-weight: 900;
            margin-bottom: 0px;
       }
        .certificaateDiv .middle {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px;
            position: relative;
            background-image: url(./../../Images/background-transparent.png);
            background-size: 125px;
            background-repeat: no-repeat;
            background-position: center;
       }
        .certificaateDiv .middle .content ul {
            padding-left: 0px;
            margin: 0px;
       }
        .certificaateDiv .middle .content ul li {
            padding: 2.5px;
            list-style: none;
            font-family: arial, verdana, sans-serif;
            font-size: 8px;
            width: 8cm;
            line-height: 10.9px;
       }
        .certificaateDiv .middle .product-image {
            width: 2.5cm;
            height: 1.65cm;
            position: absolute;
            top: 4px;
            right: 5px;
            float: right;
            text-align: center;
            display: inline-block;
       }
        .certificaateDiv .middle .product-image .verticaltext {
            font-size: 6px;
            margin-top: -15px;
            left: 33px;
            transform: rotate(90deg);
            position: absolute;
            top: 40px;
            right: -67px;
       }
        .certificaateDiv .bottom {
            border-top: 2px solid rgba(0, 0, 0, 1);
            text-align: center;
            font-size: 9px;
            color: #d80018;
            font-weight: 700;
            margin: 0px 0px 8px;

       }
        .certificaateDiv span.last {
            text-align: center;
            font-size: 9px;
            color: #d80018;
            font-weight: 700;
       }
        .certificaateDiv .backside {
            font-family: arial, verdana, sans-serif;
            text-align: center;
       }
        .certificaateDiv .backside h4 {
            font-size: 10px;
            margin: 10px 0px 1px 0px !important;
       }
        .certificaateDiv .backside h2 {
            font-family: arial, verdana, sans-serif;
            text-align: center;
            color: #939393;
            font-size: 10px;
            margin: 10px 0px 13px 0px !important;
       }
        .certificaateDiv .backside p {
            font-family: arial, verdana, sans-serif;
            text-align: justify;
            padding: 3px;
            font-size: 6.1px;
            word-spacing: 2px;
            margin: 8px 0px 1px 0px !important;
            line-height: 8px;
       }
        .certificaateDiv .backside .line {
            font-family: arial, verdana, sans-serif;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 1);
            margin: 5px 0px 8px;

       }
        .certificaateDiv .backside .last {
            font-family: arial, verdana, sans-serif;
            text-align: center;
            word-spacing: normal;
            font-size: 6px;
            vertical-align: top;
            color: #939393;
       }
       
        
       </style>
        `;

		win.document.write("<html><head>");
		win.document.write("<title>Profile</title>");
		// win.document.write('<link href="./././print.css" rel="stylesheet">')
		win.document.write(style);
		win.document.write('<script src="JsBarcode.all.min.js"></script>');

		win.document.write("</head>");
		win.document.write("<body>");
		win.document.write('<div class="certificaateDiv" id="main">');
		win.document.write(sTable);
		win.document.write("</div>");
		// win.document.write(`</body><script>JsBarcode("#code128", ${this.state.printData.entryNo});

		// JsBarcode("#code128", ${this.state.printData.entryNo}, {
		//     format: "CODE128",
		//     // lineColor: "#0aa",
		//     // width:4,
		//     // height:40,
		//     displayValue: false
		// });</script></html>`);
		win.document.write(`</body></html>`);

		win.document.close();
		setTimeout(() => {
			win.print();
		}, 700);
	}
	const [PrintArr, setPrintArr] = useState([]);
	useEffect(() => {
		// var url = new URL(window.location);
		// let arr = url.searchParams.get("arr")
		// let arr2 = []
		// if (arr && JSON.parse(arr)) {
		//     arr2 = JSON.parse(arr)
		//     setPrintArr(arr2)
		// }
		setPrintArr(JSON.parse(localStorage.getItem("arr")));
		setTimeout(() => {
			// localStorage.removeItem('arr')
		}, 2000);
	}, []);

	return (
		<div style={{ overflow: "hidden" }}>
			{/* <div style={{
            display: capture ? "none" : "block"
        }} className="loader certificateLoader">
            <div className="innerLoader">
                <Space>
                    <Spin size="large" />
                </Space>
                <h2 className="loadingText">Generating Certificate</h2>
            </div>
        </div> */}
			<div
				className="cardMain"
				style={{
					display: capture ? "none" : "block",
					overflow: PrintArr.length <= 5 && "hidden"
				}}
			>
				{PrintArr && PrintArr.length > 0
					? PrintArr.map((a, i) => {
							return (
								<div
									className={(i + 1) % 5 === 0 ? "certificaateDiv pageBreak" : "certificaateDiv"}
									style={(i + 1) % 5 === 0 ? { pageBreakAfter: "always" } : null}
									id="main"
								>
									{/* // <div className="certificaateDiv"  id="main"> */}
									<div className="report-wrapper">
										<table>
											<tbody>
												<tr>
													<td
														style={{
															borderRight: "1px Solid black"
														}}
													>
														O
													</td>
													<td className="card CardSizeBorder">
														<div className="frontside">
															<div className="thick-border">
															<div className="top">
																<div className="logo">
																	<img src={blackLogo} width="64% !important" />
																</div>
																<div className="logoText">
																	<h1 className="BlackText">Galaxy Gemological Institute</h1>
																	<h2 className="BlackText">GEMSTONE JEWELLERY REPORT </h2>{" "}
																	<story className="date">
																		{dateFunction(
																			a.date
																				? a.date
																				: a.entry_date
																					? a.entry_date
																					: a.eptyp_temp,
																			a.entry_date
																		)}
																	</story>
																</div>
															</div>
																<div className="middle gemJewel">
																	<div className="content">
																		<ul>
																			<li>
																				<b className="BlackText ColHeadings">CERTIFICATE # : </b>
																				<span className="dynamic-data BlackText">
																					{a.entryNo
																						? a.entryNo
																						: a.certificate_number
																						? a.certificate_number
																						: a.number
																						? a.number
																						: null}
																				</span>
																			</li>
																			<li>
																				<b className="BlackText ColHeadings">WEIGHT : </b>
																				<span className="dynamic-data BlackText">
																					{a.weight ? a.weight : a.carat_weight} ct
																				</span>
																			</li>
																			<li>
																				<b className="BlackText ColHeadings">
																					MEASUREMENTS (MM) :{" "}
																				</b>
																				<span className="dynamic-data BlackText">
																					{a.measurements && a.measurements2 && a.measurements3
																						? `${a.measurements} x ${a.measurements2} x ${a.measurements3}`
																						: a.measurement_mm}{" "}
																				</span>
																			</li>
																			<li>
																				<b className="BlackText ColHeadings">SHAPE AND CUT : </b>
																				<span className="dynamic-data BlackText">
																					{a.shape && a.cut
																						? `${a.shape} & ${a.cut}`
																						: a.Shap_And_Cut}
																				</span>
																			</li>

																			<li>
																				<b className="BlackText ColHeadings">COLOR : </b>
																				<span className="dynamic-data BlackText">
																					{a.color ? a.color : a.color_gem}
																				</span>
																			</li>
																			<li>
																				<b className="BlackText ColHeadings">TRANSPARENCY : </b>
																				<span className="dynamic-data BlackText">
																					{a.transparency}
																				</span>
																			</li>
																			<li style={{width:"8cm"}}>
																				<b className="BlackText ColHeadings">DESCRIPTION : </b>
																				<span className="dynamic-data BlackText">
																					{a.description}
																				</span>
																			</li>
																			<li   style={{ width: "7cm", }}>
																				<b className="BlackText ColHeadings">COMMENTS : </b>
																				<span
																					className="dynamic-data BlackText"
																					style={{ fontSize: "11px", lineHeight: "9px" }}
																				>
																					{a.comment ? a.comment : a.comments_gem}
																				</span>
																			</li>
																		</ul>
																	</div>
																	<div className="product-image">
																		<img
																			src={`${process.env.REACT_APP_BACKEND_URL}media/${
																				a.fpath
																					? a.fpath
																					: a.product_pic
																					? a.product_pic
																					: a.product_pic2
																					? a.product_pic2
																					: a.product_pic3
																			} `}
																			width="100%"
																		/>
																		<div
																			className="verticaltext"
																			align="center"
																			style={{ fontSize: "7px", marginTop: "-9px", left: "51px" }}
																		>
																			(Approx. Digital Image)
																		</div>

																		<h1
																			align="center"
																			style={{
																				fontSize: "12px",
																				fontWeight: "900",
																				position: "relative",
																				color: "#000",
																				lineHeight: "12px"
																			}}
																		>
																			{a.species_gem
																				? `${a.species_gem} ${
																						a.variety_gem || a.variety ? "/" : ""
																				  }`
																				: `${a.species} ${a.variety_gem || a.variety ? "/" : ""}`}
																			{a.variety_gem ? a.variety_gem : a.variety}
																		</h1>
																	</div>
																	<div className="isoImage" style={{
																	right: "36px",
																	width: "45px",
																	position: "absolute",
																	bottom: "27px",
																	transform: "translateZ(-1px)",


																}}>
																	<img src={isoCertified} style={{ width: "100%" }} />
																</div>
																	<span className="certStatement">
																		Grades, weight & measurements are approximate as permitted by
																		mounting.
																	</span>
																</div>
																<div
																	className="bottomDiv"
																	style={{
																		position: "absolute",
																		width: "96%",
																		bottom: "1px"
																	}}
																>
																	<div className="bottom"></div>

																	<center>
																		{" "}
																		<span className="last" style={{ color: "#d80018" }}>
																			{" "}
																			WWW.GGILAB.COM
																		</span>
																	</center>
																</div>
															</div>
														</div>
													</td>
													<td
														style={{
															borderLeft: "1px Solid black",
															borderRight: "1px Solid black"
														}}
													>
														O&nbsp;&nbsp;&nbsp;&nbsp;O
													</td>

													<td className="card CardSizeBorder">
														<div className="backside">
															<h4 className="BlackText ColHeadings">
																For online verification of this certificate, please visit <br />
															</h4>
															<span
																style={{ color: "#d80018", fontSize: "12px", marginTop: "10px" }}
															>
																<strong> www.ggilab.com</strong>
															</span>
															<h2>TERMS &amp; CONDITIONS</h2>
															<p className="BlackText">
																This report is provided by Galaxy Gemological Institute (GGI) a
																grading and appraisal organization, on the request of the purchaser
																subject to the following terms and conditions to which the purchaser
																agrees: This report does not constitute a guarantee for or an
																apprisal of the object or gemstone described there in. The value(s)
																expressed is based on the appraiser's best judgment and opinion and
																on current information on the data indicated. Valuation information
																or grading contained in this report is based on the application of
																the testing equipment and system used at the time the article is
																examined. GGI and its employees and/or agents shall not be liable
																for any loss, damage, or expense for any error in or omission from,
																or for the issuance or use of this report, even if caused by or
																resulting from the negligence or other fault(except fraud, willlful
																misconduct or gross negligence) of GGI and its employees and or
																agents. In no event shall GGIs or its employees and or agents
																liability for any damages ever exceed the amount paid to GGI to
																prepare this report, regardless of the form of action whether in
																contract, negligence, strict liability, tort, products liability or
																otherwise. This report is considered void, if any alteration(s)
																is/are made to the article(s) and/or its report itself. See website
																www.ggilab.com for complete Terms &amp; Conditions.{" "}
															</p>
															<div className="line"></div>
															<span className="last">
																{props.permission.branchCode === "PEW"
																	? `2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar
												    Tel:  (91) 256-8456`
																	: `Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-e-Iraq, Saddar, Karachi. Tel:+92-21-35216044`}
															</span>
														</div>
													</td>
													<td style={{ borderLeft: "1px Solid black" }}>O</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							);
					  })
					: null}
			</div>

			<div id="div2"></div>

			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</div>
	);
}

const mapStateToProps = ( store ) => ({
	permission: store.users.permission,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(smallGemJewPrint));
