import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Modal,
	Input,
	Button,
	Col,
	Row,
	Table,
	Space,
	Select,
	Popconfirm,
	message,
	Pagination
} from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import AccountModal from "./accountModal";
import {
	getExpense,
	addExpense,
	editExpense,
	deleteExpense,
	addExpenseSubHead,
	updateExpense,
	deleteExpenseSingle,
	getRecieveable,
	addrecievables,
	deleteRecievable,
	editRecievables
} from "../../../modules/actions/AccountActions";
import ItemReports from "../../reports/ItemReport/ItemReports";
import logo from "./../../../Images/Logo 2.png";

const { Option } = Select;

const { Column, ColumnGroup } = Table;

function AdminAccount(props) {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(1);
	const [data, setData] = useState();
	const [item, setItem] = useState([]);
	const [category, setCategory] = useState("Expense");
	const [mainCategory, setMainCategory] = useState("Laboratory");
	const [name, setName] = useState("");
	const [privateEntry, setPrivateEntry] = useState("false");

	const [subHeadArr, setSubHeadArr] = useState([]);
	const [subHeadName, setSubHeadName] = useState("");
	const [id, setId] = useState("");
	const [edit, setEdit] = useState(false);
	const [mainCategorySub, setMainCategorySub] = useState("");

	useEffect(() => {
		if (props.permission.branchCode) {
			if (category === 'Expense') {
				props.getExpense(page, props.permission.branchCode);
			} else if (category === 'Recievable') {
				props.getRecieveable(page, props.permission.branchCode)
			}
		}
	}, [page, category]);

	useEffect(() => {
		setData(props.expenses.results);
		setPage(props.expenses.page);
		setTotal(props.expenses.total);
	}, [props.expenses]);

	function confirm(e, id, obj) {
		message.success("Deleted");
		expenseDelete(id, obj);
	}

	function cancel(e) {
		message.error("Click on No");
	}

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addExpense = (editobj) => {
		if (!editobj) {
			let obj = {
				category: category,
				name: name,
				private: privateEntry,
				mainCategory: mainCategory,
				branchCode: props.permission.branchCode,
				expenseSubHead: []
			};
			props.addExpense(obj, props.permission.branchCode);
		} else {
			props.editExpense(editobj, props.permission.branchCode);
		}
	};

	const addReceiveables = (editobj) => {
		if (!editobj) {
			let obj = {
				category: category,
				name: name,
				private: privateEntry,
				mainCategory: mainCategory,
				branchCode: props.permission.branchCode,
				expenseSubHead: []
			};

			props.addrecievables(obj, props.permission.branchCode, page, clearStates);
		} else {
			props.editRecievables(editobj, props.permission.branchCode,page);
		}
	};


	const deleteExpensefn = (id) => {
		props.deleteExpenseSingle(id)
	}

	const deleteReceiveablefn = (id) => {
		props.deleteRecievable(id, page)
	}

	function addSubHead() {
		if (!edit) {
			let obj = {
				name: subHeadName,
				mainExpenses: item.id
			};
			props.addExpenseSubHead(obj, props.permission.branchCode);
			setItem({ ...item, expenseSubHead: [...item.expenseSubHead, obj] });
			handleCancel();
			clearStates();
		} else if (edit) {
			let obj = {
				id: id,
				name: subHeadName,
				mainExpenses: mainCategorySub
			};
			props.updateExpense(obj, id, props.permission.branchCode);
			setItem({ ...item, expenseSubHead: [...item.expenseSubHead, obj] });
			handleCancel();
			clearStates();
		}
	}

	function editStates(id, name, main) {
		setEdit(true);
		setSubHeadName(name);
		setId(id);
		setMainCategorySub(main);
	}

	const clearStates = () => {
		setEdit(false);
		setSubHeadName("");
		setId("");
		setMainCategorySub("");
		setName("")
	};

	const editExpense = (data) => {
		setPrivateEntry(privateEntry === "true" ? "false" : "true");
		let obj = {
			...data,
			private: data.private === "true" ? "false" : "true"
		};
		addExpense(obj);
	};
	const editRecievablesFn = (data) => {
		setPrivateEntry(privateEntry === "true" ? "false" : "true");
		let obj = {
			...data,
			private: data.private === "true" ? "false" : "true"
		};
		addReceiveables(obj);
	};
	function expenseDelete(id, obj) {
		props.deleteExpense(id, props.permission.branchCode);
		let arr = [...item.expenseSubHead];
		arr.splice(arr.indexOf(obj), 1);
		setItem([]);
		setItem({ ...item, expenseSubHead: arr });
	}
	return (
		<div classNam="adminAccount">
			<div className="innerAdminAccount">
				<Row className="spaceBetween">
					{props.userPermissions.admin_panel_add_expense_name_add && (
						<Col xs={8} sm={8} md={8} lg={8} xl={8}>
							<div className="AccountForm">
								<div className="inputFields">
									<label>
										<h5>Laboratory</h5>
										<Select
											className="w100P"
											defaultValue="Laboratory"
											onChange={(e) => setMainCategory(e)}
										>
											<Option value="Laboratory">Laboratory</Option>
											<Option value="Education">Education</Option>
										</Select>
									</label>
								</div>
								<div className="inputFields">
									<label>
										<h5>Expense</h5>
										<Select
											className="w100P"
											defaultValue="Expense"
											onChange={(e) => setCategory(e)}
										>
											<Option value="Expense">Expense</Option>
											<Option value="Recievable">Recievable</Option>
										</Select>
									</label>
								</div>
								<div className="inputFields">
									<label>
										<h5>{category} Name</h5>
										<Input onChange={(ev) => setName(ev.target.value)} />
									</label>
								</div>
							</div>
							{category === 'Expense' ?
								<Button
									type="primary"
									className="addAccBtn m-t-10"
									onClick={() => addExpense()}
									disabled={name ? false : true}
								>
									ADD EXPENSE
								</Button>
								:
								<Button
									type="primary"
									className="addAccBtn m-t-10"
									onClick={() => addReceiveables()}
									disabled={name ? false : true}
								>
									ADD RECIEVABLE
								</Button>}
						</Col>
					)}

					<Col xs={15} sm={15} md={15} lg={15} xl={15}>
						<div className="AccountTable tableDiv">
							{props.accountLoading ? (
								<div className="loaderDiv tableLoader">
									<div>
										<img src={logo} />
									</div>
								</div>
							) : null}
							<Table
								className="itemsTable"
								dataSource={data}
								pagination={{
									onChange(current) {
										setPage(current);
									}
								}}
							>
								<Column title="S.No" key="index" render={(value, item, index) => index + 1} />
								<Column
									title="Expense Name"
									dataIndex="name"
									key="name"
									render={(value, item, index) => {
										return (
											<div
												onClick={() => {
													showModal();
													setItem(item);
												}}
											>
												{item.name}
											</div>
										);
									}}
								/>
								<Column
									render={(record) => {
										return (
											<div style={{ display: 'flex', }}>
												<div onClick={() => {
													if (category === 'Expense') {
														editExpense(record)
													}else if (category === 'Recievable') {
														editRecievablesFn(record)
													}
													}
												} style={{ cursor: "pointer" }}>
													{record.private === "true" ? (
														<LockOutlined className="lockIcon" />
													) : (
														<UnlockOutlined className="lockIcon" />
													)}
												</div>
												<Popconfirm
													onConfirm={() => {
														if (category === 'Expense') {
															deleteExpensefn(record.id)
														} else if (category === 'Recievable') {
															deleteReceiveablefn(record.id)
														}
													}}
													title="Are you sure you want to delete this item?"
													okText="Yes"
													cancelText="No"
												>
													<a>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="rgb(245, 0, 87)"
															className="bi bi-trash-fill"
															viewBox="0 0 16 16"
														>
															<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
														</svg>
													</a>
												</Popconfirm>
											</div>
										);
									}}
								/>
							</Table>
						</div>
					</Col>
				</Row>
				<div style={{ float: "right", marginTop: 20 }}>
					<Pagination onChange={(ev) => setPage(ev)} current={page} total={total} />
				</div>
				<AccountModal
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					showModal={showModal}
					handleOk={handleOk}
					handleCancel={handleCancel}
					item={item}
					expenseDelete={expenseDelete}
					addSubHead={addSubHead}
					subHeadArr={subHeadArr}
					setSubHeadArr={setSubHeadArr}
					subHeadName={subHeadName}
					setSubHeadName={setSubHeadName}
					id={id}
					setId={setId}
					edit={edit}
					setEdit={setEdit}
					mainCategory={mainCategory}
					setMainCategory={setMainCategory}
					editStates={editStates}
					clearStates={clearStates}
					confirm={confirm}
					cancel={cancel}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		expenses: store.accountStore.expenses,
		accountLoading: store.accountStore.accountLoading,
		permission: store.users.permission
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getExpense,
			addExpense,
			editExpense,
			deleteExpense,
			addExpenseSubHead,
			updateExpense,
			deleteExpenseSingle,
			getRecieveable,
			addrecievables,
			deleteRecievable,
			editRecievables
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccount);
