import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';

// dataEntry loader true
export const ALL_FIELDS_LOADER = 'ALL_FIELDS_LOADER';
export const allFieldsLoader = (bool) => {
    return {
        type: ALL_FIELDS_LOADER,
        payload: bool
    }
}

// save dataEntry
export function getAllFields() {
    return dispatch => {
        dispatch(allFieldsLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}GetAllListValues/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(allFieldsLoader(false))
            dispatch(allFieldsData(res.data.data, 'getAllFields'))
        }).catch((err) => {
            dispatch(allFieldsLoader(false))
        });
    };
}
export const ALL_FIELDS_DATA = 'ALL_FIELDS_DATA';
export const allFieldsData = (obj) => ({
    type: ALL_FIELDS_DATA,
    payload: { ...obj }
});
