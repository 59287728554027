import { Modal, Button } from "antd";
import React from "react";
import { connect } from "react-redux";

export const ConsultantDetails = ({
	isModalVisible,
	setIsModalVisible,
	showModal,
	handleOk,
	handleCancel,
	consultantObj
}) => {
	// console.log(consultantObj, "MODEL MODEL MODEL");

	function printElm(id) {
		var sTable = document.getElementById(id).innerHTML;
		var win = window.open();
		var style = `<style>
        body {
            margin: 0;
        }
        #main {
            width: 115mm;
            float: right;
        }
        .h3_heading {
            text-align: center;
            text-indent: 55px;
            margin: 0;
        }
        .first {
            display: flex;
            padding: 10px;
        }
        .first table {
            margin-left: 30px;
        }
        .firstTable {
            width: 100%;
        }
        .first th {
            text-align: left;
            font-size: 12px;
            font-weight: bolder;
            padding: 5px;
        }
        .first td {
            font-size: 12px;
        }
        .table2 {
            width: 73%;
        }
        .boldFontIncrease {
            font-size: 15px;
            font-weight: bold;
        }
          
        </style>`;
		win.document.write("<html><head>");
		win.document.write("<title>Profile</title>");
		// win.document.write('<link href="./././print.css" rel="stylesheet">')
		win.document.write(style);
		win.document.write('<script src="JsBarcode.all.min.js"></script>');

		win.document.write("</head>");
		win.document.write("<body>");
		win.document.write(`<div id="main">
        <h3 class="h3_heading">
        Gemstone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Consultation</h3><div class="first">
        <table class="firstTable">
        <tbody><tr><th>
        Ref No: 
        </th>
       ${consultantObj.entryNo
				? `<td>
        ${consultantObj.entryNo}
        </td>`
				: ``
			}
        </tr><tr><th>
        Date: 
        </th>
       ${new Date().toDateString()
				? `<td>
            ${new Date().toDateString()}
        </td>
        `
				: ``
			}
        </tr>
        ${consultantObj.weight
				? `
        <tr><th>
        Weight: 
        </th>
                <td>
        ${consultantObj.weight}${consultantObj.weightDes}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.color
				? `
        <tr><th>
        Color: 
        </th>
                <td>
        ${consultantObj.color}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.meas1 && consultantObj.meas2 && consultantObj.meas3
				? `
        <tr><th>
        Meas.(mm): 
        </th>
                <td>
        ${consultantObj.meas1}&nbsp;x&nbsp;${consultantObj.meas2}&nbsp;x&nbsp;${consultantObj.meas3}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.shape
				? `
        <tr><th>
        Shape: 
        </th>
                <td>
        ${consultantObj.shape}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.cut
				? `
        <tr><th>
        Cut: 
        </th>
                <td>
        ${consultantObj.cut}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.treatement
				? `
        <tr><th>
        Treatmemt: 
        </th>
                <td>
        ${consultantObj.treatement}
        </td>
        </tr>
        `
				: ``
			}
        </tbody>
        </table><table class="table2">
        <tbody>
        ${consultantObj.variety
				? `
        <tr><th>
        Variety:
        </th>
                <td class="boldFontIncrease">
        ${consultantObj.variety}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.species
				? `
        <tr><th>
        Species:
        </th>
                <td class="boldFontIncrease">
        ${consultantObj.species}
        </td>
        </tr>
        `
				: ``
			}
                ${consultantObj.conclusion
				? `
        <tr><th>
        Conclusion: 
        </th>
                <td>
        ${consultantObj.conclusion}
        </td>
        </tr>
        `
				: ``
			}
        </tbody>
        </table>
        </div>
        </div>`);
		win.document.write(`</body></html>`);

		win.document.close();
		setTimeout(() => {
			win.print();
		}, 700);
	}

	return (
		<Modal
			title="Basic Modal"
			visible={isModalVisible}
			// onOk={handleOk}
			onCancel={handleCancel}
			okText="Print"
			onOk={() => { printElm("consultantPrint"); handleOk() }}
		>
			<div className="ConsultantDetailsModal" id="consultantPrint">
				<div className="consultationHeading">
					<h3>Gemstone</h3>
					<h3>Consultation</h3>
				</div>
				<table>
					<tbody>
						<tr>
							<th>Ref No:</th>
							<td>{consultantObj.entryNo}</td>
							{consultantObj.variety ? (
								<>
									<th>Variety:</th>
									<td>{consultantObj.variety}</td>
								</>
							) : null}
						</tr>
						<tr>
							<th>Date:</th>
							<td>{new Date().toDateString()}</td>
							{consultantObj.species ? (
								<>
									<th>Species:</th>
									<td>{consultantObj.species}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.weight ? (
								<>
									<th>Weight:</th>
									<td>
										{consultantObj.weight}
										{consultantObj.weightDes}
									</td>
								</>
							) : null}
							{consultantObj.conclusion ? (
								<>
									<th>Conclusion:</th>
									<td>{consultantObj.conclusion}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.color ? (
								<>
									<th>Color:</th>
									<td>{consultantObj.color}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.meas1 && consultantObj.meas2 && consultantObj.meas3 ? (
								<>
									<th>Meas.(mm):</th>
									<td>{`${consultantObj.meas1} x ${consultantObj.meas2} x ${consultantObj.meas3}`}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.shape ? (
								<>
									<th>Shape:</th>
									<td>{consultantObj.shape}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.cut ? (
								<>
									<th>Cut:</th>
									<td>{consultantObj.cut}</td>
								</>
							) : null}
						</tr>
						<tr>
							{consultantObj.treatement ? (
								<>
									<th>Treatmemt:</th>
									<td>{consultantObj.treatement}</td>
								</>
							) : null}
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantDetails);
