import { message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userLogin } from "../../modules/actions/authenticationActions";
import "./login.scss";

function LoginPageLayout(props) {
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");

	const login = () => {
		let obj = {
			username: userEmail,
			password: userPassword
		};
		props.userLogin(obj, pushToNext);
	};

	const pushToNext = () => {
		props.history.push("/dashboard");
	};

	// window.addEventListener("keyup", (e)=>{
	//     if(e.keyCode === 13){
	//         login()
	//     }
	// })
	// useEffect(() => {
	// }, [])

	return (
		<div className="limiter">
			<div className="container-login100">
				<div className="wrap-login100">
					<span className="login100-form-title p-b-10">Galaxy Gemological</span>
					<span className="login100-form-title p-b-48">
						<img className="logo" src={require("./images/Logo.png")} />
					</span>

					<div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (userEmail && userPassword) {
										login();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setUserEmail(e.target.value)}
							className="input100"
							type="text"
							name="email"
						/>
						<span className="focus-input100" data-placeholder="Email"></span>
					</div>

					<div className="wrap-input100 validate-input" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (userEmail && userPassword) {
										login();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setUserPassword(e.target.value)}
							className="input100"
							type="password"
							name="pass"
						/>
						<span className="focus-input100" data-placeholder="Password"></span>
					</div>

					<div className="container-login100-form-btn">
						<div className="wrap-login100-form-btn">
							<div className="login100-form-bgbtn"></div>
							<button
								onClick={login}
								className="login100-form-btn"
								disabled={props.authenticationLoading}
							>
								{props.authenticationLoading ? <div className="loading1"></div> : "Login"}
							</button>
						</div>
						<p>
							Don't have Account? <Link to="/SignUp"> Sign Up </Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		isAuthenticated: store.authentication.isAuthenticated,
		authenticationLoading: store.authentication.authenticationLoading
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			userLogin
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageLayout);
