import Axios from 'axios'
import { GET_ALL_CUTOMERS, CUSTOMER_REPORTS_LOADING } from '../actions/customerReportActions';
import { } from '../actions/reportsAction';

const initialState = {
    customerReportsLoading: false,
    customers: [],
    page: 1,
    total: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_REPORTS_LOADING:
            return {
                ...state,
                customerReportsLoading: action.payload,
            };
        case GET_ALL_CUTOMERS:
            return {
                ...state,
                customerReportsLoading: false,
                customers: action.payload.data,
                page: action.payload.page,
                total: action.payload.total,
            };
        default:
            return state
    }
}

