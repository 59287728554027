import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';

// dataEntry loader true
export const COLOR_FORM_DATA = 'COLOR_FORM_DATA';
export const formColorDataLoader = (bool) => {
    return {
        type: COLOR_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getColorFormData() {
    return dispatch => {
        dispatch(formColorDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllColors/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formColorDataLoader(false))
            console.log(res.data,'dsadasd')
            dispatch(allColorFormData(res.data, 'getColorFormData'))
        }).catch((err) => {
            dispatch(formColorDataLoader(false))
        });
    };
}
export const ALL_COLOR_FIELDS_DATA = 'ALL_COLOR_FIELDS_DATA';
export const allColorFormData = (obj) => ({
    type: ALL_COLOR_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const COLOR_FORM_DATA_ADD = 'COLOR_FORM_DATA';
export const formColorDataAddLoader = (bool) => {
    return {
        type: COLOR_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getColorFormDataAdd(data) {
    return dispatch => {
        dispatch(formColorDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllColors/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formColorDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formColorDataAddLoader(false))
        });
    };
}
export const ALL_COLOR_FIELDS_DATA_ADD = 'ALL_COLOR_FIELDS_DATA_ADD';
export const allColorFormDataAdd = (obj) => ({
    type: ALL_COLOR_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const CUT_FORM_DATA = 'CUT_FORM_DATA';
export const formCutDataLoader = (bool) => {
    return {
        type: CUT_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getCutFormData() {
    return dispatch => {
        dispatch(formCutDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllCuts/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formCutDataLoader(false))
            dispatch(allCutFormData(res.data, 'getCutFormData'))
        }).catch((err) => {
            dispatch(formCutDataLoader(false))
        });
    };
}
export const ALL_CUT_FIELDS_DATA = 'ALL_CUT_FIELDS_DATA';
export const allCutFormData = (obj) => ({
    type: ALL_CUT_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const CUT_FORM_DATA_ADD = 'CUT_FORM_DATA';
export const formCutDataAddLoader = (bool) => {
    return {
        type: CUT_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getCutFormDataAdd(data) {
    return dispatch => {
        dispatch(formCutDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllCuts/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formCutDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formCutDataAddLoader(false))
        });
    };
}
export const ALL_CUT_FIELDS_DATA_ADD = 'ALL_CUT_FIELDS_DATA_ADD';
export const allCutFormDataAdd = (obj) => ({
    type: ALL_CUT_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const SPECIES_FORM_DATA = 'SPECIES_FORM_DATA';
export const formSpeciesDataLoader = (bool) => {
    return {
        type: SPECIES_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getSpeciesFormData() {
    return dispatch => {
        dispatch(formSpeciesDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllSpecies/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formSpeciesDataLoader(false)) 
            dispatch(allSpeciesFormData(res.data, 'getSpeciesFormData'))
        }).catch((err) => {
            dispatch(formSpeciesDataLoader(false))
        });
    };
}
export const ALL_Species_FIELDS_DATA = 'ALL_Species_FIELDS_DATA';
export const allSpeciesFormData = (obj) => ({
    type: ALL_Species_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const SPECIES_FORM_DATA_ADD = 'SPECIES_FORM_DATA';
export const formSpeciesDataAddLoader = (bool) => {
    return {
        type: SPECIES_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getSpeciesFormDataAdd(data) {
    return dispatch => {
        dispatch(formSpeciesDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllSpecies/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formSpeciesDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formSpeciesDataAddLoader(false))
        });
    };
}
export const ALL_Species_FIELDS_DATA_ADD = 'ALL_Species_FIELDS_DATA_ADD';
export const allSpeciesFormDataAdd = (obj) => ({
    type: ALL_Species_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const CUTTING_STYLE_FORM_DATA = 'CUTTING_STYLE_FORM_DATA';
export const formCuttingStyleDataLoader = (bool) => {
    return {
        type: CUTTING_STYLE_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getCuttingStyleFormData() {
    return dispatch => {
        dispatch(formCuttingStyleDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllCutting_style/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formCuttingStyleDataLoader(false))
            dispatch(allCuttingStyleFormData(res.data, 'getCuttingStyleFormData'))
        }).catch((err) => {
            dispatch(formCuttingStyleDataLoader(false))
        });
    };
}
export const ALL_CUTTING_STYLE_FIELDS_DATA = 'ALL_CUTTING_STYLE_FIELDS_DATA';
export const allCuttingStyleFormData = (obj) => ({
    type: ALL_CUTTING_STYLE_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const CUTTING_STYLE_FORM_DATA_ADD = 'CUTTING_STYLE_FORM_DATA';
export const formCuttingStyleDataLAddoader = (bool) => {
    return {
        type: CUTTING_STYLE_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getCuttingStyleFormDataAdd(data) {
    return dispatch => {
        dispatch(formCuttingStyleDataLAddoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllCutting_style/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formCuttingStyleDataLAddoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formCuttingStyleDataLAddoader(false))
        });
    };
}
export const ALL_CUTTING_STYLE_FIELDS_DATA_ADD = 'ALL_CUTTING_STYLE_FIELDS_DATA_ADD';
export const allCuttingStyleFormDataAdd = (obj) => ({
    type: ALL_CUTTING_STYLE_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const ORIGIN_GEM_FORM_DATA = 'ORIGIN_GEM_FORM_DATA';
export const formOrigionGemDataLoader = (bool) => {
    return {
        type: ORIGIN_GEM_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getOrigionGemFormData() {
    return dispatch => {
        dispatch(formOrigionGemDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllOrigion_gem/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formOrigionGemDataLoader(false))
            dispatch(allOrigionGemFormData(res.data, 'getOrigionGemFormData'))
        }).catch((err) => {
            dispatch(formOrigionGemDataLoader(false))
        });
    };
}
export const ALL_ORIGIN_GEM_FIELDS_DATA = 'ALL_ORIGIN_GEM_FIELDS_DATA';
export const allOrigionGemFormData = (obj) => ({
    type: ALL_ORIGIN_GEM_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const ORIGIN_GEM_FORM_DATA_ADD = 'ORIGIN_GEM_FORM_DATA';
export const formOrigionGemDataAddLoader = (bool) => {
    return {
        type: ORIGIN_GEM_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getOrigionGemFormDataAdd(data) {
    return dispatch => {
        dispatch(formOrigionGemDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllOrigion_gem/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formOrigionGemDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formOrigionGemDataAddLoader(false))
        });
    };
}
export const ALL_ORIGIN_GEM_FIELDS_DATA_ADD = 'ALL_ORIGIN_GEM_FIELDS_DATA_ADD';
export const allOrigionGemFormDataAdd = (obj) => ({
    type: ALL_ORIGIN_GEM_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const GRIDLES_FORM_DATA = 'GRIDLES_FORM_DATA';
export const formGridlesDataLoader = (bool) => {
    return {
        type: GRIDLES_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getGridlesFormData() {
    return dispatch => {
        dispatch(formGridlesDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllGridle3s/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formGridlesDataLoader(false))
            dispatch(allGridlesFormData(res.data, 'getGridlesFormData'))
        }).catch((err) => {
            dispatch(formGridlesDataLoader(false))
        });
    };
}
export const ALL_Gridles_FIELDS_DATA = 'ALL_Gridles_FIELDS_DATA';
export const allGridlesFormData = (obj) => ({
    type: ALL_Gridles_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const GRIDLES_FORM_DATA_ADD = 'GRIDLES_FORM_DATA';
export const formGridlesDataAddLoader = (bool) => {
    return {
        type: GRIDLES_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getGridlesFormDataAdd(data) {
    return dispatch => {
        dispatch(formGridlesDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllGridle3s/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formGridlesDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formGridlesDataAddLoader(false))
        });
    };
}
export const ALL_Gridles_FIELDS_DATA_ADD = 'ALL_Gridles_FIELDS_DATA_ADD';
export const allGridlesFormDataAdd = (obj) => ({
    type: ALL_Gridles_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const REMART_FORM_DATA = 'REMART_FORM_DATA';
export const formRemarksDataLoader = (bool) => {
    return {
        type: REMART_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getRemarksFormData() {
    return dispatch => {
        dispatch(formRemarksDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllRemarks/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formRemarksDataLoader(false))
            dispatch(allRemarksFormData(res.data, 'getRemarksFormData'))
        }).catch((err) => {
            dispatch(formRemarksDataLoader(false))
        });
    };
}
export const ALL_REMART_FORM_FIELDS_DATA = 'ALL_REMART_FORM_FIELDS_DATA';
export const allRemarksFormData = (obj) => ({
    type: ALL_REMART_FORM_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const REMART_FORM_DATA_ADD = 'REMART_FORM_DATA';
export const formRemarksDatAddaLoader = (bool) => {
    return {
        type: REMART_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getRemarksFormDatAdda(data) {
    return dispatch => {
        dispatch(formRemarksDatAddaLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllRemarks/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formRemarksDatAddaLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formRemarksDatAddaLoader(false))
        });
    };
}
export const ALL_REMART_FORM_FIELDS_ADD_DATA = 'ALL_REMART_FORM_FIELDS_DATA_ADD';
export const allRemarksFormDataAdd = (obj) => ({
    type: ALL_REMART_FORM_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const VARIETY_FORM_DATA = 'VARIETY_FORM_DATA';
export const formVarietysDataLoader = (bool) => {
    return {
        type: VARIETY_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getVarietysFormData() {
    return dispatch => {
        dispatch(formVarietysDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllVarietys/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formVarietysDataLoader(false))
            dispatch(allVarietysFormData(res.data, 'getVarietysFormData'))
        }).catch((err) => {
            dispatch(formVarietysDataLoader(false))
        });
    };
}
export const ALL_VARIETY_FIELDS_DATA = 'ALL_VARIETY_FIELDS_DATA';
export const allVarietysFormData = (obj) => ({
    type: ALL_VARIETY_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const VARIETY_FORM_DATA_ADD = 'VARIETY_FORM_DATA';
export const formVarietysDatAddaLoader = (bool) => {
    return {
        type: VARIETY_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getVarietysFormDatAdda(data) {
    return dispatch => {
        dispatch(formVarietysDatAddaLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllVarietys/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formVarietysDatAddaLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formVarietysDatAddaLoader(false))
        });
    };
}
export const ALL_VARIETY_FIELDS_DATA_ADD = 'ALL_VARIETY_FIELDS_DATA_ADD';
export const allVarietysFormDataAdd = (obj) => ({
    type: ALL_VARIETY_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const SHAPE_FORM_DATA = 'SHAPE_FORM_DATA';
export const formShapesDataLoader = (bool) => {
    return {
        type: SHAPE_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getShapesFormData() {
    return dispatch => {
        dispatch(formShapesDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllShapes/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formShapesDataLoader(false))
            dispatch(allShapesFormData(res.data, 'getShapesFormData'))
        }).catch((err) => {
            dispatch(formShapesDataLoader(false))
        });
    };
}
export const ALL_SHAPE_FIELDS_DATA = 'ALL_SHAPE_FIELDS_DATA';
export const allShapesFormData = (obj) => ({
    type: ALL_SHAPE_FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const SHAPE_FORM_DATA_ADD = 'SHAPE_FORM_DATA';
export const formShapesDatAddaLoader = (bool) => {
    return {
        type: SHAPE_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getShapesFormDatAdda(data) {
    return dispatch => {
        dispatch(formShapesDatAddaLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllShapes/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formShapesDatAddaLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formShapesDatAddaLoader(false))
        });
    };
}
export const ALL_SHAPE_FIELDS_DATA_ADD = 'ALL_SHAPE_FIELDS_DATA_ADD';
export const allShapesFormDataAdd = (obj) => ({
    type: ALL_SHAPE_FIELDS_DATA,
    payload: obj
});


// dataEntry loader true
export const OBJECTS_FORM_DATA = 'OBJECTS_FORM_DATA';
export const formObjectsDataLoader = (bool) => {
    return {
        type: OBJECTS_FORM_DATA,
        payload: bool
    }
}

// save dataEntry
export function getObjectsFormData() {
    return dispatch => {
        dispatch(formObjectsDataLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}AllObjects/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(formObjectsDataLoader(false))
            dispatch(allObjectsFormData(res.data, 'getObjectsFormData'))
        }).catch((err) => {
            dispatch(formObjectsDataLoader(false))
        });
    };
}
export const ALL_OBJECTS__FIELDS_DATA = 'ALL_OBJECTS__FIELDS_DATA';
export const allObjectsFormData = (obj) => ({
    type: ALL_OBJECTS__FIELDS_DATA,
    payload: obj
});

// dataEntry loader true
export const OBJECTS_FORM_DATA_ADD = 'OBJECTS_FORM_DATA';
export const formObjectsDataAddLoader = (bool) => {
    return {
        type: OBJECTS_FORM_DATA_ADD,
        payload: bool
    }
}

// post dataEntry
export function getObjectsFormDataAdd(data) {
    return dispatch => {
        dispatch(formObjectsDataAddLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}AllObjects/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data:data
        }).then((res) => {
            dispatch(formObjectsDataAddLoader(false))
            console.log(res,'formField')
        }).catch((err) => {
            dispatch(formObjectsDataAddLoader(false))
        });
    };
}
export const ALL_OBJECTS__FIELDS_DAT_ADDA = 'ALL_OBJECTS__FIELDS_DATA_ADD';
export const allObjectsFormDataAdd = (obj) => ({
    type: ALL_OBJECTS__FIELDS_DATA,
    payload: obj
});




// getColorFormDataAdd
// getCutFormDataAdd
// getSpeciesFormDataAdd
// getCuttingStyleFormDataAdd
// getOrigionGemFormDataAdd
// getGridlesFormDataAdd
// getRemarksFormDatAdda
// getVarietysFormDatAdda
// getShapesFormDatAdda
// getObjectsFormDataAdd