import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userPermissions from "../../UserList/userPermissions";

function ReportPanelLayout(props) {
	const [arr, setArr] = useState([
		{ name: "Customer List", route: "/customer-list", permission: 'customer_view' },
		{ name: "Item List", route: "/item-list", permission: 'reports' },
		{ name: "Summary Report", route: "/summary-report", permission: 'summary_report_view' },
		{ name: "User Report", route: "/user-report", permission: 'user_report_view' },
		{ name: "Add Account", route: "/account-report", permission: 'account_report_view' }
	]);


	const [whichTrue, setWhichTrue] = useState("");

	useEffect(() => {
		console.log(props.userPermissions, 'userPermissions')
	}, [props.userPermissions])

	return (
		<div className="dashboardMain limiter ">
			{arr.map((a, i) => {
				return a.route === '/account-report' && props.userPermissions.userType != 'Admin' ? '' :  (
					<div key={i} className="div">
						<div className="wrap-login100-form-btn full">
							<div className="login100-form-bgbtn"></div>
							<button onClick={() => props.history.push(a.route)} className="login100-form-btn">
								{a.name}
							</button>
						</div>
					</div>

				) 
					
			})}
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportPanelLayout);
