import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Space, Pagination, Popconfirm } from "antd";
import {
	EditOutlined,
	DeleteOutlined,
	CheckOutlined,
	CloseOutlined,
	PrinterFilled,
	CopyFilled
} from "@ant-design/icons";
import { NavLink, withRouter } from "react-router-dom";
import { ModalReport } from "../ModalReport";
import { dateFormat } from "../../../../../common/common";
import {
	deleteGeneratedEntry,
	getServicesEntries
} from "../../../../../modules/actions/reportsAction";
import { bindActionCreators } from "redux";
import CopyToClipboard from "react-copy-to-clipboard";
import logo from "./../../../../../Images/Logo 2.png";

const { Column } = Table;

const ServiceReport = (props) => {
	const [page, setPage] = useState(1);
	const [data, setData] = useState({});
	const [pageSize, setPageSize] = useState(10);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const confirmDeleteItem = (obj) => {
		let startDate = dateFormat(new Date(props.startDate));
		let endDate = dateFormat(new Date(props.endDate));
		props.deleteGeneratedEntry(
			obj.id,
			page,
			"Service",
			props.selectedBranch,
			pageSize,
			startDate,
			endDate,
			"",
			pageSize
		);
	};

	useEffect(() => {
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));
			
			props.getServicesEntries(
				page,
				props.selectedReports,
				startDate,
				endDate,
				props.selectedService,
				props.selectedBranch,
				pageSize
			);
		}
	}, [
		props.selectedReports,
		props.searchBool,
		// props.startDate,
		// props.endDate,
		page,
		// props.selectedService,
		pageSize,
		// props.selectedBranch
	]);

	return (
		<div className="ServiceReport">
			<div className="tableDiv">
				{props.reportsLoading ? (
					<div className="loaderDiv tableLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : null}
				<Table className="itemsTable" dataSource={props.serviceEntries} footer={() => {
					return (
						<Table className="itemsTable footertable" dataSource={[props.footer]}>
                                        <Column title="No. Data Entry" render={(text, record) => {
                                            return (
                                                <div>
                                                    {props.total}
                                                </div>
                                            )
                                        }} />
                                        {/* <Column dataIndex="" key="" />
                                        <Column dataIndex="" key="" /> */}
                                        <Column title="Total Billed" dataIndex="TotalBilled" key="TotalBilled" />
                                        <Column title="Total Payment Recieved" dataIndex="TotalPaymentRecieved" key="TotalPaymentRecieved"  />
                                    </Table>
					)
				}}>
					<Column title="Date" dataIndex="date" key="date" />
					<Column title="Entry No" dataIndex="entryNo" key="entryNo" />
					<Column
						title="Customer ID"
						dataIndex="customerId"
						key="customerId"

						render={(text, record) => {
							return (
								<div>
									{record.customerId}
								</div>
							)
						}}

						// render={(text, record) => record.customerObj && record.customerObj.customerId}
					/>
					<Column
						title="Customer Name"
						dataIndex="customerName"
						key="customerName"
						render={(text, record) => {
							return (
								<div>
									{record.customerName}
								</div>
							)
						}}

						// render={(text, record) => record.customerObj && record.customerObj.customerName}
					/>
					<Column title="Service" dataIndex="service" key="service"
				
					/>
					<Column
						title="Delivered"
						dataIndex="delivered"
						key="delivered"
						render={(text, record) => (
							<div
								onClick={() => {
									setData(record);
									showModal();
								}}
							>
								<Space size="middle">
									{record.deliveryObj ? (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="green"
											className="bi bi-check-lg"
											viewBox="0 0 16 16"
										>
											<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
										</svg>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="red"
											className="bi bi-x-lg"
											viewBox="0 0 16 16"
										>
											<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
										</svg>
									)}
								</Space>
							</div>
						)}
					/>
					<Column
						title="Total Amount"
						dataIndex="totalAmount"
						key="totalAmount"
						render={(text, record) => record.totalAmount}
					/>
					<Column
						title="Payment Recieved"
						dataIndex="paymentRecieved"
						key="paymentRecieved"

						render={(text, record) => {
							console.log("record.deliveryObj", record.deliveryObj);
							return (
								<div onClick={()=>{console.log("Helllo m workimgfnjgf", record.consultation)}}>
									{/* {record.deliveryObj[0].paymentRecieved} */}
									{(record.deliveryObj ? record.deliveryObj[0].paymentRecieved : 0)}
								</div>
							)
						}}

						
					/>
					<Column
						className="actions"
						title="Action"
						key="action"
						render={(text, record) => (
							<Space size="middle">
								{props.userPermissions.reciept_generated_edit && (
									<a
										onClick={() => props.history.push(`/take-out-items?entryNo=${record.entryNo}`)}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="#001529"
											className="bi bi-pencil-fill"
											viewBox="0 0 16 16"
										>
											<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
										</svg>
									</a>
								)}

								{props.userPermissions.reciept_generated_delete && (
									<Popconfirm
										onConfirm={() => confirmDeleteItem(record)}
										title="Are you sure you want to delete this item?"
										okText="Yes"
										cancelText="No"
									>
										<a>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="rgb(245, 0, 87)"
												className="bi bi-trash-fill"
												viewBox="0 0 16 16"
											>
												<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
											</svg>
										</a>
									</Popconfirm>
								)}

								<CopyToClipboard text={record.entryNo}>
									<CopyFilled style={{ fill: "#1890ff", color: "#1890ff" }} fill="#1890ff" />
								</CopyToClipboard>
								<NavLink to={`/take-In-Print/${record.entryNo}`}>
									<PrinterFilled />
								</NavLink>
							</Space>
						)}
					/>
				</Table>
			</div>
			<Pagination
				onChange={(ev, size) => {
					setPage(ev);
					setPageSize(size);
				}}
				pageSize={pageSize}
				current={props.page}
				total={props.total}
			/>
			<ModalReport
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				showModal={showModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				data={data}
			/>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		serviceEntries: store.reports.serviceEntries ? store.reports.serviceEntries : [],
		page: store.reports.page,
		total: store.reports.total,
		reportsLoading: store.reports.reportsLoading,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission,
		footer: store.reports.footer
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getServicesEntries,
			deleteGeneratedEntry
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceReport));
