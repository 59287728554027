import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Table, Tag, Space, DatePicker, Col, Row, Select } from "antd";
import { connect } from "react-redux";
import { SummaryEducation } from "./SummaryEducation";
import { bindActionCreators } from "redux";
import { getDaySummaryReport } from "../../../modules/actions/summaryReportsAction";
import logo from "./../../../Images/Logo 2.png";

const { Column, ColumnGroup } = Table;

export const SummaryEducationModal = (props) => {
	let { educationModal, setEducationModal, showModalEduc, dataYear, record } = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const handleOkEduc = () => {
		setEducationModal(false);
	};

	const handleCancelEduc = () => {
		setEducationModal(false);
	};

	const [months, setMonths] = useState([
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	]);

	const [monthlyData, setMonthlyData] = useState("");
	useEffect(() => {
		if (record) {
			setMonthlyData(record);
		}
	}, [record]);

	const [daysDataArr, setDaysDataArr] = useState([]);

	const selectMonth = (data) => {
		props.getDaySummaryReport("days", dataYear, Number(data.month), props.selectedBranch);
	};
	useEffect(() => {
		setDaysDataArr(props.dayData);
	}, [props]);

	return (
		<Modal
			title="Basic Modal"
			visible={educationModal}
			onOk={handleOkEduc}
			onCancel={handleCancelEduc}
			style={{ position: "relative" }}
		>
			<table className="summarDetail">
				<thead>
					<tr>
						<th>Month</th>
						<th>Income</th>
						<th>Expense</th>
						<th>Profit/Loss</th>
					</tr>
				</thead>
				<tbody>
					{monthlyData &&
						monthlyData.map((a, i) => {
							return (
								<tr
									onClick={() => {
										showModal();
										selectMonth(a);
									}}
								>
									<th>
										{months[Number(a.month) - 1]} ({dataYear})
									</th>
									<td>{a.totalEducationalIncome}</td>
									<td>{a.totalEducationalExpense}</td>
									<td>{a.totalEducationalIncome - a.totalEducationalExpense}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			{props.reportsDayLoading ? (
				<div className="loaderDiv dayLoader">
					<div>
						<img src={logo} />
					</div>
				</div>
			) : (
				<SummaryEducation
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					showModal={showModal}
					handleOk={handleOk}
					handleCancel={handleCancel}
					monthlyData={daysDataArr}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = (store) => ({
	dayData: store.summaryReports.dayData,
	permission: store.users.permission,
	reportsDayLoading: store.reports.reportsDayLoading
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			// getMonthSummaryReport
			getDaySummaryReport
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SummaryEducationModal);
