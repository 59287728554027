import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, Col, Row, } from 'antd';
import { NavLink } from 'react-router-dom';
import { Table, Tag, Space, Button } from 'antd';
import { Modal } from 'antd';


const { Column, ColumnGroup } = Table;

function ApprovedExchange() {

    let data = [
        {
            proprietor: 'Syed Sajjad',
            email: 'syed.sajjad@gmail.com',
            address: 'Saddar Karachi',
            tel: "021-25022641",
            cell: '0332-3279676',
            associationName: 'APGMJA',
            reference1: 'GGI',
            reference2: 'Seven Stars Jewellers',
        },
        {
            proprietor: 'Syed Sajjad',
            email: 'syed.sajjad@gmail.com',
            address: 'Saddar Karachi',
            tel: "021-25022641",
            cell: '0332-3279676',
            associationName: 'APGMJA',
            reference1: 'GGI',
            reference2: 'Seven Stars Jewellers',
        },
        {
            proprietor: 'Syed Sajjad',
            email: 'syed.sajjad@gmail.com',
            address: 'Saddar Karachi',
            tel: "021-25022641",
            cell: '0332-3279676',
            associationName: 'APGMJA',
            reference1: 'GGI',
            reference2: 'Seven Stars Jewellers',
        },
    ]  

    // const [isModalVisible, setIsModalVisible] = useState(false);


    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };

    // const handleCancel = () => {
    //     setIsModalVisible(false);
    // };


    return (
        <div className="diamondGrading labRate">
            <div className="innerDiamondGrading">
                <div className="filter">
                    <Row className="spaceBetween">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="filterButtons">
                                {/* <button className="toFormBtn" onClick={() => setIsModalVisible(true)}>Add Course</button> */}
                                {/* <AddRate isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel} /> */}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                <Table className="itemsTable" style={{ border: "1px solid #0000001c" }} dataSource={data} >
                        <Column title="Proprietor" dataIndex="proprietor" key="proprietor" />
                        <Column title="Email" dataIndex="email" key="email" />
                        <Column title="Address" dataIndex="address" key="address" />
                        <Column title="Telephone" dataIndex="tel" key="tel" />
                        <Column title="Cellphone" dataIndex="cell" key="cell" />
                        <Column title="Association Name" dataIndex="associationName" key="associationName" />
                        <Column title="Reference 1" dataIndex="reference1" key="reference1" />
                        <Column title="Reference 2" dataIndex="reference2" key="reference2" />
                        <Column
                            title="Approve"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn editButton'>Disapprove</button>
                                </Space>
                            )}
                        />
                                                <Column
                            title="Delete"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn DeleteBtn'>Delete</button>
                                </Space>
                            )}
                        />

                    </Table>

                </div>
            </div>
        </div>

    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovedExchange)