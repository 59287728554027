import Axios from 'axios'
import { CUSTOMER_LOADING, SET_CUSTOMER, FILTERED_CUSTOMER, MEMBERSHIP_ACTIVE, All_CUSTOMERS, GET_CUSTOMER, GET_CUSTOMER_LOADING, GET_SINGLE_MEMBERSHIP } from '../actions/customerActions';

const initialState = {
    customerLoading: false,
    customers: [],
    filteredCustomer: [],
    membershipActive: false,
    allCustomers: [],
    customer: false,
    getCustomerLoading: false,
    singlemembership: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_LOADING:
            return {
                ...state,
                customerLoading: action.payload,
            };
        case SET_CUSTOMER:
            return {
                ...state,
                customerLoading: false,
            };
        case FILTERED_CUSTOMER:
            return {
                ...state,
                filteredCustomer: action.payload.data,
            };
        case MEMBERSHIP_ACTIVE:
            return {
                ...state,
                membershipActive: action.payload.data,
            };
        case All_CUSTOMERS:
            return {
                ...state,
                allCustomers: action.payload.data,
            };
        case GET_CUSTOMER:
            // console.log(action.payload, 'customerUpdate')
            return {
                ...state,
                customer: action.payload,
            };
        case GET_CUSTOMER_LOADING:
            return {
                ...state,
                getCustomerLoading: action.payload,
            };
        case GET_SINGLE_MEMBERSHIP:
            // console.log("action pay log single", action.payload)
            return {
                ...state,
                singlemembership: action.payload
            }



        default:
            return state
    }
}

