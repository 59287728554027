import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ModalReport } from "../ModalReport";
import { dateFormat } from "../../../../../common/common";
import {
	deleteGeneratedEntry,
	getDirectReceivedEntries,
	getRecieptGeneratedEntries
} from "../../../../../modules/actions/reportsAction";
import { bindActionCreators } from "redux";
import logo from "./../../../../../Images/Logo 2.png";

const { Column } = Table;

const CashRecieved = (props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const showModal = () => {
		setIsModalVisible(true);
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));

			props.getDirectReceivedEntries(
				page,
				props.selectedReports,
				startDate,
				endDate,
				"",
				props.selectedBranch,
				pageSize
			);
			// props.getRecieptGeneratedEntries(
			// 	page,
			// 	props.selectedReports,
			// 	startDate,
			// 	endDate,
			// 	"",
			// 	props.selectedBranch,
			// 	pageSize
			// );
		}
	}, [
		props.selectedReports,
		props.searchBool,
		// props.startDate,
		// props.endDate,
		page,
		// props.permission.branchCode,
		// props.selectedBranch,
		pageSize
	]);

	const [dataEntry, setDataEntry] = useState([])

	useEffect(() => {
		setDataEntry(props.entries)
	}, [props.entries, pageSize])
	useEffect(() => {
		console.log(props.footer)
	}, [props.footer])
	return (
		<div className="CashRecieved">
			<div className="tableDiv">
				{props.reportsLoading ? (
					<div className="loaderDiv tableLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : null}
				<Table pagination={false} className="itemsTable" dataSource={dataEntry}
					// footer={() => (
					// 	<Table className="itemsTable" dataSource={[props.footer]}>
					// 		{/* <Column title="" render={() => (
					// 			<div>
					// 			</div>
					// 		)} />
					// 		<Column title="" render={() => (
					// 			<div>
					// 			</div>
					// 		)} />
					// 		<Column title="" render={() => (
					// 			<div>
					// 			</div>
					// 		)} />
					// 		<Column title="" render={() => (
					// 			<div>
					// 			</div>
					// 		)} />
					// 		<Column title="" render={() => (
					// 			<div>
					// 			</div>
					// 		)} /> */}
					// 		<Column title="Total Billed Amount" dataIndex="totalAmount" key="totalAmount" />
					// 		<Column title="Total Advance Amount" dataIndex="advance" key="advance" />
					// 		<Column title="Total Discount Amount" dataIndex="discount" key="discount" />
					// 		<Column title="Total Payment Recieved" dataIndex="paymentRecieved" key="paymentRecieved" />
					// 	</Table>
					// )}

				>
					<Column
						title="Recieved Date"
						dataIndex="date"
						key="date"
						render={(text, record) =>
							record.description === "Payment Recieved"
								? record.deliveryObj.deliveryDate
								: record.description === "Advance"
									? record.entryDate
									: record.entryDate
										? record.entryDate
										: record.date
						}
					/>
					<Column title="Entry No" dataIndex="entryNo" key="entryNo" />
					<Column title="Customer ID" dataIndex="customerId" key="customerId" />
					<Column
						title="Customer Name"
						dataIndex="customerName"
						key="customerName"
						render={(text, record) => record.customerObj && record.customerObj.customerName}
					/>
					<Column title="Description" dataIndex="description" key="description" />
					<Column
						title="Total Billed"
						dataIndex="totalBilled"
						key="totalBilled"
						render={(text, record) =>
							record.description === "Payment Recieved" ? record.deliveryObj ? record.deliveryObj.totalAmount : record.totalAmount : 0
						}
					/>
					<Column
						title="advance"
						dataIndex="advance"
						key="advance"
						render={(text, record) => (record.description === "Advance" ? record.advance : 0)}
					/>
					<Column
						title="Discount"
						dataIndex="discount"
						key="discount"
						render={(text, record) =>
							record.discountApproved === "Approved" ? record.discountAmount : 0
						}
					/>
					<Column
						title="Payment Recieved"
						dataIndex="CashReceive"
						key="CashReceive"
						render={(text, record) =>
							record.description === "Payment Recieved"
								? record.deliveryObj && record.deliveryObj.paymentRecieved
								: record.CashReceive
									? record.CashReceive
									: 0
						}
					/>
				</Table>
				<Table className="itemsTable" dataSource={[props.footer]} style={{marginTop:'0'}}>
							{/* <Column title="" render={() => (
								<div>
								</div>
							)} />
							<Column title="" render={() => (
								<div>
								</div>
							)} />
							<Column title="" render={() => (
								<div>
								</div>
							)} />
							<Column title="" render={() => (
								<div>
								</div>
							)} />
							<Column title="" render={() => (
								<div>
								</div>
							)} /> */}
							<Column title="Total Billed Amount" dataIndex="totalAmount" key="totalAmount" />
							<Column title="Total Advance Amount" dataIndex="advance" key="advance" />
							<Column title="Total Discount Amount" dataIndex="discount" key="discount" />
							<Column title="Total Payment Recieved" dataIndex="paymentRecieved" key="paymentRecieved" />
						</Table>
			</div>
			<Pagination
				onChange={(ev, size) => {
					setPage(ev);
					setPageSize(size);
				}}
				pageSize={pageSize}
				current={props.page}
				total={props.total}
			/>
			{/* <ModalReport isModalVisible={isModalVisible}
setIsModalVisible={setIsModalVisible}
showModal={showModal}
handleOk={handleOk}
handleCancel={handleCancel}
 /> */}
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		entries: store.reports.entries ? store.reports.entries : [],
		page: store.reports.page,
		total: store.reports.total,
		footer: store.reports.footer,
		reportsLoading: store.reports.reportsLoading,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getRecieptGeneratedEntries,
			deleteGeneratedEntry,
			getDirectReceivedEntries
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(CashRecieved);
