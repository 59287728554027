import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import store from '../store';


// Reports loader true
function priceLoadingBool(bool) {
    return dispatch => {
        dispatch(CustomerReportsLoading(bool));
    };
}
export const PRICE_LOADING = 'PRICE_LOADING';
export const CustomerReportsLoading = (bool) => {
    return {
        type: PRICE_LOADING,
        payload: bool
    }
}


let AllServices = []



//Add loose stones
export function addLoseStoneServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}LoseStoneServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Loose Stone Service Price Added")
            dispatch(getLoseStoneServicePrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putLoseStoneServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}LoseStoneServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Loose Stone Service Price Updated")
            dispatch(getLoseStoneServicePrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}
export function getLoseStoneServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}LoseStoneServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(looseStonePrices(res.data.results))
            AllServices = [...AllServices, ...res.data.results]
            dispatch(servicesPrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}
export const LOOSE_STONE_SERVICE = 'LOOSE_STONE_SERVICE';
export const looseStonePrices = (data) => {

    return {
        type: LOOSE_STONE_SERVICE,
        payload: data
    }
}



//Add diamond Card stones
export function addDiamondCardPrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCardPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Diamond Card Price Added")
            dispatch(getDiamondCardPrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putDiamondCardPrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCardPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Diamond Card Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getDiamondCardPrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCardPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(diamondCardPrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_DIAMOND_CARD_PRICE = 'GET_DIAMOND_CARD_PRICE';
export const diamondCardPrice = (data) => {
    return {
        type: GET_DIAMOND_CARD_PRICE,
        payload: data
    }
}



//Add Diamond Certificate stones
export function addDiamondCertificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Diamond Certificate Price Added")
            dispatch(getDiamondCertificatePrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putDiamondCertificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Diamond Certificate Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getDiamondCertificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}DiamondCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(diamondCertificatePrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_DIAMOND_CERTIFICATE_PRICE = 'GET_DIAMOND_CERTIFICATE_PRICE';
export const diamondCertificatePrice = (data) => {
    return {
        type: GET_DIAMOND_CERTIFICATE_PRICE,
        payload: data
    }
}



//Add addStrandService
export function addStrandServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}StrandServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Strand Service Price Added")
            dispatch(getStrandServicePrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putStrandServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}StrandServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Strand Service Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getStrandServicePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}StrandServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            dispatch(strandServicePrice(res.data.results))
            AllServices = [...AllServices, ...res.data.results]
            dispatch(servicesPrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_STRAND_SERVICE_PRICE = 'GET_STRAND_SERVICE_PRICE';
export const strandServicePrice = (data) => {
    return {
        type: GET_STRAND_SERVICE_PRICE,
        payload: data
    }
}



//Add jeweleryServicePrice
export function addJewelleryCeritificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}jeweleryServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Jewellery Certificate Price Added")
            dispatch(getJewelleryCeritificatePrice())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putJewelleryCeritificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}jeweleryServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Jewellery Certificate Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getJewelleryCeritificatePrice(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}jeweleryServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(jewelleryCertificatePrice(res.data.results))
            AllServices = [...AllServices, ...res.data.results]
            dispatch(servicesPrice())
            dispatch(priceLoadingBool(false))

        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_JEWELLERY_CERTIFICATE = 'GET_JEWELLERY_CERTIFICATE';
export const jewelleryCertificatePrice = (data) => {
    console.log(data, "jewelleryCertificatePrice")
    return {
        type: GET_JEWELLERY_CERTIFICATE,
        payload: data
    }
}

//Add Below500Price
export function addBelow500Price(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}Below500Price/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: data
        }).then((res) => {
            message.success("Below 500 Price Added")
            dispatch(getBelow500Price())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putBelow500Price(data, id) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}Below500Price/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: data
        }).then(() => {
            message.success("Below 500 Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getBelow500Price(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}Below500Price/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(below500Price(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_BELOW_500_PRICE = 'GET_BELOW_500_PRICE';
export const below500Price = (data) => {
    return {
        type: GET_BELOW_500_PRICE,
        payload: data
    }
}



//Add ExtraService
export function addExtraService(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}extraServicesPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Extra Service Price Added")
            dispatch(getExtraService())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putExtraService(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}extraServicesPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then(() => {
            message.success("Extra Service Price Updated")
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getExtraService(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}extraServicesPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getExtraPrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_EXTRA_SERVICE = 'GET_EXTRA_SERVICE';
export const getExtraPrice = (data) => {
    return {
        type: GET_EXTRA_SERVICE,
        payload: data
    }
}


//Add CVD
export function addCVDTesting(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}cvdTestingPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("CVD Testing Price Added")
            dispatch(getCVDTesting())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putCVDTesting(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}cvdTestingPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("CVD Testing Price Updated")
            dispatch(getCVDTesting())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getCVDTesting(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}cvdTestingPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getCVDPrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_CVD_PRICE = 'GET_CVD_PRICE';
export const getCVDPrice = (data) => {
    return {
        type: GET_CVD_PRICE,
        payload: data
    }
}




//Add small gemstone lot
export function addJewelleryCertificateLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCertificateServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Added")
            dispatch(getJewelleryCertificateLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putJewelleryCertificateLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCertificateServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Updated")
            dispatch(getJewelleryCertificateLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getJewelleryCertificateLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCertificateServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getJewelleryCertificatePrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_JEWELLERY_GEMSTONE_LOT = 'GET_JEWELLERY_GEMSTONE_LOT';
export const getJewelleryCertificatePrice = (data) => {
    return {
        type: GET_JEWELLERY_GEMSTONE_LOT,
        payload: data
    }
}


//Add select gemstone lot
export function addSelectStoneLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCardServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Added")
            dispatch(getSelectStoneLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putSelectStoneLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCardServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Updated")
            dispatch(getSelectStoneLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getSelectStoneLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}jewelleryCardServicePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getSelectStoneLotPrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_SELECT_GEMSTONE_LOT = 'GET_SELECT_GEMSTONE_LOT';
export const getSelectStoneLotPrice = (data) => {
    return {
        type: GET_SELECT_GEMSTONE_LOT,
        payload: data
    }
}


//Add select gemstone lot
export function addSmallGemstonesLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}smallGemstonesLotPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Added")
            dispatch(getSmallGemstonesLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putSmallGemstonesLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}smallGemstonesLotPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Updated")
            dispatch(getSmallGemstonesLot())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getSmallGemstonesLot(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}smallGemstonesLotPrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getSmallGemstonesLotPrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_SMALL_GEMSTONE_LOT_PRICE = 'GET_SMALL_GEMSTONE_LOT_PRICE';
export const getSmallGemstonesLotPrice = (data) => {
    return {
        type: GET_SMALL_GEMSTONE_LOT_PRICE,
        payload: data
    }
}

//Add select gemstone lot
export function addLotApprasialCertificate(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}LotApprasialCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Added")
            dispatch(getLotApprasialCertificate())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function putLotApprasialCertificate(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}LotApprasialCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: { data }
        }).then((res) => {
            message.success("Small Gemstone Lot Price Updated")
            dispatch(getLotApprasialCertificate())
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export function getLotApprasialCertificate(data) {
    return dispatch => {
        dispatch(priceLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}LotApprasialCertificatePrice/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getLotApprasialCertificatePrice(res.data.results))
            dispatch(priceLoadingBool(false))
        }).catch(() => {
            dispatch(priceLoadingBool(false))
        });
    };
}

export const GET_LOT_APPRAISAL_PRICE = 'GET_LOT_APPRAISAL_PRICE';
export const getLotApprasialCertificatePrice = (data) => {
    return {
        type: GET_LOT_APPRAISAL_PRICE,
        payload: data
    }
}


const servicesPrice = () => {
    console.log(AllServices, "AllServices")
    return dispatch => {
        let data = AllServices
    }

}




export const ADD_PRICE = 'ADD_PRICE';
export const addPriceSuccess = (data) => ({
    type: ADD_PRICE,
    payload: {
        data
    }
});


