import Axios from 'axios'
import { AUTHENTICATION_LOADING, GET_USER_PERMISSION, LOGIN_USER_DATA, PENDING_ITEMS } from '../actions/authenticationActions';

const initialState = {
    authenticationLoading: false,
    isAuthenticated: false,
    user: false,
    userPermissions: false,
    pendingObj: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATION_LOADING:
            return {
                ...state,
                authenticationLoading: action.payload,
            };
        case LOGIN_USER_DATA:
            return {
                ...state,
                authenticationLoading: false,
                isAuthenticated: action.payload.isAuthenticated,
                user: action.payload.data
            };
        case GET_USER_PERMISSION:
            return {
                ...state,
                authenticationLoading: false,
                userPermissions: action.payload.data
            };
        case PENDING_ITEMS:
            return {
                ...state,
                pendingObj: action.payload.data
            };
        default:
            return state
    }
}

