const DiamondCertificate = {
	Shape: ["Princess", "Round", "Rectangular", "OVAL", "PEAR", "MARQUISE", "Cushion"],

	Cut: [
		"Brilliant Cut",
		"Step Cut",
		"MIXED Cut",
		"Cabochon",
		"Bead",
		"Carved",
		"Modified Brilliant"
	],

	Clarity: ["FL", "IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "I1", "I2", "I3", "N/A"],

	Diamond_Cut: ["Excellent", "Very Good", "Good", "Fair", "Poor", "N/A"],

	Polish: ["Excellent", "Very Good", "Good", "Fair", "Poor", "N/A"],

	Symmetry: ["Excellent", "Very Good", "Good", "Fair", "Poor", "N/A"],

	Fluorescence: ["Strong", "Medium", "Faint", "Inert"],

	Fl_Color: ["Green", "Pink", "Blue", "Yellow"]
};

export default DiamondCertificate;
