import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import { getCustomer, getCustomerSuccess } from './customerActions';
import { getDirectCash } from './reportsAction';

// dataEntry loader true
export const DATA_ENTRY_LOADING = 'DATA_ENTRY_LOADING';
export const dataEntryLoading = (bool) => {
    return {
        type: DATA_ENTRY_LOADING,
        payload: bool
    }
}

// save dataEntry
export function saveDataEntry(obj, clearState, jumpPrintPage, branchCode) {
    console.log('helloooooooooooooooooo');
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            dispatch(saveDataEntrySuccess(res.data));
            clearState()
            dispatch(getCustomerSuccess(false))
            console.log("res.data.entryNo", res.data.entryNo);
            jumpPrintPage(res.data.entryNo)
            message.success("Successfully added")
            dispatch(dataEntryLoading(false))
        }).catch((err) => {
            dispatch(dataEntryLoading(false))
            if (err.response.data && err.response.data.error) {
                message.error("failed")
                console.log(err.response.data)
            }
        })
            ;
    };
}
export const SAVE_DATA_ENTRY = 'SAVE_DATA_ENTRY';
export const saveDataEntrySuccess = (obj) => ({
    type: SAVE_DATA_ENTRY,
    payload: { obj }
});

// Update dataEntry
export function updateDataEntry(obj, clearState, msgText, goBack, notTake, branchCode, jumpToPrint, entryNo, pushToNext, bool, itemId) {
    console.log('function chal gaya', obj.id)
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${obj.id}/?`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            console.log(bool, 'booooool')
            if (notTake) {
                dispatch(getSingleDataEntrySuccess(false))
            }
            if (bool) {
                console.log('bool mil gaya')
                pushToNext(itemId, itemId.id, obj.entryNo)
            }
            if (!bool) {
                dispatch(getSingleDataEntry(entryNo, branchCode))
            }
            clearState()
            dispatch(dataEntryLoading(false))
            jumpToPrint(obj.entryNo)
            message.success(msgText)
            // if (goBack) {
            //     goBack()
            // }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}

// Save Delivery dataEntry
export function SaveDeliveryDataEntry(obj, clearState, msgText, goBack, notTake, branchCode, jumpToPrint, entryNo, pushToNext, bool, itemId) {
    console.log('function chal gaya')
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}DeleiveryEntry/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            console.log(bool, 'booooool')
            if (notTake) {
                dispatch(getSingleDataEntrySuccess(false))
            }
            if (bool) {
                console.log('bool mil gaya')
                pushToNext(itemId, itemId.id, obj.entryNo)
            }
            if (!bool) {
                dispatch(getSingleDataEntry(entryNo, branchCode))
            }
            clearState()
            dispatch(dataEntryLoading(false))
            jumpToPrint(obj.entryNo)
            message.success(msgText)
            // if (goBack) {
            //     goBack()
            // }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}


// Update dataEntry
export function deleteDataItem(data, branchCode, items, setItems) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}items/${data.id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            message.success('Item Deleted Successfully')


        }).catch((res) => {
            dispatch(dataEntryLoading(false))
            message.error('Item Delete Failed')
            setItems([...items])
        });
    };
}

// save getDataEntry
export function getDataEntry() {
    console.log("Ill have to work");
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/?page=2`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            let entries = res.data.results
            dispatch(getDataEntrySuccess(entries))
            dispatch(dataEntryLoading(false))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_DATA_ENTRY = 'GET_DATA_ENTRY';
export const getDataEntrySuccess = (entries) => ({
    type: GET_DATA_ENTRY,
    payload: { entries }
});


//Get Single entry
export function getSingleDataEntry(id, branchCode) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            if (res.data) {
                dispatch(getSingleDataEntrySuccess(res.data))
            } else {
                dispatch(getSingleDataEntrySuccess(false))
            }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_SINGLE_DATA_ENTRY = 'GET_SINGLE_DATA_ENTRY';
export const getSingleDataEntrySuccess = (data) => ({
    type: GET_SINGLE_DATA_ENTRY,
    payload: { data }
});

//Clear Single entry
export function clearSingleDataEntry(id) {
    return dispatch => {
        dispatch(clearSingleDataEntrySuccess(false))
    };
}
export const CLEAR_SINGLE_DATA_ENTRY = 'CLEAR_SINGLE_DATA_ENTRY';
export const clearSingleDataEntrySuccess = (data) => ({
    type: CLEAR_SINGLE_DATA_ENTRY,
    payload: { data }
});


//Update Single Item copy

// export function updateSingleItemCopy(obj, clearState, msgText, goBack, notTake, branchCode, jumpToPrint, entryNo, pushToNext, bool, itemId) {
//     console.log('function chal gaya')
//     return dispatch => {
//         console.log("itemssssssss", itemId);
//         dispatch(dataEntryLoading(true))
//         let token = getCookie("authToken")
//         Axios({
//             method: 'put',
//             url: `${process.env.REACT_APP_BACKEND_URL}items/${itemId.id}/?`,
//             headers: {
//                 'Authorization': `JWT ${token}`
//             },
//             data: obj
//         }).then((res) => {
//             console.log(bool, 'booooool')
//             if (notTake) {
//                 dispatch(getSingleDataEntrySuccess(false))
//             }
//             if (bool) {
//                 pushToNext(res.data, itemId, entryNo)
//                 dispatch(updateSingleItemCopySuccess())
//                 pushToNext(itemId, itemId.id, obj.entryNo)
//             }
//             if (!bool) {
//                 dispatch(getSingleDataEntry(entryNo, branchCode))
//             }
//             clearState()
//             dispatch(dataEntryLoading(false))
//             jumpToPrint(obj.entryNo)
//             message.success(msgText)
//             // if (goBack) {
//             //     goBack()
//             // }
//         }).catch((res) => {
//             dispatch(dataEntryLoading(false))
//         });
//     };
// }


export function updateSingleItemCopy(obj, bool, pushToNext, itemId , entryNo, branchCode ) {
    console.log('function chal gaya',obj)
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: obj.consultation ? `${process.env.REACT_APP_BACKEND_URL}consultation/${itemId}/?`:`${process.env.REACT_APP_BACKEND_URL}items/${itemId}/?`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            console.log(bool, 'booooool')
            // if (notTake) {
            //     dispatch(getSingleDataEntrySuccess(false))
            // }
            if (bool) {
                pushToNext(res.data, itemId, entryNo)
                dispatch(updateSingleItemCopySuccess())
                pushToNext(itemId, itemId.id, obj.entryNo)
            }
            if (!bool) {
                dispatch(getSingleDataEntry(entryNo, branchCode))
            }
            // clearState()
            dispatch(dataEntryLoading(false))
            // jumpToPrint(obj.entryNo)
            // message.success(msgText)
            // if (goBack) {
            //     goBack()
            // }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}

// export function updateSingleItemCopy(id, obj, pushToNext, bool, closeAddItemModel, branchCode, itemId, entryNumber) {
//     return dispatch => {
//         console.log(obj)
//         dispatch(dataEntryLoading(true))
//         let token = getCookie("authToken")
//         Axios({
//             method: 'put',
//             url: `${process.env.REACT_APP_BACKEND_URL}items/${id}/`,
//             headers: {
//                 'Authorization': `JWT ${token}`
//             },
//             data: obj
//         }).then((res) => {
//             message.success("Item updated")
//             if (bool) {
//                 pushToNext(res.data, itemId, entryNumber)
//                 dispatch(updateSingleItemCopySuccess())
//             } else {
//                 dispatch(dataEntryLoading(false))
//                 dispatch(getSingleDataEntry(obj.entryNo))
//                 closeAddItemModel()
//             }
//         }).catch((res) => {
//             dispatch(dataEntryLoading(false))
//         });
//     };
// }
export const GET_SINGLE_ITEM_COPY = 'GET_SINGLE_ITEM_COPY';
export const updateSingleItemCopySuccess = () => ({
    type: GET_SINGLE_ITEM_COPY,
    payload: {}
});


//Update Single Item
export function updateSingleItem(id, obj, pushToNext, bool, closeAddItemModel, branchCode, itemId, entryNumber) {
    return dispatch => {
        console.log(obj)
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}items/${id}/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Item updated")
            if (bool) {
                pushToNext(res.data, itemId, entryNumber)
                dispatch(updateSingleItemSuccess())
            } else {
                dispatch(dataEntryLoading(false))
                dispatch(getSingleDataEntry(obj.entryNo))
                closeAddItemModel()
            }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_SINGLE_ITEM = 'GET_SINGLE_ITEM';
export const updateSingleItemSuccess = () => ({
    type: GET_SINGLE_ITEM,
    payload: {}
});


//Update Single Item
export function updateSingleItemConsultant(id, obj, pushToNext, bool, closeAddItemModel, branchCode, itemId, entryNumber) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}consultation/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Consultation Item updated")
            if (bool) {
                pushToNext(res.data, itemId, entryNumber)
                dispatch(updateSingleItemConsultantSuccess())
            } else {
                dispatch(dataEntryLoading(false))
                dispatch(getSingleDataEntry(obj.relatedEntryNo))
                closeAddItemModel()
            }
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_SINGLE_ITEM_CONSULTANT = 'GET_SINGLE_ITEM_CONSULTANT';
export const updateSingleItemConsultantSuccess = () => ({
    type: GET_SINGLE_ITEM_CONSULTANT,
    payload: {}
});

// save direct cash recieve
export function saveDirectCash(obj, branchCode, setCashRecivedData, id) {
    return dispatch => {
        let token = getCookie("authToken")
        dispatch(dataEntryLoading(true))
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}cashRcEntry/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            // dispatch(saveDirectCashSuccess(res.data));
            dispatch(dataEntryLoading(false))
            setCashRecivedData(obj)
            dispatch(getCashReceivedSingleItem(res.data))
            // dispatch(getCustomer(id, branchCode))
            message.success("Successfully added")
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}



export const GET_CASH_RECEIVED_SINGLE_ITEM = 'GET_CASH_RECEIVED_SINGLE_ITEM';
export const getCashReceivedSingleItem = (data) => ({
    type: GET_CASH_RECEIVED_SINGLE_ITEM,
    payload: { data: data }
});

//Get Single entry
export function getSingleItem(id, branchCode) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}items/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            dispatch(getSingleItemSuccess(res.data))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}

//Get Single entry consultation
export function getSingleItemConsultation(id, branchCode) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}consultation/${id}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            dispatch(getSingleItemSuccess(res.data))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}

export const GET_SINGLE_ITEM_ENTRY = 'GET_SINGLE_ITEM_ENTRY';
export const getSingleItemSuccess = (data) => ({
    type: GET_SINGLE_ITEM_ENTRY,
    payload: { data }
});

//Get Single entry
export function getSingleReport(id, branchCode) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}report/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            dispatch(getSingleReportSuccess(res.data))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_SINGLE_REPORT_ENTRY = 'GET_SINGLE_REPORT_ENTRY';
export const getSingleReportSuccess = (data) => ({
    type: GET_SINGLE_REPORT_ENTRY,
    payload: { data }
});

//Get packet number
export function getPacketNumber(branchCode) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}getPacketNo/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            dispatch(getPacketNumberSuccess(res.data.packetNo))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_PACKET_NUMBER = 'GET_PACKET_NUMBER';
export const getPacketNumberSuccess = (data) => ({
    type: GET_PACKET_NUMBER,
    payload: { data }
});


//Get single customer pending entries
export function getSingleCustomerPendingEntries(id, branchCode, page) {
    return dispatch => {
        dispatch(dataEntryLoading(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}getSingleuserAllDataEntires/?branchCode=${branchCode}&customerId=${id}&page=${page}&page_size=${5}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(dataEntryLoading(false))
            dispatch(getSingleCustomerPendingEntriesSuccess(res.data.results, res.data.page, res.data.total))
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}
export const GET_SINGLE_CUSTOMER_PENDING_ENTRIES = 'GET_SINGLE_CUSTOMER_PENDING_ENTRIES';
export const getSingleCustomerPendingEntriesSuccess = (data, page, total) => ({
    type: GET_SINGLE_CUSTOMER_PENDING_ENTRIES,
    payload: { data, page, total }
});

//Get single customer pending entries
export function deleteStone(id, arr, setStoneArr, updateData) {
    return dispatch => {
        if (id) {

            let token = getCookie("authToken")
            Axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BACKEND_URL}stone/${id}`,
                headers: {
                    'Authorization': `JWT ${token}`
                }
            }).then((res) => {
                setStoneArr(arr);
                updateData(arr);
                message.success("Stone Deleted")
                // dispatch(dataEntryLoading(false))
                // dispatch(deleteStoneSuccess(res.data.results, res.data.page, res.data.total))
            }).catch((res) => {
                message.success("Failed to delete stone , Try Again")
                // dispatch(dataEntryLoading(false))
            });
        } else {
            setStoneArr(arr);
            updateData(arr);
        };
    }
}




//delete delivered
export function deleteDelivery(id) {
    console.log("iddddddddd", id);
    return dispatch => {
        if (id) {

            let token = getCookie("authToken")
            Axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BACKEND_URL}DeleiveryEntry/${id}`,
                headers: {
                    'Authorization': `JWT ${token}`
                }
            }).then((res) => {

                message.success("delivery Deleted")
                dispatch(getSingleDataEntrySuccess(false))
                // dispatch(dataEntryLoading(false))
                // dispatch(deleteStoneSuccess(res.data.results, res.data.page, res.data.total))
            }).catch((res) => {
                message.success("Failed to delete delivery , Try Again")
                // dispatch(dataEntryLoading(false))
            });
        } else {

        };
    }
}


export const deleteSingleDelivery = (data) => ({
    type: GET_SINGLE_DATA_ENTRY,
    payload: data
});




//delete delivered
export function deleteEntryExpense(id,i,confirm) {
    return dispatch => {
        if (id) {

            let token = getCookie("authToken")
            Axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BACKEND_URL}EntryExpense/${id}/`,
                headers: {
                    'Authorization': `JWT ${token}`
                }
            }).then((res) => {

                message.success("delivery Deleted")
                dispatch(getSingleDataEntrySuccess(false))
                confirm(id,i)
                // dispatch(dataEntryLoading(false))
                // dispatch(deleteStoneSuccess(res.data.results, res.data.page, res.data.total))
            }).catch((res) => {
                message.success("Failed to delete delivery , Try Again")
                // dispatch(dataEntryLoading(false))
            });
        } else {

        };
    }
}
export function saveEntryExpense(obj) {
    return dispatch => {
        let token = getCookie("authToken")
        dispatch(dataEntryLoading(true))
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}EntryExpense/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {;
            dispatch(dataEntryLoading(false))
            message.success("Successfully added")
        }).catch((res) => {
            dispatch(dataEntryLoading(false))
        });
    };
}

// export const GET_SINGLE_CUSTOMER_PENDING_ENTRIES = 'GET_SINGLE_CUSTOMER_PENDING_ENTRIES';
// export const deleteStoneSuccess = (data, page, total) => ({
//     type: GET_SINGLE_CUSTOMER_PENDING_ENTRIES,
//     payload: { data, page, total }
// });