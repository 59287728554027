import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import signLogo from "./../../../Images/Gemstone-Report-2.jpg";
import signature from "./../../../Images/Sgnature.png";
import chip from "./../../../Images/chip.jpg";
import ring from "./../../../Images/132.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import { Spin, Space } from "antd";
import line from "./../../../Images/symbols/line.png";
import { dateFunction } from "../../../modules/actions/managerPanelActions";
import isoCertified from "./../../../Images/isoCeritifiedR.PNG";


function jeweReportPrint(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#capture").clientWidth;
		let height = document.querySelector("#capture").clientHeight;
		html2canvas(document.querySelector("#capture"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}

	const [PrintArr, setPrintArr] = useState(false);
	useEffect(() => {
		setPrintArr(JSON.parse(localStorage.getItem("arr")));
	}, []);
	useEffect(() => {
		QRCode.toDataURL(`https://ggilab.com/Certificate/${PrintArr.certificateNumber}`)
			.then((url) => {
				setQrUrl(url);
			})
			.catch((err) => { });

		// With async/await
		const generateQR = async (text) => {
			try {
			} catch (err) { }
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, [PrintArr]);

	console.log(PrintArr, "PrintArr");

	const lineBreak = (text) => {
		let str = text ? text : false;
		console.log(str);
		if (str) {
			let updatedStr = str.split("@@").join(" DouBleBreAk ").split("@").join(" SinGleBreAk ");
			return (
				<p>
					{updatedStr.split(" DouBleBreAk ").length
						? updatedStr.split(" DouBleBreAk ").map((a, i) => {
							return (
								<>
									{a.split(" SinGleBreAk ").length > 1 ? (
										a.split(" SinGleBreAk ").map((b, j) => {
											return (
												<>
													{b}
													<br />
												</>
											);
										})
									) : (
										<>
											{a}
											<br />
											<br />
										</>
									)}
								</>
							);
						})
						: null}
				</p>
			);
		}
	};



	return (
		<>
			{/* <div style={{
                display: capture ? "none" : "block"
            }} className="loader certificateLoader">
                <div className="innerLoader">
                    <Space>
                        <Spin size="large" />
                    </Space>
                    <h2 className="loadingText">Generating Certificate</h2>
                </div>
            </div> */}
			<div
				id="bigCertificate"
				style={{
					display: capture ? "none" : "flex",
					alignItems: "center",
					marginLeft: "100px"
				}}
			>
				<div
					id="capture"
					style={{
						width: "1200px",
						margin: "13px 8px 8px",
						// overflow: "hidden",
						display: "flex"
					}}
				>
					{PrintArr ? (
						<>
							<div
								style={{
									width: "600px",
									textAlign: "center",
									marginTop: "8px",
									float: "left",
									borderRight: "1px solid darkblue",
									position: "relative",
									paddingRight: "10px",
								}}
								className="linedevClass"
							>
								<div>
									<img src={signLogo} style={{ marginRight: "81px", width: "240px" }} />
									<p
										className="MsoNormal"
										style={{
											fontSize: "26px",
											marginLeft: "0px",
											marginLeft: "-66px",
											marginTop: "-4px"
										}}
									>
										<strong style={{ msoBidiFontWeight: "normal" }}>
											<span
												className="logoName"
												style={{
													fontSize: "40px",
													color: "#E00836"
												}}
											>
												<span onClick={() => console.log(PrintArr)}>
													Galaxy Gemological Institute <p></p>
												</span>
											</span>
										</strong>
									</p>
									<p
										style={{
											fontSize: "32px",
											color: "#000",
											fontFamily: "cleric",
											textTransform: "uppercase",
											fontWeight: "bold",
											marginRight: "83px",
											fontStyle: "italic"
										}}
									>
										JEWELLERY REPORT
									</p>
									<p
										style={{
											fontSize: "20px",
											color: "#000",
											fontFamily: "cleric",
											textTransform: "uppercase",
											fontWeight: "bold",
											marginRight: "83px",
											fontStyle: "italic"
										}}
									></p>
									<table
										style={{
											width: "100%",
											textAlign: "left",
											fontFamily: "arial",
											fontSize: "18px",
											marginTop: "10px"
										}}
										align="center"
										cellpadding="0"
										cellspacing="0"
									>
										<tbody>
											{PrintArr.date ?
												<tr className="tablerow_height" style={{ width: "30%" }}>
													<td style={{ fontWeight: "bold" }}> Date: </td>
													<td>{dateFunction(PrintArr.date != "0000-00-00" ? PrintArr.date : new Date(), true)}</td>
												</tr>
												: null}
											{PrintArr.certificateNumber ?

												<tr className="tablerow_height" style={{ width: "30%" }}>
													<td style={{ fontWeight: "bold" }}> No:</td>
													<td>{PrintArr.certificateNumber}</td>
												</tr>
												: null}
											{PrintArr.object ?
												<tr className="tablerow_height" style={{ width: "30%" }}>
													<td style={{ fontWeight: "bold", display: "flex" }}>Object:&nbsp;</td>
													<td style={{ textTransform: "none" }}>{PrintArr.object}</td>
												</tr>
												: null}
										</tbody>
									</table>
									<table
										style={{
											width: "100%",
											textAlign: "center",
											fontFamily: "arial",
											fontSize: "17px",
											marginTop: "10px",
											color: "black",
											textTransform: "none !important"
										}}
										className="grading_terms_tbl"
										align="center"
									>
										<thead>
											<tr style={{ border: "1px solid black" }}>
												<th style={{ border: "1px solid black" }}>S.No.</th>
												<th style={{ border: "1px solid black" }}>Article</th>
												<th style={{ border: "1px solid black" }}>No of diamonds</th>
												<th style={{ border: "1px solid black" }}>Shape</th>
												<th style={{ border: "1px solid black" }}>Weight</th>
												<th style={{ border: "1px solid black" }}>Color</th>
												<th style={{ border: "1px solid black" }}>Clarity</th>
											</tr>
										</thead>
										<tbody>
											{PrintArr.reportContent.length &&
												PrintArr.reportContent.map((a, i) => {
													return (
														<tr>
															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="left"
															>
																{i + 1}
															</td>
															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="left"
															>
																{a.item}
															</td>
															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="center"
															>
																{a.quantity}
															</td>
															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="left"
															>
																{a.shape}
															</td>

															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="left"
															>
																{a.weight}
															</td>
															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="left"
															>
																{a.color}
															</td>

															<td
																style={{
																	border: "1px solid black",
																	bordeCollapse: "collapse",
																	borderSpacing: "0px"
																}}
																align="center"
															>
																{a.clarity}
															</td>
														</tr>
													);
												})}
										</tbody>
									</table>
									<div style={{ height: "80%", width: "80%," }}>
										<p
											align="justify"
											style={{
												marginLeft: "46px",
												fontSize: "17px",
												fontWeight: "bold",
												marginBottom: "210px;"
											}}
										></p>
									</div>
								</div>

								<div style={{ position: "fixed", bottom: "40px", width: "480px" }}>
									{" "}
									<p
										align="justify"
										style={{
											fontFamily: "arial",
											fontSize: "11px",
											textAlign: "left",
											marginBottom: "0px",
											padding: "0",
											marginRight: "0px"
										}}
									>
										GGI Galaxy Gemological Institute reports are issued under conditions and
										limitations stated on the reverse side of &nbsp;this report which form an
										integral part of this report.
									</p>
								</div>
							</div>

							<div
								style={{
									width: "624px",
									float: "left",
									marginTop: "33px",
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
									justifyContent: "center",
									marginLeft: "22px"
								}}
							>
								<div
									style={{
										width: "100%",
										minHeight: "665px",
										marginLeft: "30px",
										marginLeft: "74px",
										marginTop: "63px"
									}}
								>
									<div>
										<div
											style={{
												width: "100%",
												height: "100%",
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												alignContent: "center",
												justifyContent: "center"
											}}
										>
											{PrintArr.picture1.includes(".") && (
												<img
													src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.picture1.split('/').includes('certificate_images') ? PrintArr.picture1 : `/certificate_images/${PrintArr.picture1}`}`}
													alt=""
													className="img-thumbnail img-responsive"
													style={{ width: "165px", height: "165px" }}
												/>
											)}
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											{PrintArr.picture2.includes(".") && (
												<img
													src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.picture2.split('/').includes('certificate_images') ? PrintArr.picture2 : `/certificate_images/${PrintArr.picture2}`}`}
													alt=""
													className="img-thumbnail img-responsive"
													style={{ width: "165px", height: "165px" }}
												/>
											)}
										</div>
										{PrintArr.comment ? 
										<div
											style={{
												width: "90%",
												textAlign: "left",
												fontFamily: "arial",
												fontSize: "18px",
												marginTop: "85px",
												marginBottom: "20px",
												marginRight: "20px",
												borderCollapse: "collapse",
												borderSpacing: "0px"
											}}
										>
											<p style={{ fontSize: "18px", fontFamily: "arial", textAlign: "left" }}>
												<strong>Comments</strong>
											</p>

											<p
												style={{
													textAlign: "left",
													fontFamily: "arial",
													fontSize: "17px",
													width: "100%"
												}}
											>
												{" "}
												Grades, weight &amp; measurements are approximate as permitted by mounting.
												{/* {PrintArr.comment.split("@@")[0] === "1" ? ( */}
												<>
													<br />
													<br />
													{/* {PrintArr.comment.split("@@")[1]} */}
													{lineBreak(PrintArr.comment)}
												</>
												{/* // ) : null} */}
											</p>
										</div>
										:null}
										{/* <p style={{ textAlign: "left", fontFamily: "arial", fontSize: "17px", width: "330px" }}>
                                            This Diamond is of Natural Origin.    </p> */}
										<div style={{ position: "fixed", bottom: "30px", width: "474px" }}>
											<div
												style={{
													width: "478px",
													height: "auto",
													margin: "0 auto",
													marginTop: "190px"
												}}
											>
												<div
													style={{
														width: "154px",
														height: "79px",
														float: "left",
														paddingBottom: "0px",
														marginLeft: "7px",
														marginBottom: "40px"
													}}
												>
													<img src={signLogo} style={{ width: "220px" }} />
												</div>
												<div
													style={{
														width: "200px",
														height: "auto",
														float: "right",
														textAlign: "center",
														paddingBottom: "0px",
														display: "flex",
														flexDirection: "column",
														alignItems: "flex-end",
														justifyContent: "flex-end"
													}}
												>
													<p
														style={{
															color: "#000",
															fontFamily: "arial",
															fontWeight: "bold",
															fontSize: "15px",
															marginTop: "-19px",
															textDecoration: "underline"
														}}
													>
														{" "}
														<img width="160px" src={signature} />
													</p>

													<div
														style={{
															width: "170px",
															height: "auto",
															textAlign: "center",
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															justifyContent: "center"
														}}
													>
														<p
															style={{
																color: "#000",
																fontWeight: "bold",
																fontSize: "20px",
																marginBottom: "-5px",
																marginTop: "-28px",
																fontFamily: "cleric",
																fontStyle: "italic"
															}}
														>
															Mohammad Ali
														</p>
														<label
															style={{
																color: "#000",
																fontFamily: "cleric",
																fontStyle: "italic",
																fontSize: "17px",
																marginTop: "0px",
																fontWeight: "bold"
															}}
														>
															Gemologist, GIA (USA)
														</label>
													</div>
												</div>
											</div>
											<div style={{ clear: "both" }}></div>
											<div style={{ width: "452px", height: "auto", margin: "0 auto" }}>
												<div
													style={{
														width: "60px",
														height: "60px",
														float: "left",
														marginTop: "-5px",
														marginLeft: "7px",
														marginBottom: "30px"
													}}
												>
													<img src={chip} className="hollograme" />
												</div>
												<div className="isoImage" style={{

													left: "143px",
													width: "88px",
													position: "absolute",
													bottom: "115px",
													transform: "translateZ(-1px)",



												}}>
													<img src={isoCertified} style={{ width: "100%" }} />
												</div>
											</div>
											<div style={{ clear: "both" }}></div>
											<div
												style={{
													width: "474px",
													height: "auto",
													margin: "0 auto 0 27px",
													paddingTop: "10px",
													display: "flex",
													justifyContent: "space-around"
												}}
											>
												<div
													style={{
														width: "90px",
														height: "60px",
														float: "left",
														paddingRight: "50px"
													}}
												>
													<p
														style={{
															float: "left",
															marginTop: "-18px",
															position: "relative",
															transform: "rotate(270deg)",
															filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
															filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
															fontSize: "11px",
															fontWeight: "bold",
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															marginLeft: "-70px"
														}}
													>
														{" "}
														<span
															style={{
																marginLeft: "25px",
																position: "absolute",
																top: "-8px",
																fontSize: "14px",
																fontWeight: "900"
															}}
														>
															Verification
														</span>{" "}
														<img
															src={qrUrl}
															alt="QR code"
															style={{ width: "120px", height: "120px", marginLeft: "50px" }}
														/>
													</p>
												</div>
												<div
													style={{
														width: "300px",
														height: "auto",
														float: "left",
														textAlign: "left",
														marginTop: "-16px",
														marginLeft: "20px"
													}}
												>
													<p className="MsoNormal" style={{ marginLeft: "0px", height: "16px" }}>
														<strong style={{ fontWeight: "normal" }}>
															<span
																style={{
																	color: "#e00837",
																	fontSize: "19px",
																	fontFamily: "&quot,Bookman Old Style,quot,&quot,serif&quot",
																	fontFamily: "&quot,Adobe Arabic,quot"
																}}
															>
																Galaxy Gemological Institute <p></p>
															</span>
														</strong>
													</p>

													<img
														src={line}
														style={{
															marginTop: "-6px",
															height: "6px",
															width: "300px",
															position: "absolute"
														}}
													/>
													<p
														style={{
															color: "#000",
															fontFamily: "arial",
															fontSize: "12px",
															marginTop: "0px",
															fontWeight: "bold"
														}}
													>
														{props.permission.branchCode === "PEW" ? (
															<>
																2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar{" "}
																<br />
																Tel: (91) 256-8456,{" "}
																<b>
																	<strong
																		style={{
																			fontWeight: "900",
																			fontSize: "12px",
																			textTransform: "lowercase"
																		}}
																	>
																		www.ggilab.com
																	</strong>
																	<b></b>
																</b>
															</>
														) : (
															<>
																Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-
																<span style={{ textTransform: "lowercase" }}>e</span>-Iraq, Saddar,
																Karachi <br />
																Tel: +92-21-35216044,{" "}
																<b>
																	<strong
																		style={{
																			fontWeight: "900",
																			fontSize: "12px",
																			textTransform: "lowercase"
																		}}
																	>
																		www.ggilab.com
																	</strong>
																	<b></b>
																</b>
															</>
														)}
													</p>
													<b>
														<b></b>
													</b>
												</div>
												<b>
													<b></b>
												</b>
											</div>
											<b>
												<b>
													<div style={{ clear: "both" }}></div>
												</b>
											</b>
										</div>
										<b>
											<b></b>
										</b>
									</div>
									<b>
										<b></b>
									</b>
								</div>
								<b>
									<b>
										{/* <style>
                                                    .verticaltext {
                                                        transform: rotate(-90deg);
            transform-origin: right, top;
            -ms-transform: rotate(-90deg);
            -ms-transform-origin: right, top;
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: right, top;
            position: relative;
            right: 0px;
            color: black;
            display: inline-block;
            top: -70px;
            float: right;
            left: 20px;
        }
</style> */}
									</b>
								</b>
							</div>
						</>
					) : null}
				</div>
			</div>
			<div id="div2"></div>
			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(jeweReportPrint);
