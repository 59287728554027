import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./dashboard.scss";

function Dashboard(props) {
	const [arr, setArr] = useState([
		{
			name: "Take in",
			double: false,
			permission: "taken_in",
			route: "/take-in-items"
		},
		{
			name: "Take out",
			double: false,
			permission: "take_out",
			route: "/take-out-items"
		},
		{
			name: "Report",
			double: true,
			arr: [
				{ name: "Customer List", route: "/customer-list", permission: 'customer_view' },
				{ name: "Item List", route: "/item-list", permission: 'reports' },
				{ name: "Summary Report", route: "/summary-report", permission: 'summary_report_view' },
				{ name: "User Report", route: "/user-report", permission: 'user_report_view' },
				{ name: "Add Account", route: "/account-report", permission: 'account_report_view' }
			],
			permission: "reports",
			route: "/report-panel"
		},
		{
			name: "Consultation",
			double: false,
			permission: "consultation",
			route: "/consultant"
		},
		{
			name: "Add account",
			double: false,
			permission: "account",
			route: "/add-account"
		},
		{
			name: "Admin",
			double: true,
			arr: [
				{ name: "Add Prices", route: "/add-prices", permission: 'admin_panel_add_prices_add' },
				{ name: "Add Branch", route: "/add-branch", permission: 'admin_panel_add_branch_add' },
				{ name: "Add User", route: "/add-user", permission: 'admin_panel_add_user_add' },
				{ name: "Admin Account", route: "/admin-account", permission: 'account_report_add' }
			],
			permission: "admin_panel",
			route: "/admin-panel"
		},
		{
			name: "Manager",
			double: true,
			arr: [
				{ name: "Diamond Grading", route: "/diamond-grading", permission: "manager_panel" },
				{ name: "Gemstone Report", route: "/gemstone-report", permission: "manager_panel" },
				{ name: "Small Jewellery", route: "/small-jewellery", permission: "manager_panel" },
				{ name: "Small Gemstone", route: "/small-gemstone", permission: "manager_panel" },
				{ name: "Small Gemstone Jewellery", route: "/small-gemstone-jewellery", permission: "manager_panel" },
				{ name: "Small Diamond", route: "/small-diamond", permission: "manager_panel" },
				{ name: "Jewellery Report", route: "/jewellery-report", permission: "manager_panel" },
				{ name: "Master Colored Stone", route: "/master-colored-stone", permission: "manager_panel" },
				{ name: "Appraisal Certificate", route: "/appraisal-certificate", permission: "manager_panel" }
			],
			permission: "manager_panel",
			route: "/manager-account"
		}
	]);

	const [whichTrue, setWhichTrue] = useState("");
	useEffect(() => {
		console.log(props.userPermissions, 'props.userPermissions')
	}, [props.userPermissions])

	return (
		<div className="dashboardMain limiter ">
			{arr.map((a, i) => {
				return a.double
					? props.userPermissions[a.permission] && (
						<div key={i} className="div">
							<div className="wrap-login100-form-btn">
								<div className="login100-form-bgbtn"></div>
								<button onClick={() => props.history.push(a.route)} className="login100-form-btn">
									{a.name}
								</button>
							</div>
							<div
								className="second ant-dropdown-link"
								onClick={() => setWhichTrue(whichTrue ? "" : a.name)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="bi bi-chevron-down"
									viewBox="0 0 16 16"
								>
									<path
										fill-rule="evenodd"
										d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
									/>
								</svg>
								<div
									className={`dropdown-menu ${whichTrue && whichTrue === a.name ? "show" : ""}`}
								>
									{a.arr.map((b, i2) => {
										return props.userPermissions[b.permission] && (
											<div
												onClick={() => props.history.push(b.route)}
												key={i2}
												className="dropdown-item"
											>
												{b.name}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)
					: props.userPermissions[a.permission] && (
						<div key={i} className="div">
							<div className="wrap-login100-form-btn full">
								<div className="login100-form-bgbtn"></div>
								<button onClick={() => props.history.push(a.route)} className="login100-form-btn">
									{a.name}
								</button>
							</div>
						</div>
					);
			})}
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
