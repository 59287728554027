import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Input,
	Select,
	Col,
	Row,
	Upload,
	message,
	InputNumber,
	Checkbox,
	Divider,
	Button
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import upload from "./../../../Images/upload.png";
import {
	addJewelleryCertificate,
	updateJewelleryCertificate
} from "../../../modules/actions/reportsAction";
import { createImageFile, createMultipleImageFile, getSepJewelleryCertificates } from "../../../modules/actions/managerPanelActions";
import { checkImageType } from "../../../common/common";
import { getColorFormDataAdd, getCutFormDataAdd, getCuttingStyleFormDataAdd, getGridlesFormDataAdd, getObjectsFormDataAdd, getOrigionGemFormDataAdd, getRemarksFormDatAdda, getShapesFormDatAdda, getSpeciesFormDataAdd, getVarietysFormDatAdda } from "../../../modules/actions/FormValues";

const { TextArea } = Input;
const { Option } = Select;

async function getBase64(file) {
	console.log(file, 'filefile')
	if (file) {

		let base = new Promise((resolve, reject) => {
			const reader = new FileReader();
			if (reader) {
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			}
		});
		return await base
	}
}

function JewelleryReportForm(props) {
	const [itemArrOpt, setItemArrOpt] = useState([
		"Items",
		"Ring",
		"Earings",
		"Bracelet",
		"Necklace",
		"Nosepin",
		"Pendent"
		// "Other",
	]);

	const [name, setName] = useState(false);

	const onNameChange = (event) => {
		setName(event.target.value);
	};

	const addItemOpt = () => {
		if (name) {
			// console.log("addItem");
			setItemArrOpt([...itemArrOpt, name]);
			setName(false);
		}
	};

	const [shapeArrOpt, setShapeArrOpt] = useState([
		"Shape",
		"Round",
		"Oval",
		"Princess",
		"Emerald",
		"Marquise",
		"Taperred",
		"Baguette",
		"Cushion",
		"Heart",
		"Trilliant",
		"Pear"
		// "other",
		// "Other",
	]);

	const [shapeName, setShapeName] = useState(false);

	const onShapeChange = (event) => {
		setShapeName(event.target.value);
	};

	const addShapeOpt = () => {
		if (shapeName) {
			// console.log("addItem");
			setShapeArrOpt([...shapeArrOpt, shapeName]);
			setShapeName(false);
		}
	};

	useEffect(() => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("editId");
		if (entryNo) {
			props.getSepJewelleryCertificates(entryNo);
		}
	}, []);

	const [obj, setobj] = useState({
		date: new Date(),
		object: "",
		picture1: "",
		picture2: "",
		updatedAt: "",
		status: 1,
		comment: "",
		reportContent: []
	});

	function backFun() {
		props.history.goBack();
	}

	const [reportContent, setReportContent] = useState([
		{
			item: "",
			shape: "",
			quantity: "",
			color: "",
			weight: "",
			clarity: "",
			content_status: "1",
			addedon: ""
		}
	]);

	useEffect(() => {
		// console.log(reportContent, "reportContent");
	}, [reportContent]);

	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}
		}
	};

	const [commentArr, setCommentArr] = useState([])
	const [commentName, setCommentName] = useState(false);
	const onCommentChange = (event) => {
		setCommentName(event.target.value);
		let obj2 = { ...obj };
		obj2.comment = event.target.value;
		setobj(obj2);
	};

	const addCommentOpt = () => {
		if (commentName) {
			console.log("addItem");
			setCommentArr([...commentArr, commentName]);
			setCommentName(false);
		}
	};

	useEffect(() => {
		if (props.fields) {
			// setCutArr(props.fields.cuts)
			// setShapeArr(props.fields.shapes)
			setColorArr(props.fields.color)
			setCommentArr(props.fields.comment)
			// setSpeciesArr(props.fields.species)
			// setObjectsArr(props.fields.objects)
			// setVarietyArr(props.fields.variety)
			// setOriginArr(props.fields.origion_gem)

		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])


	const [color1Arr, setColor1Arr] = useState([{ name: "color1", value: "" }]);

	const [color2Arr, setColor2Arr] = useState([{ name: "color2", value: "" }]);

	const [clarity1Arr, setClarity1Arr] = useState([{ name: "clarity1", value: "" }]);

	const [clarity2Arr, setClarity2Arr] = useState([{ name: "clarity2", value: "" }]);

	const [weightDes, setWeightDes] = useState([{ name: "weightDes", value: "" }]);

	const [image, setImage] = useState("");
	const [image2, setImage2] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [image2Url, setImage2Url] = useState("");
	const [imageLoading, setImageLoading] = useState(false);
	const [image2Loading, setImage2Loading] = useState(false);

	const addNewReportContent = () => {
		let arr = [...reportContent];
		let arr2 = [...color1Arr];
		let arr3 = [...color2Arr];
		let arr4 = [...clarity1Arr];
		let arr5 = [...clarity2Arr];
		let arr6 = [...weightDes];
		let objj = {
			item: "",
			shape: "",
			quantity: "",
			color: "",
			weight: "",
			clarity: "",
			content_status: "1",
			addedon: ""
		};
		let color1Obj = { name: "color1", value: "" };
		let color2Obj = { name: "color2", value: "" };
		let clarity1Obj = { name: "clarity1", value: "" };
		let clarity2Obj = { name: "clarity2", value: "" };
		let weightObj = { name: "weightDes", value: "" };

		arr.push(objj);
		arr2.push(color1Obj);
		arr3.push(color2Obj);
		arr4.push(clarity1Obj);
		arr5.push(clarity2Obj);
		arr6.push(weightObj);

		setReportContent(arr);
		setColor1Arr(arr2);
		setColor2Arr(arr3);
		setClarity1Arr(arr4);
		setClarity2Arr(arr5);
		setWeightDes(arr6);
	};

	function removeItem(i) {
		let arr = [...reportContent];
		arr.splice(i, 1);
		setReportContent(arr);
	}

	function handleChange(e, name) {
		console.log("1")
		setImageLoading(true)
		setImage2Loading(true)
		if (name === "image") {
			console.log('wrongggg')
			setImageUrl(e.target.files[0]);
		}
		console.log("2")
		if (name === "image2") {
			setImage2Url(e.target.files[0]);
		}
		console.log("3")
		getBase64(e.target.files[0]).then((data) => {
			console.log("4")
			if (name === "image") {
				console.log(data);
				setImage(data);
				setImageLoading(false)
				setImage2Loading(false)
				console.log("5")
			}
			if (name === "image2") {
				setImage2(data);
				setImageLoading(false)
				setImage2Loading(false)
			}
		});
	}

	useEffect(() => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let id = url.searchParams.get("id");
		let objj = { ...obj };
		objj.relatedEntryNo = entryNo;
		objj.itemId = Number(id);
		setobj(objj);
	}, []);

	const save = () => {
		let objj = { ...obj };
		let arr = [...reportContent];
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("editId");
		let entryId = url.searchParams.get("id");
		let relatedEntryNo = url.searchParams.get("entryNo");

		arr.map((a, i) => {
			if (color1Arr[i].value && color2Arr[i].value) {
				if (color1Arr[i].value != color2Arr[i].value) {
					arr[i].color = `${color1Arr[i].value} - ${color2Arr[i].value}`;
				} else {
					arr[i].color = `${color1Arr[i].value}`;
				}
			} else if (color1Arr[i].value) {
				arr[i].color = `${color1Arr[i].value}`;
			} else if (color1Arr[i].value) {
				arr[i].color = `${color2Arr[i].value}`;
			} else {
				arr[i].color = ``;
			}

			if (clarity1Arr[i].value && clarity2Arr[i].value) {
				if (clarity1Arr[i].value != clarity2Arr[i].value) {
					arr[i].clarity = `${clarity1Arr[i].value} - ${clarity2Arr[i].value}`;
				} else {
					arr[i].clarity = `${clarity1Arr[i].value}`;
				}
			} else if (clarity1Arr[i].value) {
				arr[i].clarity = `${clarity1Arr[i].value}`;
			} else if (clarity1Arr[i].value) {
				arr[i].clarity = `${clarity2Arr[i].value}`;
			} else {
				arr[i].clarity = ``;
			}

			arr[i].weight = arr[i].weight + " " + weightDes[i].value;
		});

		objj.reportContent = arr;

		let data = new FormData();

		for (const property in objj) {
			// console.log(obj[property],property,"==>obj[property]")
			if (objj[property]) {
				if (property === "reportContent") {
					// console.log(property, JSON.stringify(objj[property]),'====>reportContent')
					data.append(property, JSON.stringify(objj[property]));
				} else if (property === "relatedEntryNo" && !obj[property] && entryNo) {
					data.append(property, entryNo);
				} else {
					data.append(property, objj[property]);
				}

			} else if (property === "status") {
				data.append(property, objj[property]);
			} else {
				data.append(property, '')

			}
			//   else if (property === "picture1") {
			// 	data.append(property, imageUrl);
			// }  else if (property === "picture2") {
			// 	data.append(property, image2Url);
			// } 
		}

		if (entryNo) {
			console.log(objj, 'datadatadatadatadatadata')
			data.append("updatedAt", new Date().toISOString().split('T')[0]);
			for (var value of data.values()) {
				console.log(value);
			}
			// if (objj.picture1) {
			// 	createImageFile(process.env.REACT_APP_BACKEND_URL + 'media/' + objj.picture1).then((result) => {
			// 		// imageFile.push(result);
			// 		console.log(result, 'resultresultresult')
			// 		data.append('picture1', result);
			// 		// getBase64(result).then((data) => {
			// 		// 	console.log(data, 'datadatadata')
			// 		// 	setImage(data);
			// 		// });
			// 		// data.append(property, result);
			// 	});
			// }
			// if (objj.picture2) {
			// 	createImageFile(process.env.REACT_APP_BACKEND_URL + 'media/' + objj.picture2).then((result) => {
			// 		// imageFile.push(result);
			// 		console.log(result, 'resultresultresult')
			// 		data.append('picture2', result);
			// 		// getBase64(result).then((data) => {
			// 		// });
			// 		// data.append(property, result);
			// 	});
			// }
			// // Display the key/value pairs
			// for (var pair of data.entries()) {
			// 	console.log(pair[0] + ', ' + pair[1],'dsdasdasdsadasdasdsa');
			// }
			props.updateJewelleryCertificate(data, objj.certificateNumber, backFun);
		} else {
			// console.log(objj,'====>objj')
			data.append("itemId", Number(entryId));
			data.append("relatedEntryNo", relatedEntryNo);

			// data.append("picture1", imageUrl);
			// data.append("picture2", image2Url);

			props.addJewelleryCertificate(data, backFun);
		}
	};

	// useEffect(() => {
	// 	if (props.certificates) {
	// 		let data = props.certificates;

	// 		// if (obj[property] && (property.toLowerCase().match("picture2") || property.toLowerCase().match("picture1"))) {


	// 		if (typeof imageUrl != 'object' || typeof data.picture1 != 'object') {
	// 			createImageFile(imageUrl ? imageUrl : data.picture1 ? `${process.env.REACT_APP_BACKEND_URL + data.picture1}` : "").then((result) => {
	// 				// imageFile.push(result);
	// 				setImageUrl(result)
	// 				// data.append(property, result);
	// 				console.log(result)
	// 				setobj({
	// 					...obj,
	// 					picture1: result,
	// 					date: data.date
	// 				});

	// 				getBase64(result).then((data) => {
	// 					setImage(data);
	// 				});


	// 			});

	// 		} else {
	// 			setobj({
	// 				...obj,
	// 				picture1: imageUrl,
	// 				date: data.date
	// 			});

	// 			getBase64(imageUrl).then((data) => {
	// 				setImage(data);
	// 			});
	// 		}
	// 		if (typeof image2Url != 'object' || typeof data.picture2 != 'object') {
	// 			createImageFile(image2Url ? image2Url : data.picture2 ? `${process.env.REACT_APP_BACKEND_URL + data.picture2}` : "").then((result) => {
	// 				// imageFile.push(result);
	// 				setImage2Url(result)
	// 				// data.append(property, result);
	// 				setobj({
	// 					...obj,
	// 					picture2: result,
	// 					date: data.date
	// 				});
	// 				getBase64(result).then((data) => {
	// 					setImage2(data);
	// 				});
	// 			});
	// 		} else {
	// 			setobj({
	// 				...obj,
	// 				picture2: image2Url,
	// 				date: data.date
	// 			});

	// 			getBase64(image2Url).then((data) => {
	// 				setImage(data);
	// 			});
	// 		}
	// 		// } 
	// 		// else if (obj[property] || obj[property] == "") {
	// 		// 	data.append(property, obj[property]);
	// 		// }


	// 		console.log(data)


	// 	}
	// 	// console.log("once");
	// }, [props.certificates, imageUrl, image2Url]);

	// const [picLinkRender, setPicLinkRender] = useState("")
	// const [picLinkRender2, setPicLinkRender2] = useState("")

	// useEffect(() => {
	// 	if (props.certificates) {
	// 		let data = props.certificates
	// 		let objData = { ...data }
	// 		let pic1 = ''
	// 		let pic2 = ''
	// 		setPicLinkRender(process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture1)
	// 		setPicLinkRender2(process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture2)
	// 		if (typeof imageUrl === 'object') {
	// 			objData.picture1 = imageUrl
	// 		} else {

	// 			if (objData.picture1) {
	// 				createImageFile(`${process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture1}`)
	// 					.then((result) => {
	// 						console.log("newwwWork")
	// 						if(obj){

	// 							setobj({
	// 								...obj,
	// 								picture1: result
	// 							})
	// 						}else{
	// 							setobj({
	// 								...data,
	// 								picture1: result
	// 							})
	// 						}
	// 						// objData.picture1 : result
	// 						getBase64(result).then((data) => {
	// 							// console.log(data, "newwwWork")
	// 							setImage(data);
	// 						});
	// 						// console.log(objData, "newwwWork")
	// 					})
	// 			}
	// 		}
	// 		if (typeof image2Url === 'object') {
	// 			objData.picture2 = image2Url
	// 		} else {

	// 			if (objData.picture2) {
	// 				createImageFile(`${process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture2}`)
	// 					.then((result) => {
	// 						console.log("newwwWork")
	// 						if(obj){

	// 							setobj({
	// 								...obj,
	// 								picture2: result
	// 							})
	// 						}else{
	// 							setobj({
	// 								...data,
	// 								picture2: result
	// 							})
	// 						}
	// 						getBase64(result).then((data) => {
	// 							setImage2(data);
	// 						});
	// 						// console.log(objData, "newwwWork")
	// 					})
	// 			}
	// 		}

	// 		console.log({ ...objData }, 'newwwWork')
	// 	}
	// }, [props.certificates, imageUrl, image2Url]);

	useEffect(() => {
		console.log(obj, 'newwwWork')
	}, [obj])

	useEffect(() => {
		if (props.certificates.certificateNumber) {
			console.log(props.certificates)
			let data = props.certificates;
			let arr = [];
			let weightDesArr = [];
			let colorArr1 = [];
			let colorArr2 = [];
			let clarityArr1 = [];
			let clarityArr2 = [];

			let images = {}
			console.log(`${process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture1}`, 'datadatadata')

			setobj({ ...data, ...images })
			if (checkImageType(data.picture1)) {
				console.log(data.picture1, 'data.picture')
				createImageFile(process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture1).then((result) => {
					// imageFile.push(result);
					setImageUrl(result)
					getBase64(result).then((data) => {
						console.log(data, 'datadatadata')
						setImage(data);
					});
					// data.append(property, result);
				});
			}
			if (checkImageType(data.picture2)) {
				console.log(data.picture2, 'data.picture')
				createImageFile(process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture2).then((result) => {
					// imageFile.push(result);
					setImage2Url(result)
					getBase64(result).then((data) => {
						setImage2(data);
					});
					// data.append(property, result);
				});
			}
			// createMultipleImageFile(`${process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture1}`, `${process.env.REACT_APP_BACKEND_URL + 'media/' + data.picture2}`)
			// 	.then((result) => {
			// 		images = result
			// 		setobj({ ...data, ...result })
			// 		console.log(result, 'datadatadata')
			// 		console.log(result.picture1, 'result.picture1')
			// 		console.log(result.picture2, 'result.picture2')
			// 		getBase64(result.picture1).then((data) => {
			// 			console.log(data,'datadatadata')
			// 			setImage(data);
			// 		});

			// 		getBase64(result.picture2).then((data) => {
			// 			setImage2(data);
			// 		});
			// 	})

			console.log({ ...data, ...images })
			if (Array.isArray(data.reportContent)) {
				data.reportContent.map((a, i) => {
					console.log(a, '==>a=>')
					weightDesArr.push({ name: "weightDes", value: a.weight.split(" ")[1] });
					arr.push({
						...a,
						weight: a.weight.split(" ")[0]
					});
					colorArr1.push({
						name: "color1",
						value: a.color.split(" - ")[0]
					});
					colorArr2.push({
						name: "color2",
						value: a.color.split(" - ")[1]
					});

					clarityArr1.push({ name: "clarity1", value: a.clarity.split(" - ")[0] });
					clarityArr2.push({ name: "clarity2", value: a.clarity.split(" - ")[1] });
				});
				setReportContent(arr);
				setColor1Arr(colorArr1);
				setColor2Arr(colorArr2);
				setClarity1Arr(clarityArr1);
				setClarity2Arr(clarityArr2);
				setWeightDes(weightDesArr);

			}
		}
	}, [props.certificates])


	useEffect(() => {
		setobj({
			...obj,
			picture1: imageUrl ? imageUrl : obj.picture1,
			picture2: image2Url ? image2Url : obj.picture2,
		})
	}, [imageUrl, image2Url])




	// useEffect(() => {
	// 	console.log(reportContent,"reportContent");
	// 	console.log(clarity2Arr,"clarity2Arr");
	// 	console.log(clarity1Arr,"clarity1Arr");
	// 	console.log(color2Arr,"color2Arr");
	// 	console.log(color1Arr,"color1Arr");
	// 	console.log(obj,"obj");
	// }, [reportContent,clarity2Arr,clarity1Arr,color2Arr,color1Arr,obj]);

	return (
		<div className="DiamondGradingFrom SmallJewelleryForm jewelleryReport">
			<div className="innerGradingForm">
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Product Image</h6>
								<div className="imageBox">
									<div
										className="box"
										// style={{ backgroundImage: `url('${image ? image : picLinkRender ? picLinkRender : ''}')` }}
										style={{ backgroundImage: `url('${image}')` }}
									// style={{ backgroundImage: `url('${image ? image : imageLoading === false && obj.picture1 ? `${process.env.REACT_APP_BACKEND_URL}media/${obj.picture1.split('/').includes('certificate_images') ? obj.picture1 : `/certificate_images/${obj.picture1}`}` : upload}')` }}
									// style={{ backgroundImage: `url('${image ? image : ''}')` }}
									></div>
									<Input
										onChange={(e) => {
											handleChange(e, "image");
											let objj = { ...obj };
											// console.log(e.target.value)
											// if(e.target.files){
											// 	objj.picture1 = e.target.files[0];
											// 	setobj(objj);
											// }
										}}
										type="file"
										placeholder="upload"
									/>
								</div>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Product Image</h6>
								<div className="imageBox">
									<div
										className="box"
										// style={{ backgroundImage: `url('${image2 ? image2 : image2Loading === false && obj.picture2 ? `${process.env.REACT_APP_BACKEND_URL}media/${obj.picture2.split('/').includes('certificate_images') ? obj.picture2 : `/certificate_images/${obj.picture2}`}` : upload}')` }}
										// style={{ backgroundImage: `url('${image2 ? image2 : picLinkRender2 ? picLinkRender2 : ''}')` }}
										style={{ backgroundImage: `url('${image2}')` }}
									></div>
									<Input
										onChange={(e) => {
											handleChange(e, "image2")
											let objj = { ...obj };
											// console.log(e.target.value)
											// if(e.target.files){
											// 	objj.picture2 = e.target.files[0];
											// 	setobj(objj);
											// }
										}}
										type="file"
										placeholder="upload"
									/>
								</div>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6 onClick={() => console.log(obj)}>Date</h6>
								<Input
									value={obj.date}
									type="date"
									onChange={(e) => {
										let objj = { ...obj };
										// console.log(e.target.value)
										objj.date = e.target.value;
										setobj(objj);
									}}
								/>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>
									Object <span className="maxWord">(Max Character: 130)</span>
								</h6>
								<TextArea
									placeholder="Controlled autosize"
									autoSize={{ minRows: 3, maxRows: 5 }}
									onChange={(e) => {
										let objj = { ...obj };
										objj.object = e.target.value;
										setobj(objj);
									}}
									value={obj.object}
								/>
							</label>
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="spaceBetween">
					<Col xs={9} sm={9} md={9} lg={9} xl={9}>
						<div className="inputDiv commnetInput">
							<label>
								<h6>Comments</h6>
								<Input
									onChange={(e) => {
										let objj = { ...obj };
										objj.comment = e.target.value;
										setobj(objj);
									}}
									value={obj.comment}
								/>
							</label>
							<label >
								<h6>Show</h6>
								<Select
									className="select"
									defaultValue="Show"
									onChange={(e) => {
										let objj = { ...obj };
										objj.status = e;
										setobj(objj);
									}}
									value={obj.status}
								>
									<Option value={1}>Show</Option>
									<Option value={0}>Hide</Option>
								</Select>
							</label>
						</div>
					</Col>
				</Row>
				<div className="desFormJewellery">
					<h2>DESCRIPTION</h2>
				</div>
				{reportContent.length > 0
					? reportContent.map((a, i) => {
						return (
							<Row key={i} className="spaceBetween">
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Item</h6>
											<Select
												className="select"
												defaultValue="Items"
												onChange={(e) => {
													let arr = [...reportContent];
													let objj = arr[i];
													objj.item = e;
													setReportContent(arr);
												}}
												value={reportContent[i].item}
												dropdownRender={(menu) => (
													<div>
														{menu}
														<Divider style={{ margin: "4px 0" }} />
														<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
															<Input
																style={{ flex: "auto" }}
																value={name ? name : ""}
																onChange={onNameChange}
															/>
															{name ? (
																<a
																	style={{
																		flex: "none",
																		padding: "8px",
																		display: "block",
																		cursor: "pointer"
																	}}
																	onClick={addItemOpt}
																>
																	<PlusOutlined /> Other Items
																</a>
															) : null}
														</div>
													</div>
												)}
											>
												{itemArrOpt.map((op, j) => {
													return <Option value={op}>{op}</Option>;
												})}
											</Select>
										</label>
									</div>
								</Col>
								{/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className="inputDiv">
                                    <label>
                                        <Input disabled />
                                    </label>
                                </div>
                            </Col> */}
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Shape</h6>
											<Select
												className="select"
												defaultValue="Shape"
												onChange={(e) => {
													let arr = [...reportContent];
													let objj = arr[i];
													objj.shape = e;
													setReportContent(arr);
												}}
												value={reportContent[i].shape}
												dropdownRender={(menu) => (
													<div>
														{menu}
														<Divider style={{ margin: "4px 0" }} />
														<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
															<Input
																style={{ flex: "auto" }}
																value={shapeName ? shapeName : ""}
																onChange={onShapeChange}
															/>
															{shapeName ? (
																<a
																	style={{
																		flex: "none",
																		padding: "8px",
																		display: "block",
																		cursor: "pointer"
																	}}
																	onClick={addShapeOpt}
																>
																	<PlusOutlined /> Other Items
																</a>
															) : null}
														</div>
													</div>
												)}
											>
												{shapeArrOpt.map((op, j) => {
													return <Option value={op}>{op}</Option>;
												})}
											</Select>
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>No Of Diamond</h6>
											<Input
												placeholder="No Of Diamond"
												onChange={(e) => {
													let arr = [...reportContent];
													let objj = arr[i];
													objj.quantity = e.target.value;
													setReportContent(arr);
												}}
												value={reportContent[i].quantity}
											/>
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Color 1</h6>
											<Select
												showSearch
												onChange={(e) => {
													let arr = [...color1Arr];
													let objj = arr[i];
													objj.value = e;
													setColor1Arr(arr);
												}}
												className="select"
												defaultValue=""
												value={color1Arr[i].value}
												dropdownRender={(menu) => (
													<div>
														{menu}
														<Divider style={{ margin: "4px 0" }} />
														<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
															<Input
																style={{ flex: "auto" }}
																value={colorName ? colorName : ""}
																onChange={onColorChange}
															/>
															{colorName ? (
																<a
																	style={{
																		flex: "none",
																		padding: "8px",
																		display: "block",
																		cursor: "pointer"
																	}}
																	onClick={addColorOpt}
																>
																	<PlusOutlined />
																</a>
															) : null}
														</div>
													</div>
												)}
											>
												{colorArr.map((a, i) => {
													return <Option value={a.name}>{a.name}</Option>;
												})}
											</Select>
											{/* <Select
												className="select"
												defaultValue="Select"
												onChange={(e) => {
													let arr = [...color1Arr];
													let objj = arr[i];
													objj.value = e;
													setColor1Arr(arr);
												}}
												value={color1Arr[i].value}
											>
												
											</Select> */}
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Color 2</h6>
											<Select
												showSearch
												onChange={(e) => {
													let arr = [...color2Arr];
													let objj = arr[i];
													objj.value = e;
													setColor2Arr(arr);
												}}
												className="select"
												defaultValue=""
												value={color2Arr[i].value}
												dropdownRender={(menu) => (
													<div>
														{menu}
														<Divider style={{ margin: "4px 0" }} />
														<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
															<Input
																style={{ flex: "auto" }}
																value={colorName ? colorName : ""}
																onChange={onColorChange}
															/>
															{colorName ? (
																<a
																	style={{
																		flex: "none",
																		padding: "8px",
																		display: "block",
																		cursor: "pointer"
																	}}
																	onClick={addColorOpt}
																>
																	<PlusOutlined />
																</a>
															) : null}
														</div>
													</div>
												)}
											>
												{colorArr.map((a, i) => {
													return <Option value={a.name}>{a.name}</Option>;
												})}
											</Select>
											{/* <Select
												className="select"
												defaultValue="Select"
												onChange={(e) => {
													let arr = [...color2Arr];
													let objj = arr[i];
													objj.value = e;
													setColor2Arr(arr);
												}}
												value={color2Arr[i].value}
											>

											</Select> */}
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Clartiy 1</h6>
											<Select
												className="select"
												defaultValue="Select"
												onChange={(e) => {
													let arr = [...clarity1Arr];
													let objj = arr[i];
													objj.value = e;
													setClarity1Arr(arr);
												}}
												value={clarity1Arr[i].value}
											>
												<Option value="">Select</Option>
												<Option value="F">F</Option>
												<Option value="IF">IF</Option>
												<Option value="VVS1">VVS1</Option>
												<Option value="VVS2">VVS2</Option>
												<Option value="VS1">VS1</Option>
												<Option value="VS2">VS2</Option>
												<Option value="SI1">SI1</Option>
												<Option value="SI2">SI2</Option>
												<Option value="I1" selected="">
													I1
												</Option>
												<Option value="I2">I2</Option>
												<Option value="I3">I3</Option>
											</Select>
										</label>
									</div>
								</Col>

								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Clartiy 2</h6>
											<Select
												className="select"
												defaultValue="Select"
												onChange={(e) => {
													let arr = [...clarity2Arr];
													let objj = arr[i];
													objj.value = e;
													setClarity2Arr(arr);
												}}
												value={clarity2Arr[i].value}
											>
												<Option value="">Select</Option>
												<Option value="F">F</Option>
												<Option value="IF">IF</Option>
												<Option value="VVS1">VVS1</Option>
												<Option value="VVS2">VVS2</Option>
												<Option value="VS1">VS1</Option>
												<Option value="VS2">VS2</Option>
												<Option value="SI1">SI1</Option>
												<Option value="SI2">SI2</Option>
												<Option value="I1" selected="">
													I1
												</Option>
												<Option value="I2">I2</Option>
												<Option value="I3">I3</Option>
											</Select>
										</label>
									</div>
								</Col>

								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Weight</h6>
											<Input
												placeholder="Enter Weight"
												onChange={(e) => {
													let arr = [...reportContent];
													let objj = arr[i];
													// console.log(objj);
													objj.weight = e.target.value;
													setReportContent(arr);
												}}
												value={reportContent[i].weight}
											/>
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="inputDiv">
										<label>
											<h6>Weight Unit</h6>
											<Select
												className="select"
												onChange={(e) => {
													let arr = [...weightDes];
													let objj = arr[i];
													// console.log(arr)
													objj.value = e;
													setWeightDes(arr);
												}}
												value={weightDes[i] ? weightDes[i].value : ""}
											>
												<Option value="CT">CT</Option>
												<Option value="GM">GM</Option>
											</Select>
										</label>
									</div>
								</Col>
								<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>

								{i > 0 ? (
									<button className="removeItemButton" onClick={() => removeItem(i)}>
										Remove
									</button>
								) : null}

								<hr />
							</Row>
						);
					})
					: addNewReportContent()}
			</div>
			<div className="saveButton">
				<button
					className="addFieldBtn"
					onClick={() => {
						addNewReportContent();
					}}
				>
					Add Fields
				</button>
				<button onClick={save} disabled={props.reportsLoading}>
					{props.reportsLoading ? <div className="loading1 reportLoder"></div> : "Save"}
				</button>
			</div>

			<Button onClick={() => props.history.goBack()} type="primary" style={{ margin: "0px 10px" }}>
				Cancel
			</Button>
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	certificates: store.managerPanel.certificateList ? store.managerPanel.certificateList : [],
	reportsLoading: store.reports.reportsLoading,
	fields: store.FormValues

});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addJewelleryCertificate,
			getSepJewelleryCertificates,
			updateJewelleryCertificate,
			getColorFormDataAdd,
			getCutFormDataAdd,
			getSpeciesFormDataAdd,
			getCuttingStyleFormDataAdd,
			getOrigionGemFormDataAdd,
			getGridlesFormDataAdd,
			getRemarksFormDatAdda,
			getVarietysFormDatAdda,
			getShapesFormDatAdda,
			getObjectsFormDataAdd
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(JewelleryReportForm);
