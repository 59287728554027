import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, Col, Row, } from 'antd';
import { NavLink } from 'react-router-dom';
import { Table, Tag, Space, Button } from 'antd';
import { Modal } from 'antd';
import AddRate from './addRate';


const { Column, ColumnGroup } = Table;

function LabRate() {

    let data = [
        {
            parentName: 'Appraisal',
            testType: 'Plain Jewellery (per article)',
            nonMemberRate: 500,
            memberRate: 5000,
            discount: '2 %',
        },
        {
            parentName: 'Appraisal',
            testType: 'Plain Jewellery (per article)',
            nonMemberRate: 500,
            memberRate: 5000,
            discount: '2 %',
        },
        {
            parentName: 'Appraisal',
            testType: 'Plain Jewellery (per article)',
            nonMemberRate: 500,
            memberRate: 5000,
            discount: '2 %',
        },
    ]

    const [isModalVisible, setIsModalVisible] = useState(false);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div className="diamondGrading labRate">
            <div className="innerDiamondGrading">
                <div className="filter">
                    <Row className="spaceBetween">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="filterButtons">
                                <button className="toFormBtn" onClick={() => setIsModalVisible(true)}>Add Product</button>
                                <AddRate isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Table className="itemsTable" style={{ border: "1px solid #0000001c" }} dataSource={data} >
                        <Column title="Parent Name" dataIndex="parentName" key="parentName" />
                        <Column title="Type Of Testing" dataIndex="testType" key="testType" />
                        <Column title="Non Memeber Rate" dataIndex="nonMemberRate" key="nonMemberRate" />
                        <Column title="Memeber Rate" dataIndex="memberRate" key="memberRate" />
                        <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn editButton'>Edit Product</button>
                                </Space>
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <button className='actionBtn DeleteBtn'>Delete Product</button>
                                </Space>
                            )}
                        />
                    </Table>

                </div>
            </div>
        </div>

    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabRate)