import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Space, Pagination, Popconfirm } from "antd";
import { Modal, Button } from "antd";
import { Table } from "antd";
import {
	deleteDirectCash,
	getRecieptGeneratedEntries
} from "../../../../../modules/actions/reportsAction";
import { bindActionCreators } from "redux";
import { getDirectCash, editDirectCash } from "../../../../../modules/actions/reportsAction";
import { CopyFilled, PrinterFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import logo from "./../../../../../Images/Logo 2.png";
import CashRecieved from "../../../../TakeIn/CashRecieved";
import CashRecievedModal from "../../../../TakeIn/CashRecievedModal";
import { dateFormat } from "../../../../../common/common";
const { Column } = Table;

const DirectCash = (props) => {
	let {
		isModalVisibleBal,
		setIsModalVisibleBalance,
		showModalBal,
		handleOkBal,
		handleCancelBal
	} = props;
	const [data, setData] = useState("");
	const [TotalAmount, setTotalAmount] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [entryData, setEntryData] = useState("");
	const showModal2 = (record) => {
		setEntryData(record);
		setIsModalVisible2(true);
	};
	const handleOk2 = () => {
		setIsModalVisible2(false);
	};

	const handleCancel2 = () => {
		setIsModalVisible2(false);
	};

	const [isModalVisible3, setIsModalVisible3] = useState(false);
	const showModal3 = (record) => {
		setEntryData(record);
		setIsModalVisible3(true);
	};
	const handleOk3 = () => {
		setIsModalVisible3(false);
	};

	const handleCancel3 = () => {
		setIsModalVisible3(false);
	};

	// <th>Date</th>
	// <th>Entry No.</th>
	// <th>Customer ID</th>
	// <th>Customer Name</th>
	// <th>Delivered</th>
	// <th>Total Billed</th>
	// <th>Advance</th>
	// <th>Discount</th>
	// <th>Payment</th>
	useEffect(() => {
		console.log("propsss", props)
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));
			props.getDirectCash(
				startDate,
				endDate,
				page,
				props.searchEnter,
				props.selectedBranch,
				pageSize
			);
		}
	}, [
		// props.startDate,
		// props.endDate,
		page,
		// props.searchEnter,
		// props.permission.branchCode,
		pageSize,
		props.searchBool
		// ,
		// props.selectedBranch
	]);

	const editDirectCashFn = (obj, setCashRecivedData) => {
		props.editDirectCash(obj, props.selectedBranch, setCashRecivedData, page, pageSize);
	};

	return (
		<div className="DirectCashItem">
			{/* <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}> */}
			<div className="tableDiv">
				{props.reportsLoading ? (
					<div className="loaderDiv tableLoader">
						<div>
							<img src={logo} />
						</div>
					</div>
				) : null}
				<Table className="itemsTable" dataSource={props.cashReport} footer={() => {
					return (
						<Table className="itemsTable footertable" dataSource={[props.footer]}>
							<Column title="No. Data Entry" render={(text, record) => {
								return (
									<div>
										{props.total}
									</div>
								)
							}} />
							<Column title="Total Recieved Amount" dataIndex="TotalAmount" key="TotalAmount"
							render={(text, record) => {
								// console.log("recordddd", TotalAmount);
								return (
									<div>
										{props.total}
									</div>
								)
							}}  />
						</Table>
					)
				}}>
					<Column
						title="Date"
						key="date"
						dataIndex="date"
						render={(text, record) => {
							
							return (
								<p>
									{record.date
										? `${new Date(record.date).getDate()}-${new Date(record.date).getMonth() + 1
										}-${new Date(record.date).getFullYear()}`
										: "No date"}
								</p>
							);
						}}
					/>
					<Column title="Entry No." dataIndex="entryNo" key="entryNo" />
					<Column title="Customer ID" dataIndex="customerId" key="customerId" />
					<Column
						title="Customer Name"
						dataIndex="customerName"
						key="customerName"
						render={(text, record) => {
							return (
								<p>
									{/* {record.customerName} */}
									{record.customerObj &&
										record.customerObj.length &&
										record.customerObj[0].customerName}
								</p>
							);
						}}
					/>
					<Column title="Recieved Amount" dataIndex="CashReceive" key="CashReceive"
					render={(text, record) => {
						// console.log("render", record.CashReceive)
					
						// setTotalAmount(TotalAmount+record.totalAmount);
						return (
							<p>
								{record.CashReceive}
								{/* {record.customerObj &&
									record.customerObj.length &&
									record.customerObj[0].CashRecieve} */}
							</p>
						);
					}}
					/>
					<Column
						className="actions"
						title="Action"
						key="action"
						render={(text, record) => (
							<Space size="middle">
								<div onClick={() => showModal2(record)}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="#001529"
										className="bi bi-pencil-fill"
										viewBox="0 0 16 16"
									>
										<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
									</svg>
								</div>
								<Popconfirm
									onConfirm={() =>
										props.deleteDirectCash(record, props.permission.branchCode, page, pageSize)
									}
									title="Are you sure you want to delete this item?"
									okText="Yes"
									cancelText="No"
								>
									<div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="rgb(245, 0, 87)"
											className="bi bi-trash-fill"
											viewBox="0 0 16 16"
										>
											<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
										</svg>
									</div>
								</Popconfirm>
								<NavLink>
									<PrinterFilled onClick={() => showModal3(record)} className="printActions" />
								</NavLink>
							</Space>
						)}
					/>
				</Table>
			</div>
			<Pagination
				onChange={(ev, size) => {
					setPage(ev);
					setPageSize(size);
				}}
				pageSize={pageSize}
				current={props.page}
				total={props.total}
			/>
			<CashRecieved
				edit={true}
				isModalVisible={isModalVisible2}
				setIsModalVisible={setIsModalVisible2}
				handleOk={handleOk2}
				handleCancel={handleCancel2}
				data={entryData}
				editDirectCashFn={editDirectCashFn}
			/>
			<CashRecievedModal
				isModalVisible={isModalVisible3}
				setIsModalVisible={setIsModalVisible3}
				showModal={showModal3}
				handleOk={handleOk3}
				handleCancel={handleCancel3}
				data={entryData}
				edit={true}
				selectedCustomer={entryData.customerObj ? entryData.customerObj[0] : {}}
			/>
			{/* </Col>
            </Row> */}
		</div>
	);
};

const mapStateToProps = (store) => ({
	cashReport: store.reports.cashRecEntries,
	page: store.reports.page,
	total: store.reports.total,
	permission: store.users.permission,
	reportsLoading: store.reports.reportsLoading,
	footer: store.reports.footer
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getDirectCash,
			editDirectCash,
			deleteDirectCash
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(DirectCash);
