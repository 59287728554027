import Axios from 'axios'
import { REPORTS_LOADING, GET_SUMMARY_REPORT, GET_MONTH_SUMMARY_REPORT, GET_DAYS_SUMMARY_REPORT, GET_5_SUMMARY_REPORT, GET_5_YEAR_REPORT, GET_2_SUMMARY_REPORT, GET_3_SUMMARY_REPORT, GET_4_SUMMARY_REPORT, GET_1_SUMMARY_REPORT, GET_1_YEAR_REPORT_LOADER, GET_2_YEAR_REPORT_LOADER, GET_3_YEAR_REPORT_LOADER, GET_4_YEAR_REPORT_LOADER, GET_5_YEAR_REPORT_LOADER, GET_SEEN_REPORT, GET_UN_SEEN_REPORT } from '../actions/summaryReportsAction';

const initialState = {
    reportsLoading: false,
    entries: false,
    cashRecEntries: false,
    page: 1,
    total: 0,
    TotalLaboratoryCollection: false,
    TotalExpenses: false,
    TotalMembershipCollection: false,
    TotalRecievable: false,
    last5yearTotal: false,
    MonthData: false,
    dayData: false,
    TotalEducationalColletion: false,
    fiveYearData: false,
    Year1DataLoader: false,
    Year1Data: false,
    Year2Data: false,
    Year3Data: false,
    Year4Data: false,
    Year5Data: false,
    unSeenReports: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORTS_LOADING:
            return {
                ...state,
                reportsLoading: action.payload,
            };
        case GET_SUMMARY_REPORT:
            console.log("get summary report", action.payload)
            return {
                ...state,
                TotalLaboratoryCollection: action.payload.TotalLaboratoryCollection,
                TotalExpenses: action.payload.TotalExpenses,
                TotalMembershipCollection: action.payload.TotalMembershipCollection,
                TotalRecievable: action.payload.TotalRecievable,
                last5yearTotal: action.payload.last5yearTotal,
                TotalEducationalColletion: action.payload.TotalEducationalColletion
            };
        case GET_MONTH_SUMMARY_REPORT:
            return {
                ...state,
                MonthData: action.payload.MonthData
            }
        case GET_DAYS_SUMMARY_REPORT:
            return {
                ...state,
                dayData: action.payload.data
            }
        case GET_1_SUMMARY_REPORT:
            console.log(action.payload)
            return {
                ...state,
                Year1Data: action.payload.data.last5yearTotal[0]
            }

        case GET_2_SUMMARY_REPORT:
            console.log(action.payload)
            return {
                ...state,
                Year2Data: action.payload.data.last5yearTotal[0]
            }
        case GET_3_SUMMARY_REPORT:
            console.log(action.payload)
            return {
                ...state,
                Year3Data: action.payload.data.last5yearTotal[0]
            }
        case GET_4_SUMMARY_REPORT:
            console.log(action.payload)
            return {
                ...state,
                Year4Data: action.payload.data.last5yearTotal[0]
            }
        case GET_5_SUMMARY_REPORT:
            console.log(action.payload)
            return {
                ...state,
                Year5Data: action.payload.data.last5yearTotal[0]
            }


        case GET_1_YEAR_REPORT_LOADER:
            console.log(action.payload)
            return {
                ...state,
                Year1DataLoader: action.payload
            }

        case GET_2_YEAR_REPORT_LOADER:
            console.log(action.payload)
            return {
                ...state,
                Year2DataLoader: action.payload
            }

        case GET_3_YEAR_REPORT_LOADER:
            console.log(action.payload)
            return {
                ...state,
                Year3DataLoader: action.payload
            }

        case GET_4_YEAR_REPORT_LOADER:
            console.log(action.payload)
            return {
                ...state,
                Year4DataLoader: action.payload
            }
        case GET_5_YEAR_REPORT_LOADER:
            console.log(action.payload)
            return {
                ...state,
                Year5DataLoader: action.payload
            }
        case GET_UN_SEEN_REPORT:
            return {
                ...state,
                unSeenReports: action.payload
            }
        default:
            return state
    }
}

