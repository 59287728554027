import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, InputNumber } from 'antd';
import { saveDirectCash } from '../../modules/actions/dataEntryActions';
import { bindActionCreators } from 'redux';
import CashRecievedModal from './CashRecievedModal';
import { dateFormat } from '../../common/common';
import { getCustomer } from '../../modules/actions/customerActions';




function CashRecieved(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cash, setCash] = useState()
    const [cashRecivedData, setCashRecivedData] = useState(false)

    const showModal = () => {
        setIsModalVisible(true);
    };


    useEffect(() => {
        if (props.edit && props.data) {
            setCash(props.data.CashReceive)
            console.log(props.data)
        }
    }, [props.edit, props.data])

    const handleOk = () => {
        if (!props.edit) {
            setIsModalVisible(false);
            addDirectCash()
            showModal2()
        } else {
            props.setIsModalVisible(false);
            addDirectCash()
            showModal2()
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleOk2 = () => {
        setIsModalVisible2(false);
        props.getCustomer(props.selectedCustomer.customerId,props.permission.branchCode)
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
        props.getCustomer(props.selectedCustomer.customerId,props.permission.branchCode)
    };

    const addDirectCash = () => {
        if (!props.edit) {

          

            let obj = {
                "CashReceive": cash,
                "userName": "ali",
                "customerId": props.selectedCustomer.customerId,
                "PreAdvanceOrBalance": props.PreAdvanceOrBalance,
                "branchCode" : props.permission.branchCode,
                date: dateFormat(new Date())
            }
            props.saveDirectCash(obj, props.permission.branchCode, setCashRecivedData, props.selectedCustomer.customerId)
        } else {
            console.log(props.data)
           

                let obj = {
                    "CashReceive": cash,
                    "userName": "ali",
                    "customerId": props.selectedCustomer.customerId,
                    "PreAdvanceOrBalance": props.PreAdvanceOrBalance,
                    "branchCode" : props.permission.branchCode,
                    date: dateFormat(new Date())
                }
            
            setCashRecivedData(obj)
            props.editDirectCashFn(obj, props.permission.branchCode, props.selectedCustomer.customerId)

        }
    }
    return (
        <div>
            {!props.edit ?
                <Button type="primary" disabled={props.selectedCustomer && !props.selectedCustomer.customerName} className="customerModalButton" onClick={showModal}>
                    Cash Recieved
                </Button>
                : null}
            {!props.edit ?
                <Modal title="Cash Recieved" visible={isModalVisible} onOk={() => { handleOk() }} okText="Save & Close" onCancel={handleCancel}>
                    <div className="inputFields">
                        <label>
                            <h5>Cash Recieved Amount</h5>
                            <InputNumber type="number" onChange={(ev) => setCash(ev)} style={{ width: "170px" }} />
                        </label>
                    </div>
                </Modal>
                : <Modal title="Cash Recieved" visible={props.isModalVisible} onOk={() => { handleOk() }} okText="Save & Close" onCancel={props.handleCancel}>
                    <div className="inputFields">
                        <label>
                            <h5>Cash Recieved Amount</h5>
                            <InputNumber type="number" value={cash} onChange={(ev) => setCash(ev)} style={{ width: "170px" }} />
                        </label>
                    </div>
                </Modal>}

            <CashRecievedModal
                isModalVisible={isModalVisible2}
                setIsModalVisible={setIsModalVisible2}
                showModal={showModal2}
                handleOk={handleOk2}
                handleCancel={handleCancel2}
                data={cashRecivedData}
                edit={props.edit}
                selectedCustomer={props.edit && props.data ? props.data.customerObj[0] : props.selectedCustomer}
                customerId={props.selectedCustomer ? props.selectedCustomer.customerId : null}
            />
        </div>
    )

}

const mapStateToProps = (store) => ({
    permission: store.users.permission
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveDirectCash,
            getCustomer
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CashRecieved)