import { message } from 'antd';
import Axios from 'axios'
import { getCookie } from '../../common/common';
import store from '../store';


// Reports loader true
function ReportsLoadingBool(bool) {
    return dispatch => {
        dispatch(ReportsLoading(bool));
    };
}
export const REPORTS_LOADING = 'REPORTS_LOADING';
export const ReportsLoading = (bool) => {
    return {
        type: REPORTS_LOADING,
        payload: bool
    }
}


// Reports loader true
function GetSumaryLoadingBool(bool) {
    return dispatch => {
        dispatch(GetSumaryLoading(bool));
    };
}
export const GET_SUMMARY_LOADING = 'GET_SUMMARY_LOADING';
export const GetSumaryLoading = (bool) => {
    return {
        type: GET_SUMMARY_LOADING,
        payload: bool
    }
}


//Get summaryReport
export function getSummaryReport(startDate, endDate, categorie, branchCode) {
    return dispatch => {
        dispatch(GetSumaryLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}getSummaryReport/?startDate=${startDate}&endDate=${endDate}&category=${categorie}&branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            console.log("responsee araha?", res.data.data);
            dispatch(getSummaryReportSuccess(res.data.data.TotalLaboratoryCollection, res.data.data.TotalExpensesData,
                res.data.data.TotalMemberShipCollection, res.data.data.TotalRecievable, res.data.data.last5yearTotal,
                res.data.data.TotalEducationalColletion
            ))
            dispatch(GetSumaryLoadingBool(false))

            //     res.data.data.TotalMembershipCollection, res.data.data.TotalRecievable, res.data.data.last5yearTotal,
            //     res.data.data.TotalEducationalColletion
            // )
        }).catch((res) => {
            dispatch(GetSumaryLoadingBool(false))
        })
    };
}
export const GET_SUMMARY_REPORT = 'GET_SUMMARY_REPORT';
export const getSummaryReportSuccess = (TotalLaboratoryCollection,
    TotalExpenses,
    TotalMembershipCollection,
    TotalRecievable,
    last5yearTotal, TotalEducationalColletion) => ({
        type: GET_SUMMARY_REPORT,
        payload: {
            TotalLaboratoryCollection: TotalLaboratoryCollection,
            TotalExpenses: TotalExpenses,
            TotalMembershipCollection: TotalMembershipCollection,
            TotalRecievable: TotalRecievable,
            last5yearTotal: last5yearTotal,
            TotalEducationalColletion: TotalEducationalColletion,

        }
    });

// Reports loader true
function ReportsMonthLoadingBool(bool) {
    return dispatch => {
        dispatch(ReportsMonthLoading(bool));
    };
}
export const REPORTS_MONTH_LOADING = 'REPORTS_MONTH_LOADING';
export const ReportsMonthLoading = (bool) => {
    return {
        type: REPORTS_MONTH_LOADING,
        payload: bool
    }
}
//Get month wise summaryReport
export function getMonthSummaryReport(type, year, branchCode) {
    return dispatch => {
        dispatch(ReportsMonthLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}get5yearSummaryReport/?type=${type}&year=${year}&branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getMonthSummaryReportSuccess(res.data.data))
            dispatch(ReportsMonthLoadingBool(false))
        }).catch((res) => {
            dispatch(ReportsMonthLoadingBool(false))
        })
    };
}
export const GET_MONTH_SUMMARY_REPORT = 'GET_MONTH_SUMMARY_REPORT';
export const getMonthSummaryReportSuccess = (res) => ({
    type: GET_MONTH_SUMMARY_REPORT,
    payload: {
        MonthData: res,
    }
});


// Reports loader true
function ReportsDayLoadingBool(bool) {
    return dispatch => {
        dispatch(ReportsDayLoading(bool));
    };
}
export const REPORTS_DAY_LOADING = 'REPORTS_DAY_LOADING';
export const ReportsDayLoading = (bool) => {
    return {
        type: REPORTS_DAY_LOADING,
        payload: bool
    }
}

//Get day wise summaryReport
export function getDaySummaryReport(type, year, month, branchCode, loaderDis) {
    return dispatch => {
        if (!loaderDis) {
            dispatch(ReportsDayLoadingBool(true))
        }
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}get5yearSummaryReport/?type=${type}&year=${year}&month=${month}&branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getDaySummaryReportSuccess(res.data.data))
            if (!loaderDis) {
                dispatch(ReportsDayLoadingBool(false))
            }
        }).catch((res) => {
            if (!loaderDis) {
                dispatch(ReportsDayLoadingBool(false))
            }
        })
    };
}
export const GET_DAYS_SUMMARY_REPORT = 'GET_DAYS_SUMMARY_REPORT';
export const getDaySummaryReportSuccess = (data) => ({
    type: GET_DAYS_SUMMARY_REPORT,
    payload: {
        data
    }
});


export const GET_1_YEAR_REPORT_LOADER = 'GET_1_YEAR_REPORT_LOADER';
export const get1yearReportLoader = (data) => ({
    type: GET_1_YEAR_REPORT_LOADER,
    payload: data
});

export const GET_2_YEAR_REPORT_LOADER = 'GET_2_YEAR_REPORT_LOADER';
export const get2yearReportLoader = (data) => ({
    type: GET_2_YEAR_REPORT_LOADER,
    payload: data
});
export const GET_3_YEAR_REPORT_LOADER = 'GET_3_YEAR_REPORT_LOADER';
export const get3yearReportLoader = (data) => ({
    type: GET_3_YEAR_REPORT_LOADER,
    payload: data
});
export const GET_4_YEAR_REPORT_LOADER = 'GET_4_YEAR_REPORT_LOADER';
export const get4yearReportLoader = (data) => ({
    type: GET_4_YEAR_REPORT_LOADER,
    payload: data
});
export const GET_5_YEAR_REPORT_LOADER = 'GET_5_YEAR_REPORT_LOADER';
export const get5yearReportLoader = (data) => ({
    type: GET_5_YEAR_REPORT_LOADER,
    payload: data
});

//Get day wise summaryReport
export function get5yearReport(branchCode, year, numberYear) {
    return dispatch => {
        if (numberYear === 1) {
            dispatch(get1yearReportLoader(true))

        } else if (numberYear === 2) {
            dispatch(get2yearReportLoader(true))

        } else if (numberYear === 3) {
            dispatch(get3yearReportLoader(true))

        } else if (numberYear === 4) {
            dispatch(get4yearReportLoader(true))

        } else if (numberYear === 5) {
            dispatch(get5yearReportLoader(true))

        }
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}get5yearReport/?branchCode=${branchCode}&year=${year}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            if (numberYear === 1) {
                console.log(res.data)
                dispatch(get1yearReportLoader(false))
                dispatch(get1yearReportSuccess(res.data.data))
            } else if (numberYear === 2) {
                console.log(res.data)
                dispatch(get2yearReportLoader(false))
                dispatch(get2yearReportSuccess(res.data.data))
            } else if (numberYear === 3) {
                console.log(res.data)
                dispatch(get3yearReportLoader(false))
                dispatch(get3yearReportSuccess(res.data.data))
            } else if (numberYear === 4) {
                console.log(res.data)
                dispatch(get4yearReportLoader(false))
                dispatch(get4yearReportSuccess(res.data.data))
            } else if (numberYear === 5) {
                console.log(res.data)
                dispatch(get5yearReportLoader(false))
                dispatch(get5yearReportSuccess(res.data.data))
            }
        }).catch((res) => {
            if (numberYear === 1) {
                dispatch(get1yearReportLoader(false))
            } else if (numberYear === 2) {
                dispatch(get2yearReportLoader(false))
            } else if (numberYear === 3) {
                dispatch(get3yearReportLoader(false))
            } else if (numberYear === 4) {
                dispatch(get4yearReportLoader(false))
            } else if (numberYear === 5) {
                dispatch(get5yearReportLoader(false))
            }
        })
    };
}


export const GET_1_SUMMARY_REPORT = 'GET_1_SUMMARY_REPORT';
export const get1yearReportSuccess = (data) => ({
    type: GET_1_SUMMARY_REPORT,
    payload: {
        data
    }
});
export const GET_2_SUMMARY_REPORT = 'GET_2_SUMMARY_REPORT';
export const get2yearReportSuccess = (data) => ({
    type: GET_2_SUMMARY_REPORT,
    payload: {
        data
    }
});
export const GET_3_SUMMARY_REPORT = 'GET_3_SUMMARY_REPORT';
export const get3yearReportSuccess = (data) => ({
    type: GET_3_SUMMARY_REPORT,
    payload: {
        data
    }
});
export const GET_4_SUMMARY_REPORT = 'GET_4_SUMMARY_REPORT';
export const get4yearReportSuccess = (data) => ({
    type: GET_4_SUMMARY_REPORT,
    payload: {
        data
    }
});
export const GET_5_SUMMARY_REPORT = 'GET_5_SUMMARY_REPORT';
export const get5yearReportSuccess = (data) => ({
    type: GET_5_SUMMARY_REPORT,
    payload: {
        data
    }
});


export const SeenFn = (obj, day, dataYear, month, selectedBranch, data, check, updateMonthlyData,index) => {
    return dispatch => {
        updateMonthlyData(index,true,false)
        let token = getCookie("authToken")
        console.log('ads')
        console.log(obj, day, dataYear, month, selectedBranch)
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}report_seen/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            console.log(month)
            dispatch(getSeenReport(selectedBranch))
            // dispatch(getDaySummaryReport(day, dataYear, month, selectedBranch, true))
        }).catch((err) => {
            updateMonthlyData(index,false,false)
            console.log(err.response)
        })
    };
}


export const unSeenFn = (obj, day, dataYear, month, selectedBranch, data, check, updateMonthlyData,index) => {
    return dispatch => {
        updateMonthlyData(index,false,true)
        let token = getCookie("authToken")
        console.log('ads')
        console.log(obj, day, dataYear, month, selectedBranch)
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}report_unseen/`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            console.log(month)
            dispatch(getUnSeenFn(selectedBranch))
            // dispatch(getDaySummaryReport(day, dataYear, month, selectedBranch, true))
        }).catch((err) => {
            updateMonthlyData(index,true,true)
            console.log(err.response)
        })
    };
}



export const getUnSeenFn = (branchCode) => {
    return dispatch => {
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}report_unseen/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            // console.log(month)
            console.log(res.data.data)
            dispatch(getUnSeenReportSuccess(res.data.data))
            // dispatch(getDaySummaryReport(day, dataYear, month, selectedBranch, true))
        }).catch((err) => {
            console.log(err.response)
        })
    };
}
export const GET_UN_SEEN_REPORT = 'GET_UN_SEEN_REPORT';
export const getUnSeenReportSuccess = (res) => ({
    type: GET_UN_SEEN_REPORT,
    payload: res,
});


//Get month wise summaryReport
export function getSeenReport(branchCode) {
    return dispatch => {
        // dispatch(ReportsMonthLoadingBool(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}report_seen/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((res) => {
            dispatch(getSeenReportSuccess(res.data.data))
            // dispatch(ReportsMonthLoadingBool(false))
        }).catch((res) => {
            // dispatch(ReportsMonthLoadingBool(false))
        })
    };
}
export const GET_SEEN_REPORT = 'GET_SEEN_REPORT';
export const getSeenReportSuccess = (res) => ({
    type: GET_SEEN_REPORT,
    payload: {
        MonthData: res,
    }
});

export const DeleteUnSeenFn = (id,obj, day, dataYear, month, selectedBranch, data, check, updateMonthlyData,index) => {
    return dispatch => {
        console.log("DeleteUnSeenFn")
        updateMonthlyData(index,true,true)
        let token = getCookie("authToken")
        Axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}report_unseen/${id}/?branchCode=${selectedBranch}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
        }).then((res) => {
            // console.log(month)
            console.log(res.data.data)
            dispatch(getUnSeenFn(selectedBranch))
            // dispatch(getUnSeenReportSuccess(res.data.data))
            // dispatch(getDaySummaryReport(day, dataYear, month, selectedBranch, true))
        }).catch((err) => {
            // updateMonthlyData(data, true, false)
        updateMonthlyData(index,true,true)
        console.log(err.response)
        })
    };
}



// export const GET_5_SUMMARY_REPORT = 'GET_5_SUMMARY_REPORT';
// export const get5yearReportSuccess = (data) => ({
//     type: GET_5_SUMMARY_REPORT,
//     payload: {
//         data
//     }
// });



// get5yearReport/
// get5yearSummaryReport/
// getSummaryReport/

