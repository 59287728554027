import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EducationalExpenses from "./EducationalExpenses";
import LaboratoryExpenses from "./LaboratoryExpenses";
import {
	getAllAccountEntries,
	getAllExpenseNames
} from "../../../../../modules/actions/reportsAction";
import { dateFormat } from "../../../../../common/common";
import { bindActionCreators } from "redux";

const ExpenseReport = (props) => {
	const [tabs, setTabs] = useState(true);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [tabName, setTabName] = useState("Laboratory");

	useEffect(() => {
		props.getAllExpenseNames(props.selectedBranch);
	}, []);

	useEffect(() => {
		console.log(props.userPermissions.userType)
	}, [props.userPermissions])

	useEffect(() => {
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));
			props.getAllAccountEntries(
				page,
				props.selectedReports,
				startDate,
				endDate,
				tabName,
				"Expense",
				props.multiVal,
				props.selectedBranch,
				pageSize,
				props.userPermissions.userType.toLowerCase(),
				props.keyword
			);
		}
	}, [
		props.selectedReports,
		props.searchBool,
		// props.startDate,
		// props.endDate,
		page,
		// tabName,
		// props.multiVal,
		pageSize,
		// props.selectedBranch,
		// props.userPermissions.userType,
		// props.searchEnter,
	]);

	return (
		<div className="ExpenseReport">
			<div className="expensesBtn">
				<div>
					<button
						onClick={() => {
							setTabs(true);
							setTabName("Laboratory");
						}}
						className={tabs ? "active" : ""}
					>
						LABORATORY EXPENSES
					</button>
					<button
						onClick={() => {
							setTabs(false);
							setTabName("Education");
						}}
						className={!tabs ? "active" : ""}
					>
						EDUCATIONAL EXPENSES
					</button>
				</div>
			</div>
			<LaboratoryExpenses
				pageA={page}
				setPageA={setPage}
				pageSizeA={pageSize}
				setPageSizeA={setPageSize}
				selectedReports={props.selectedReports}
				startDate={dateFormat(new Date(props.startDate))}
				endDate={dateFormat(new Date(props.endDate))}
				tabName={tabName}
				type="Expense"
				multiVal={props.multiVal}
			/>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		permission: store.users.permission,
		userPermissions: store.authentication.userPermissions,

	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAllAccountEntries,
			getAllExpenseNames,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReport);
