import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'antd';
import { Table, Tag, Space } from 'antd';
const { Column, ColumnGroup } = Table;

const ExpenseModal = ({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    TotalExpense,
    collectionData,
    handleCancel, ...props }) => {
    const [updatedTotalExpense, setUpdatedTotalExpense] = useState([])

    let data = [
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },
        {
            date: '3/22/2021',
            entryNo: 508905,
            expenseName: 'petrol',
            description: 'da afsj saduas dsadh',
            enteredBy: 'Saqib',
            amount: 1000
        },

    ]
    const [totalAmount, setTotalAmount] = useState(0)
    useEffect(() => {
        let arr = []
        if (TotalExpense.length) {
            // console.log(TotalExpense, 'TotalExpenseTotalExpense')
            let totalAmountNumber = 0
            TotalExpense.map((a, i) => {
                if (props.userPermissions.userType != "Admin") {
                    if (a.private === "false") {
                        // setTotalAmount()
                        // console.log(a,'TotalExpenseT/otalExpense')
                        totalAmountNumber += Number(a.amount)
                        arr.push(a)
                    }
                } else if (props.userPermissions.userType === "Admin") {
                    // if (a.private === "false") {
                    totalAmountNumber += Number(a.amount)
                    arr.push(a)
                    // }
                }
            })
            setUpdatedTotalExpense(arr)
            setTotalAmount(totalAmountNumber)
        }else{
            setUpdatedTotalExpense([])

        }
    }, [TotalExpense,collectionData.TotalExpenses])
    return (
        <>

            <Modal title="Report (TOTAL EXPENSE)" className="reportModal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <Table pagination={false} className="itemsTable" dataSource={updatedTotalExpense} footer={() => {
                    return (
                        <div className="expenseTotal">
                            <h5>TOTAL</h5>
                            <p>{props.userPermissions.userType === "Admin" ? collectionData.TotalExpenses : totalAmount }</p>
                        </div>
                    )
                }} >
                    <Column title="Date" dataIndex="date" key="date" render={(record) => {
                        return (
                            <div onClick={() => { }}>
                                {new Date(record).toLocaleDateString()}
                            </div>
                        )
                    }} />
                    <Column title="Entry No." dataIndex="code" key="code" />
                    <Column title="Expense Name" dataIndex="name" key="name" />
                    <Column title="Sub Head" dataIndex="subHead" key="subHead" />
                    <Column title="Description" dataIndex="description" key="description" />
                    <Column title="Entered By" dataIndex="userName" key="userName" />
                    <Column title="Amount" dataIndex="amount" key="amount" />
                </Table>
            </Modal>
        </>
    );
};

const mapStateToProps = (store) => ({
    userPermissions: store.authentication.userPermissions,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpenseModal)