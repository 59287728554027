import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function AdminLayoutMain(props) {
	const [arr, setArr] = useState([
		{ name: "Add Prices", route: "/add-prices" },
		{ name: "Add Branch", route: "/add-branch" },
		{ name: "Add User", route: "/add-user" },
		{ name: "Admin Account", route: "/admin-account" }
	]);

	const [whichTrue, setWhichTrue] = useState("");

	return (
		<div className="dashboardMain limiter ">
			{arr.map((a, i) => {
				return (
					<div key={i} className="div">
						<div className="wrap-login100-form-btn full">
							<div className="login100-form-bgbtn"></div>
							<button onClick={() => window.location.href = (a.route)} className="login100-form-btn">
								{a.name}
							</button>
						</div>
					</div>
				);
			})}
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayoutMain);
