import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd';
import logo from './../../../../../Images/Logo 2.png'

export const CustomerSumModal = (props) => {
    let { isModalVisible,
        setIsModalVisible,
        showModal,
        handleOk,
        handleCancel,
        singleCustomerObj
    } = props
    const [summaryArr, setSummaryArr] = useState([])
    useEffect(() => {
        let arr = []
        if (singleCustomerObj) {
            console.log(singleCustomerObj,'singleCustomerObj')
            // arr = (singleCustomerObj.summaryData.filter((a, i) => a.Quantity != 0 || a.price != 0))
        }
        setSummaryArr(arr)
    }, [singleCustomerObj])

    return (
        <div>
            <Modal className="CustomerSumModal" title="Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <div className="innerCustomerSumModal">
                    <div className="tableDiv">
                        {props.reportsLoading ?
                            <div className="loaderDiv tableLoader">
                                <div>
                                    <img src={logo} />
                                </div>
                            </div>
                            : null}
                        <table className="itemsTable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="textCenter">Quantity</th>
                                    <th className="textCenter">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaryArr.length > 0 ?
                                    summaryArr.map((a, i) => {
                                        return (
                                            <tr>
                                                <th>{a.name}</th>
                                                <td className="textCenter">{a.Quantity}</td>
                                                <td className="textCenter">{a.price}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="3">No Summary Data</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (store) => ({
    reportsLoading: store.reports.reportsLoading,

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSumModal)
