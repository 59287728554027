import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Badge, Button, Input, InputNumber, Modal, Popconfirm } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import {
	deleteEntryExpense,
	saveEntryExpense
} from "../../modules/actions/dataEntryActions";

function AddAndViewExpense(props) {
	const [addedExpensesModel, setAddedExpensesModel] = useState(false);
	const [edit, setEdit] = useState(false);

	const [name, setName] = useState("");
	const [amount, setAmount] = useState(0);
	const [index, setIndex] = useState("");

	const addExpense = () => {
		if(props.type === "takeOut"){
			props.saveEntryExpense({
				entryNo:props.entryNo,
				expenseName: name,
				expenseAmount: amount	
			})
		}
		let obj = {
			expenseName: name,
			expenseAmount: amount
		};
		let arr = [...props.addedExpenses];
		arr.push(obj);
		props.setAddedExpenses(arr);
		setName("");
		setAmount(0);
	};

	const editFun = (a, i) => {
		setName(a.expenseName);
		setAmount(a.expenseAmount);
		setIndex(i);
		setEdit(true);
		setAddedExpensesModel(false);
	};

	const updateExpense = () => {
		let arr = [...props.addedExpenses];
		arr[index].expenseName = name;
		arr[index].expenseAmount = amount;
		props.setAddedExpenses(arr);
		setAddedExpensesModel(true);
		setEdit(false);
		setIndex("");
		setName("");
		setAmount("");
	};


	const deleteConfrim = (id, i) => {
		props.deleteEntryExpense(id,i,confirm)
		// confirm(e, i)
	}

	const confirm = (e, i) => {
		let arr = [...props.addedExpenses];
		arr.splice(i, 1);
		props.setAddedExpenses(arr);
	};

	return (
		<div className="entriesModal">
			<Badge count={props.addedExpenses && props.addedExpenses.length}>
				<Button
					disabled={props.fromEdit ? false : !props.selectedCustomer.customerName}
					type="primary"
					className="customerModalButton"
					onClick={() => props.setExpenseModel(true)}
				>
					Add Or View Expenses
				</Button>
			</Badge>
			<Modal
				title="Entrie List"
				className="expenseModal"
				centered
				style={{ top: 20, overflow: "auto" }}
				visible={props.expenseModel}
				onOk={() => (edit ? updateExpense() : addExpense())}
				onCancel={() => props.setExpenseModel(false)}
				okText={edit ? "Update Expense" : "Add Expense"}
				cancelText="Close"
				okButtonProps={{ style: { background: "#001529", color: "white", borderColor: "#001529" } }}
				cancelButtonProps={{
					style: { background: "#f50057", color: "white", borderColor: "#f50057" }
				}}
			>
				<div className="addExpenseInput">
					<div className="inputFields">
						<label className="w100P">
							<h5>Expense name</h5>
							<Input
								value={name}
								onChange={(e) => setName(e.target.value)}
								type="text"
								style={{ width: "100%" }}
							/>
						</label>
					</div>
					<div className="inputFields" style={{ marginTop: 10 }}>
						<label className="w100P">
							<h5>Expense amount</h5>
							<Input
								type="number"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								style={{ width: "100%" }}
							/>
						</label>
					</div>

					{props.addedExpenses && props.addedExpenses.length && !edit ? (
						<div className="inputFields" style={{ marginTop: 20 }}>
							<Badge count={props.addedExpenses.length}>
								<Button
									onClick={() => setAddedExpensesModel(true)}
									style={{ width: "100%", background: "green", color: "white" }}
									className="addCustomer"
								>
									View Expense
								</Button>
							</Badge>
							<Modal
								title="Added Expenses"
								className="expenseModal"
								centered
								style={{ top: 20, overflow: "auto" }}
								visible={addedExpensesModel}
								footer={null}
								onCancel={() => setAddedExpensesModel(false)}
								cancelText="Close"
							>
								<div className="addedExpensesList">
									<table>
										<tbody>
											<tr>
												<th className="sNo">No.</th>
												<th className="name">Name</th>
												<th className="amount">Amount</th>
												<th className="amount">Action</th>
											</tr>
											{props.addedExpenses.map((a, i) => {
												return (
													<tr key={i}>
														<td className="sNo">{i + 1}.</td>
														<td className="name">{a.expenseName}</td>
														<td className="amount">{a.expenseAmount}</td>
														<td className="amount">
															<EditFilled
																onClick={() => editFun(a, i)}
																style={{ color: "blue", marginRight: 5, cursor: "pointer" }}
															/>
															<Popconfirm
																onConfirm={(e) => {
																	props.fromEdit ?
																		deleteConfrim(a.id, i)
																		:
																		confirm(e, i)
																}}
																title="Are you sure？"
																okText="Yes"
																cancelText="No"
															>
																<DeleteFilled style={{ color: "red", cursor: "pointer" }} />
															</Popconfirm>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
								<Button
									onClick={() => setAddedExpensesModel(false)}
									style={{ width: "100%", background: "#001529", color: "white" }}
									className="addCustomer"
								>
									Close
								</Button>
							</Modal>
						</div>
					) : null}
				</div>
			</Modal>
		</div>
	);
}

const mapStateToProps = ({ store }) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	deleteEntryExpense,
	saveEntryExpense
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddAndViewExpense);
