import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, Button, Col, Row, Table, Space } from 'antd';
import { bindActionCreators } from 'redux';
import { addBranch, deleteBranch, getBranch, updateBranch } from '../../../modules/actions/branchActions';

const { Column, ColumnGroup } = Table;


function AddBranch(props) {
    const [page, setPage] = React.useState(1);
    const [branches, setBranches] = useState([])
    const [branchName, setBranchName] = useState('')
    const [branchCode, setBrancheCode] = useState('')
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState('')


    useEffect(() => {
        props.getBranch(props.permission.branchCode)
    }, [props.permission.branchCode])

    useEffect(() => {
        setBranches(props.branch)
    }, [props.branch])

    const addBranch = () => {
        if (!edit) {
            let obj = {
                "branchName": branchName,
                "branchCode": branchCode
            }
            props.addBranch(obj, props.permission.branchCode)
            clearState()
        } else if (edit) {
            let obj = {
                "branchName": branchName,
                "branchCode": branchCode,
            }
            props.updateBranch(obj, id, props.permission.branchCode)
            clearState()
        }
    }

    const removeBranch = (obj) => {
        props.deleteBranch(obj, props.permission.branchCode)
    }

    const editBranch = (data) => {
        setBranchName(data.branchName);
        setBrancheCode(data.branchCode);
        setId(data.id)
        setEdit(true)
    }
    const clearState = () => {
        setBranchName("");
        setBrancheCode("");
        setEdit(false)
    }

    return (
        <div className="AddBranch">
            <div className="innerAddBranch">
                <Row className="spaceBetween">

                    {props.userPermissions.admin_panel_add_branch_add &&
                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <div className="branchForm">
                                <div className="branchInput">
                                    <div className="inputFields">
                                        <label>
                                            <h5>Branch Name</h5>
                                            <Input value={branchName} onChange={(ev) => setBranchName(ev.target.value)} />
                                        </label>
                                    </div>
                                    <div className="inputFields">
                                        <label>
                                            <h5>Branch Code</h5>
                                            <Input value={branchCode} onChange={(ev) => setBrancheCode(ev.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="branchAddBtn m-t-10">
                                    <Button type="primary" onClick={addBranch}> {edit ? `EDIT` : `ADD`} </Button>
                                </div>
                            </div>
                        </Col>
                    }

                    <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                        <div className="branchList">
                            <Table className="itemsTable" dataSource={branches} pagination={{
                                onChange(current) {
                                    setPage(current);
                                }
                            }}
                            >
                                <Column title="S.No" key="index"
                                    render={(value, item, index) => index + 1} />
                                <Column title="Branch Name" dataIndex="branchName" key="branchName" />
                                <Column title="Branch Code" dataIndex="branchCode" key="branchCode" />
                                {/* <Column title="Actions" render={(value, item, index) => {
                                    return (
                                        <>
                                            {
                                                props.userPermissions.admin_panel_add_branch_edit &&
                                                <a className="m-r-5" onClick={() => editBranch(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#001529" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                    </svg>
                                                </a>
                                            }

                                            {
                                                props.userPermissions.admin_panel_add_branch_delete &&
                                                <a onClick={() => removeBranch(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(245, 0, 87)" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                    </svg>
                                                </a>
                                            }
                                        </>
                                    )
                                }} /> */}
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

const mapStateToProps = (store) => {
    return {
        userPermissions: store.authentication.userPermissions,
        branch: store.branchStore.branch,
        permission: store.users.permission
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBranch,
            addBranch,
            deleteBranch,
            updateBranch

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBranch)