import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AcceptDiscount } from "../../../../modules/actions/AccountActions";
import { getSingleDataEntry } from "../../../../modules/actions/dataEntryActions";
import logo from "./../../../../Images/Logo 2.png";

const ModalReportNoti = (props) => {
	let { data } = props;

	const [totalAmount, setTotalAmount] = useState(0);
	const [entries, setEntries] = useState(false);
	useEffect(() => {
		if (entries && entries.length) {
			let t = 0;
			entries.map((a) => {
				t += Number(a.serviceRate);
			});
			setTotalAmount(t);
		}
	}, [entries]);
	useEffect(() => {
		if (props.isModalVisible) {
			console.log(data.entryNo, "data.entryNo");
			props.getSingleDataEntry(data.entryNo, props.permission.branchCode);
		}
	}, [data, props.isModalVisible]);

	useEffect(() => {
		if (props.singleEntry) {
			setEntries(props.singleEntry.items);
		}
	}, [props]);

	const update = (decision) => {
		let obj = {
			entryNo: props.singleEntry.entryNo,
			entryDate: props.singleEntry.entryDate,
			totalAmount: props.singleEntry.totalAmount,
			deliveryDate: props.singleEntry.deliveryDate,
			advance: props.singleEntry.advance,
			boxNo: props.singleEntry.boxNo,
			branchCode: props.singleEntry.branchCode,
			selectBoxOrPacket: props.singleEntry.selectBoxOrPacket,
			checkBoxAskForDiscount: props.singleEntry.checkBoxAskForDiscount,
			customerComments: props.singleEntry.customerComments,
			userName: props.singleEntry.userName,
			discountApproved: decision,
			discountAmount: props.singleEntry.discountAmount,
			discountReason: props.singleEntry.discountReason,
			customerId: props.singleEntry.customerId,
			customerName: props.singleEntry.customerName,
			items: props.singleEntry.items,
			expenses: props.singleEntry.expenses
			// "customerId"
		};
		// console.log(props.permission.branchCode)
		props.AcceptDiscount(obj, props.permission.branchCode, props.handleCancel);
	};
	// onOk={props.handleOk}  okText={"Accept"} cancelText={'Declined'}

	return (
		// <div className="itemReportModal">
		<Modal
			title="Details"
			className="itemReportModal"
			visible={props.isModalVisible}
			onCancel={props.handleCancel}
			footer={[
				<Button key="1" type="primary" onClick={() => update("Approved")}>
					Accept
				</Button>,
				<Button key="2" onClick={() => update("Decline")}>
					Declined
				</Button>
			]}
		>
			<div className="modalBodyItem">
				<div className="detail">
					<div className="entryDetail">
						<table>
							<tbody>
								<tr>
									<th onClick={() => {}}>Entered By:</th>
									<td>{data.userName}</td>
								</tr>
								{data.deliveryObj ? (
									<tr>
										<th>Delivered By:</th>
										<td>{data.deliveryObj.userName}</td>
									</tr>
								) : null}
								<tr>
									<th>Packet:</th>
									<td>{data.boxNo}</td>
								</tr>
							</tbody>
						</table>
						<div></div>
					</div>
					<div className="statusDetail">
						<div className="entryDiv">
							<h3>Entry On</h3>
							<p>{new Date(data.date).toLocaleString().split(",")[0]}</p>
							<p>{new Date(data.date).toLocaleString().split(",")[1]}</p>
						</div>
						{data.deliveryObj ? (
							<div className="deliveryDiv">
								<h3>Delivered</h3>
								<p>{new Date(data.deliveryObj.deliveryDateStr).toLocaleString().split(",")[0]}</p>
								<p>{new Date(data.deliveryObj.deliveryDateStr).toLocaleString().split(",")[1]}</p>
							</div>
						) : null}
					</div>
				</div>
				<div className="customerDetail">
					<h3>Customer Details:</h3>
					<table>
						<tbody>
							<tr>
								<th>Name</th>
								<th>ID</th>
								<th>Entry No</th>
								<th>Amount</th>
								<th>Req Discount</th>
								<th>Discount Reason</th>
							</tr>
							<tr>
								<td>{data.customerName}</td>
								<td>{data.customerId}</td>
								<td>{data.entryNo}</td>
								<td>{totalAmount}</td>
								<td>{data.discountAmount}</td>
								<td>{data.discountReason}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="itemsDetail">
					<h3>Items Details:</h3>
					{entries ? (
						<table>
							<tbody>
								<tr>
									<th>S.No</th>
									<th>Service</th>
									<th>Color</th>
									<th>Qty</th>
									<th>Weight</th>
									<th>Seal</th>
									<th>Mine</th>
									<th>Urgent</th>
									<th>Gold Test</th>
									<th>Amount</th>
								</tr>
								{entries.map((a, i) => {
									return (
										<tr>
											<td>{`${i + 1}).`}</td>
											<td>{a.service}</td>
											<td>{a.color}</td>
											<td>{a.quantity}</td>
											<td>{a.weight}</td>
											<td>{a.sealRate}</td>
											<td>{a.mineRate}</td>
											<td>{a.urgentRate}</td>
											<td>{a.goldTestingRate}</td>
											<td>{a.serviceRate}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						<div className="loaderDiv modalReportNotiLoader">
							<div>
								<img src={logo} />
							</div>
						</div>
					)}
				</div>
				<div className="totalSection">
					<div className="commentBox">
						<h6>Customer Comment</h6>
						<p>{data.customerComments}</p>
						{/* <p>dsadas dasd sa dasd as sa dasd asd a as ds dasdas asdasasd</p> */}
					</div>
					<div>
						<table>
							<tbody>
								<tr>
									<th>Total Amount:</th>
									<td>{totalAmount}</td>
								</tr>
								<tr>
									<th>Advance:</th>
									<td>{data.advance}</td>
								</tr>
								<tr>
									<th>Payment Recieved:</th>
									<td>{data.deliveryObj ? data.deliveryObj.paymentRecieved : null}</td>
								</tr>
								<tr style={{ borderTop: "1px solid black" }}>
									<th>Balance:</th>
									<td>
										{totalAmount -
											(data.deliveryObj ? data.deliveryObj.paymentRecieved : 0) -
											data.advance}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Modal>
		// </div>/
	);
};

const mapStateToProps = (store) => {
	return {
		user: store.authentication.user,
		singleEntry: store.dataEntry.singleEntry,
		permission: store.users.permission,
		dataEntryLoading: store.dataEntry.dataEntryLoading
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getSingleDataEntry,
			AcceptDiscount
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ModalReportNoti);
