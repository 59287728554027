import { message } from 'antd';
import Axios from 'axios'
import { deleteAllCookies, getCookie, setCookie } from '../../common/common';
import store from '../store';


// consultation loader true
function branchLoader(bool) {
    return dispatch => {
        dispatch(BranchLoading(bool));
    };
}
export const BRANCH_LOADING = 'BRANCH_LOADING';
export const BranchLoading = (bool) => {
    return {
        type: BRANCH_LOADING,
        payload: bool
    }
}

// get Branch
export function getBranch(branchCode) {
    return dispatch => {
        dispatch(branchLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}branches/${branchCode ? `?branchCode=${branchCode}` : ''}`,
            // headers: {
            //     'Authorization': `JWT ${token}`
            // },
        }).then((res) => {
            dispatch(getBranchSuccess(res.data))
            dispatch(branchLoader(false))
        }).catch(() => {
            dispatch(branchLoader(false))
        });
    };
}
export const GET_BRANCH = 'GET_BRANCH';
export const getBranchSuccess = (data) => ({
    type: GET_BRANCH,
    payload: { data }
});

// add Branch
export function addBranch(obj, branchCode) {
    return dispatch => {
        dispatch(branchLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}branches/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Branch Added")
            dispatch(getBranch())
            dispatch(branchLoader(false))
        }).catch(() => {
            dispatch(branchLoader(false))
        });
    };
}

// Delete Branch
export function deleteBranch(obj, branchCode) {
    return dispatch => {
        dispatch(branchLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'Delete',
            url: `${process.env.REACT_APP_BACKEND_URL}branches/${obj.id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Branch Deleted")
            dispatch(getBranch())
            dispatch(branchLoader(false))
        }).catch(() => {
            dispatch(branchLoader(false))
        });
    };
}

// Update Branch
export function updateBranch(obj, id, branchCode) {
    return dispatch => {
        dispatch(branchLoader(true))
        let token = getCookie("authToken")
        Axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}branches/${id}/?branchCode=${branchCode}`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: obj
        }).then((res) => {
            message.success("Branch Updated")
            dispatch(getBranch())
            dispatch(branchLoader(false))
        }).catch(() => {
            dispatch(branchLoader(false))
        });
    };
}



