import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Space, Pagination, Button, Input } from "antd";
import { Col, Row } from "antd";
import { Table } from "antd";
import {
	getCustomerSummaryEntry,
	getSingleCustomerEntries
} from "../../../../modules/actions/reportsAction";
import { withRouter } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyFilled, PrinterFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ModalReport } from "./ModalReport";
import { CustomerSumModal } from "./customerSummaryModals/customerSumModal";
import CustomerBalanceModal from "./customerSummaryModals/customerBalanceModal";
import { dateFormat } from "../../../../common/common";

import logo from "./../../../../Images/Logo 2.png";
import { allCustomers } from "../../../../modules/actions/customerActions";
const { Column } = Table;

function CustomerSummary(props) {
	const [page, setPage] = useState(1);
	const [insidePage, setInsidePage] = useState(1);
	const [insidePageSize, setInsidePageSize] = useState(10);
	const [data, setData] = useState(false);
	const [pageSize, setPageSize] = useState(10);

	const [singleCustomerObj, setSingleCustomerObj] = useState({});

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const [isModalVisibleBal, setIsModalVisibleBal] = useState(false);

	const showModalBal = (obj) => {
		if (props.selectedBranch) {
			setSingleCustomerObj(obj);
			let startDate = dateFormat(
				// new Date(props.startDate).setDate(new Date(props.startDate).getDate() - 1)
				new Date(0)
			);
			let endDate = dateFormat(new Date(props.endDate));
			props.getSingleCustomerEntries(
				insidePage,
				"",
				startDate,
				endDate,
				obj.customerId,
				props.selectedBranch,
				insidePageSize
			);
			setIsModalVisibleBal(true);
		}
	};

	// useEffect(() => {
	// 	if (Object.keys(singleCustomerObj).length) {
	// 		let startDate = dateFormat(
	// 			new Date(props.startDate).setDate(new Date(props.startDate).getDate() - 1)
	// 		);
	// 		let endDate = dateFormat(new Date(props.endDate));
	// 		props.getSingleCustomerEntries(
	// 			insidePage,
	// 			"",
	// 			startDate,
	// 			endDate,
	// 			singleCustomerObj.customerId,
	// 			props.selectedBranch,
	// 			insidePageSize
	// 		);
	// 	}
	// }, [
	// 	insidePageSize,
	// 	insidePage,
	// 	singleCustomerObj.customerId,
	// 	props.permission.branchCode,
	// 	singleCustomerObj,
	// 	props.selectedBranch
	// ]);

	const handleOkBal = () => {
		setIsModalVisibleBal(false);
	};

	const handleCancelBal = () => {
		setIsModalVisibleBal(false);
	};

	useEffect(() => {
		let startDate = dateFormat(new Date(0));
		let endDate = dateFormat(new Date(props.endDate));
		props.allCustomers();
	}, [
		props.selectedReports,
		props.searchBool,
		// props.startDate,
		// props.endDate,
		page,
		// props.searchEnter,
		// props.permission.branchCode,
		pageSize,
		// props.selectedBranch,
		// props.sortType
	]);
	console.log(props.reportsLoading);
	const [entriesData, setEntriesData] = useState([])

	useEffect(() => {
		setEntriesData([])
		console.log(props.customerSummaryEntries)
		setEntriesData(props.customerSummaryEntries)
	}, [props.customerSummaryEntries, pageSize])
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div
				style={{
					padding: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					autoFocus
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>}
						size="small"
						style={{
							width: 90,
						}}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{
							width: 90,
						}}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({
								closeDropdown: false,
							});
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<svg style={{
				color: filtered ? '#1677ff' : undefined,
			}} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>

		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current.select(), 100);
			}
		},
		render: (text) =>

			text
	});

	return (
		<div className="recieptReports">
			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="tableDiv">
						{props.getCustomerLoading ? (
							<div className="loaderDiv tableLoader">
								<div>
									<img src={logo} />
								</div>
							</div>
						) : null}
						<Table
							pagination={true}
							className="itemsTable customerSummaryTable"
							dataSource={props.allCustomersData}
							onRow={(record, rowIndex) => {
								return {
									onClick: (event) => {
										setData(record);
									} // click row
								};
							}}
						>
							<Column
								className="customerIdCol"
								title="Customer ID"
								key="customerId"
								dataIndex="customerId"
								filterDropdown={getColumnSearchProps('customerId').filterDropdown}
								filterIcon={getColumnSearchProps('customerId').filterIcon}
								onFilter={getColumnSearchProps('customerId').onFilter}
								onFilterDropdownOpenChange={getColumnSearchProps('customerId').onFilterDropdownOpenChange}
								sorter={(a, b) => Number(a.customerId.split('-')[2]) - Number(b.customerId.split('-')[2])}
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.customerId}</div>;
								}}
							/>
							<Column
								title="Customer Name"
								dataIndex="customerName"
								key="customerName"
								filterDropdown={getColumnSearchProps('customerName').filterDropdown}
								filterIcon={getColumnSearchProps('customerName').filterIcon}
								onFilter={getColumnSearchProps('customerName').onFilter}
								onFilterDropdownOpenChange={getColumnSearchProps('customerName').onFilterDropdownOpenChange}
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.customerName}</div>;
								}}
							/>
							{/* <Column
								title="Total Billed"
								dataIndex="totalBilled"
								key="totalBilled"
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.summaryObj.totalAmount}</div>;
								}}
							/>
							<Column
								title="Advance"
								dataIndex="Advance"
								key="Advance"
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.summaryObj.advance}</div>;
								}}
							/>
							<Column
								title="Discount"
								key="discount"
								dataIndex="discount"
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.summaryObj.discount}</div>;
								}}
							/>
							<Column
								title="Payment Recieved"
								key="paymentRecieved"
								dataIndex="paymentRecieved"
								render={(text, record) => {
									return <div onClick={() => showModal()}>{record.summaryObj.paymentRecieved}</div>;
								}}
							/> */}
							<Column
								className="balanceTd"
								title="balance"
								key="balance"
								dataIndex="balance"
								sorter={(a, b) => a.balance - b.balance}
								// sortDirections={['descend']}
								render={(text, record) => {
									return (
										<button
											onClick={() => {
												showModalBal(record);
											}}
											className="balanceButton"
											style={record.balance < 0 ? { background: "#07c949" } : record.balance > 0 ? { background: "#fb2525" } : { background: "#1890ff" }}
										>
											{record.balance}
										</button>
									);
								}}
							/>
						</Table>
					</div>
				</Col>
			</Row>
			<CustomerSumModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				showModal={showModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				singleCustomerObj={data}
			/>
			<CustomerBalanceModal
				isModalVisibleBal={isModalVisibleBal}
				setIsModalVisibleBalance={setIsModalVisibleBal}
				showModalBal={showModalBal}
				handleOkBal={handleOkBal}
				handleCancelBal={handleCancelBal}
				setInsidePage={setInsidePage}
				insidePage={insidePage}
				insidePageSize={insidePageSize}
				setInsidePageSize={setInsidePageSize}
			/>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		customerSummaryEntries: store.reports.customerSummaryEntries
			? store.reports.customerSummaryEntries
			: [],
		page: store.reports.page,
		total: store.reports.total,
		reportsLoading: store.reports.reportsLoading,
		permission: store.users.permission,
		getCustomerLoading: store.customers.getCustomerLoading,

	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getCustomerSummaryEntry,
			getSingleCustomerEntries,
			allCustomers
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerSummary));
