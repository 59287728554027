import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, DatePicker, message, Row, Select } from "antd";
import { Table, Tag, Space } from "antd";
import Items from "./entries";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { editSingleMembership } from "../../modules/actions/customerReportActions";
import { dateFormat } from "../../common/common";
import moment from "moment";
import { setMembership } from "../../modules/actions/customerActions";
const { Option } = Select;
const { Panel } = Collapse;
const { Column, ColumnGroup } = Table;

function AddMembershipModel(props) {
	const [membershipDate, setMembershipDate] = useState(new Date());
	const [membershipRate, setMembershipRate] = useState(5000);
	const [membershipType, setMembershipType] = useState("New Membership");
	const [membershipPayment, setMembershipPayment] = useState(0);
	const [membershipBalance, setMembershipBalance] = useState(
		Number(membershipRate) - Number(membershipPayment)
	);
	const [checkBoxAskForDiscount, setCheckBoxAskForDiscount] = useState(false);
	const [reasonForDiscount, setReasonForDiscount] = useState("");
	const [discountAmount, setDiscountAmount] = useState(0);
	const [branchCode, setBranchCode] = useState();
	const [branchCodeSelect, setBranchCodeSelect] = useState();
	const [ID, setID] = useState(0);


	const plainOptions = ["Discount"];

	function sameDayNextYear(d) {
		// Copy date
		var t = new Date(+d);
		// Get current day number
		var startDayNum = d.getDay();
		// Add one year
		t.setFullYear(t.getFullYear() + 1);
		// Move to closest day that is the same day number
		var diff = startDayNum - t.getDay();
		// If diff is more than 3 days, need to subtract rather than add
		diff = diff > 3 ? diff - 7 : diff;
		t.setDate(t.getDate() + diff);
		console.log(t);
		return t;
	}

	useEffect(() => {
		setBranchCode(props.permission.branchCode)
		setBranchCodeSelect(props.permission.branchCode)
	}, [props.permission.branchCode])

	const setMemArr = () => {
		console.log('setMemArr')
		props.setAllowPrint(true)
		let obj = {
			membershipType: membershipType,
			membershipPayment: Number(membershipPayment),
			membershipRate: Number(membershipRate),
			customerId: props.memeberReceptData.customerId,
			membershipBalance: membershipBalance,
			startDate: membershipDate ? dateFormat(membershipDate) : dateFormat(new Date()),
			entryDate: membershipDate ? dateFormat(membershipDate) : dateFormat(new Date()),
			endDate: membershipDate ? dateFormat(sameDayNextYear(membershipDate)) : dateFormat(new Date()),
			discountApproved:
				props.permission.userType.toLowerCase() == "admin" ? "Approved" : "InProcess",
			discountAmount: Number(discountAmount),
			discountReason: reasonForDiscount,
			branchCode: props.permission.userType.toLowerCase() == "admin" ? branchCodeSelect : branchCode,
			entryNo: "2342342342342342",
			membershipNo: "0",


		};
		console.log("objj", props.memeberReceptData)
		props.setMembership(obj);

		// props.setMemArr([...props.memArr, obj]);
		setMembershipDate(new Date());
		setMembershipRate(5000);
		setMembershipType("New Membership");
		setMembershipPayment(0);
		setMembershipBalance(Number(membershipRate) - Number(membershipPayment));
		setCheckBoxAskForDiscount(false);
		setReasonForDiscount("");
		setDiscountAmount(0);

		props.onCancel(clearState);
	};

	const updateMemArr = () => {
		console.log('updateMemArr')
		props.setAllowPrint(true)
		let obj = {
			membershipType: membershipType,
			membershipPayment: Number(membershipPayment),
			membershipRate: Number(membershipRate),
			membershipBalance: membershipBalance,
			customerId: props.editObj.customerId,
			entryNo: props.editObj.entryNo,
			membershipNo: props.editObj.membershipNo,
			branchCode: props.editObj.branchCode,
			startDate:
				props.editObj.startDate !== membershipDate
					? dateFormat(new Date(membershipDate))
					: dateFormat(new Date(membershipDate)),
			entryDate:
				props.editObj.entryDate !== membershipDate
					? dateFormat(new Date(membershipDate))
					: dateFormat(new Date(membershipDate)),
			endDate:
				props.editObj.startDate !== membershipDate
					? dateFormat(sameDayNextYear(membershipDate))
					: dateFormat(new Date(props.editObj.endDate)),
			discountAmount: Number(discountAmount),
			discountReason: reasonForDiscount,
			branchCode: props.permission.userType.toLowerCase() == "admin" ? branchCodeSelect : branchCode,
			discountApproved: "InProcess",

		};
		let arr = [...props.memArr];
		arr[props.editObj.index] = obj;
		props.setMemArr(arr);
		setMembershipDate(new Date());
		setMembershipRate(5000);
		setMembershipType("New Membership");
		setMembershipPayment(0);
		setMembershipBalance(5000);
		setCheckBoxAskForDiscount(false);
		setReasonForDiscount("");
		setDiscountAmount(0);

		if (props.fromReports) {
			console.log(props.editObj, 'props.editObj');
			props.editSingleMembership(
				ID,
				obj,
				props.page,
				props.permission.userType.toLowerCase() == "admin" ? branchCodeSelect : props.permission.branchCode
			);



			props.onCancel(clearState);
		} else {
			props.onCancel(clearState);
		}
	};

	useEffect(() => {
		if (props.editObj) {
			setMembershipDate(props.editObj.startDate);
			setMembershipRate(props.editObj.membershipRate);
			setMembershipType(props.editObj.membershipType);
			setMembershipPayment(props.editObj.membershipPayment);
			setMembershipBalance(props.editObj.membershipBalance);
			setCheckBoxAskForDiscount(props.editObj.discountAmount > 0);
			setReasonForDiscount(props.editObj.discountReason);
			setDiscountAmount(props.editObj.discountAmount);
			setID(props.editObj.id);
			setBranchCodeSelect(props.editObj.branchCode)
		}
	}, [props.editObj]);

	const clearState = () => {
		setMembershipDate(new Date());
		setMembershipRate(5000);
		setMembershipType("New Membership");
		setMembershipPayment(0);
		setMembershipBalance(5000);
		setCheckBoxAskForDiscount(false);
		setReasonForDiscount("");
		setDiscountAmount(0);
		setBranchCodeSelect('')
	};

	const dateFormatt = "YYYY/MM/DD";
	let today = new Date();
	let defDate = `${today.getFullYear()}/${Number(today.getMonth() + 1).toString().length > 1
		? Number(today.getMonth() + 1)
		: "0" + Number(today.getMonth() + 1)
		}/${today.getDate().toString().length > 1 ? today.getDate() : "0" + today.getDate()}`;

	return (
		<Modal
			title={props.title}
			className="AddModal"
			style={props.style}
			visible={props.visible}
			onOk={() => {
				if (membershipBalance == 0) {
					if (!props.editObj) {
						// console.log('props.editObj das')
						setMemArr();
					} else {
						console.log('i got called')
						updateMemArr();
					}
				} else {
					message.error("Please enter Membership payment");
				}
			}}
			onCancel={() => props.onCancel(clearState)}
			okText={props.okText}
		>
			<div className="addMemMain">
				<div className="addMemInputMain">
					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
						<label>Date</label>
						<DatePicker
							defaultValue={moment(defDate, dateFormatt)}
							format={dateFormatt}
							onChange={(ev) => setMembershipDate(new Date(ev))}
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
						<label>Membership type</label>
						<Select
							onChange={(ev) => {
								setMembershipType(ev);
								if (ev === "Renewal") {
									setMembershipRate(3000);
									setMembershipBalance(
										Number(3000) - Number(membershipPayment) - Number(discountAmount)
									);
								} else {
									setMembershipRate(5000);
									setMembershipBalance(
										Number(5000) - Number(membershipPayment) - Number(discountAmount)
									);
								}
							}}
							value={membershipType}
							allowClear
						>
							<Option value="Renewal">Renewal</Option>
							<Option value="New Membership">New Membership</Option>
						</Select>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
						<label>Membership rate</label>
						<Input
							value={membershipRate}
							type="number"
							disabled
							size="large"
							placeholder="Membership rate"
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
						<label>Membership payment</label>
						<Input
							onChange={(ev) => {
								setMembershipPayment(ev.target.value);
								setMembershipBalance(
									Number(membershipRate) - Number(ev.target.value) - Number(discountAmount)
								);
							}}
							value={membershipPayment}
							type="number"
							size="large"
							placeholder="Membership payment"
						/>
					</Col>
					{props.permission.userType.toLowerCase() == "admin" ?
						<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
							<label>Select Branch</label>
							<Select
								onChange={(ev) => {
									setBranchCodeSelect(ev)
								}}
								value={branchCodeSelect}
								allowClear
							>
								<Option value="KHI">Karachi</Option>
								<Option value="PEW">Peshawar</Option>
							</Select>
						</Col>
						: null}
					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
						<label>Balance</label>
						<Input
							type="number"
							disabled
							size="large"
							value={membershipBalance}
							placeholder="Balance"
						/>
					</Col>

					<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign two">
						<Checkbox.Group
							onChange={(ev) => setCheckBoxAskForDiscount(ev.length ? true : false)}
							className="checkboxGroup"
							options={plainOptions}
						/>{" "}
						<span className="span">
							{checkBoxAskForDiscount &&
								props.permission.userType == "User" &&
								"(Will be approved by Admin)"}
						</span>
					</Col>

					{checkBoxAskForDiscount && (
						<>
							<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
								<label>Discount amount expected</label>
								<Input
									onChange={(ev) => {
										setDiscountAmount(ev.target.value);
										setMembershipBalance(
											Number(membershipRate) - Number(membershipPayment) - Number(ev.target.value)
										);
									}}
									type="number"
									size="large"
									placeholder="Discount amount expected"
									value={discountAmount}
								/>
							</Col>

							<Col xs={11} sm={11} md={11} lg={11} xl={11} className="centerAlign">
								<label>Reason for discount</label>
								<Input
									style={{ textTransform: "capitalize" }}
									value={reasonForDiscount}
									onChange={(ev) => setReasonForDiscount(ev.target.value)}
									type="text"
									size="large"
									placeholder="Reason for discount"
								/>
							</Col>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			editSingleMembership,
			setMembership
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AddMembershipModel);
