import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "antd";

const TotalModal = ({
	isModalVisible,
	setIsModalVisible,
	showModal,
	handleOk,
	collectionData,
	handleCancel
}) => {
	console.log(collectionData, "collectionData collectionData");
	return (
		<>
			<Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<table style={{ width: "100%" }}>
					<tbody>
						<tr>
							<th>Total Cash Collections:</th>
							<td>{collectionData.TotalCashRecieved}</td>
						</tr>
						<tr>
							<th>Advance:</th>
							<td>{collectionData.TotalAdvanceRecieved}</td>
						</tr>
						<tr>
							<th>Total Expenses:</th>
							<td>-({collectionData.TotalExpenses})</td>
						</tr>
						<tr>
							<th>Total Membership Collections:</th>
							<td>{collectionData.TotalMemberShipCollection}</td>
						</tr>

						<tr>
							<th>Total Recievables:</th>
							<td>{collectionData.TotalRecievable}</td>
						</tr>
						<tr>
							<th>Net Income</th>
							<td>
								{Number(collectionData.TotalCashRecieved) +
									Number(collectionData.TotalAdvanceRecieved) +
									Number(collectionData.TotalMemberShipCollection) -
									Number(collectionData.TotalExpenses)}
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>
		</>
	);
};

const mapStateToProps = ({ store }) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalModal);
