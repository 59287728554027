import Axios from 'axios'
import { ACCOUNT_LOADING, GET_EXPENSE, GET_ALL_EXPENSE, GET_ALL_NOTFICATION, ADD_ACCOUNT_LOADING } from '../actions/AccountActions';

const initialState = {
    accountLoading: false,
    expenses: false,
    allExpenses: false,
    notificationArr: false,
    addAccountLoader: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_LOADING:
            return {
                ...state,
                accountLoading: action.payload,
            };
        case GET_EXPENSE:
            return {
                ...state,
                expenses: action.payload.data,
            };
        case GET_ALL_EXPENSE:
            return {
                ...state,
                allExpenses: action.payload.data,
            };
        case GET_ALL_NOTFICATION:
            return {
                ...state,
                notificationArr: action.payload.data,
            };
            case ADD_ACCOUNT_LOADING:
                return {
                    ...state,
                    addAccountLoader: action.payload,
                };

            


        default:
            return state
    }
}

