import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../../Layout/Layout";
import TakeIn from "../TakeIn/index";
import TakeOut from "../TakeOut";
import CustomerReport from "../reports/customerReport/CustomerReport";
import ItemReport from "../reports/ItemReport/ItemReports";
import SummaryReport from "../reports/summaryReport/SummaryReport";
import userList from "../UserList/userList";
import AddAcount from "../addAccount/Account";
import Consultant from "../consultant/Consultant";
import Notification from "../adminPanel/notification/Notification";
import AddBranch from "./../adminPanel/Branch/Branch";
import AddUser from "./../adminPanel/User/User";
import AdminAccount from "./../adminPanel/Account/Account";
import DiamondGrading from "./../managerPanel/diamondGrading/diamondGrading";
import DiamondGradingFrom from "./../managerPanel/diamondGrading/diamondGradingForm";
import GemReport from "./../managerPanel/GemReport/GemReport";
import GemReportForm from "./../managerPanel/GemReport/gemReportForm";
import SmallJewellery from "./../managerPanel/smallJewellery/SmallJewellery";
import SmallJewelleryForm from "./../managerPanel/smallJewellery/SmallJewelleryForm";
import SmallGemstone from "./../managerPanel/smallGemstone/SmallGemstone";
import SmallGemstoneForm from "./../managerPanel/smallGemstone/SmallGemstoneForm";
import SmallGemstoneJewellery from "./../managerPanel/smallGemstoneJewellery/SmallGemstoneJewellery";
import SmallGemstoneJewelleryForm from "./../managerPanel/smallGemstoneJewellery/SmallGemstoneJewelleryForm";
import SmallDiamond from "./../managerPanel/smallDiamond/SmallDiamond";
import SmallDiamondForm from "./../managerPanel/smallDiamond/SmallDiamondForm";
import jewelleryReportForm from "../managerPanel/JewelleyReport/jewelleryReportForm";
import jewelleryReport from "../managerPanel/JewelleyReport/jewelleryReport";
import TakeOutPrint from "../TakeOut/print/Print";
import TakeInPrint from "../TakeIn/print/Print";
import smallGemPrint from "../managerPanel/smallGemstone/print";
import DGReportPage from "../managerPanel/GGI-Setting/diamond-grading-report-page/DGReportPage";
import labRate from "../managerPanel/GGI-Setting/labRate/labRate";
import EducationRate from "../managerPanel/GGI-Setting/educationRate/EducationRate";
import DisapprovedExchange from "../managerPanel/GemExchange/disapproved/DisapprovedExchange";
import ApprovedExchange from "../managerPanel/GemExchange/approved/ApprovedExchange";
import AllDemands from "../managerPanel/GemExchange/allDemand/AllDemand";
import AllStone from "../managerPanel/GemExchange/allStone/AllStone";
import masteredColor from "../managerPanel/masterColored/masteredColor";
import masterColoredForm from "../managerPanel/masterColored/masterColoredForm";
import AppraisalCertificateForm from "../managerPanel/appraisalCertificate/AppraisalCertificateForm";
import AppraisalCertificate from "../managerPanel/appraisalCertificate/AppraisalCertificate";
import AppraisalPrint from "../managerPanel/appraisalCertificate/print";
import Diamond from "../managerPanel/specialDealPanel/diamond";
import diamondCertificate from "../managerPanel/diamondGrading/certificate/print";
import gemStoneCertificate from "../managerPanel/GemReport/certificate/print";
import SmallJewPrint from "../managerPanel/smallJewellery/print";
import smallGemJewPrint from "../managerPanel/smallGemstoneJewellery/print";
import smallDiaPrint from "../managerPanel/smallDiamond/print";
import jeweReportPrint from "../managerPanel/JewelleyReport/print";
import masterColoredPrint from "../managerPanel/masterColored/print";
import { allCustomers } from "../../modules/actions/customerActions";
import LoginPageLayout from "../LoginAndSignup/LoginPageLayout";
import SignUp from "../LoginAndSignup/SignUp";
import { checkUser, getUserPermissions } from "../../modules/actions/authenticationActions";
import userPermissions from "../UserList/userPermissions";
import AddPrices from "../adminPanel/addPrices/AddPrices";
import Dashboard from "../dashboard/Dashboard";
import ManagerPanelLayout from "../managerPanel/ManagerPanelLayout/ManagerPanelLayout";
import ReportPanelMain from "../reports/ReportPanelMain/ReportPanelMain";
import AdminLayoutMain from "../adminPanel/AdminLayoutMain/AdminLayoutMain";
import AccountReport from "../reports/accountReport/AccountReport";
import logo from "./../../Images/Logo 2.png";
import "./index.scss";
import { getCookie } from "../../common/common";
import {
	getLoseStoneServicePrice,
	getDiamondCardPrice,
	getDiamondCertificatePrice,
	getStrandServicePrice,
	getJewelleryCeritificatePrice,
	getBelow500Price,
	getExtraService,
	getCVDTesting,
	getSelectStoneLot,
	getJewelleryCertificateLot,
	getSmallGemstonesLot,
	getLotApprasialCertificate
} from "../../modules/actions/AddPricesAction";
import { getAllFields } from "../../modules/actions/FieldsAction";
import { getColorFormData, getCutFormData, getCuttingStyleFormData, getGridlesFormData, getObjectsFormData, getOrigionGemFormData, getRemarksFormData, getShapesFormData, getSpeciesFormData, getVarietysFormData } from "../../modules/actions/FormValues";
import SummaryDetailDaily from "../reports/summaryReport/SummaryDetailDaily";

require("dotenv").config();

function App(props) {
	useEffect(() => {
		props.checkUser(props.permission.branchCode);
	}, []);

	useEffect(() => {
		if (props.user) {
			props.getUserPermissions(props.user.id, props.permission.branchCode);
		}
	}, [props.isAuthenticated]);

	useEffect(() => {
		if (props.isAuthenticated) {
			props.allCustomers(props.permission.branchCode);
			props.getLoseStoneServicePrice();
			props.getDiamondCardPrice();
			props.getDiamondCertificatePrice();
			props.getStrandServicePrice();
			props.getJewelleryCeritificatePrice();
			props.getBelow500Price();
			props.getExtraService();
			props.getCVDTesting();
			props.getAllFields();
			props.getColorFormData();
			props.getCutFormData();
			props.getSpeciesFormData();
			props.getCuttingStyleFormData();
			props.getOrigionGemFormData();
			props.getGridlesFormData();
			props.getRemarksFormData();
			props.getVarietysFormData();
			props.getShapesFormData();
			props.getObjectsFormData();
			props.getSelectStoneLot();
			props.getJewelleryCertificateLot();
			props.getSmallGemstonesLot();
			props.getLotApprasialCertificate();
		}
	}, [props.isAuthenticated]);

	useEffect(() => {
		if (!getCookie("authToken")) {
			let path = window.location.pathname;
			if (path != "/") {
				window.location.pathname = "/";
			}
		}
		//  = '/'
	}, []);
	// debugger;
	return (
		<>
			{props.authenticationLoading ? (
				<div className="loaderDiv">
					<div>
						<img src={logo} />
						<h1>GALAXY GEMOLOGICAL INSTITUTE</h1>
					</div>
				</div>
			) : !props.isAuthenticated ? (
				<>
					<Route exact path="/" component={LoginPageLayout} />
					<Route exact path="/SignUp" component={SignUp} />
				</>
			) : (
				<Layout>
					<Route exact path="/" component={Dashboard} />
					<Route exact path="/dashboard" component={Dashboard} />

					{props.userPermissions.taken_in && (
						<Route exact path="/take-in-items" component={TakeIn} />
					)}

					{props.userPermissions.take_out && (
						<Route exact path="/take-out-items" component={TakeOut} />
					)}

					{props.userPermissions.customer_report_view && (
						<Route exact path="/customer-list" component={CustomerReport} />
					)}

					<Route exact path="/item-list" component={ItemReport} />

					{props.userPermissions.customer_report_view && (
						<Route exact path="/summary-report" component={SummaryReport} />
					)}
					{props.userPermissions.customer_report_view && (
						<Route exact path="/summary-report/daily/:month/:year/:branchCode" component={SummaryDetailDaily} />
					)}


					<Route exact path="/user-report" component={userList} />
					<Route exact path="/user-permissions" component={userPermissions} />
					<Route exact path="/add-account" component={AddAcount} />
					<Route exact path="/consultant" component={Consultant} />
					<Route exact path="/add-Branch" component={AddBranch} />
					<Route exact path="/add-user" component={AddUser} />
					<Route exact path="/admin-account" component={AdminAccount} />
					<Route exact path="/add-prices" component={AddPrices} />
					<Route exact path="/admin-panel" component={AdminLayoutMain} />
					<Route exact path="/manager-account" component={ManagerPanelLayout} />
					<Route exact path="/report-panel" component={ReportPanelMain} />
					<Route exact path="/diamond-grading" component={DiamondGrading} />
					<Route exact path="/diamond-grading-form" component={DiamondGradingFrom} />
					<Route exact path="/gemstone-report" component={GemReport} />
					<Route exact path="/gemstone-report-form" component={GemReportForm} />
					<Route exact path="/small-jewellery" component={SmallJewellery} />
					<Route exact path="/small-jewellery-form" component={SmallJewelleryForm} />
					<Route exact path="/small-gemstone" component={SmallGemstone} />
					<Route exact path="/small-gemstone-form" component={SmallGemstoneForm} />
					<Route exact path="/small-gemstone-jewellery" component={SmallGemstoneJewellery} />
					<Route
						exact
						path="/small-gemstone-jewellery-form"
						component={SmallGemstoneJewelleryForm}
					/>
					<Route exact path="/small-diamond" component={SmallDiamond} />
					<Route exact path="/small-diamond-form" component={SmallDiamondForm} />
					<Route exact path="/master-colored-stone" component={masteredColor} />
					<Route exact path="/master-colored-stone-form" component={masterColoredForm} />
					<Route exact path="/appraisal-certificate" component={AppraisalCertificate} />
					<Route exact path="/appraisal-certificate-form" component={AppraisalCertificateForm} />
					<Route exact path="/jewellery-report" component={jewelleryReport} />
					<Route exact path="/jewellery-report-form" component={jewelleryReportForm} />
					<Route exact path={`/Take-Out-Print/:id`} component={TakeOutPrint} />
					<Route exact path={`/Take-In-Print/:id`} component={TakeInPrint} />
					<Route exact path="/certificates/small-gems-print" component={smallGemPrint} />
					<Route exact path="/Diamond-Grading-Report-Page" component={DGReportPage} />
					<Route exact path="/lab-rate-list" component={labRate} />
					<Route exact path="/education-rate-list" component={EducationRate} />
					<Route exact path="/disapproved-gem-exchange" component={DisapprovedExchange} />
					<Route exact path="/approved-gem-exchange" component={ApprovedExchange} />
					<Route exact path="/all-demands" component={AllDemands} />
					<Route exact path="/all-stone" component={AllStone} />
					<Route exact path="/special-deal-diamond" component={Diamond} />
					<Route exact path="/certificates/diamond-grading" component={diamondCertificate} />
					<Route exact path="/certificates/Gemstone" component={gemStoneCertificate} />
					<Route exact path="/certificates/small-jewellery-print" component={SmallJewPrint} />
					<Route
						exact
						path="/certificates/small-gemstone-jewellery-print"
						component={smallGemJewPrint}
					/>
					<Route exact path="/certificates/small-diamond-print" component={smallDiaPrint} />
					<Route exact path="/certificates/jewellery-report-print" component={jeweReportPrint} />
					<Route
						exact
						path="/certificates/master-colored-report-print"
						component={masterColoredPrint}
					/>
					<Route exact path="/account-report" component={AccountReport} />
					<Route exact path="/certificates/appraisal-report-print" component={AppraisalPrint} />
				</Layout>
			)}
		</>
	);
}

const mapStateToProps = (store) => {
	return {
		isAuthenticated: store.authentication.isAuthenticated,
		user: store.authentication.user,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission,
		authenticationLoading: store.authentication.authenticationLoading
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			allCustomers,
			checkUser,
			getUserPermissions,
			getLoseStoneServicePrice,
			getDiamondCardPrice,
			getDiamondCertificatePrice,
			getStrandServicePrice,
			getJewelleryCeritificatePrice,
			getBelow500Price,
			getExtraService,
			getCVDTesting,
			getAllFields,
			getColorFormData,
			getCutFormData,
			getSpeciesFormData,
			getCuttingStyleFormData,
			getOrigionGemFormData,
			getGridlesFormData,
			getRemarksFormData,
			getVarietysFormData,
			getShapesFormData,
			getObjectsFormData,
			getSelectStoneLot,
			getJewelleryCertificateLot,
			getSmallGemstonesLot,
			getLotApprasialCertificate
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(App);
