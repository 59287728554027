import { message } from "antd";
import Select from "rc-select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBranch } from "../../modules/actions/branchActions";
import { addUserObj } from "../../modules/actions/userAction";
import "./login.scss";

function SignUp(props) {
	const [userName, setUserName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [city, setCity] = useState("KHI");
	const [userType, setUserType] = useState("User");

	const redirectLogin = () => {
		props.history.push("/");
	};
	useEffect(() => {
		props.getBranch();
	}, []);

	const userAdd = () => {
		if (password === password2) {
			let obj = {
				email: email,
				first_name: firstName,
				last_name: lastName,
				username: userName,
				password: password,
				is_superuser: true,
				is_staff: true,
				is_active: false
			};
			let userPermissions = {};
			userPermissions = props.userStore.userPermissions;
			userPermissions.branchCode = city;
			userPermissions.userType = userType;
			props.addUserObj(
				obj,
				redirectLogin,
				userPermissions,
				city,
				"Admin Will Approve Your Account"
			);
		} else {
			message.error(`password doesn't matched`);
		}
	};

	// const login = () => {
	//     let obj = {
	//         "username": userEmail,
	//         "password": userPassword
	//     }
	//     props.addUserObj(obj, redirectLogin, false, branchCode)
	// }

	const pushToNext = () => {
		props.history.push("/dashboard");
	};

	// window.addEventListener("keyup", (e)=>{
	//     if(e.keyCode === 13){
	//         login()
	//     }
	// })
	// useEffect(() => {
	// }, [])

	return (
		<div className="limiter">
			<div className="container-login100">
				<div className="wrap-login100">
					<span className="login100-form-title p-b-10">Galaxy Gemological</span>
					<span className="login100-form-title p-b-48">
						<img className="logo" src={require("./images/Logo.png")} />
					</span>

					<div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setUserName(e.target.value)}
							className="input100"
							type="text"
							name="text"
						/>
						<span className="focus-input100" data-placeholder="User Name"></span>
					</div>

					<div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setFirstName(e.target.value)}
							className="input100"
							type="text"
							name="text"
						/>
						<span className="focus-input100" data-placeholder="First Name"></span>
					</div>
					<div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setLastName(e.target.value)}
							className="input100"
							type="text"
							name="text"
						/>
						<span className="focus-input100" data-placeholder="Last Name"></span>
					</div>

					<div className="wrap-input100 validate-input" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setEmail(e.target.value)}
							className="input100"
							type="email"
						/>
						<span className="focus-input100" data-placeholder="Email"></span>
					</div>

					<div className="wrap-input100 validate-input" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setPassword(e.target.value)}
							className="input100"
							type="password"
							name="pass"
						/>
						<span className="focus-input100" data-placeholder="Password"></span>
					</div>
					<div className="wrap-input100 validate-input" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<input
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setPassword2(e.target.value)}
							className="input100"
							type="password"
							name="pass"
						/>
						<span className="focus-input100" data-placeholder="Confirm Password"></span>
					</div>

					<div className="wrap-input100 validate-input signupSelect" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<select
							onKeyUp={(e) => {
								if (e.keyCode === 13) {
									if (
										userName &&
										firstName &&
										lastName &&
										email &&
										password &&
										password2 &&
										city &&
										userType
									) {
										userAdd();
									} else {
										message.error("All Fields are Required");
									}
								}
							}}
							onChange={(e) => setCity(e.target.value)}
							className="input100"
						>
							{props.branch &&
								props.branch.map((a, i) => {
									return <option value={a.branchCode}>{a.branchName}</option>;
								})}
						</select>
						<span className="focus-input100" data-placeholder="Select Your City"></span>
					</div>

					<div className="container-login100-form-btn">
						<div className="wrap-login100-form-btn">
							<div className="login100-form-bgbtn"></div>
							<button onClick={userAdd} className="login100-form-btn">
								Login
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		userStore: store.users,
		branch: store.branchStore.branch ? store.branchStore.branch : []
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addUserObj,
			getBranch
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
