import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "./../../../../Images/Gemstone-Report-2.jpg";
import "./print.scss";
import chart from "./../../../../Images/diamondCertificateImage1.jpg";
import diamondSize from "./../../../../Images/diamondCertificateImagejpg.jpg";
import diamondImage from "./../../../../Images/diamondCertificateImage2.jpg";
// import Feather from './../../../../Images/symbols/Feather.jpg'
// import crystal from './../../../../Images/symbols/Crystal.jpg'
import line from "./../../../../Images/symbols/line.png";
import signLogo from "./../../../../Images/Gemstone-Report-2.jpg";
import signature from "./../../../../Images/Sgnature.png";
import chip from "./../../../../Images/chip.jpg";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import { Spin, Space } from "antd";

import Bruise from "./../../images/Bruise.jpg";
import Cavity from "./../../images/Cavity.jpg";
import Chip from "./../../images/Chip.jpg";
import Cleavage from "./../../images/Cleavage.jpg";
import Cloud from "./../../images/Cloud.jpg";
import Crystal from "./../../images/Crystal.jpg";
import ExtraFacet from "./../../images/Extra Facet.jpg";
import Feather from "./../../images/Feather.jpg";
import GrainCenter from "./../../images/Grain Center.jpg";
import IndentedNatural from "./../../images/Indented Natural.jpg";
import InternalGraining from "./../../images/Internal Graining.jpg";
import Knot from "./../../images/Knot.jpg";
import LaserDrillHole from "./../../images/Laser Drill Hole.jpg";
import Natural from "./../../images/Natural.jpg";
import Needle from "./../../images/Needle.jpg";
import PinPoint from "./../../images/Pin Point.jpg";
import SurfaceGraining from "./../../images/Surface Graining.jpg";
import TwinningWisp from "./../../images/Twinning Wisp.jpg";
import { dateFunction } from "../../../../modules/actions/managerPanelActions";
import isoCertified from "./../../../../Images/isoCeritifiedR.PNG";

// import { dateFunctionB } from '../../../../modules/actions/managerPanelActions';

function diamondCertificate(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#capture")
			? document.querySelector("#capture").clientWidth
			: 0;
		let height = document.querySelector("#capture")
			? document.querySelector("#capture").clientHeight
			: 0;
		html2canvas(document.querySelector("#capture"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}
	const [PrintArr, setPrintArr] = useState({});
	useEffect(() => {
		setPrintArr(JSON.parse(localStorage.getItem("arr")));
	}, []);

	useEffect(() => {
		console.log(PrintArr, "PrintArr Of Test");
	}, [PrintArr]);

	useEffect(() => {
		QRCode.toDataURL(
			`https://ggilab.com/Certificate/${PrintArr.entryNo
				? PrintArr.entryNo
				: PrintArr.certificate_number
					? PrintArr.certificate_number
					: PrintArr.number
						? PrintArr.number
						: null}`
		)
			.then((url) => {
				setQrUrl(url);
			})
			.catch((err) => { });

		// With async/await
		const generateQR = async (text) => {
			try {
			} catch (err) { }
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, [PrintArr]);

	let keyToSymbolsArr = [
		{ img: Bruise, name: "Bruise", key: "key_symbol1" },
		{ img: Cavity, name: "Cavity", key: "key_symbol2" },
		{ img: Chip, name: "Chip", key: "key_symbol3" },
		{ img: Cleavage, name: "Cleavage", key: "key_symbol4" },
		{ img: Cloud, name: "Cloud", key: "key_symbol5" },
		{ img: Crystal, name: "Crystal", key: "key_symbol6" },
		{ img: ExtraFacet, name: "Extra Facet", key: "key_symbol7" },
		{ img: Feather, name: "Feather", key: "key_symbol8" },
		{ img: GrainCenter, name: "Grain Center", key: "key_symbol9" },
		{ img: IndentedNatural, name: "Indented Natural", key: "key_symbol10" },
		{ img: TwinningWisp, name: "Twinning Wisp", key: "key_symbol11" },
		{ img: InternalGraining, name: "Internal Graining", key: "key_symbol12" },
		{ img: Knot, name: "Knot", key: "key_symbol13" },
		{ img: LaserDrillHole, name: "Laser Drill Hole", key: "key_symbol14" },
		{ img: Natural, name: "Natural", key: "key_symbol15" },
		{ img: Needle, name: "Needle", key: "key_symbol16" },
		{ img: PinPoint, name: "Pin Point", key: "key_symbol17" },
		{ img: SurfaceGraining, name: "Surface Graining", key: "key_symbol18" }
	];

	const [keySymbolArr, setKeySymbolArr] = useState([]);
	useEffect(() => {
		let arr = [];
		Object.keys(PrintArr).map((a, i) => {
			if (a.includes("key_symbol")) {
				if (PrintArr[a]) {
					for (let j = 0; j < keyToSymbolsArr.length; j++) {
						if (keyToSymbolsArr[j].key === a) {
							arr.push({
								...keyToSymbolsArr[j],
								url: `${PrintArr[a]}`
							});
						}
					}
				}
			}
		});
		setKeySymbolArr(arr);
	}, [PrintArr]);

	useEffect(() => {
		console.log(keySymbolArr, "THis IS key symbol array");
	}, [keySymbolArr]);

	const lineBreak = (text) => {
		let str = text ? text : false;
		console.log(str);
		if (str) {
			let updatedStr = str.split("@@").join(" DouBleBreAk ").split("@").join(" SinGleBreAk ");
			return (
				<p>
					{updatedStr.split(" DouBleBreAk ").length
						? updatedStr.split(" DouBleBreAk ").map((a, i) => {
							return (
								<>
									{a.split(" SinGleBreAk ").length > 1 ? (
										a.split(" SinGleBreAk ").map((b, j) => {
											return (
												<>
													{b}
													<br />
												</>
											);
										})
									) : (
										<>
											{a}
											<br />
											<br />
										</>
									)}
								</>
							);
						})
						: null}
				</p>
			);
		}
	};

	return (
		<>
			{/* <div style={{
                display: capture ? "none" : "block"
            }} className="loader certificateLoader">
                <div className="innerLoader">
                    <Space>
                        <Spin size="large" />
                    </Space>
                    <h2 className="loadingText">Generating Certificate</h2>
                </div>
            </div> */}
			<div
				id="bigCertificate"
				style={{
					display: capture ? "none" : "flex",
					alignItems: "center"
					// justifyContent: "center",
				}}
			>
				<div
					id="capture"
					style={{
						width: "1200px",
						margin: "13px 8px 8px",
						margin: "13px 8px 8px 8px",
						display: "flex"
					}}
				>
					<div
						style={{
							width: "600px",
							textAlign: "center",
							marginTop: "8px",
							float: "left",
							borderRight: "1px solid darkblue",
							position: "relative"
						}}
						className="linedevClassName"
					>
						<div>
							<img onClick={() => console.log(props.permission)} src={logo} style={{ marginRight: "81px", width: "228px" }} />
							<p
								className="MsoNormal"
								style={{
									fontSize: "26px",
									marginLeft: "0px",
									marginLeft: "-66px",
									marginTop: "-4px"
								}}
							>
								<strong style={{ msoBidiFontWeight: "normal" }}>
									<span
										className="logoName"
										style={{
											fontSize: "28px",
											color: "#E00836"
										}}
									>
										<span onClick={() => console.log(PrintArr)}>
											Galaxy Gemological Institute <p></p>
										</span>
									</span>
								</strong>
							</p>
							<p
								style={{
									fontSize: "24px",
									color: "#000",
									fontFamily: "cleric",
									textTransform: "uppercase",
									fontWeight: "bold",
									marginRight: "83px",
									fontStyle: "italic"
								}}
							>
								DIAMOND GRADING REPORT
							</p>
							<table
								style={{
									marginLeft: 0,
									width: "80%",
									textAlign: "left",
									fontFamily: "arial",
									fontSize: "18px",
									marginTop: "10px",
									fontWeight: "bold"
								}}
								align="center"
								cellpadding="0"
								cellspacing="0"
							>
								<tbody>
									<tr style={{ height: "40px", fontSize: "18px" }}>
										<td>
											<strong>
												{dateFunction(
													PrintArr.entry_date != "0000-00-00"
														? PrintArr.entry_date
															? PrintArr.entry_date
															: PrintArr.eptyp_temp
														: PrintArr.date,
													true
												)}{" "}
											</strong>
										</td>
										<td> </td>
									</tr>
								</tbody>
							</table>
							<table
								style={{
									marginLeft: 0,
									textAlign: "left",
									fontFamily: "arial",
									fontSize: "18px",
									marginTop: "10px",
									fontWeight: "bold",
									color: "black"
								}}
								align="center"
								cellpadding="0"
								cellspacing="0"
							>
								<tbody>
									<tr className="tablerow_height">
										<td>No. </td>
										<td>
											<strong>
												{PrintArr.entryNo
													? PrintArr.entryNo
													: PrintArr.certificate_number
														? PrintArr.certificate_number
														: PrintArr.number
															? PrintArr.number
															: null}
											</strong>
										</td>
									</tr>
									<tr className="tablerow_height">
										<td>Shape &amp; Cut</td>
										<td>
											{PrintArr.cut && PrintArr.shape
												? `${PrintArr.shape} & ${PrintArr.cut} `
												: PrintArr.Shap_And_Cut}
										</td>
									</tr>

									<tr className="tablerow_height">
										<td>
											Measurements <span style={{ textTransform: "lowercase" }}>(mm)</span>
										</td>
										<td>
											{PrintArr.measurements} x {PrintArr.measurements2} x {PrintArr.measurements3}
										</td>
									</tr>

									<tr>
										<td style={{ width: "40%", height: "45px", fontSize: "20px" }} colspan="2">
											<strong>Grading Results</strong>
										</td>
									</tr>

									<tr className="tablerow_height">
										<td>Carat Weight</td>
										<td>
											{PrintArr.carat_weight} {PrintArr.weight_unit}
										</td>
									</tr>

									<tr className="tablerow_height">
										<td>Color</td>
										<td>{PrintArr.color}</td>
									</tr>

									<tr className="tablerow_height">
										<td>Clarity</td>
										<td>{PrintArr.clarity}</td>
									</tr>
									<tr className="tablerow_height">
										<td>Cut Grade</td>
										<td>{PrintArr.cut_grade}</td>
									</tr>
									<tr>
										<td style={{ width: "40%", height: "45px", fontSize: "20px" }} colspan="2">
											<strong>Finish</strong>
										</td>
									</tr>

									<tr className="tablerow_height">
										<td>Polish</td>
										<td>{PrintArr.polish}</td>
									</tr>

									<tr className="tablerow_height">
										<td>Symmetry</td>
										<td>{PrintArr.symmetry}</td>
									</tr>

									<tr className="fluorescence tablerow_height">
										<td>Fluorescence</td>
										<td>{PrintArr.flourescence} {PrintArr.flourescenceColor}</td>
									</tr>

									<tr className="tablerow_height">
										<td colspan="2">
											{" "}
											<span id="comment" style={{ height: "35px" }}>
												Comment
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<div style={{ height: "200px", width: "80%", margin: "0px auto 0px 0px" }}>
								<p
									align="justify"
									style={{
										fontSize: "18px",
										fontWeight: "bold",
										marginTop: "-5px",
										textTransform: "none"
									}}
								>
									{lineBreak(PrintArr.comment)}
									<br />{" "}
								</p>
							</div>
						</div>
						<div style={{ position: "fixed", bottom: "40px", width: "480px" }}>
							{" "}
							<p
								align="justify"
								style={{
									fontFamily: "arial",
									fontSize: "11px",
									textAlign: "left",
									marginBottom: "0px",
									padding: "0",
									marginRight: "0px"
								}}
							>
								GGI Galaxy Gemological Institute reports are issued under conditions and limitations
								stated on the reverse side of &nbsp;this report which form an integral part of this
								report.
							</p>
						</div>
					</div>

					<div
						style={{
							width: "624px",
							float: "left",
							marginTop: "33px",
							display: "flex",
							// alignItems: "center",
							// flexWrap: "wrap",
							justifyContent: "center",
							marginLeft: "22px"
						}}
						clear="all"
					>
						<div style={{ widows: "207px", float: "left" }}>
							<img src={chart} style={{ width: "260px" }} align="left" />
						</div>

						<div
							style={{
								float: "left",
								width: "222px",
								float: "left",
								marginLeft: "52px",
								marginTop: "36px",
								position: "relative"
							}}
						>
							{/* Working Here */}
							<img src={diamondSize} style={{ width: "280px" }} width="214" id="product_pic2" />
							<p
								style={{
									position: "absolute",
									right: "52px",
									fontSize: "11px",
									top: "2px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.table}%
							</p>
							<p
								style={{
									position: "absolute",
									right: "-32px",
									fontSize: "11px",
									top: "22px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.crown_angle}°
							</p>
							<p
								style={{
									position: "absolute",
									right: "-38px",
									fontSize: "11px",
									top: "59px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.pavilion_angle_d}°
							</p>
							<p
								style={{
									position: "absolute",
									right: "22px",
									fontSize: "11px",
									top: "70px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.pavilion_depth}%
							</p>
							<p
								style={{
									position: "absolute",
									right: "38px",
									fontSize: "11px",
									top: "55px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.depth}%
							</p>
							<p
								style={{
									position: "absolute",
									right: "41px",
									top: "130px",
									fontSize: "11px",
									fontWeight: "bold"
								}}
							>
								{PrintArr.culet ? PrintArr.culet : "None"}
							</p>
							<p
								style={{
									left: "0",
									top: "25px",
									position: "absolute",
									fontSize: "11px",
									fontWeight: "bold",
									width: "30px",
									lineHeight: "11px"
								}}
							>
								{PrintArr.gridle ? (
									<>
										{PrintArr.gridle}
										<br />
										-
										<br />
									</>
								) : (
									""
								)}
								{PrintArr.gridle2 ? (
									<>
										{PrintArr.gridle2}
										<br />
										-
										<br />
									</>
								) : (
									<></>
								)}
								{PrintArr.gridle3 ? <>{PrintArr.gridle3}</> : <></>}
							</p>

							<div
								style={{ width: "230px", float: "left", marginTop: "94px", position: "relative" }}
							>
								<img
									src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.product_pic
										? PrintArr.product_pic
										: PrintArr.product_pic2
											? PrintArr.product_pic2
											: PrintArr.product_pic3
										} `}
									align="left"
									id="product_pic"
									height="139.06"
									style={{ marginLeft: "-47px" }}
								/>
								<div
									className="verticaltext"
									align="center"
									style={{
										fontSize: "10px",
										position: "absolute",
										right: "-76px",
										top: "45px",
										fontWeight: "500",
										transform: "rotate(268.8deg)"
									}}
								>
									(Approx. Digital Image)
								</div>
							</div>
							<div
								style={{
									float: "left",
									fontSize: "16px",
									fontWeight: "bold",
									width: "324px",
									border: "1px dashed",
									marginTop: "16px",
									marginLeft: "-57px"
								}}
							>
								<p align="center" style={{ marginTop: "4px", fontFamily: "arial" }}>
									Key to Symbols
								</p>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										margin: "5px",
										flexWrap: "wrap"
									}}
								>
									{keySymbolArr.map((a, i) => {
										return (
											<div className="SmallSizeDiv" style={{ width: "50%" }}>
												{" "}
												<span align="left">
													<img src={a.img} width="10px" height="10px" />
													&nbsp;&nbsp;{a.name}
												</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div style={{ clear: "both" }}></div>

						<div style={{ position: "fixed", bottom: "30px", width: "474px" }}>
							<div style={{ width: "478px", height: "auto", margin: "0 auto", marginTop: "190px" }}>
								<div
									style={{
										width: "154px",
										height: "79px",
										float: "left",
										paddingBottom: "0px",
										marginLeft: "7px",
										marginBottom: "40px"
									}}
								>
									<img src={signLogo} style={{ width: "220px" }} />
								</div>
								<div
									style={{
										width: "200px",
										height: "auto",
										float: "right",
										textAlign: "center",
										paddingBottom: "0px",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										justifyContent: "flex-end"
									}}
								>
									<p
										style={{
											color: "#000",
											fontFamily: "arial",
											fontWeight: "bold",
											fontSize: "15px",
											marginTop: "-19px",
											textDecoration: "underline"
										}}
									>
										{" "}
										<img width="160px" src={signature} />
									</p>
									<div
										style={{
											width: "170px",
											height: "auto",
											textAlign: "center",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<p
											style={{
												color: "#000",
												fontWeight: "bold",
												fontSize: "20px",
												marginBottom: "-5px",
												marginTop: "-28px",
												fontFamily: "cleric",
												fontStyle: "italic"
											}}
										>
											Mohammad Ali
										</p>
										<label
											style={{
												color: "#000",
												fontFamily: "cleric",
												fontStyle: "italic",
												fontSize: "17px",
												marginTop: "0px",
												fontWeight: "bold"
											}}
										>
											Gemologist, GIA (USA)
										</label>
									</div>
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
							<div style={{ width: "452px", height: "auto", margin: "0 auto" }}>
								<div
									style={{
										width: "60px",
										height: "60px",
										float: "left",
										marginTop: "-5px",
										marginLeft: "7px",
										marginBottom: "30px"
									}}
								>
									<img src={chip} clclassNameass="hollograme" />
								</div>
								<div className="isoImage" style={{

									left: "143px",
									width: "88px",
									position: "absolute",
									bottom: "115px",
									transform: "translateZ(-1px)",



								}}>
									<img src={isoCertified} style={{ width: "100%" }} />
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
							<div
								style={{
									width: "474px",
									height: "auto",
									margin: "0 auto 0 27px",
									paddingTop: "10px",
									display: "flex",
									justifyContent: "space-around"
								}}
							>
								<div style={{ width: "90px", height: "60px", float: "left", paddingRight: "50px" }}>
									<p
										style={{
											float: "left",
											marginTop: "-18px",
											position: "relative",
											transform: "rotate(270deg)",
											filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
											filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
											fontSize: "11px",
											fontWeight: "bold",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											marginLeft: "-70px"
										}}
									>
										{" "}
										<span
											style={{
												marginLeft: "25px",
												position: "absolute",
												top: "-8px",
												fontSize: "14px",
												fontWeight: "900"
											}}
										>
											Verification
										</span>{" "}
										<img
											src={qrUrl}
											alt="QR code"
											style={{ width: "120px", height: "120px", marginLeft: "50px" }}
										/>
									</p>
								</div>
								<div
									style={{
										width: "300px",
										height: "auto",
										float: "left",
										textAlign: "left",
										marginTop: "-16px",
										marginLeft: "20px"
									}}
								>
									<p className="MsoNormal" style={{ marginLeft: "0px", height: "16px" }}>
										<strong style={{ fontWeight: "normal" }}>
											<span
												style={{
													color: "#e00837",
													fontSize: "19px",
													fontFamily: "&quot,Bookman Old Style,quot,&quot,serif&quot",
													fontFamily: "&quot,Adobe Arabic,quot"
												}}
											>
												Galaxy Gemological Institute <p></p>
											</span>
										</strong>
									</p>

									<img
										src={line}
										style={{
											marginTop: "-6px",
											height: "6px",
											width: "290px",
											position: "absolute"
										}}
									/>
									<p
										style={{
											color: "#000",
											fontFamily: "arial",
											fontSize: "12px",
											marginTop: "0px",
											fontWeight: "bold"
										}}
									>
										{props.permission.branchCode === "PEW" ? (
											<>
												2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar <br />
												Tel: (91) 256-8456,{" "}
												<b>
													<strong
														style={{
															fontWeight: "900",
															fontSize: "12px",
															textTransform: "lowercase"
														}}
													>
														www.ggilab.com
													</strong>

													<b></b>
												</b>
											</>
										) : (
											<>
												Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-e-Iraq, Saddar,
												Karachi <br />
												Tel: +92-21-35216044,{" "}
												<b>
													<strong
														style={{
															fontWeight: "900",
															fontSize: "12px",
															textTransform: "lowercase"
														}}
													>
														www.ggilab.com
													</strong>

													<b></b>
												</b>
											</>
										)}
									</p>
									<b>
										<b></b>
									</b>
								</div>
								<b>
									<b></b>
								</b>
							</div>
							<b>
								<b>
									<div style={{ clear: "both" }}></div>
								</b>
							</b>
						</div>
						<div style={{ clear: "both" }}></div>
					</div>
				</div>
			</div>
			<div id="div2"></div>
			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(diamondCertificate);
