import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row, Select } from 'antd';
import { Button, Collapse, Modal, Input, InputNumber, Checkbox, Table } from 'antd';

function AddRate({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    handleCancel }) {

    const { Option } = Select;
    const { Panel } = Collapse;
    const { Column, ColumnGroup } = Table;
    const { TextArea } = Input;


    return (
        <>

            <Modal title="Add Product" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Submit">
                <div className="addItemForm">
                    <div className="inputFields">
                        <label>
                            <h5>Main Category</h5>
                            <Select className="select w100P" defaultValue="Select One" >
                                <Option value="default">Select One</Option>
                                <Option value="colored stones">Colored Stones</Option>
                                <Option value="diamond certificate">Diamond Certificate</Option>
                                <Option value="diamond card">Diamond Card</Option>
                                <Option value="pearls">Pearls</Option>
                                <Option value="jewellery">Jewellery</Option>
                                <Option value="appraisal">Aoppraisal</Option>
                            </Select>
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Type of testing</h5>
                            <TextArea />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Non Member Rate *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Discount in % for Members *</h5>
                            <Input />
                        </label>
                    </div>
                    <div className="inputFields">
                        <label>
                            <h5>Dicount Rate *</h5>
                            <Input />
                        </label>
                    </div>

                </div>
            </Modal>
        </>
    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRate)