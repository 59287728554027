import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { Input, Space, DatePicker } from "antd";
import { Col, Row, Select, Table } from "antd";
const { Search } = Input;

const { Option } = Select;
const { Column } = Table;

const RecievableModal = (props) => {
	const [data, setData] = useState([
		{
			date: new Date().toDateString(),
			entryNo: "KHI-290898",
			name: "fardeen",
			discription: "paid",
			debit: 20000,
			credit: 5000,
			balance: 5000
		},
		{
			date: new Date().toDateString(),
			entryNo: "KHI-290898",
			name: "fardeen",
			discription: "paid",
			debit: 20000,
			credit: 5000,
			balance: 5000
		},
		{
			date: new Date().toDateString(),
			entryNo: "KHI-290898",
			name: "fardeen",
			discription: "paid",
			debit: 20000,
			credit: 5000,
			balance: 5000
		}
	]);
	return (
		<div>
			<Modal
				title="Basic Modal"
				className="RecievableModal"
				style={{ width: "85vw" }}
				visible={props.isModalVisible}
				onOk={props.handleOk}
				onCancel={props.handleCancel}
			>
				<div className="filterDiv">
					<Row className="">
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<Space direction="vertical" className="w100P">
								<DatePicker className="w100P" />
							</Space>
						</Col>
						<Col xs={4} sm={4} md={4} lg={4} xl={4}>
							<Space direction="vertical" className="w100P">
								<DatePicker className="w100P" />
							</Space>
						</Col>
					</Row>
				</div>
				<table className="itemsTable">
					<thead>
						<th>Date</th>
						<th>Entry No</th>
						<th>Name</th>
						<th>Discription</th>
						<th>Debit</th>
						<th>Credit</th>
						<th>Balance</th>
						<th>Action</th>
					</thead>
					<tbody>
						{data.map((a, i) => {
							return (
								<tr>
									<td>{a.date}</td>
									<td>{a.entryNo}</td>
									<td>{a.name}</td>
									<td>{a.discription}</td>
									<td>{a.debit}</td>
									<td>{a.credit}</td>
									<td>{a.balance}</td>
									<td>
										{" "}
										<a>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="#001529"
												className="bi bi-pencil-fill"
												viewBox="0 0 16 16"
											>
												<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
											</svg>
										</a>
										<a>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="rgb(245, 0, 87)"
												className="bi bi-trash-fill"
												viewBox="0 0 16 16"
											>
												<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
											</svg>
										</a>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<th>Total</th>
						<th></th>
						<th></th>
						<th></th>
						<th className="bordertop">2000</th>
						<th className="bordertop">50000</th>
						<th></th>
						<th></th>
					</tfoot>
				</table>
				{/* <Table className="itemsTable" dataSource={data}>
                    <Column title="Date" dataIndex="date" key="date" />
                    <Column title="Entry No" dataIndex="entryNo" key="entryNo" />
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column title="Discription" dataIndex="discription" key="discription" />
                    <Column title="Debit" dataIndex="debit" key="debit" />
                    <Column title="Credit" dataIndex="credit" key="credit" />
                    <Column title="Balance" dataIndex="balance" key="balance" />
                    <Column title="Action" render={() => {
                        return (
                            <>
                            
                            </>
                        )
                    }} />

                </Table> */}
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RecievableModal);
