import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, Col, Row, Upload, message, InputNumber, Checkbox } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}
const { Option } = Select;
const { TextArea } = Input;



function DGReportPage() {

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    const plainOptions = ['Discount'];



    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                setLoading(false),
                setImageUrl(imageUrl)
            );
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    return (
        <div className="DiamondGradingFrom">
            <div className="innerGradingForm">
                <Row className="spaceBetween">
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Logo</h6>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>QR Image</h6>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Small Logo Image</h6>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Scale Image</h6>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Diamond Image</h6>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </label>
                        </div>
                    </Col>


                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Report Name</h6>
                                <Input />
                            </label>
                        </div>
                    </Col>

                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Institute Name</h6>
                                <Input />
                            </label>
                        </div>
                    </Col>


                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Telephone No</h6>
                                <Input type="tel" />
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Address</h6>
                                <TextArea
                                    // value={value}
                                    // onChange={this.onChange}
                                    placeholder="Controlled autosize"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </label>
                        </div>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div className="inputDiv">
                            <label>
                                <h6>Comments</h6>
                                <TextArea
                                    // value={value}
                                    // onChange={this.onChange}
                                    placeholder="Controlled autosize"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </label>
                        </div>
                    </Col>
                </Row>
                <button className="saveChangeBtn">Save Changes</button>
            </div>
        </div>
    )

}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DGReportPage)