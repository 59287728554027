import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Loader.scss";

function Loader() {
	return (
		<div className="loaderDiv">
			<div className="spinner">
				<div className="double-bounce1"></div>
				<div className="double-bounce2"></div>
			</div>
		</div>
	);
}

const mapStateToProps = ({ store }) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
