import { message } from "antd";
import Axios from "axios";
import { getCookie } from "../../common/common";
import store from "../store";

// consultation loader true
function consultationLoadingBool(bool) {
	return (dispatch) => {
		dispatch(consultationLoading(bool));
	};
}
export const CONSULTATION_LOADING = "CONSULTATION_LOADING";
export const consultationLoading = (bool) => {
	return {
		type: CONSULTATION_LOADING,
		payload: bool
	};
};

// set consultation
export function setConsultation(obj, msgText, clearState, branchCode, openModal) {
	return (dispatch) => {
		dispatch(consultationLoadingBool(true));
		let token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/${obj.entryNo}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				clearState(true);
				dispatch(getSingleDataEntrySuccess(res.data));
				dispatch(consultationLoadingBool(false));
				// dispatch(getSingleDataEntry(res.data.entryNo,branchCode))
				message.success(msgText);
				openModal();
			})
			.catch(() => {
				dispatch(consultationLoadingBool(false));
			});
	};
}

//Get Single entry
export function getSingleDataEntry(id, branchCode) {
	return (dispatch) => {
		dispatch(consultationLoadingBool(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${id}/?`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				dispatch(consultationLoadingBool(false));
				if (res.data) {
					dispatch(getSingleDataEntrySuccess(res.data));
				} else {
					dispatch(getSingleDataEntrySuccess(false));
				}
			})
			.catch(() => {
				dispatch(consultationLoadingBool(false));
			});
	};
}
export function getSingleDataEntryfromid(id, branchCode) {
	return (dispatch) => {
		dispatch(consultationLoadingBool(true));
		let token = getCookie("authToken");
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${id}/?branchCode=${branchCode}`,
			headers: {
				Authorization: `JWT ${token}`
			}
		})
			.then((res) => {
				console.log(res.data,'sdadasdasdasd');
				dispatch(consultationLoadingBool(false));
				if (res.data) {
					dispatch(getSingleDataEntrySuccess(res.data));
				} else {
					dispatch(getSingleDataEntrySuccess(false));
				}
			})
			.catch(() => {
				dispatch(consultationLoadingBool(false));
			});
	};
}
export const GET_SINGLE_DATA_ENTRY = "GET_SINGLE_DATA_ENTRY";
export const getSingleDataEntrySuccess = (data) => ({
	type: GET_SINGLE_DATA_ENTRY,
	payload: { data }
});

// add consultation
export function addConsultation(obj, setIsModalVisible) {
	return (dispatch) => {
		dispatch(consultationLoadingBool(true));
		const token = getCookie("authToken");
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND_URL}consultation/`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Successfully made consultation");
				setIsModalVisible(true);
				dispatch(getSingleDataEntry(obj.relatedEntryNo));
				dispatch(consultationLoadingBool(false));
			})
			.catch(() => {
				dispatch(consultationLoadingBool(false));
			});
	};
}

// update consultation
export function updateConsultation(obj, setIsModalVisible) {
	return (dispatch) => {
		dispatch(consultationLoadingBool(true));
		const token = getCookie("authToken");
		Axios({
			method: "put",
			url: `${process.env.REACT_APP_BACKEND_URL}consultation/${obj.id}/`,
			headers: {
				Authorization: `JWT ${token}`
			},
			data: obj
		})
			.then((res) => {
				message.success("Successfully updated consultation");
				setIsModalVisible(true);
				dispatch(getSingleDataEntry(obj.relatedEntryNo));
				dispatch(consultationLoadingBool(false));
			})
			.catch(() => {
				dispatch(consultationLoadingBool(false));
			});
	};
}
