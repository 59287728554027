import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import signLogo from "./../../../Images/Gemstone-Report-2.jpg";
import signature from "./../../../Images/Sgnature.png";
import chip from "./../../../Images/chip.jpg";
import { Spin, Space } from "antd";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import line from "./../../../Images/line.png";
import stone from "./../../../Images/LG2100199.png";
import { dateFunction } from "../../../modules/actions/managerPanelActions";
import isoCertified from "./../../../Images/isoCeritifiedR.PNG";


function masterColoredPrint(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#capture").clientWidth;
		let height = document.querySelector("#capture").clientHeight;
		html2canvas(document.querySelector("#capture"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}

	const [PrintArr, setPrintArr] = useState({});
	useEffect(() => {
		setPrintArr(JSON.parse(localStorage.getItem("arr")));
	}, []);

	useEffect(() => {
		QRCode.toDataURL(
			`https://ggilab.com/Certificate/${PrintArr.entryNo
				? PrintArr.entryNo
				: PrintArr.certificate_number
					? PrintArr.certificate_number
					: PrintArr.number
						? PrintArr.number
						: null}`
		)
			.then((url) => {
				setQrUrl(url);
			})
			.catch((err) => { });

		// With async/await
		const generateQR = async (text) => {
			try {
			} catch (err) { }
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, [PrintArr]);

	// useEffect(() => {
	//     // var url = new URL(window.location);
	//     // let arr = url.searchParams.get("arr")
	//     // let arr2 = []
	//     // if (arr && JSON.parse(arr)) {
	//     //     arr2 = JSON.parse(arr)
	//     //     setPrintArr(arr2)
	//     // }
	//     setPrintArr(JSON.parse(localStorage.getItem('arr')))
	//     setTimeout(() => {

	//         localStorage.removeItem('arr')
	//     }, 1000);
	// }, [])

	const lineBreak = (text) => {
		let str = text ? text : false;
		console.log(str);
		if (str) {
			let updatedStr = str.split("@@").join(" DouBleBreAk ").split("@").join(" SinGleBreAk ");
			return (
				<p>
					{updatedStr.split(" DouBleBreAk ").length
						? updatedStr.split(" DouBleBreAk ").map((a, i) => {
							return (
								<>
									{a.split(" SinGleBreAk ").length > 1 ? (
										a.split(" SinGleBreAk ").map((b, j) => {
											return (
												<>
													{b}
													<br />
												</>
											);
										})
									) : (
										<>
											{a}
											<br />
											<br />
										</>
									)}
								</>
							);
						})
						: null}
				</p>
			);
		}
	};

	return (
		<>
			{/* <div style={{
				display: capture ? "none" : "block"
			}} className="loader certificateLoader">
				<div className="innerLoader">
					<Space>
						<Spin size="large" />
					</Space>
					<h2 className="loadingText">Generating Certificate</h2>
				</div>
			</div> */}
			<div
				id="bigCertificate"
				style={{
					display: capture ? "none" : "flex",
					alignItems: "center",
					marginLeft: "50px"
				}}
			>
				<div
					id="capture"
					style={{
						width: "1200px",
						margin: "13px 8px 8px",
						display: "flex",
						marginTop: "25px"
					}}
				>
					<div
						style={{
							width: "640px",
							textAlign: "center",
							marginTop: "8px",
							float: "left",
							borderRight: "1px solid darkblue",
							position: "relative"
						}}
						className="linedevClass"
					>
						<div>
							<img src={signLogo} style={{ marginRight: "81px", width: "228px" }} />
							{/* <!-- <p className="MsoNormal" style={{marginLeft:"0px",marginLeft:"-66px", marginTop:"-4px"}}"><strong style="mso-bidi-font-weight:normal"><span style="font-size:26px; font-family:&quot;Bookman Old Style&quot;,&quot;serif&quot;;mso-bidi-font-family:&quot;Adobe Arabic&quot;">Galaxy Gemological Institute <p></p></span></strong></p> --> */}
							<p
								style={{
									fontSize: "24px",
									color: "#000",
									fontFamily: "cleric",
									textTransform: "uppercase",
									fontWeight: "bold",
									marginRight: "83px",
									fontStyle: "italic",
									marginTop: "0px"
								}}
							>
								Master Colored Stone Report
							</p>

							<table
								style={{
									width: "100%",
									textAlign: "left",
									fontFamily: "arial",
									fontSize: "13px",
									marginTop: "10px",
									fontSize: "18px"
								}}
								align="center"
								cellpadding="0"
								cellspacing="0"
							>
								<tbody>
									<tr style={{ height: "40px", fontSize: "18px" }}>
										<td>
											<strong>
												{dateFunction(
													PrintArr.date
														? PrintArr.date
														: PrintArr.entry_date
															? PrintArr.entry_date
															: PrintArr.eptyp_temp,
													true
												)}{" "}
											</strong>
										</td>
										<td> </td>
									</tr>
									<tr style={{ width: "40%", height: "30px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Report No. .........................................................
										</td>

										<td>
											{PrintArr.entryNo
												? PrintArr.entryNo
												: PrintArr.certificate_number
													? PrintArr.certificate_number
													: PrintArr.number
														? PrintArr.number
														: null}
										</td>
									</tr>

									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Object.................................................................
										</td>
										<td>{PrintArr.object}</td>
									</tr>

									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Species...............................................................
										</td>
										<td
											className="td_val_big"
											style={{
												fontWeight: "700"
											}}
										>
											{PrintArr.species}
										</td>
									</tr>

									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Variety.................................................................
										</td>
										<td
											className="td_val_big"
											style={{
												fontWeight: "700"
											}}
										>
											{PrintArr.variety}
										</td>
									</tr>

									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Origin..................................................................
										</td>

										<td
											className="td_val_big"
											style={{
												fontWeight: "bold"
											}}
										>
											{PrintArr.origion_gem}
										</td>
									</tr>

									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Weight................................................................
										</td>
										<td>
											{PrintArr.carat_weight} {PrintArr.weight_unit}
										</td>
									</tr>
									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Measurements...................................................
										</td>
										<td style={{ width: "40%", height: "25px" }}>
											{PrintArr.measurements} x {PrintArr.measurements2} x {PrintArr.measurements3}
											{PrintArr.measurement_unit}
										</td>
									</tr>
									<tr style={{ width: "40%", height: "25px" }}>
										<td className="td_heading tablerow_height" style={{ fontWight: "bold" }}>
											Shape.................................................................
										</td>
										<td className="td_val_big">{PrintArr.shape}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Cutting Style......................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.cutting_style}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Color...................................................................
										</td>
										<td style={{ width: "40%", height: "25px" }}>{PrintArr.color}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Transparency.....................................................
										</td>
										<td style={{ width: "40%", height: "25px" }}>{PrintArr.transparency}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Color Grade........................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.color_grade}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Cut Grade............................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.cut_grade}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Clarity Grade.......................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.clarity_grade}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Enhancements....................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.Enhancements}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											RI..........................................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.RI}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Polariscope.........................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.Polariscope}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											UV Fluorescence................................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>LW: {PrintArr.uv_flourescence}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "25px" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										>
											Microscopic evidence........................................
										</td>

										<td style={{ width: "40%", height: "25px" }}>{PrintArr.micro_evidence}</td>
									</tr>

									<tr>
										<td
											style={{ width: "40%", height: "0px", textAlign: "justify" }}
											className="td_heading tablerow_height"
										// style={{ fontWight: "bold" }}
										></td>
									</tr>

									<tr>
										<td
											style={{
												width: "40%",
												height: "25px",
												textAlign: "justify",
												verticalAlign: "top"
												, textTransform: "none"
											}}
											colspan="2"
										>
											<strong>Comments:</strong>
											<br />
											{lineBreak(PrintArr.comment)}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
							<br />
							<div style={{ height: "4px", width: "85%" }}>
								<p
									align="justify"
									style={{ marginLeft: "46px", fontSize: "12px", fontWeight: "bold" }}
								></p>
							</div>
						</div>

						<div>
							<div style={{ position: "fixed", bottom: "40px", width: "480px" }}>
								{" "}
								<p
									align="justify"
									style={{
										fontFamily: "arial",
										fontSize: "11px",
										textAlign: "left",
										marginBottom: "0px",
										padding: "0",
										marginRight: "0px"
									}}
								>
									GGI Galaxy Gemological Institute reports are issued under conditions and
									limitations stated on the reverse side of &nbsp;this report which form an integral
									part of this report.
								</p>
							</div>
						</div>
					</div>

					<div
						style={{
							width: "550px",
							textAlign: "center",
							marginBottom: "0px",
							float: "right",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							alignContent: "center",
							justifyContent: "unset"
						}}
					>
						<div
							style={{
								height: "180px",
								marginLeft: "82px",
								marginTop: "0px",
								position: "relative",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<img
								src={`${process.env.REACT_APP_BACKEND_URL}media/${PrintArr.relatedEntryNo
									? PrintArr.product_pic
										? PrintArr.product_pic
										: PrintArr.product_pic2
											? PrintArr.product_pic2
											: PrintArr.product_pic3
									: PrintArr.product_pic
										? "PropertyPics/" + PrintArr.product_pic
										: PrintArr.product_pic2
											? "PropertyPics/" + PrintArr.product_pic2
											: "PropertyPics/" + PrintArr.product_pic3
									}`}
								style={{ marginTop: "37px", height: "160px", marginBottom: "31px" }}
								className="product_image"
							/>
							<div
								className="verticaltext"
								align="center"
								style={{
									// fontSize: "10px",
									// marginTop: "0px",
									// left: "193px",
									// position: "absolute",

									top: "113px",
									fontSize: "13px",
									transform: "rotate(270deg)",
									marginBottom: "-32px",
									marginLeft: "-50px"
								}}
							>
								(Approx. Digital Image)
							</div>
						</div>
						<style
							dangerouslySetInnerHTML={{
								__html:
									"\n\n \n\n\n\n\n table.grading_terms_tbl td {\n    border: 1px solid black !important;\n    border-collapse: collapse !important;\n\tborder-spacing: 0px !important;\n\t \n}\n\n \n\n"
							}}
						/>
						<h1
							style={{
								fontSize: "22px",
								color: "#000",
								fontFamily: "cleric",
								textTransform: "uppercase",
								fontWeight: "bold",
								fontStyle: "italic",
								marginTop: "30px"
							}}
						>
							<strong>GRADING TERMS</strong>
						</h1>
						<table
							style={{
								width: "100%",
								textAlign: "center",
								fontFamily: "arial",
								fontSize: "10px",
								marginTop: "10px",
								borderCollapse: "separate"
							}}
							className="grading_terms_tbl"
							align="center"
						>
							<tbody>
								<tr>
									<td width={10} rowSpan={7} style={{ fontSize: "17px" }}>
										<strong>
											{" "}
											<div style={{ transform: "rotate(-90deg)" }}>Grades</div>
										</strong>
									</td>
									<td width={92} style={{ fontSize: "17px" }}>
										<strong>Clarity</strong>
									</td>
									<td width={35} style={{ fontSize: "17px" }}>
										<strong>Color</strong>
									</td>
									<td width={61} style={{ fontSize: "17px" }}>
										<strong>Cut</strong>
									</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Loupe Clean</td>
									<td style={{ fontSize: "16px" }}>GEM</td>
									<td style={{ fontSize: "16px" }}>Excellent</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Eye Clean</td>
									<td style={{ fontSize: "16px" }}>AAA</td>
									<td style={{ fontSize: "16px" }}>Very Good</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Slightly Included</td>
									<td style={{ fontSize: "16px" }}>AA</td>
									<td style={{ fontSize: "16px" }}>Good</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Moderately Included</td>
									<td style={{ fontSize: "16px" }}>A</td>
									<td style={{ fontSize: "16px" }}>Fair</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Highiy Included</td>
									<td style={{ fontSize: "16px" }}>B</td>
									<td style={{ fontSize: "16px" }}>Poor</td>
								</tr>
								<tr>
									<td style={{ fontSize: "16px" }}>Severely Included</td>
									<td style={{ fontSize: "16px" }}>C</td>
									<td style={{ fontSize: "16px" }}>-</td>
								</tr>
							</tbody>
						</table>
						<br />
						<h1
							style={{
								fontSize: "22px",
								color: "#000",
								fontFamily: "cleric",
								textTransform: "uppercase",
								fontWeight: "bold",
								fontStyle: "italic",
								marginTop: "-10px"
							}}
						>
							<strong>INSTRUMENT USED</strong>
						</h1>
						<table
							style={{
								width: "75%",
								textAlign: "left",
								fontFamily: "arial",
								fontSize: "15px",
								marginTop: "0px"
							}}
							align="center"
						>
							<tbody>
								<tr>
									<td>
										<input type="checkbox" name="microscope" defaultChecked />
										<strong>Microscope</strong>
									</td>
									<td>
										<input type="checkbox" name="FTIR" defaultChecked />
										<strong>FTIR</strong>
									</td>
									<td>
										<input type="checkbox" name="UV" />
										<strong>UV-VIS/NIR</strong>
									</td>
								</tr>
								<tr>
									<td>
										<input type="checkbox" name="Refractometer" defaultChecked />{" "}
										<strong>Refractometer</strong>
									</td>
									<td>
										<input type="checkbox" name="Polariscope" defaultChecked />
										<strong>Polariscope</strong>
									</td>
									<td>
										<input type="checkbox" name="Lamp" defaultChecked />
										<strong>UV Lamp</strong>
									</td>
								</tr>
								<tr>
									<td>
										<input type="checkbox" name="XRF" />
										<strong>XRF</strong>
									</td>
									<td />
									<td />
								</tr>
							</tbody>
						</table>

						<div style={{ position: "fixed", bottom: "30px", width: "474px" }}>
							<div
								style={{
									width: "478px",
									height: "auto",
									margin: "0 auto",
									marginTop: "190px"
								}}
							>
								<div
									style={{
										width: "154px",
										height: "79px",
										float: "left",
										paddingBottom: "0px",
										marginLeft: "7px",
										marginBottom: "40px"
									}}
								>
									<img src={signLogo} style={{ width: "220px" }} />
								</div>
								<div
									style={{
										width: "200px",
										height: "auto",
										float: "right",
										textAlign: "center",
										paddingBottom: "0px",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										justifyContent: "flex-end"
									}}
								>
									<p
										style={{
											color: "#000",
											fontFamily: "arial",
											fontWeight: "bold",
											fontSize: "15px",
											marginTop: "-19px",
											textDecoration: "underline"
										}}
									>
										{" "}
										<img width="160px" src={signature} />
									</p>

									<div
										style={{
											width: "170px",
											height: "auto",
											textAlign: "center",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<p
											style={{
												color: "#000",
												fontWeight: "bold",
												fontSize: "20px",
												marginBottom: "-5px",
												marginTop: "-28px",
												fontFamily: "cleric",
												fontStyle: "italic"
											}}
										>
											Mohammad Ali
										</p>
										<label
											style={{
												color: "#000",
												fontFamily: "cleric",
												fontStyle: "italic",
												fontSize: "17px",
												marginTop: "0px",
												fontWeight: "bold"
											}}
										>
											Gemologist, GIA (USA)
										</label>
									</div>
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
							<div style={{ width: "452px", height: "auto", margin: "0 auto" }}>
								<div
									style={{
										width: "60px",
										height: "60px",
										float: "left",
										marginTop: "-5px",
										marginLeft: "7px",
										marginBottom: "30px"
									}}
								>
									<img src={chip} className="hollograme" />
								</div>
								<div className="isoImage" style={{

									left: "143px",
									width: "88px",
									position: "absolute",
									bottom: "115px",
									transform: "translateZ(-1px)",



								}}>
									<img src={isoCertified} style={{ width: "100%" }} />
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
							<div
								style={{
									width: "474px",
									height: "auto",
									margin: "0 auto 0 27px",
									paddingTop: "10px",
									display: "flex",
									justifyContent: "space-around"
								}}
							>
								<div
									style={{
										width: "90px",
										height: "60px",
										float: "left",
										paddingRight: "50px"
									}}
								>
									<p
										style={{
											float: "left",
											marginTop: "-18px",
											position: "relative",
											transform: "rotate(270deg)",
											filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
											filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
											fontSize: "11px",
											fontWeight: "bold",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											marginLeft: "-70px"
										}}
									>
										{" "}
										<span style={{ marginLeft: "25px", "position": "absolute", "top": "-8px", "fontSize": "14px", "fontWeight": "900" }}>Verification</span>{" "}
										{" "}
										<img
											src={qrUrl}
											alt="QR code"
											style={{ width: "120px", height: "120px", marginLeft: "50px" }}
										/>
									</p>
								</div>
								<div
									style={{
										width: "300px",
										height: "auto",
										float: "left",
										textAlign: "left",
										marginTop: "-16px",
										marginLeft: "20px"
									}}
								>
									<p className="MsoNormal" style={{ marginLeft: "0px", height: "16px" }}>
										<strong style={{ fontWeight: "normal" }}>
											<span
												style={{
													color: "#e00837",
													fontSize: "19px",
													fontFamily: "&quot,Bookman Old Style,quot,&quot,serif&quot",
													fontFamily: "&quot,Adobe Arabic,quot"
												}}
											>
												Galaxy Gemological Institute <p></p>
											</span>
										</strong>
									</p>

									<img
										src={line}
										style={{
											marginTop: "-6px",
											height: "6px",
											width: "300px",
											position: "absolute"
										}}
									/>
									<p
										style={{
											color: "#000",
											fontFamily: "arial",
											fontSize: "12px",
											marginTop: "0px",
											fontWeight: "bold"
										}}
									>
										{props.permission.branchCode === "PEW" ? (
											<>
												2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar <br />
												Tel: (91) 256-8456,{" "}
												<b>
													<strong
														style={{
															fontWeight: "900",
															fontSize: "12px",
															textTransform: "lowercase"
														}}
													>
														www.ggilab.com
													</strong>
													<b></b>
												</b>
											</>
										) : (
											<>
												Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-
												<span style={{ textTransform: "lowercase" }}>e</span>-Iraq, Saddar, Karachi{" "}
												<br />
												Tel: +92-21-35216044,{" "}
												<b>
													<strong
														style={{
															fontWeight: "900",
															fontSize: "12px",
															textTransform: "lowercase"
														}}
													>
														www.ggilab.com
													</strong>
													<b></b>
												</b>
											</>
										)}
									</p>
									<b>
										<b></b>
									</b>
								</div>
								<b>
									<b></b>
								</b>
							</div>
							<b>
								<b>
									<div style={{ clear: "both" }}></div>
								</b>
							</b>
						</div>
						<b>
							<b></b>
						</b>
					</div>
					<b>
						<b></b>
					</b>
				</div>
			</div>
			<div id="div2"></div>
			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(masterColoredPrint);
