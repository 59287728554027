import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "antd";

const TotalModal = ({ isModalVisible, handleOk, handleCancel, TotalCollection }) => {
	return (
		<>
			<Modal
				title={`Report (Total Collection (${
					TotalCollection ? TotalCollection.TotalCashRecieved : "0"
				}) + ADVANCE (${TotalCollection ? TotalCollection.TotalAdvanceRecieved : "0"}))`}
				className="reportModal"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				style={{ width: "70vw" }}
			>
				<table className="totalTable" style={{ width: "100%" }}>
					<tr>
						<th onClick={() => {}}>
							<b>Total Cash Recieved: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalCashRecieved|| 0})` : `(0)`}</td>

						<th>
							<b>Total Cash Balance: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalCashBalance|| 0})` : `(0)`}</td>
					</tr>
					<tr>
						<th>
							<b>Total Advance Recieved: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalAdvanceRecieved|| 0})` : `(0)`}</td>
						<th>
							<b>Total Discount Given: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalDiscountGiven|| 0})` : `(0)`}</td>
					</tr>
					<tr>
						<th>
							<b>Total Recepit Generated: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalRecepitGenerated|| 0})` : `(0)`}</td>
						<th>
							<b>Total Recepit Pending: </b>{" "}
						</th>
						<td>{TotalCollection ? `(${TotalCollection.TotalRecepitPending|| 0})` : `(0)`} </td>
					</tr>
					<tr>
						<th>
							<b>Total Consultation: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalConsultation|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalConsultationAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Total Apprasial: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalApprasialCard|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalApprasialCardAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total Gemstone Cards: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalGemstoneCards|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalGemstoneCardsAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Total Gemstone Certificates: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalGemstoneCertificates|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalGemstoneCertificatesAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total Diamond Cards: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalDiamondCards|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalDiamondCardsAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Total Diamond Certificates: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalDiamondCertificates|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalDiamondCertificatesAmt|| 0 : "0"}/ -
						</td>
					</tr>

					<tr>
						<th>
							<b>Total Jewellery Card: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalJewelleryCard|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection.TotalJewelleryCardAmt|| 0}/ -
						</td>
						<th>
							<b>Total Jewellery Certificate: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalJewelleryCertificate|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection.TotalJewelleryCertificateAmt|| 0}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Gemstone Jewellery Card: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.GemstoneJewelleryCard|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.GemstoneJewelleryCardAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Gemstone Jewellery Certificate: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.GemstoneJewelleryCertificate|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.GemstoneJewelleryCertificateAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Gemstone Strand Card: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.GemstoneStrandCard || 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.GemstoneStrandCardAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Gemstone Strand Certificate: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.GemstoneStrandCertificate|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.GemstoneStrandCertificateAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Pearl Strand Card: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.PearlStrandCard|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.PearlStrandCardAmt|| 0 : "0"}/ -
						</td>
						<th>
							<b>Pearl Strand Certificate: </b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.PearlStrandCertificate|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.PearlStrandCertificateAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>CVD Testing (Lots):</b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.CVDTesting|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.CVDTestingAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total Mine:</b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalMine|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalMineAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total Seal:</b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalSeal|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalSealAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total Urgent:</b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalUrgent|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalUrgentAmt|| 0 : "0"}/ -
						</td>
					</tr>
					<tr>
						<th>
							<b>Total No Decision:</b>{" "}
						</th>
						<td>
							{TotalCollection ? `(${TotalCollection.TotalNoDecision|| 0})` : `(0)`} Rs.{" "}
							{TotalCollection ? TotalCollection.TotalNoDecisionAmt|| 0 : "0"}/ -
						</td>
					</tr>
				</table>
			</Modal>
		</>
	);
};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalModal);
