import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./layout.scss";

import "antd/dist/antd.css";

import { Layout, Menu, Avatar, Dropdown, Badge, Select } from "antd";
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined,
	VideoCameraOutlined,
	UploadOutlined,
	PlusSquareOutlined,
	LayoutOutlined,
	FileAddOutlined,
	DownSquareOutlined,
	UpSquareOutlined,
	UnorderedListOutlined,
	FileProtectOutlined,
	ProfileOutlined,
	DownOutlined,
	BellTwoTone
} from "@ant-design/icons";

import Logo from "../Images/Logo 2.png";
import { NavLink, withRouter } from "react-router-dom";
import Notifications from "./notifaction";
import { getPendingItems, userLogout } from "../modules/actions/authenticationActions";
import { RiDashboardFill, RiAdminFill } from "react-icons/ri";
import { FaCashRegister } from "react-icons/fa";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { HiDocumentReport } from "react-icons/hi";
import { GrUserManager } from "react-icons/gr";
import dashboard from "../assets/dashboard.svg";
import tekeOuticon from "../assets/box.svg";
import report from "../assets/profit-report.svg";
import account from "../assets/accounting.svg";
import user from "../assets/user.svg";
import bag from "../assets/businessman.svg";
import takeinIcon from "../assets/delivery.svg";
import setting from "../assets/settings.svg";
import { getBranch } from "../modules/actions/branchActions";

const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const menu = (props) => {
	return (
		<Menu>
			<Menu.Item>
				<a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
					{props.user.first_name} {props.user.last_name}
				</a>
			</Menu.Item>
			<Menu.Item>
				<a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
					{props.user.email}
				</a>
			</Menu.Item>
			{/* <Menu.Item disabled>
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    {props.user.user_type}
                </a>
            </Menu.Item> */}
			<Menu.Item
				onClick={() => {
					props.userLogout();
					window.location.href = ("/");
				}}
				danger
			>
				Logout
			</Menu.Item>
		</Menu>
	);
};
function Layoutt(props) {
	const [collapsed, setCollapsed] = useState(true);
	const [page, setPage] = useState();
	const [NotiOn, setNotiOn] = useState(false);
	const [certifcatePage, setCertifcatePage] = useState("");

	const [notiLength, setNotiLength] = useState(0);
	const [selectedBranch, setSelectedBranch] = useState("");
	useEffect(() => {
		if (props.userPermissions) {
			setSelectedBranch(props.userPermissions.branchCode)
		}
	}, [props.userPermissions])


	useEffect(() => {
		setNotiLength(Object.values(props.notificationArr).flat().length);
	}, [props.notificationArr]);

	useEffect(() => {
		setPage(window.location.pathname.slice(1).split("-").join(" "));
		let name = window.location.pathname.slice(1).split("-").join(" ");
		setPage(name);
	}, []);

	useEffect(() => {
		setCertifcatePage(props.history.location.pathname.split("/")[1]);
	}, [props]);

	function toggle() {
		setCollapsed(!collapsed);
	}

	useEffect(() => {
		props.getBranch(props.userPermissions.branchCode);
	}, [props.userPermissions.branchCode])
	const toggleStoneDrawer = () => {
		let div = document.getElementById("stoneRemain");
		div.classList.toggle("stoneRemainOpen");
		props.getPendingItems(selectedBranch);
	};

	useEffect(() => {
		if (selectedBranch) {
			props.getPendingItems(selectedBranch);
		}
	}, [selectedBranch]);

	return certifcatePage != "certificates" ? (
		<Layout>
			<Sider className="siderLay" trigger={null} collapsible collapsed={collapsed}>
				<div className="logo">
					<img src={Logo} /> {!collapsed && <h3>GGI Software</h3>}
				</div>
				<Menu theme="dark" mode="inline" defaultSelectedKeys={[page]}>
					{props.userPermissions.dashboard && (
						<Menu.Item
							onClick={() => setPage("dashboard")}
							key="dashboard"
							icon={<img className="layoutIcon" src={dashboard} />}
						>
							<NavLink className= {collapsed ? "": "tobeHide"} to="/dashboard">Dashboard</NavLink>
						</Menu.Item>
					)}

					{props.userPermissions.taken_in && (
						<Menu.Item
							onClick={() => setPage("take in items")}
							key="take in items"
							icon={<img className="layoutIcon" src={takeinIcon} />}
						>
							<NavLink className= {collapsed ? "": "tobeHide"}  to="/take-in-items">Taken In Item</NavLink>
						</Menu.Item>
					)}

					{props.userPermissions.take_out && (
						<Menu.Item
							onClick={() => setPage("take out items")}
							key="take out items"
							icon={<img className="layoutIcon" src={tekeOuticon} />}
						>
							<NavLink className= {collapsed ? "": "tobeHide"}  to="/take-out-items">Taken Out Items</NavLink>
						</Menu.Item>
					)}

					{props.userPermissions.reports && (
						<SubMenu
							key="sub1"
							icon={
								<img className="layoutIcon" src={report} />
								// <HiDocumentReport className="iconLayout" style={{ fontSize: '19px' }} onClick={() => props.history.push("/report-panel")} />
							}
							title={!collapsed && "Reports"}
						>
							{props.userPermissions.customer_view && (
								<Menu.Item
									onClick={() => setPage("customer list")}
									key="customer list"
									icon={<UserOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink  className= "tobeHide" to="/customer-list">Customer List</NavLink>
								</Menu.Item>
							)}
							<Menu.Item
								onClick={() => setPage("Item List")}
								key="Item List"
								icon={<UnorderedListOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink   className= "tobeHide"  to="/item-list">Item List</NavLink>
							</Menu.Item>

							{props.userPermissions.summary_report_view && (
								<Menu.Item
									onClick={() => setPage("Summary Report")}
									key="Summary Report"
									icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink  className= "tobeHide"  to="/summary-report">Summary Report</NavLink>
								</Menu.Item>
							)}

							{props.userPermissions.user_report_view && (
								<Menu.Item
									onClick={() => setPage("user report")}
									key="user report"
									icon={<UserOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink  className= "tobeHide"  to="/user-report">User Report</NavLink>
								</Menu.Item>
							)}

							{props.userPermissions.account_report_view && (
								<Menu.Item
									onClick={() => setPage("account report")}
									key="account report"
									icon={<ProfileOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink  className= "tobeHide"  to="/account-report">Account Report</NavLink>
								</Menu.Item>
							)}
						</SubMenu>
					)}

					{props.userPermissions.consultation && (
						<Menu.Item
							onClick={() => setPage("consultant")}
							key="consultant"
							icon={<HiDocumentReport className="iconLayout" style={{ fontSize: "23px" }} />}
						>
							<NavLink className= {collapsed ? "": "tobeHide"}  to="/consultant">Consultant</NavLink>
						</Menu.Item>
					)}

					{props.userPermissions.account && (
						<Menu.Item
							onClick={() => setPage("add account")}
							key="add account"
							icon={<img className="layoutIcon" src={account} />}
						>
							<NavLink className= {collapsed ? "": "tobeHide"}  to="/add-account">Add Account</NavLink>
						</Menu.Item>
					)}

					{props.userPermissions.admin_panel && (
						<SubMenu
							key="sub2"
							icon={<img className="layoutIcon" src={user} />}
							title={!collapsed && "Admin Panel"}
						>
							{props.userPermissions.admin_panel_add_prices_view && (
								<Menu.Item
									onClick={() => setPage("add prices")}
									key="add prices"
									icon={
										<UnorderedListOutlined className="iconLayout" style={{ fontSize: "19px" }} />
									}
								>
									<NavLink  className= "tobeHide"  to="/add-prices">Add Prices</NavLink>
								</Menu.Item>
							)}

							{props.userPermissions.admin_panel_add_branch_view && (
								<Menu.Item
									onClick={() => setPage("add branch")}
									key="add branch"
									icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink   className= "tobeHide" to="/add-branch">Add Branch</NavLink>
								</Menu.Item>
							)}

							{props.userPermissions.admin_panel_add_user_view && (
								<Menu.Item
									onClick={() => setPage("add user")}
									key="add user"
									icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink   className= "tobeHide"  to="/add-user">Add user</NavLink>
								</Menu.Item>
							)}

							{props.userPermissions.admin_panel_add_expense_name_view && (
								<Menu.Item
									onClick={() => setPage("admin account")}
									key="admin account"
									icon={<UserOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
								>
									<NavLink   className= "tobeHide"  to="/admin-account">Admin Account</NavLink>
								</Menu.Item>
							)}
						</SubMenu>
					)}

					{props.userPermissions.manager_panel && (
						<SubMenu
							key="sub3"
							icon={<img className="layoutIcon" src={bag} />}
							title={!collapsed && "Manager Panel"}
						>
							<Menu.Item
								onClick={() => setPage("diamond grading")}
								key="diamond grading"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink   className= "tobeHide"  to="/diamond-grading">Diamond Grading</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("gemstone report")}
								key="gemstone report"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink   className= "tobeHide"  to="/gemstone-report">Gemstone Report</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("small jewellery")}
								key="small jewellery"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/small-jewellery">Small Jewellery</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("small gemstone")}
								key="small gemstone"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/small-gemstone">Small Gemstone</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("small gemstone jewellery")}
								key="small gemstone jewellery"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/small-gemstone-jewellery">Small Gemstone Jewellery</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("small diamond")}
								key="small diamond"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/small-diamond">Small Diamond</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("jewellery report")}
								key="jewellery report"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/jewellery-report">Jewellery Report</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("master colored stone")}
								key="master colored stone"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/master-colored-stone">Master Colored Stone</NavLink>
							</Menu.Item>
							<Menu.Item
								onClick={() => setPage("appraisal certificate")}
								key="appraisal certificate"
								icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: "19px" }} />}
							>
								<NavLink  className= "tobeHide"  to="/appraisal-certificate">Appraisal Certificate</NavLink>
							</Menu.Item>

							{/* <SubMenu key="sub3-1" icon={<img className="layoutIcon setting" src={setting} />} title="GGI Setting">
                                <Menu.Item onClick={() => setPage("Diamond Grading Report Page")} key="Diamond Grading Report Page" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/Diamond-Grading-Report-Page">Diamond Grading Report Page</NavLink></Menu.Item>
                                <Menu.Item onClick={() => setPage("Lab Rate List")} key="Lab Rate List" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/lab-rate-list">Lab Rate List</NavLink></Menu.Item>
                                <Menu.Item onClick={() => setPage("Education Rate List")} key="Education Rate List" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/education-rate-list">Education Rate List</NavLink></Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub3-2" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />} title="Gems Exchange">
                                <Menu.Item onClick={() => setPage("Disapproved Gem Exchange")} key="Disapproved Gem Exchange" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/disapproved-gem-exchange">Disapproved Gem Exchange</NavLink></Menu.Item>
                                <Menu.Item onClick={() => setPage("Approved Gem Exchange")} key="Approved Gem Exchange" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/approved-gem-exchange">Approved Gem Exchange</NavLink></Menu.Item>
                                <Menu.Item onClick={() => setPage("All Demands")} key="All Demands" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/all-demands">All Demands</NavLink></Menu.Item>
                                <Menu.Item onClick={() => setPage("All Stones")} key="All Stones" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/ all-stone">All Stones</NavLink></Menu.Item>
                                <SubMenu key="sub3-2.1" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />} title="Special Deal Panel">
                                    <Menu.Item onClick={() => setPage("Diamond")} key="Diamond" icon={<FileProtectOutlined className="iconLayout" style={{ fontSize: '19px' }} />}><NavLink to="/special-deal-diamond">Special Deal Diamond</NavLink></Menu.Item>
                                </SubMenu>
                            </SubMenu> */}
						</SubMenu>
					)}
				</Menu>
			</Sider>
			<Layout id="site-layout" className="site-layout">
				<Header className="site-layout-background layoutHeader" style={{ padding: 0 }}>
					<div className="pageNameDiv">
						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: "trigger",
							onClick: toggle
						})}
						<h3>{page}</h3>
					</div>
					<div className="stoneRemain" id="stoneRemain">
						<div className="countDiv">
							{props.userPermissions.userType === "Admin" && (
								<Select
									value={selectedBranch}
									onChange={(e) => setSelectedBranch(e)}
									className="select w100P branchSelectStone"
								>
									{props.branch
										? props.branch.map((a, i) => {
											return <Option value={a.branchCode}>{a.branchName}</Option>;
										})
										: null}
								</Select>
							)}
							<div style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%"
							}}>

								<div>
									<h5>Pending Stones</h5>
									<p>{props.pendingObj.pendingStones}</p>
								</div>
								<div>
									<h5>Pending Reciepts</h5>
									<p>{props.pendingObj.pendingReceipt}</p>
								</div>
							</div>
						</div>
						<svg onClick={toggleStoneDrawer}
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-arrow-down-circle-fill"
							viewBox="0 0 16 16"
						>
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
						</svg>
					</div>
					<div className="avatarAvatar">
						<Avatar icon={<UserOutlined />} />
						<h5>
							{props.user.first_name} {props.user.last_name}
						</h5>
						<Dropdown overlay={menu(props)}>
							<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
								<DownOutlined />
							</a>
						</Dropdown>
						{props.userPermissions.admin_panel && (
							<Badge className="notiBadge" count={notiLength}>
								<div className="notificationsDiv">
									<button className="notiBellButn" onClick={() => setNotiOn(!NotiOn)}>
										{" "}
										<BellTwoTone />{" "}
									</button>
									<Notifications NotiOn={NotiOn} />
								</div>
							</Badge>
						)}
					</div>
				</Header>
				<Content
				id="site-layout-background"
					className="site-layout-background"
					style={{
						margin: "16px",
						padding: 24,
						minHeight: 280
					}}
				>
					{props.children}
				</Content>
			</Layout>
		</Layout>
	) : (
		<div>{props.children}</div>
	);
}

const mapStateToProps = (store) => {
	return {
		user: store.authentication.user,
		pendingObj: store.authentication.pendingObj ? store.authentication.pendingObj : {},
		isAuthenticated: store.authentication.isAuthenticated,
		userPermissions: store.authentication.userPermissions,
		notificationArr: store.accountStore.notificationArr,

		branch: store.branchStore.branch,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			userLogout,
			getPendingItems,
			getBranch
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layoutt));
