import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Space, Pagination, Popconfirm } from "antd";
import {
	EditOutlined,
	DeleteOutlined,
	CheckOutlined,
	CloseOutlined,
	PrinterFilled
} from "@ant-design/icons";
import { Modal, Button } from "antd";
import AddAccount from "../../../../addAccount/Account";
import { dateFormat } from "../../../../../common/common";
import { deleteAccountEntry } from "../../../../../modules/actions/reportsAction";
import { bindActionCreators } from "redux";

const { Column } = Table;

const LaboratoryExpenses = (props) => {
	// const [page, setPage] = useState(1)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [data, setData] = useState(false);
	// const [pageSize, setPageSize] = useState(10)
	const [updatedTotalExpense, setUpdatedTotalExpense] = useState([])

	const confirmDeleteItem = (obj) => {
		props.deleteAccountEntry(
			obj.id,
			props.page,
			props.selectedReports,
			props.startDate,
			props.endDate,
			props.tabName,
			"Expense",
			props.multiVal,
			props.permission.branchCode
		);
	};

	const editExpenseModel = (a) => {
		setData(a);
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	useEffect(()=>{
		console.log(props.footer)
	},[props.footer])

	return (
		<div className="LaboratoryExpenses">
			<Table className="itemsTable" dataSource={props.allAccountEntries} footer={() => {
				return (
					<Table className="itemsTable footertable" dataSource={[props.footer]}>
						<Column title="No. Data Entry" render={(text, record) => {
							return (
								<div>
									{props.total}
								</div>
							)
						}} />
						<Column title="Total Expense Amount" dataIndex="TotalAmount" key="TotalAmount" />
					</Table>
				)
			}}>
				<Column title="Date" dataIndex="date" key="date" />
				<Column title="Entry No" dataIndex="code" key="code" />
				<Column title="Expense Name" dataIndex="name" key="name" />
				<Column title="Sub Head" dataIndex="subHead" key="subHead" />
				<Column title="Description" dataIndex="description" key="description" />
				<Column title="Entered By" dataIndex="userName" key="userName" />
				<Column title="Amount" dataIndex="amount" key="amount" />
				<Column
					className="actions"
					title="Action"
					key="action"
					render={(text, record) => props.userPermissions.userType === "Admin" ? (
						<Space size="middle">
							<a>
								<svg
									onClick={() => editExpenseModel(record)}
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="#001529"
									className="bi bi-pencil-fill"
									viewBox="0 0 16 16"
								>
									<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
								</svg>
							</a>

							<Popconfirm
								onConfirm={() => confirmDeleteItem(record)}
								title="Are you sure you want to delete this expense?"
								okText="Yes"
								cancelText="No"
							>
								<a>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="rgb(245, 0, 87)"
										className="bi bi-trash-fill"
										viewBox="0 0 16 16"
									>
										<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
									</svg>
								</a>
							</Popconfirm>
						</Space>
					) : (
						null
					)
					}
				/>
			</Table>
			<Pagination
				onChange={(ev, size) => {
					props.setPageA(ev);
					props.setPageSizeA(size);
				}}
				pageSize={props.pageSizeA}
				current={props.page}
				total={props.total}
			/>
			<Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<AddAccount
					handleCancel={handleCancel}
					edit={true}
					data={data}
					page={props.pageA}
					selectedReports={props.selectedReports}
					startDate={dateFormat(new Date(props.startDate))}
					endDate={dateFormat(new Date(props.endDate))}
					tabName={props.tabName}
					type="Expense"
					multiVal={props.multiVal}
				/>
			</Modal>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		allAccountEntries: store.reports.allAccountEntries ? store.reports.allAccountEntries : [],
		page: store.reports.page,
		total: store.reports.total,
		footer: store.reports.footer,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			deleteAccountEntry
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(LaboratoryExpenses);
