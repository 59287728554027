import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'antd';
import { Table, Tag, Space } from 'antd';
const { Column, ColumnGroup } = Table;

const MemberShip = ({ isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    TotalMembershipCollection,
    collectionData,
    handleCancel }) => {

    let data = [
        {
            date: '3/22/2021',
            entryNo: 590140,
            membershipNo: "Saqib",
            startDate: '3/25/2021',
            endDate: '3/25/2022',
            toPay: 3000,
            recAmt: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            membershipNo: "Saqib",
            startDate: '3/25/2021',
            endDate: '3/25/2022',
            toPay: 3000,
            recAmt: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            membershipNo: "Saqib",
            startDate: '3/25/2021',
            endDate: '3/25/2022',
            toPay: 3000,
            recAmt: 3000
        },
        {
            date: '3/22/2021',
            entryNo: 590140,
            membershipNo: "Saqib",
            startDate: '3/25/2021',
            endDate: '3/25/2022',
            toPay: 3000,
            recAmt: 3000
        },

    ]

    useEffect(()=>{

        console.log("Helllooo TotalMembershipCollection", TotalMembershipCollection);

    },[TotalMembershipCollection])

    return (
        <>
            <Modal title="Basic Modal" className='reportModal' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Table className="itemsTable" dataSource={TotalMembershipCollection} footer={() => {
                    return (
                        <div className="expenseTotal">
                            <h5>TOTAL</h5>
                            <div className="spaceBetween">
                                <p><b>To Pay:</b> {collectionData.TotalMemberShipToPay}</p>
                                <p><b>Rec Amt:</b> {collectionData.TotalMemberShipRecievedAmt}</p>
                            </div>
                        </div>
                    )
                }} >
                    <Column title="Date" dataIndex="entryDate" key="entryDate" />
                    <Column title="Entry No." dataIndex="entryNo" key="entryNo" />
                    <Column title="Membership No" dataIndex="membershipNo" key="membershipNo" />
                    <Column title="Start Date" dataIndex="startDate" key="startDate" />
                    <Column title="End Date" dataIndex="endDate" key="endDate" />
                    <Column title="To Pay" dataIndex="membershipBalance" key="membershipBalance" />
                    <Column title="Rec Amt" dataIndex="membershipPayment" key="membershipPayment" />
                </Table>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberShip)